import { defineStore } from 'pinia'
import kardexApi from '@graphql/kardex'
import {
  ICompleteKardexProjectRequest,
  IGetItemRequest,
  IGetKardexProjectByIdRequest,
  IRetryOrderRequest
} from '@graphql/kardex/types'

export const useKardexStore = defineStore('kardex', {
  actions: {
    async getKardexProjectById(request: IGetKardexProjectByIdRequest) {
      const response = await kardexApi.getKardexProjectById(request)

      return response.data.data.getKardexProjectById
    },
    async getItem(request: IGetItemRequest) {
      const response = await kardexApi.getItem(request)

      return response.data.data.getItem
    },
    async retryOrder(request: IRetryOrderRequest) {
      const response = await kardexApi.retryOrder(request)

      return response.data.data.retryOrder
    },
    async completeKardexProject(request: ICompleteKardexProjectRequest) {
      const response = await kardexApi.completeKardexProject(request)

      return response.data.data.completeKardexProject
    }
  }
})
