import { computed } from 'vue'
import { useStore } from '@store/store'
import { IOutboundSortProject } from '@store/modules/projects/outbound-sort/types'
import { ID } from '@graphql/types'

export default () => {
  const store = useStore()

  const isActive = computed<boolean>(() => store.getters['projects/outbound-sort/isActive'])
  const itemCount = computed<number>(() => store.getters['projects/outbound-sort/itemCount'])
  const items = computed<IOutboundSortProject[]>(
    () => store.getters['projects/outbound-sort/items']
  )
  const activeItem = computed<IOutboundSortProject | null>(
    () => store.getters['projects/outbound-sort/activeItem']
  )

  const addProject = async (project: IOutboundSortProject) =>
    store.dispatch('projects/outbound-sort/addProject', project)

  const updateProject = async (project: IOutboundSortProject) =>
    store.dispatch('projects/outbound-sort/updateProject', project)
  const clearProjects = async () => store.dispatch('projects/outbound-sort/clearProjects')

  const removeProject = async (pickBatchId: ID) =>
    store.dispatch('projects/outbound-sort/removeProject', pickBatchId)

  return {
    isActive,
    itemCount,
    items,
    activeItem,
    clearProjects,
    addProject,
    updateProject,
    removeProject
  }
}
