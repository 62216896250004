import { useProjectInboundSortStore } from '@store/pinia/project-inbound-sort/store'
import { IProjectInboundSortItem } from '@store/pinia/project-inbound-sort/types'
import { computed } from 'vue'

export default () => {
  const store = useProjectInboundSortStore()

  const items = computed<IProjectInboundSortItem[]>(() => store.items)
  const activeItem = computed<IProjectInboundSortItem>(() => store.activeItem)
  const isActive = computed<boolean>(() => store.isActive)
  const itemCount = computed<number>(() => store.itemCount)

  const addProjectItem = async (item: IProjectInboundSortItem) => store.add(item)

  const updateProjectItem = async (item: IProjectInboundSortItem) => store.update(item)

  const removeProjectItem = async (item: IProjectInboundSortItem) => store.remove(item)

  const clearProjectItems = async () => store.clear()

  const createBreadcrumbs = (projectItem: IProjectInboundSortItem, currentPage: IBreadcrumb) => {
    const breadcrumbs: IBreadcrumb[] = [
      {
        href: '/goodsreceipt/',
        title: 'Goods receipts'
      },
      {
        href: `/goodsreceipt/${projectItem.goodsReceiptId}/`,
        title: `#${projectItem.goodsReceiptId}`
      },
      {
        href: `/inbound/sort/${projectItem.goodsReceiptId}/process/`,
        title: `Sort`
      },
      currentPage
    ]

    return breadcrumbs
  }

  return {
    isActive,
    items,
    activeItem,
    itemCount,
    addProjectItem,
    updateProjectItem,
    removeProjectItem,
    clearProjectItems,
    createBreadcrumbs
  }
}
