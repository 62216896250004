import { IAction } from '@components/actions'
import { f7 } from 'framework7-vue'
import { Router } from 'framework7/modules/router/router'
import { confirmYesNo } from '@/functions/dialog'

export default () => {
  const onActionClick = async (action: IAction) => {
    const requiresConfirmation = action['requiresConfirmation'] ?? false
    if (requiresConfirmation) {
      await confirmYesNo({
        title: `Are you sure that you want to perform this action?`,
        yesButtonCallback: async () => performAction(action)
      })
      return
    }

    await performAction(action)
  }

  const performAction = async (action: IAction) => {
    const options: Router.RouteOptions = {}
    if (action.props) {
      options.props = action.props
    }

    if (action.callback) {
      await action.callback()
    }

    if (action.href) {
      f7.views.main.router.navigate(action.href, options)
    }
  }

  return {
    onActionClick
  }
}
