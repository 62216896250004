<template>
  <page-with-search-subscriber :page-content="false" v-slot="{ searchSubscriber }">
    <app-navigation v-slot="{ searchId }">
      <search-bar
        :search-id="searchId"
        :scan-subscriber="searchSubscriber"
        :search-strategy="SearchStrategy.DefaultWithoutRouting"
        :search-type="TypeName.Location"
        :singular-result-callback="onResult"
        @no-result="onNoResult"
        expandable
      />
    </app-navigation>
    <f7-page-content>
      <f7-block-header>Load carrier #{{ id }}</f7-block-header>
      <f7-card>
        <scan-barcode-animation title="Scan location" />
      </f7-card>
    </f7-page-content>
  </page-with-search-subscriber>
</template>
<script setup lang="ts">
import AppNavigation from '@components/AppNavigation.vue'
import { ID } from '@graphql/types'
import ScanBarcodeAnimation from '@components/ScanBarcodeAnimation.vue'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'
import SearchBar from '@components/search/SearchBar.vue'
import { TypeName } from '@graphql/search/types'
import useSearch from '@composables/useSearch'
import { SearchStrategy } from '@services/search/search'
import useLoadCarrier from '@composables/useLoadCarrier'
import { confirmThat } from '@/functions/dialog'
import { f7 } from 'framework7-vue'
import { perceptibleToast } from '@services/perceptibleToast'

const props = defineProps<{ id: ID }>()

const { onNoResult } = useSearch()
const { moveToLocation } = useLoadCarrier()

const onResult = async (location: ILocation) => {
  confirmThat(
    `Confirm placement of load carrier #${props.id} on location ${location.name}`,
    async () => {
      await moveToLocation({ loadCarrierId: props.id, locationId: location.id })

      await perceptibleToast.success(
        `Placement of Load carrier #${props.id} on location ${location.name} succeeded!`
      )

      f7.views.main.router.navigate(`/load-carrier/${props.id}/`)
    }
  )
}
</script>
