<template>
  <page-with-search-subscriber v-slot="{ searchSubscriber }" :page-content="false">
    <app-navigation show-search-icon v-slot="{ searchId }" hide-back-button>
      <search-bar
        :search-id="searchId"
        :scan-subscriber="searchSubscriber"
        :search-strategy="SearchStrategy.DefaultWithoutRouting"
        @no-result="onNoResult"
        :search-callback="onSearch"
        expandable
      />
    </app-navigation>
    <f7-page-content>
      <f7-card :class="{ 'skeleton-text': isLoading }">
        <f7-list v-if="loadCarriers">
          <f7-list-item v-for="loadCarrier in loadCarriers" :key="loadCarrier.id">
            <template #header>
              <string-label :value="loadCarrier.id" />
            </template>
            <template #title>
              <string-label :value="loadCarrier.locationName" />
            </template>
            <template #default>
              <font-awesome-icon
                :icon="['far', getIndicatorIcon(loadCarrier)]"
                :class="getTaskContextClass(loadCarrier.picked)"
              />
            </template>
          </f7-list-item>
        </f7-list>
      </f7-card>
    </f7-page-content>
    <app-actions v-if="actions.length > 0" :actions="actions" @action-clicked="onActionClick" />
  </page-with-search-subscriber>
</template>
<script setup lang="ts">
import AppNavigation from '@components/AppNavigation.vue'
import { ID } from '@graphql/types'
import { ref, onMounted, computed, watch } from 'vue'
import useOutboundTransport from '@composables/useOutboundTransport'
import { IPickableLoadCarrier } from '@graphql/outbound/types'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'
import SearchBar from '@components/search/SearchBar.vue'
import { IOnSearchCallbackContext, SearchStrategy } from '@services/search/search'
import useSearch from '@composables/useSearch'
import useLoading from '@composables/useLoading'
import StringLabel from '@components/label/StringLabel.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import useContext from '@composables/useContext'
import useAction from '@composables/useAction'
import { IAction } from '@components/actions'
import AppActions from '@components/AppActions.vue'

const props = defineProps<{
  transportId: ID
}>()

const { isLoading, withAsyncLoading } = useLoading()
const { getPickableLoadCarriers, markLoadCarrierPicked, onFinalizeTransport } =
  useOutboundTransport()
const { onNoResult } = useSearch()
const { getTaskContextClass } = useContext()
const { onActionClick } = useAction()

const loadCarriers = ref<IPickableLoadCarrier[]>()
const actions = ref<IAction[]>([])

const todoLoadCarriers = computed(() =>
  loadCarriers.value?.filter((loadCarrier) => loadCarrier.picked === false)
)

const pickedLoadCarriers = computed(() =>
  loadCarriers.value?.filter((loadCarrier) => loadCarrier.picked)
)

const onSearch = async (context: IOnSearchCallbackContext) => {
  context.stopAfterCallback = true

  const loadCarrier = loadCarriers.value?.find((loadCarrier) => loadCarrier.id === context.query)

  if (loadCarrier) {
    await markLoadCarrierPicked({ loadCarrierId: loadCarrier.id })
    loadCarrier.picked = true

    loadCarrier.value.push(loadCarrier.value.splice(loadCarrier.value.indexOf(loadCarrier), 1)[0])
    return
  }

  await onNoResult(context.query)
}

const getIndicatorIcon = (loadCarrier: IPickableLoadCarrier) => {
  return loadCarrier.picked ? 'box-check' : 'square'
}

onMounted(async () => {
  await withAsyncLoading(async () => {
    loadCarriers.value = await getPickableLoadCarriers({ transportId: props.transportId })
  })
})

watch(pickedLoadCarriers, (value) => {
  if (value.length > 0 && todoLoadCarriers.value.length === 0) {
    actions.value.push({
      name: 'Finalize pick',
      callback: async () => await onFinalizeTransport(props.transportId)
    })
  }
})
</script>
