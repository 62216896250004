import { Module } from 'vuex'
import { IProjectOutboundSortState } from '@store/modules/projects/outbound-sort/types'
import { IRootState } from '@store/types'
import actions from '@store/modules/projects/outbound-sort/actions'
import mutations from '@store/modules/projects/outbound-sort/mutations'
import getters from '@store/modules/projects/outbound-sort/getters'

const state: IProjectOutboundSortState = {
  count: 0,
  itemCount: 0,
  title: 'Outbound sort',
  type: 'outbound-sort',
  items: [],
  isActive: false,
  link: '/project/outbound-sort/',
  activeItem: null
}

const projectSorting: Module<IProjectOutboundSortState, IRootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default projectSorting
