import { IWarehouse, IWarehouseZoneConnection } from '@graphql/warehouse/types'
import { useWarehouseStore } from '@store/pinia/warehouse/store'

export default function () {
  const store = useWarehouseStore()
  const getList = (): Promise<IWarehouse[]> => store.getList()
  const getWarehouseZones = (): Promise<IWarehouseZoneConnection> => store.getWarehouseZones()

  return {
    getList,
    getWarehouseZones
  }
}
