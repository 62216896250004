<template>
  <f7-popup :opened="popupOpened" @popup:closed="onPopupClosed" class="print-article-label-popup">
    <default-popup-header title="Print article label" />
    <f7-page>
      <print-article-labels-card
        :article="goodsReceiptLine.purchaseOrderLine.article"
        :print-amount="printAmount"
        :goods-receipt-line-id="goodsReceiptLine.id"
        @documents-printed="onPopupClosed"
      />
    </f7-page>
  </f7-popup>
</template>
<script setup lang="ts">
import DefaultPopupHeader from '@components/DefaultPopupHeader.vue'
import { IGoodsReceiptLine } from '@graphql/goodsReceipt/types'
import PrintArticleLabelsCard from '@pages/inbound/components/PrintArticleLabelsCard.vue'

withDefaults(
  defineProps<{
    popupOpened: boolean
    goodsReceiptLine: IGoodsReceiptLine
    printAmount: number
  }>(),
  {
    popupOpened: false
  }
)

const emit = defineEmits<{
  (e: 'popupClosed')
}>()

const onPopupClosed = async () => {
  emit('popupClosed')
}
</script>
