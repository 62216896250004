<template>
  <f7-card>
    <f7-card-content>
      <f7-list media-list>
        <f7-list-item class="bg-color-yellow no-margin" media-list>
          This article has no weight defined! Weigh the article and enter the weight (in Grams)
          below.
        </f7-list-item>
        <f7-list-item header="Weight in Grams (1 kilogram = 1000 grams)">
          <input-number
            v-if="!isLoading"
            :value="weightRef"
            :auto-focus="true"
            @change="onWeightChange"
          />
        </f7-list-item>
      </f7-list>
    </f7-card-content>
  </f7-card>
</template>
<script setup lang="ts">
import InputNumber from '@components/form/InputNumber.vue'
import { ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    weight: number
    isLoading?: boolean
  }>(),
  {
    isLoading: false
  }
)

const emit = defineEmits<{
  (e: 'weight-changed', value: number)
}>()

const weightRef = ref<number>(props.weight)
const onWeightChange = (value: number | Event) => {
  if (value instanceof Event) {
    //@ts-ignore
    value = Number(value.target.value)
  }

  weightRef.value = value

  emit('weight-changed', value)
}

watch(
  () => props.weight,
  (value) => {
    weightRef.value = value
  }
)
</script>
