<template>
  <f7-card :class="{ 'skeleton-text': isLoading }">
    <f7-card-content>
      <f7-list>
        <f7-list-input
          label="Location type"
          type="select"
          :value="locationTypeId"
          required
          error-message="Location type can't be empty..."
          @input="onLocationTypeSelect($event.target.value)"
          placeholder="Select a location type..."
        >
          <option
            :value="locationType.id"
            v-for="locationType in locationTypes"
            :key="locationType.id"
          >
            {{ locationType.name }}
          </option>
        </f7-list-input>
      </f7-list>
    </f7-card-content>
  </f7-card>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { ID } from '@graphql/types'
import { IWarehouseZone } from '@graphql/warehouse/types'
import { ILocationType } from '@graphql/location/types'
import useLocation from '@composables/useLocation'
import useLoading from '@composables/useLoading'

const { getLocationTypesByWarehouseZone } = useLocation()
const { isLoading, withAsyncLoading } = useLoading()

const locationTypeId = ref<ID | null>(null)
const locationTypes = ref<ILocationType[]>([])

const props = defineProps<{
  warehouseZone: IWarehouseZone
}>()

const emit = defineEmits<{
  (e: 'location-type-selected', selectedLocationType: ILocationType)
}>()

onMounted(async () => {
  await withAsyncLoading(async () => {
    locationTypes.value = await getLocationTypesByWarehouseZone({
      warehouseZoneId: props.warehouseZone.id
    })
  })
})

const onLocationTypeSelect = (value: ID) => {
  locationTypeId.value = value

  const locationType = locationTypes.value.find((type: ILocationType) => type.id === value)

  if (locationType) {
    emit('location-type-selected', locationType)
  }
}
</script>
