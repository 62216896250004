import pick from '@graphql/pick'
import {
  IAddLoadCarrierRequest,
  IAddLoadCarrierResponse,
  IGetLoadCarrierByIdRequest,
  IGetLocationToVisitResponse,
  IGetPickTaskRequest,
  ILoadCarrier,
  IPickBatchPartitionQueue,
  IPickBatchPartition,
  ISavePickBatchStateRequest,
  ISavePickBatchStateResponse,
  IStartPickProcessRequest,
  IStartKardexPickRequest
} from '@graphql/pick/types'
import {
  ICreateNewLoadCarrierRequest,
  IMarkPickedRequest,
  IMarkPickedResult,
  IOutboundPickProject,
  IProjectPickState,
  IUpdatePickBatchLineContext
} from '@store/modules/projects/pick/types'
import { IRootState } from '@store/types'
import { ActionTree } from 'vuex'
import { ID } from '@graphql/types'

const actions: ActionTree<IProjectPickState, IRootState> = {
  async addLoadCarrier(context, request: IAddLoadCarrierRequest): Promise<IAddLoadCarrierResponse> {
    const response = await pick.addLoadCarrier(request)

    return response.data.data
  },
  async getLoadCarrierById(
    context,
    request: IGetLoadCarrierByIdRequest
  ): Promise<Partial<ILoadCarrier>> {
    const response = await pick.getLoadCarrierById(request)

    return response.data.data.getLoadCarrierById
  },
  async addProject({ commit }, project: IOutboundPickProject) {
    await commit('addProject', project)
  },
  async getLocationToVisit(
    context,
    pickBatchPartitionId: ID
  ): Promise<IGetLocationToVisitResponse> {
    const response = await pick.getLocationToVisit(pickBatchPartitionId)

    return response.data.data
  },
  async getPickTask(
    context,
    request: IGetPickTaskRequest
  ): Promise<Partial<IPickBatchPartition | null>> {
    const response = await pick.getPickTask(request)

    return response.data.data.getPickTask
  },
  async markPicked(context, request: IMarkPickedRequest): Promise<IMarkPickedResult> {
    const response = await pick.markPicked(request)

    return response.data.data.markPicked
  },
  async removeProject({ commit }, pickBatchPartitionId: ID) {
    await commit('remove', pickBatchPartitionId)
  },
  async savePickBatchLineState(
    context,
    request: ISavePickBatchStateRequest
  ): Promise<ISavePickBatchStateResponse> {
    const response = await pick.savePickBatchLineState(request)

    return response.data.data
  },
  async startPickProject({ dispatch }, request: IStartPickProcessRequest) {
    const response = await pick.startPickProcess(request)

    const pickBatchPartition = response.data.data.startPickProcess

    const project: IOutboundPickProject = {
      selectedPickBatchPartition: pickBatchPartition
    }

    await dispatch('addProject', project)

    return response.data.data.startPickProcess
  },

  async startKardexPick({ dispatch, commit }, request: IStartKardexPickRequest) {
    const response = await pick.startKardexPick(request)

    const pickBatchPartition = response.data.data.startKardexPick.pickBatchPartition
    const kardexProject = response.data.data.startKardexPick

    const project: IOutboundPickProject = {
      selectedPickBatchPartition: pickBatchPartition,
      kardexProject: kardexProject
    }

    await dispatch('addProject', project)
    await commit('updatePrintedDocuments', true)

    return response.data.data.startKardexPick
  },

  async updatePickBatchLine({ commit }, context: IUpdatePickBatchLineContext) {
    await commit('updatePickBatchLine', context)
  },
  async updateProcessedLines({ commit }) {
    await commit('updateProcessedLines')
  },
  async updateProject({ commit }, payload: IOutboundPickProject) {
    await commit('updateProject', payload)
  },
  async updateSelectedQueue({ commit }, payload: IPickBatchPartitionQueue) {
    await commit('updateSelectedQueue', payload)
  },
  async removeProjects({ commit }) {
    await commit('removeProjects')
  },
  async updatePrintedDocuments({ commit }, printed: boolean) {
    await commit('updatePrintedDocuments', printed)
  },
  async getPickBatchPartitionQueues() {
    const response = await pick.getPickBatchPartitionQueues()

    return response.data.data.getPickBatchPartitionQueues
  },
  async createNewLoadCarrier(context, request: ICreateNewLoadCarrierRequest) {
    const response = await pick.createNewLoadCarrier(request)

    return response.data.data.createNewLoadCarrier
  }
}

export default actions
