import locationApi from '@graphql/location'
import { ILocation } from '@graphql/location/types'
import {
  IGetLocationByIdContext,
  IGetLocationTypesByWarehouseZoneRequest,
  ILocationState,
  ILocationSuggestionContext
} from '@store/modules/location/types'
import { IRootState } from '@store/types'
import { ActionContext, ActionTree } from 'vuex'

const actions: ActionTree<ILocationState, IRootState> = {
  async setActiveLocation(
    { commit }: ActionContext<ILocationState, IRootState>,
    location: ILocation
  ) {
    await commit('setActiveLocation', location)
  },

  async getById(
    { commit }: ActionContext<ILocationState, IRootState>,
    context: IGetLocationByIdContext
  ) {
    const response = await locationApi.getById(context)

    await commit('setActiveLocation', response.data.data.location)

    return response.data.data.location
  },

  async getSuggestions(
    { commit }: ActionContext<ILocationState, IRootState>,
    context: ILocationSuggestionContext
  ) {
    const response = await locationApi.getSuggestions(context)

    await commit('setLocationSuggestions', response.data.data.locationSuggestions)

    return response
  },

  async setSuggestions({ commit }, locations: ILocation[]) {
    await commit('setLocationSuggestions', locations)
  },

  async clearSuggestions({ commit }: ActionContext<ILocationState, IRootState>) {
    await commit('setLocationSuggestions', [])
  },

  async getLocationTypesByWarehouseZone(
    // eslint-disable-next-line no-empty-pattern
    {}: ActionContext<ILocationState, IRootState>,
    context: IGetLocationTypesByWarehouseZoneRequest
  ) {
    const response = await locationApi.getLocationTypesByWarehouseZone(context)

    return response.data.data.getLocationTypesByWarehouseZone
  }
}

export default actions
