<template>
  <page-with-search-subscriber v-slot="{ searchSubscriber }" :page-content="false">
    <navigation show-search-icon>
      <template #page-title>
        <string-label class="padding-right" value="Inbound receptions" />
      </template>
      <template #default="{ searchId }">
        <search-bar
          :search-id="searchId"
          :scan-subscriber="searchSubscriber"
          :search-type="TypeName.InboundReception"
          :search-strategy="SearchStrategy.DefaultWithoutRouting"
          :restricted="true"
          expandable
          @result="onClick"
        />
      </template>
    </navigation>
    <f7-page-content ptr :ptr-distance="50" ptr-mousewheel @ptr:refresh="onPullToRefresh">
      <pull-to-refresh-preloader />
      <f7-block-header class="margin-top-10">Receptions</f7-block-header>
      <f7-card v-if="receptions.length === 0 && !isLoading">
        <f7-card-content>
          <icon-with-text
            :icon="['fad', 'party-horn']"
            title="All work done!"
            description="Well done, all receptions completed"
          />
        </f7-card-content>
      </f7-card>

      <f7-card v-if="isLoading">
        <f7-card-content>
          <f7-preloader class="loader" />
        </f7-card-content>
      </f7-card>

      <data-table-card
        v-if="receptions.length > 0 && !isLoading"
        :items="receptions"
        :is-collapsible="true"
        :is-loading="isLoading"
      >
        <data-table>
          <template #header>
            <table-row>
              <label-cell>#</label-cell>
              <numeric-cell>Colli</numeric-cell>
              <label-cell>State</label-cell>
              <label-cell>Created At</label-cell>
              <label-cell>Updated At</label-cell>
            </table-row>
          </template>
          <table-row
            v-for="reception in receptions"
            :key="reception.id"
            @click="onClick(reception)"
          >
            <label-cell data-collapsible-title="#">{{ reception.internalReference }}</label-cell>
            <numeric-cell data-collapsible-title="Colli">{{ reception.colli.length }}</numeric-cell>
            <label-cell data-collapsible-title="State">
              <state-chip :color="getColorForState(reception.state)">{{
                reception.stateForHuman
              }}</state-chip>
            </label-cell>
            <label-cell data-collapsible-title="Created At">
              <date-time-label :value="reception.createdAt" />
            </label-cell>
            <label-cell data-collapsible-title="Updated At">
              <date-time-label :value="reception.updatedAt" to-relative />
            </label-cell>
          </table-row>
        </data-table>
      </data-table-card>
    </f7-page-content>
    <fab-with-loading-state :is-loading="isLoading" :on-click="onNewReceiptRegistration">
      <font-awesome-icon :icon="['far', 'plus']" />
    </fab-with-loading-state>
  </page-with-search-subscriber>
</template>
<script lang="ts" setup>
import Navigation from '@components/AppNavigation.vue'
import { IInboundReception } from '@graphql/inbound/types'
import { onMounted, ref } from 'vue'
import useLoading from '@composables/useLoading'
import useInbound from '@composables/useInbound'
import DataTable from '@components/data-table/DataTable.vue'
import DataTableCard from '@components/data-table/DataTableCard.vue'
import NumericCell from '@components/data-table/NumericCell.vue'
import LabelCell from '@components/data-table/LabelCell.vue'
import TableRow from '@components/data-table/TableRow.vue'
import DateTimeLabel from '@components/label/DateTime.vue'
import StateChip from '@components/chip/StateChip.vue'
import PullToRefreshPreloader from '@components/PullToRefreshPreloader.vue'
import SearchBar from '@components/search/SearchBar.vue'
import { TypeName } from '@graphql/search/types'
import FabWithLoadingState from '@components/FabWithLoadingState.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { f7 } from 'framework7-vue'
import { perceptibleToast } from '@services/perceptibleToast'
import StringLabel from '@components/label/StringLabel.vue'
import IconWithText from '@components/IconWithText.vue'
import { ReceptionState } from '@store/pinia/inbound/types'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'
import { SearchStrategy } from '@services/search/search'

const { isLoading, withAsyncLoading } = useLoading()
const { getReceptions, getColorForState, clearReception } = useInbound()

const receptions = ref<IInboundReception[]>([])

const getReceptionsWithLoadingState = async () => {
  await withAsyncLoading(async () => {
    receptions.value = await getReceptions()
  })
}

const onPullToRefresh = async (done: () => void) => {
  await getReceptionsWithLoadingState()

  done()
}

const onClick = async (inboundReception: IInboundReception) => {
  if (inboundReception.colli.length === 0) {
    await navigateToRegistrationPage(inboundReception)
    return
  }

  await onProcessReception(inboundReception)
}

const onProcessReception = async (inboundReception: IInboundReception) => {
  if (inboundReception.state === ReceptionState.COMPLETED) {
    await perceptibleToast.error(`Reception #${inboundReception.id} is already completed!`)
    return
  }
  f7.views.main.router.navigate(`/inbound/reception/${inboundReception.id}/process/`)
}

const onNewReceiptRegistration = async () => {
  await navigateToRegistrationPage()
}

const navigateToRegistrationPage = async (currentReception: IInboundReception | null = null) => {
  clearReception()
  f7.views.main.router.navigate('/inbound/reception/registration/', {
    props: {
      item: currentReception
    }
  })
}

onMounted(async () => {
  await getReceptionsWithLoadingState()
})
</script>
