<template>
  <f7-popup :opened="popupOpened" class="quantity-popup" @popup:closed="onPopupClose">
    <f7-navbar>
      <f7-nav-title>Bulk pick</f7-nav-title>
      <f7-nav-right>
        <f7-link popup-close>
          <f7-icon material="close" />
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-block>
      <f7-card>
        <f7-card-content>
          <f7-list media-list>
            <f7-list-item header="Quantity picked?">
              <input-article-quantity
                :article="article"
                :auto-focus="inputAutofocus"
                :max-value="maxValue"
                :value="currentValue ? currentValue : value"
                @change="onQuantityChange"
                @submit="onQuantityEntered"
              />
            </f7-list-item>
            <f7-list-item>
              <f7-button
                fill
                color="text-secondary"
                @click="onQuantityEntered"
                :disabled="confirmBulkPickByScan"
              >
                Confirm
              </f7-button>
            </f7-list-item>
            <f7-list-item
              class="bg-color-yellow no-margin"
              media-list
              v-show="confirmBulkPickByScan"
            >
              <div>
                Confirm the bulk pick by scanning the article barcode.<br />
                <i>Previous entered quantity will be replaced!</i>
              </div>
            </f7-list-item>
          </f7-list>
        </f7-card-content>
      </f7-card>
    </f7-block>
  </f7-popup>
</template>
<script lang="ts" setup>
import { IArticle } from '@/services/api/graphql/article/types'
import { ref, watch } from 'vue'
import { toast } from '@services/toast'
import InputArticleQuantity from '@components/form/InputArticleQuantity.vue'
import useArticle from '@composables/useArticle'

const { getStockQuantity } = useArticle()

const props = withDefaults(
  defineProps<{
    popupOpened: boolean
    value: number | string | null
    maxValue: number | string | null
    scannedItem: IArticle | null
    article: IArticle
    needsConfirmationScan?: boolean
  }>(),
  {
    value: null,
    maxValue: null,
    scannedItem: null,
    needsConfirmationScan: true
  }
)

const emits = defineEmits<{
  (e: 'popup-closed', payload: unknown): void
  (e: 'quantity-entered', payload: unknown): void
}>()

const currentValue = ref<number | null>(props.value > 0 ? props.value : null)
const inputAutofocus = ref(props.popupOpened)
const confirmBulkPickByScan = ref(false)

const validateQuantityEntered = (value: number | string | null) => {
  if (!value || value < 1) {
    throw Error('Please enter a quantity')
  }

  if (props.maxValue && value > props.maxValue) {
    const quantity = props.scannedItem
      ? getStockQuantity(props.scannedItem, props.maxValue)
      : props.maxValue
    throw Error(`Entered quantity exceeds maximum of ${quantity}`)
  }
}

const onQuantityEntered = () => {
  try {
    validateQuantityEntered(currentValue.value)

    if (props.needsConfirmationScan) {
      confirmBulkPickByScan.value = true

      return
    }
    emits('quantity-entered', currentValue.value)
  } catch (e: any) {
    toast.error(e.message).open()
    return
  }
}

const onPopupClose = () => {
  confirmBulkPickByScan.value = false

  emits('popup-closed', true)
}

watch(
  () => props.popupOpened,
  (value) => {
    inputAutofocus.value = value
  }
)

watch(
  () => props.scannedItem,
  (newValue, oldValue) => {
    if (oldValue === newValue) {
      return
    }

    if (!newValue) {
      return
    }

    if (!props.needsConfirmationScan) {
      return
    }

    if (confirmBulkPickByScan.value === false) {
      toast.error('Click on the confirm button before scanning the article barcode!').open()
      return
    }

    try {
      validateQuantityEntered(currentValue.value)
    } catch (e: any) {
      toast.error(e.message).open()
      return
    }

    emits('quantity-entered', currentValue.value)
  }
)

const onQuantityChange = (value: number) => {
  currentValue.value = value
}
</script>
