import { IGetByIdRequest, ITask, TaskState } from '@graphql/task/types'
import { useTaskStore } from '@store/pinia/task/store'
import { sleepWithBackoffFullJitter } from '@/utilities/generic'

export default () => {
  const store = useTaskStore()
  const getById = async (request: IGetByIdRequest) => store.getById(request)

  const executeTaskUntilDone = async (
    callback: () => Promise<ITask>,
    preStateText = 'Task',
    maxTries = 0
  ) => {
    let iterations = 0
    let task = await callback()
    const taskId = task.id

    do {
      iterations++

      task = await getById({ id: taskId })

      if (task.state === TaskState.FAILED.toString()) {
        throw Error(`${preStateText} failed!`)
      }

      if (task.state === TaskState.FINISHED.toString()) {
        return task
      }

      await sleepWithBackoffFullJitter(iterations * 1000)
    } while (maxTries === 0 || iterations < maxTries)

    throw new Error(`${preStateText} ended without reaching a final state, retry your action!`)
  }

  return {
    getById,
    executeTaskUntilDone
  }
}
