import { DefaultParser } from '@services/search/parser/defaultParser'
import LocationHeightScanParser from '@services/search/parser/locationHeightScanParser'
import { SearchResults } from '@store/modules/search/types'
import NfcTokenParser from '@services/search/parser/nfcTokenParser'
import { Spec1BarcodeParser } from '@services/search/parser/spec1BarcodeParser'

export interface IParser {
  parse(query: string): ParserResult

  supports(query: string): boolean
}

export class ParserResult {
  constructor(
    public readonly query: string,
    public readonly type: string,
    public readonly results: SearchResults = [],
    public readonly executeSearch: boolean = true,
    public readonly blocking = false,
    public readonly extraData: string[] = []
  ) {}

  isBlocking() {
    return this.blocking
  }
}

export class ParserFactory {
  private parsers: IParser[] = []
  private static instance: ParserFactory

  public static get getInstance() {
    if (!this.instance) {
      this.instance = new this()
    }

    return this.instance
  }

  public add(parser: IParser): void {
    this.parsers.push(parser)
  }

  public get(query: string): IParser {
    for (let i = 0; i < this.parsers.length; i++) {
      if (this.parsers[i].supports(query)) {
        return this.parsers[i]
      }
    }

    throw Error('No parser found for query: ' + query)
  }
}

const parserFactory = ParserFactory.getInstance
parserFactory.add(new LocationHeightScanParser())
parserFactory.add(new NfcTokenParser())
parserFactory.add(new Spec1BarcodeParser())
parserFactory.add(new DefaultParser())

export { parserFactory }
