import { Router } from 'framework7/framework7-types'
import OverviewPage from '@pages/kardex/OverviewPage.vue'

const routes: Partial<Router.RouteParameters>[] = [
  {
    path: '/kardex/:kardexProjectId/',
    component: OverviewPage
  }
]

export default routes
