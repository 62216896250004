<template>
  <div class="processable-item">
    <div class="tags-and-state">
      <div class="tags">
        <f7-chip class="chip-small" v-for="tag in processableItem.tags" :key="tag">
          {{ tag }}
        </f7-chip>
        <chip-with-icon
          v-if="processableItem.totalQuantity > 0"
          :icon="['far', 'boxes']"
          :title="processableItem.totalQuantity.toString()"
        />
      </div>
      <div class="state item-header">
        {{ processableItem.state }}
      </div>
    </div>
    <div class="identifiers">
      <div class="identifier">
        {{ processableItem.identifier }}
      </div>
      <div class="purchase-orders">
        &nbsp;/ {{ processableItem.purchaseOrderReferences.join(', ') }}
      </div>
    </div>
    <div class="creditors">
      {{ processableItem.creditors[0] }}
      <span v-if="processableItem.creditors.length > 1">
        +{{ processableItem.creditors.length - 1 }}
      </span>
    </div>
    <div class="supplier">
      {{ processableItem.supplier }}
    </div>
    <div class="colli">
      <template v-for="(collo, index) in processableItem.inboundColli" :key="collo">
        <chip-with-icon
          class="sequence"
          size="medium"
          :icon="['far', 'pallet-box']"
          :title="collo"
          v-if="hideAll ? index < 4 : true"
        />
      </template>
      <f7-chip
        class="chip-small sequence total"
        v-if="processableItem.inboundColli.length > 5 && hideAll"
      >
        +{{ processableItem.inboundColli.length - 4 }}
      </f7-chip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IProcessableReceipt } from '@graphql/goodsReceipt/types'
import { ref } from 'vue'
import ChipWithIcon from '@components/chip/ChipWithIcon.vue'

const hideAll = ref<boolean>(true)

defineProps<{
  processableItem: IProcessableReceipt
}>()
</script>

<style scoped lang="less">
.processable-item {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1px;
  flex-direction: column;
  justify-content: space-between;

  .tags-and-state {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tags {
      display: flex;
      gap: 10px;
    }
  }

  .identifiers {
    display: grid;
    grid-template-columns: auto 1fr;

    .purchase-orders {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .colli {
    display: grid;
    column-gap: 10px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    align-items: flex-end;
    width: max-content;

    .sequence {
      justify-content: center;

      &.total {
        grid-row: 1;
        grid-column: 5;
      }
    }
  }
}
</style>
