<template>
  <f7-page :page-content="false">
    <f7-navbar title="">
      <f7-link popup-close>
        <f7-icon material="close" />
      </f7-link>
    </f7-navbar>
    <f7-page-content v-if="stockCountItem">
      <f7-card class="data-table">
        <DataTable>
          <TableRow>
            <label-cell>Location</label-cell>
            <label-cell>{{ stockCountItem.location.name }}</label-cell>
          </TableRow>
          <TableRow>
            <label-cell>Counted qty</label-cell>
            <numeric-cell>{{
              getStockQuantity(stockCountItem.article, stockCountItem.countedQty)
            }}</numeric-cell>
          </TableRow>
          <TableRow>
            <label-cell>Expected qty</label-cell>
            <numeric-cell>{{
              getStockQuantity(stockCountItem.article, stockCountItem.systemQty)
            }}</numeric-cell>
          </TableRow>
          <TableRow>
            <label-cell>State</label-cell>
            <label-cell>{{ stockCountItem.state }}</label-cell>
          </TableRow>
          <TableRow>
            <label-cell>Counted at</label-cell>
            <label-cell><date-time :value="stockCountItem.countedAt" /></label-cell>
          </TableRow>
          <TableRow>
            <label-cell>Origin</label-cell>
            <label-cell>{{ stockCountItem.origin }}</label-cell>
          </TableRow>
        </DataTable>
      </f7-card>
    </f7-page-content>
  </f7-page>
</template>
<script lang="ts" setup>
import { IStockCountItem } from '@graphql/stock/types'
import DateTime from '@components/label/DateTime.vue'
import DataTable from '@components/data-table/DataTable.vue'
import TableRow from '@components/data-table/TableRow.vue'
import LabelCell from '@components/data-table/LabelCell.vue'
import NumericCell from '@components/data-table/NumericCell.vue'
import useArticle from '@composables/useArticle'

const { getStockQuantity } = useArticle()

defineProps<{
  stockCountItem: IStockCountItem
}>()
</script>
