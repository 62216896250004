<template>
  <f7-card :class="{ 'skeleton-text': isLoading }">
    <f7-card-content>
      <f7-list media-list v-if="goodsReceiptLines">
        <f7-list-item
          v-for="goodsReceiptLineEdge in goodsReceiptLines.edges"
          :key="goodsReceiptLineEdge.cursor"
        >
          <template #header>
            {{ goodsReceiptLineEdge.node.purchaseOrderLine.article.name }}
          </template>
          <template #title>
            {{
              getStockQuantity(
                goodsReceiptLineEdge.node.purchaseOrderLine.article,
                goodsReceiptLineEdge.node.amountProcessed
              )
            }}/{{
              getStockQuantity(
                goodsReceiptLineEdge.node.purchaseOrderLine.article,
                goodsReceiptLineEdge.node.amountExpected
              )
            }}
            Delivered<br />
          </template>
          <template #subtitle>
            <div
              v-if="
                goodsReceiptLineEdge.node.goodsReceiptLineLocation &&
                goodsReceiptLineEdge.node.goodsReceiptLineLocation.length > 0
              "
            >
              {{ flattenGoodsLocations(goodsReceiptLineEdge.node.goodsReceiptLineLocation) }}
            </div>
            <div v-if="goodsReceiptLineEdge.node.sourceLocation">
              <location-chip :text="goodsReceiptLineEdge.node.sourceLocation?.name" />
            </div>
          </template>
        </f7-list-item>
      </f7-list>
    </f7-card-content>
  </f7-card>
</template>
<script setup lang="ts">
import useGoodsReceipt from '@composables/useGoodsReceipt'
import { IConnection } from '@graphql/types'
import { IGoodsReceiptLine } from '@graphql/goodsReceipt/types'
import useArticle from '@composables/useArticle'
import LocationChip from '@components/chip/LocationChip.vue'

const { getStockQuantity } = useArticle()

withDefaults(
  defineProps<{
    goodsReceiptLines?: IConnection<IGoodsReceiptLine>
    isLoading: boolean
  }>(),
  {
    goodsReceiptLines: undefined,
    isLoading: false
  }
)

const { flattenGoodsLocations } = useGoodsReceipt()
</script>
