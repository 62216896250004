import Base from '@graphql/base'
import { AxiosInstance, AxiosResponse } from 'axios'
import GRAPHQL_API_CLIENT from './client'
import { IGetListResponse, IGetWarehouseZonesResponse } from '@graphql/warehouse/types'

class Warehouse extends Base {
  constructor(axios: AxiosInstance) {
    super(axios, 'warehouse')
  }

  public getList(): Promise<AxiosResponse<IGetListResponse>> {
    return this.makeRequest<IGetListResponse>('WarehouseListQuery')
  }

  public getWarehouseZones(): Promise<AxiosResponse<IGetWarehouseZonesResponse>> {
    return this.makeRequest<IGetWarehouseZonesResponse>('GetZonesQuery')
  }
}

export default new Warehouse(GRAPHQL_API_CLIENT)
