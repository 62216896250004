<template>
  <page-with-search-subscriber v-slot="{ searchSubscriber }" :page-content="false">
    <app-navigation show-search-icon v-slot="{ searchId }">
      <search-bar
        :scan-subscriber="searchSubscriber"
        :search-id="searchId"
        :search-type="TypeName.GoodsReceiptLine"
        :search-filters="searchFilters"
        expandable
        @result="onSearchResult"
        @no-result="onNoResult"
      />
    </app-navigation>
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#ibs-todo" tab-link-active>Todo ({{ todoItemTotalCount }})</f7-link>
      <f7-link tab-link="#ibs-done">Done ({{ doneItemTotalCount }})</f7-link>
      <f7-link tab-link="#ibs-details">Details</f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab
        id="ibs-todo"
        class="page-content ptr-content"
        tab-active
        :data-ptr-distance="55"
        :data-ptr-mousewheel="true"
        @ptr:refresh="onPullToRefresh"
      >
        <pull-to-refresh-preloader />
        <app-breadcrumbs :breadcrumbs="breadcrumbs" />
        <sort-todo-items-tab
          v-if="todoItemEdges"
          :todo-item-edges="todoItemEdges"
          :is-loading="isLoading"
          @todo-item-clicked="onTodoItemClicked"
          @item-marked-as-incomplete="onPullToRefresh()"
        />
      </f7-tab>
      <f7-tab
        id="ibs-done"
        class="page-content ptr-content"
        :data-ptr-distance="55"
        :data-ptr-mousewheel="true"
        @ptr:refresh="onPullToRefresh"
      >
        <pull-to-refresh-preloader />
        <app-breadcrumbs :breadcrumbs="breadcrumbs" />
        <sort-done-items-tab
          v-if="doneItemEdges"
          :done-item-edges="doneItemEdges"
          :is-loading="isLoading"
        />
      </f7-tab>
      <f7-tab id="ibs-details" class="page-content no-z-index">
        <app-breadcrumbs :breadcrumbs="breadcrumbs" />
        <details-card :goods-receipt="goodsReceipt" :is-loading="isLoading" />
        <inbound-sort-load-carrier-list
          :load-carriers="loadCarriers"
          @start-marking-sorted="searchSubscriber.pause()"
          @stopped-marking-sorted="searchSubscriber.resume()"
          @marked-sorted="onPullToRefresh()"
          :is-loading="isLoading"
        />
      </f7-tab>
    </f7-tabs>
  </page-with-search-subscriber>
</template>
<script setup lang="ts">
import { ID, IEdge } from '@graphql/types'
import AppNavigation from '@components/AppNavigation.vue'
import { TypeName } from '@graphql/search/types'
import SearchBar from '@components/search/SearchBar.vue'
import useGoodsReceipt from '@composables/useGoodsReceipt'
import useLoading from '@composables/useLoading'
import { computed, onMounted, ref } from 'vue'
import {
  IGoodsReceipt,
  IGoodsReceiptLine,
  IInboundSortDoneItem,
  IInboundSortTodoItem,
  InboundSortTodoItem
} from '@graphql/goodsReceipt/types'
import SortTodoItemsTab from '@pages/inbound/components/SortTodoItemsTab.vue'
import useSearch from '@composables/useSearch'
import { UnionTypeSearchResult } from '@store/modules/search/types'
import PullToRefreshPreloader from '@components/PullToRefreshPreloader.vue'
import { ISearchFilter } from '@services/search/search'
import { f7 } from 'framework7-vue'
import useProjectInboundSort from '@composables/useProjectInboundSort'
import { IProjectInboundSortItem } from '@store/pinia/project-inbound-sort/types'
import SortDoneItemsTab from '@pages/inbound/components/SortDoneItemsTab.vue'
import { perceptibleToast } from '@services/perceptibleToast'
import DetailsCard from '@pages/goods-receipt/components/DetailsCard.vue'
import InboundSortLoadCarrierList from '@pages/inbound/components/InboundSortLoadCarrierList.vue'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'
import AppBreadcrumbs, { IBreadcrumb } from '@components/AppBreadcrumbs.vue'

const props = defineProps<{
  goodsReceiptId: ID
}>()

const { getSortableItems } = useGoodsReceipt()
const { withAsyncLoading, isLoading } = useLoading()
const { onNoResult } = useSearch()
const { addProjectItem, activeItem, removeProjectItem } = useProjectInboundSort()

const searchFilters: ISearchFilter[] = [
  {
    key: 'receiptId',
    operator: '=',
    value: String(props.goodsReceiptId)
  }
]

const breadcrumbs: IBreadcrumb[] = [
  {
    href: '/goodsreceipt/',
    title: 'Goods receipts'
  },
  {
    href: `/goodsreceipt/${props.goodsReceiptId}/`,
    title: `#${props.goodsReceiptId}`
  },
  {
    href: '#',
    title: 'Sort'
  }
]

const goodsReceipt = ref<IGoodsReceipt>()

onMounted(async () => {
  await onPullToRefresh()
})

const todoItemEdges = computed<IEdge<IInboundSortTodoItem>[]>(() => {
  if (!goodsReceipt.value) {
    return []
  }

  return goodsReceipt.value.inboundSortTodoItems.edges
})

const doneItemEdges = computed<IEdge<IInboundSortDoneItem>[]>(() => {
  if (!goodsReceipt.value) {
    return []
  }

  return goodsReceipt.value.inboundSortDoneItems.edges
})

const todoItemTotalCount = computed(() => {
  if (!goodsReceipt.value) {
    return 0
  }

  return goodsReceipt.value.inboundSortTodoItems.totalCount
})

const doneItemTotalCount = computed(() => {
  if (!goodsReceipt.value) {
    return 0
  }

  return goodsReceipt.value.inboundSortDoneItems.totalCount
})

const loadCarriers = computed(() => {
  if (!goodsReceipt.value) {
    return []
  }

  return goodsReceipt.value.inboundSortLoadCarriers.edges
    .map((edge) => edge.node)
    .filter((loadCarrier) => loadCarrier.state !== 'completed')
})

const onPullToRefresh = async (e?: any) => {
  await withAsyncLoading(async () => {
    goodsReceipt.value = await getSortableItems({ id: props.goodsReceiptId })

    if (goodsReceipt.value.inboundSortTodoItems.totalCount === 0) {
      await completeSort()
    }

    if (e) {
      await e.detail()
    }
  })
}

const completeSort = async () => {
  if (activeItem.value) {
    await removeProjectItem(activeItem.value)
  }
  await perceptibleToast.finishedTask(`Goods receipt #${goodsReceipt.value.id} is sorted.`)
  await f7.views.main.router.navigate('/home/', {
    reloadAll: true
  })
}

const onSearchResult = async (result: UnionTypeSearchResult) => {
  await onTodoItemClicked(
    new InboundSortTodoItem(
      result.id,
      0,
      0,
      (result as IGoodsReceiptLine).purchaseOrderLine.article,
      []
    )
  )
}

const onTodoItemClicked = async (todoItem: IInboundSortTodoItem) => {
  const projectItem: IProjectInboundSortItem = {
    todoItem,
    labelPrinted: false,
    goodsReceiptId: props.goodsReceiptId,
    quantityEntered: 0,
    weightEntered: 0
  }

  await addProjectItem(projectItem)

  f7.views.main.router.navigate('/inbound/sort/redirect/')
}
</script>
