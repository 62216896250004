import { ColloType } from '@graphql/inbound/types'
import { ID } from '@graphql/types'

interface IFormInput<T> {
  value: T
  errors: string[]
}

export interface IQuantityInboundColloTypeFormInput {
  type: IFormInput<ColloType>
  quantity: IFormInput<number>
}

export interface IColloInput {
  barcode: string
  locationId: ID
  colloType: ColloType
}

export interface IReceptionRegistration {
  startedAt: Date
  colli: IQuantityInboundColloTypeFormInput[]
  notes: string | null
}

export interface IInboundState {
  receptionRegistration: IReceptionRegistration | null
}

export enum ReceptionState {
  NEW = 'new',
  PROCESSING = 'processing',
  STAGED = 'staged',
  COMPLETED = 'completed'
}
