import { computed } from 'vue'
import { ILocation, ILocationType } from '@graphql/location/types'
import { useStore } from '@store/store'
import {
  IGetLocationByIdContext,
  IGetLocationTypesByWarehouseZoneRequest,
  ILocationSuggestionContext
} from '@store/modules/location/types'

export default function () {
  const store = useStore()

  const location = computed<ILocation | null>(
    () => store.getters[`location/activeLocation`] ?? null
  )
  const isLocationActive = computed<boolean>(() => location.value !== null)
  const suggestions = computed(() => store.getters[`location/suggestions`])
  const activeLocation = async () => computed(() => store.getters[`location/activeLocation`])

  const getById = async (context: IGetLocationByIdContext): Promise<ILocation> =>
    store.dispatch(`location/getById`, context)
  const setActiveLocation = async (location: ILocation) =>
    store.dispatch(`location/setActiveLocation`, location)
  const setSuggestions = async (locations: ILocation[]) =>
    store.dispatch(`location/setSuggestions`, locations)
  const clearSuggestions = async () => store.dispatch(`location/clearSuggestions`)
  const getSuggestions = async (context: ILocationSuggestionContext) =>
    store.dispatch(`location/getSuggestions`, context)
  const getLocationTypesByWarehouseZone = async (
    context: IGetLocationTypesByWarehouseZoneRequest
  ): Promise<ILocationType[]> => store.dispatch(`location/getLocationTypesByWarehouseZone`, context)

  const isLocationName = (value: string) => value.match(/^\d+-[A-Z0-9]+$/)

  return {
    suggestions,
    activeLocation,
    location,
    isLocationActive,
    getById,
    setActiveLocation,
    setSuggestions,
    clearSuggestions,
    getSuggestions,
    getLocationTypesByWarehouseZone,
    isLocationName
  }
}
