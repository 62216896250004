import { Router } from 'framework7/framework7-types'
import ReceptionRegistrationPage from '@pages/inbound/ReceptionRegistrationPage.vue'
import ReceptionListPage from '@pages/inbound/ReceptionListPage.vue'
import ReceptionProcessPage from '@pages/inbound/ReceptionProcessPage.vue'
import ColloScanPage from '@pages/inbound/ColloScanPage.vue'
import ColloDetailPage from '@pages/inbound/ColloDetailPage.vue'
import { confirmYesNo } from '@/functions/dialog'
import { useInboundStore } from '@store/pinia/inbound/store'
import InboundSortProcessPage from '@pages/inbound/sort/ProcessPage.vue'
import InboundSortEnterQuantityPage from '@pages/inbound/sort/EnterQuantityPage.vue'
import InboundSortZoneSuggestionPage from '@pages/inbound/sort/WarehouseZoneSuggestionPage.vue'
import InboundSortPrintLabelsPage from '@pages/inbound/sort/PrintLabelsPage.vue'
import InboundSortEnterWeightPage from '@pages/inbound/sort/EnterWeightPage.vue'
import { redirectMiddleware } from '@framework7/routes/inbound/sortRedirectMiddleware'
import PutawayPage from '@pages/inbound/putaway/pages/PutawayPage.vue'
import PutawayItemContentsPage from '@pages/inbound/putaway/pages/PutawayItemContentsPage.vue'
import PutawayProcessPage from '@pages/inbound/putaway/pages/PutawayProcessPage.vue'
import SortLoadCarrierDetailPage from '@pages/inbound/SortLoadCarrierDetailPage.vue'
import SelectKardexPage from '@/pages/inbound/putaway/pages/Kardex/SelectKardexPage.vue'

const routes: Partial<Router.RouteParameters>[] = [
  {
    path: '/inbound/reception/',
    component: ReceptionListPage
  },
  {
    path: '/inbound/reception/registration/',
    component: ReceptionRegistrationPage,
    beforeLeave: ({ resolve, reject }) => {
      const store = useInboundStore()

      if (!store.receptionRegistration) {
        resolve()
        return
      }

      confirmYesNo({
        title: 'Are you sure that you want to leave this page with possible unsaved data?',
        yesButtonText: 'Yes, discard',
        noButtonText: 'No, stay',
        noButtonColor: 'red',
        yesButtonCallback: () => {
          store.clearReception()
          resolve()
        },
        noButtonCallback: () => reject()
      })
    }
  },
  {
    path: '/inbound/reception/:receptionId/process/',
    component: ReceptionProcessPage
  },
  {
    path: '/inbound/collo/:colloId/',
    component: ColloDetailPage
  },
  {
    path: '/inbound/collo/:colloId/scan/',
    component: ColloScanPage
  },
  {
    path: '/inbound/sort/redirect/',
    redirect: redirectMiddleware
  },
  {
    path: '/inbound/sort/:goodsReceiptId/process/',
    component: InboundSortProcessPage
  },
  {
    path: '/inbound/sort/:goodsReceiptLineId/process/enter-quantity/',
    component: InboundSortEnterQuantityPage
  },
  {
    path: '/inbound/sort/:goodsReceiptLineId/process/enter-weight/',
    component: InboundSortEnterWeightPage
  },
  {
    path: '/inbound/sort/:goodsReceiptLineId/process/print-labels/',
    component: InboundSortPrintLabelsPage
  },
  {
    path: '/inbound/sort/:goodsReceiptLineId/process/warehouse-zone/suggestion',
    component: InboundSortZoneSuggestionPage
  },
  {
    path: '/inbound/sort/load-carrier/:loadCarrierId/',
    component: SortLoadCarrierDetailPage
  },
  {
    path: '/inbound/putaway/',
    component: PutawayPage
  },
  {
    path: '/inbound/putaway/item/contents/',
    component: PutawayItemContentsPage
  },
  {
    path: '/inbound/putaway/process/',
    component: PutawayProcessPage
  },
  {
    path: '/inbound/putaway/kardex/:kardexProjectId/process/',
    component: SelectKardexPage
  }
]

export default routes
