<template>
  <f7-block-title class="margin-top" v-if="article">
    ({{ article.code }}) {{ article.name }}
  </f7-block-title>
  <f7-list media-list inset :class="{ 'skeleton-text': !article }">
    <f7-list-item header="Quantity">
      {{ getStockQuantity(article, expectedQuantity) }}
    </f7-list-item>
    <f7-list-item header="Primary barcode">
      <string-label :value="article?.primaryBarcode" />
    </f7-list-item>
    <f7-list-item header="Weight (gr.)">
      <template #title>
        <string-label :value="article?.weight" />
      </template>
    </f7-list-item>
    <f7-list-item
      header="Classifications"
      v-if="article?.classifications && article.classifications.length > 0"
    >
      <template #title>
        <classifications-label :items="article.classifications" />
      </template>
    </f7-list-item>
    <f7-list-button @click="$emit('show-article-popup')">Show details</f7-list-button>
  </f7-list>
</template>
<script setup lang="ts">
import { IArticle } from '@graphql/article/types'
import ClassificationsLabel from '@components/label/ClassificationsLabel.vue'
import StringLabel from '@components/label/StringLabel.vue'
import useArticle from '@composables/useArticle'

const { getStockQuantity } = useArticle()

withDefaults(
  defineProps<{
    article: IArticle | undefined
    expectedQuantity: number
  }>(),
  {
    article: undefined,
    expectedQuantity: 0
  }
)

defineEmits<{
  (e: 'show-article-popup')
}>()
</script>
