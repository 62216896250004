<template>
  <f7-page :page-content="false">
    <div v-show="!activeLine">
      <app-navigation />
      <f7-card class="margin-top">
        <f7-card-content>
          <icon-with-text title="Loading location to visit...">
            <template #icon>
              <font-awesome-icon
                :icon="['fad', 'spinner-third']"
                spin
                style="--fa-primary-color: #d6d95f"
              />
            </template>
          </icon-with-text>
        </f7-card-content>
      </f7-card>
    </div>

    <f7-popup
      v-if="activeLine"
      :opened="progression.total !== progression.current"
      :close-on-ecape="false"
      :close-by-backdrop-click="false"
      :tablet-fullscreen="true"
      class="pick-line-popup"
    >
      <pick-line-scan
        v-if="activeLine"
        v-model="activeLine"
        :key="activeLine.pickBatchLineId"
        :progression="progression"
        :process="process"
        :pick-batch-partition="pickBatchPartition"
        :load-carrier-line-count="loadCarrierLineCount"
        @completed="onLineComplete"
        @refresh-state="getNextLocationToVisit"
      />
    </f7-popup>
  </f7-page>
</template>
<script lang="ts" setup>
import { scanInput } from '@/utilities/scanInput'
import useLoading from '@composables/useLoading'
import useProjectPick from '@composables/useProjectPick'
import { ILine, IPickBatchPartition, IProgress, Process } from '@graphql/pick/types'
import { soundBoard } from '@services/sound'
import { f7 } from 'framework7-vue'
import { nextTick, onMounted, ref, watch } from 'vue'
import IconWithText from '@components/IconWithText.vue'
import AppNavigation from '@components/AppNavigation.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import PickLineScan from '@components/pick/PickLineScan.vue'
import { ID } from '@graphql/types'
import { toast } from '@services/toast'

const props = defineProps<{
  pickBatchPartitionId: ID
  pickBatchPartition: IPickBatchPartition
}>()

const { withAsyncLoading } = useLoading()
const { getLocationToVisit, updateProcessedLines } = useProjectPick()

const activeLine = ref<ILine | null>(null)
const progression = ref<IProgress>({ total: 0, current: 0, percentage: 0 })
const process = ref<string>(Process.PICK_ARTICLES)
const loadCarrierLineCount = ref<number>(0)

const onLineComplete = async () => {
  await soundBoard.playSuccessSound()

  if (process.value === Process.PICK_ARTICLES) {
    await updateProcessedLines()
  }

  await getNextLocationToVisit()
}

const getNextLocationToVisit = async () => {
  scanInput.disable()

  await withAsyncLoading(async () => {
    try {
      const response = await getLocationToVisit(props.pickBatchPartitionId)

      activeLine.value = response.getLocationToVisit.line
      process.value = response.getLocationToVisit.process
      progression.value = response.getLocationToVisit.progress
      loadCarrierLineCount.value = response.getLocationToVisit.loadCarrierLineCount
    } catch (e) {
      toast.error(e.message, 0).open()
      throw e
    }
  })

  scanInput.enable()
}

watch(progression, async (newValue) => {
  if (newValue.total !== newValue.current) {
    return
  }

  await nextTick(() => f7.views.main.router.navigate('/pick-entry/'))
})

onMounted(async () => {
  await getNextLocationToVisit()
})
</script>
