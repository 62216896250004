import { Router } from 'framework7/types'
import TodoTransportPage from '@pages/outbound/transport/TodoTransportPage.vue'
import DetailPage from '@pages/outbound/transport/DetailPage.vue'
import PickPage from '@pages/outbound/transport/PickPage.vue'

const outbound: Partial<Router.RouteParameters>[] = [
  {
    path: '/outbound/transport/todo/',
    component: TodoTransportPage
  },
  {
    path: '/outbound/transport/:transportId/',
    component: DetailPage
  },
  {
    path: '/outbound/transport/:transportId/pick/',
    component: PickPage
  }
]

export default outbound
