<template>
  <items-card-with-loading-state
    :is-loading="isLoading"
    :items="todoItemEdges"
    no-items-description="Well done, all items have been sorted"
  >
    <f7-list media-list>
      <template v-for="todoItemEdge in todoItemEdges" :key="todoItemEdge.cursor">
        <f7-list-item swipeout v-if="todoItemEdge.node">
          <template #media>
            {{ getStockQuantity(todoItemEdge.node.article, todoItemEdge.node.doneQuantity) }}
          </template>
          <template #header>
            <slot name="quantities">
              {{
                getStockQuantity(
                  todoItemEdge.node.article,
                  todoItemEdge.node.todoQuantity - todoItemEdge.node.doneQuantity
                )
              }}
              todo,
              {{ getStockQuantity(todoItemEdge.node.article, todoItemEdge.node.doneQuantity) }}
              done
            </slot>
          </template>
          <template #subtitle>
            <template v-if="todoItemEdge.node.article.primaryBarcode">
              {{ todoItemEdge.node.article.primaryBarcode }}
              <br />
            </template>
            <div class="tags">
              <f7-chip class="chip-small" v-for="tag in todoItemEdge.node.tags" :key="tag">
                {{ tag }}
              </f7-chip>
            </div>
          </template>
          <template #title>
            {{ todoItemEdge.node.article.name }}
          </template>
          <f7-swipeout-actions left>
            <f7-swipeout-button
              color="dimass"
              overswipe
              close
              @click="onTodoItemClick(todoItemEdge.node)"
            >
              <f7-icon material="add_location" class="text-color-white" />
            </f7-swipeout-button>
          </f7-swipeout-actions>
          <f7-swipeout-actions right>
            <f7-swipeout-button
              color="red"
              overswipe
              close
              @click="onIncompleteMark(todoItemEdge.node)"
            >
              <font-awesome-icon :icon="['far', 'pen-slash']" />
            </f7-swipeout-button>
          </f7-swipeout-actions>
        </f7-list-item>
      </template>
    </f7-list>
  </items-card-with-loading-state>
</template>
<script setup lang="ts">
import { IInboundSortTodoItem } from '@graphql/goodsReceipt/types'
import { IEdge } from '@graphql/types'
import ItemsCardWithLoadingState from '@components/ItemsCardWithLoadingState.vue'
import useArticle from '@composables/useArticle'
import useInbound from '@composables/useInbound'
import { perceptibleToast } from '@services/perceptibleToast'
import { captureException } from '@sentry/vue'
import { confirmYesNo } from '@/functions/dialog'

const { getStockQuantity } = useArticle()
const { markLineAsIncomplete } = useInbound()

withDefaults(
  defineProps<{
    todoItemEdges: IEdge<IInboundSortTodoItem>[]
    isLoading: boolean
  }>(),
  {
    isLoading: false
  }
)

const emit = defineEmits<{
  (e: 'todo-item-clicked', todoItem: IInboundSortTodoItem)
  (e: 'item-marked-as-incomplete', todoItem: IInboundSortTodoItem)
}>()
const onTodoItemClick = async (todoItem: IInboundSortTodoItem) => {
  await emit('todo-item-clicked', todoItem)
}

const onIncompleteMark = async (todoItem: IInboundSortTodoItem) => {
  confirmYesNo({
    title: 'Are you sure you want to mark this line as incomplete?',
    yesButtonCallback: async () => {
      try {
        await markLineAsIncomplete({
          goodsReceiptLineId: todoItem.goodsReceiptLineId
        })

        await emit('item-marked-as-incomplete', todoItem)
      } catch (e) {
        captureException(e)

        await perceptibleToast.error(e.message)
      }
    }
  })
}
</script>

<style scoped>
.tags {
  display: flex;
  gap: 10px;
}
</style>
