import {
  IOutboundPickProject,
  IProjectPickState,
  IUpdatePickBatchLineContext
} from '@store/modules/projects/pick/types'
import { IPickBatchPartitionQueue } from '@graphql/pick/types'
import { MutationTree } from 'vuex'

const mutations: MutationTree<IProjectPickState> = {
  addProject(state, payload: IOutboundPickProject) {
    if (
      payload.selectedPickBatchPartition.id !== state.activeProject?.selectedPickBatchPartition?.id
    ) {
      state.printedDocuments = false
    }

    state.activeProject = payload
  },
  updateProject(state, payload: IOutboundPickProject) {
    if (
      payload.selectedPickBatchPartition.id !== state.activeProject?.selectedPickBatchPartition?.id
    ) {
      state.printedDocuments = false
    }

    state.activeProject.selectedPickBatchPartition = payload.selectedPickBatchPartition
  },
  updateSelectedQueue(state, payload: IPickBatchPartitionQueue) {
    state.selectedQueue = payload
  },
  updatePickBatchLine(state, payload: IUpdatePickBatchLineContext) {
    if (state.activeProject === undefined) {
      return
    }

    const item = state.activeProject.selectedPickBatchPartition
    const lineIndex = item.pickBatchLines.findIndex((l) => l.id === payload.pickBatchLine.id)

    if (lineIndex === -1) {
      return
    }

    item.pickBatchLines[lineIndex] = payload.pickBatchLine
    if (item.processedLines) {
      item.processedLines++
    }
  },
  updateProcessedLines(state) {
    if (state.activeProject) {
      state.activeProject.selectedPickBatchPartition.pickBatch.processedLines++
      state.activeProject.selectedPickBatchPartition.processedLines++
    }
  },
  updatePrintedDocuments(state, printed: boolean) {
    state.printedDocuments = printed
  },
  remove(state, pickBatchPartitionId: number) {
    if (
      state.activeProject !== undefined &&
      state.activeProject.selectedPickBatchPartition.id === pickBatchPartitionId
    ) {
      state.activeProject = undefined
      state.printedDocuments = false
    }
  },
  removeProjects(state) {
    state.items = []
    state.activeProject = undefined
    state.printedDocuments = false
  }
}

export default mutations
