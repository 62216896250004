import { useStore } from '@store/store'
import { computed } from 'vue'
import { ID } from '@graphql/types'
import { IPackProject } from '@store/modules/projects/pack/types'

export default () => {
  const store = useStore()

  const itemCount = computed<number>(() => store.getters['projects/pack/itemCount'])
  const items = computed<IPackProject[]>(() => store.getters['projects/pack/items'])
  const isActive = computed<boolean>(() => store.getters['projects/pack/isActive'])
  const activeProject = computed<IPackProject | null>(
    () => store.getters['projects/pack/activeItem']
  )
  const addProject = async (context: IPackProject) =>
    store.dispatch('projects/pack/addPackProject', context)

  const updateProject = async (context: IPackProject) =>
    store.dispatch('projects/pack/updatePackProject', context)

  const removeProject = async (id: ID) => store.dispatch('projects/pack/removeProject', id)
  const removeProjects = async () => store.dispatch('projects/pack/removeProjects')

  return {
    itemCount,
    items,
    isActive,
    activeProject,
    addProject,
    updateProject,
    removeProject,
    removeProjects
  }
}
