<template>
  <f7-page :page-content="false">
    <app-navigation />
    <f7-page-content :class="{ 'skeleton-text': isLoading }">
      <app-breadcrumbs :breadcrumbs="breadcrumbs" />
      <enter-weight-card
        :weight="weight"
        @weight-changed="onWeightChanged"
        :is-loading="isLoading"
      />
      <article-details-list :article="activeItem.todoItem?.article" />
      <f7-fab position="center-bottom" @click="onConfirm">
        <f7-icon material="done" text="Confirm" />
      </f7-fab>
    </f7-page-content>
  </f7-page>
</template>
<script setup lang="ts">
import AppNavigation from '@components/AppNavigation.vue'
import EnterWeightCard from '@components/inbound/EnterWeightCard.vue'
import ArticleDetailsList from '@components/inbound/ArticleDetailsList.vue'
import useProjectInboundSort from '@composables/useProjectInboundSort'
import { ref } from 'vue'
import { toast } from '@services/toast'
import { f7 } from 'framework7-vue'
import useLoading from '@composables/useLoading'
import AppBreadcrumbs from '@components/AppBreadcrumbs.vue'

const { activeItem, updateProjectItem, createBreadcrumbs } = useProjectInboundSort()
const { isLoading } = useLoading()

const weight = ref<number>(0)

const breadcrumbs = createBreadcrumbs(activeItem.value, {
  href: '#',
  title: 'Enter weight'
})

const onWeightChanged = async (value: number) => (weight.value = value)

const onConfirm = async () => {
  if (weight.value <= 0) {
    toast.error('Weight must be greater than 0!').open()
    return
  }

  activeItem.value.weightEntered = weight.value

  await updateProjectItem(activeItem.value)

  f7.views.main.router.navigate('/inbound/sort/redirect/')
}
</script>
