<template>
  <data-table-card :items="inboundColliEdges" :is-loading="isLoading">
    <template #no-items>
      <icon-with-text :icon="['far', 'empty-set']" title="No colli available" />
    </template>
    <data-table>
      <template #header>
        <table-row>
          <label-cell element="th">Barcode</label-cell>
          <label-cell element="th">Location</label-cell>
          <label-cell element="th">Put away at</label-cell>
        </table-row>
      </template>
      <table-row
        v-for="inboundColloEdge in inboundColliEdges"
        :key="inboundColloEdge.cursor"
        @click="onNavigateToCollo(inboundColloEdge.node)"
      >
        <label-cell><string-label :value="inboundColloEdge.node.barcode" /></label-cell>
        <label-cell><string-label :value="inboundColloEdge.node.location?.name" /></label-cell>
        <label-cell><date-time :value="inboundColloEdge.node.putAwayAt" /></label-cell>
      </table-row>
    </data-table>
  </data-table-card>
</template>

<script setup lang="ts">
import { IInboundCollo, IInboundColloConnection } from '@graphql/inbound/types'
import TableRow from '@components/data-table/TableRow.vue'
import DataTableCard from '@components/data-table/DataTableCard.vue'
import IconWithText from '@components/IconWithText.vue'
import LabelCell from '@components/data-table/LabelCell.vue'
import DataTable from '@components/data-table/DataTable.vue'
import { computed } from 'vue'
import StringLabel from '@components/label/StringLabel.vue'
import DateTime from '@components/label/DateTime.vue'
import { f7 } from 'framework7-vue'

const props = withDefaults(
  defineProps<{
    inboundColli: IInboundColloConnection
    isLoading: boolean
  }>(),
  {
    inboundColli: () => [],
    isLoading: false
  }
)

const inboundColliEdges = computed(() => {
  if (props.inboundColli && props.inboundColli.edges) {
    return props.inboundColli.edges
  }

  return []
})

const onNavigateToCollo = async (inboundCollo: IInboundCollo) => {
  f7.views.main.router.navigate(`/inbound/collo/${inboundCollo.id}/`)
}
</script>
