<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-right>
        <f7-link icon-only panel-close="right">
          <f7-icon material="close" />
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-block v-if="isAuthenticated" class="text-align-center">
      <font-awesome-icon size="4x" :icon="['far', 'user']" />
      <br />
      {{ currentUser?.name }}<br />
      <p>
        <font-awesome-icon size="1x" :icon="['far', 'mobile']" />
        {{ deviceId ?? 'unregistered' }}
      </p>
    </f7-block>
    <f7-list>
      <f7-list-item
        v-if="hasActiveProjects"
        @click="navigateToPage('/projects/')"
        link="#"
        title="Projects"
        panel-close
      >
        <f7-badge v-if="hasActiveProjects">
          {{ activeProjectCount }}
        </f7-badge>
      </f7-list-item>
      <f7-list-item
        v-if="isGranted(Permissions.VIEW_PACK_STATION)"
        title="Settings"
        @click="navigateToPage('/settings/')"
        link="#"
      />
      <f7-list-item
        v-if="deviceId && isGranted(Permissions.REGISTER_DEVICE_SCANNER)"
        title="Deregister device"
        @click="onDeregisterDevice"
        link="#"
      />
      <f7-list-item
        v-if="!deviceId"
        title="Register device"
        @click="navigateToPage('/device/register/')"
        link="#"
      />
      <f7-list-item @click="doLogout" title="Logout" link="#" panel-close />
    </f7-list>
    <f7-block-footer class="version-container">
      <f7-button fill @click="forceReload">
        <font-awesome-icon :icon="['fad', 'rotate-right']" />
      </f7-button>
      Version {{ appVersion }}
    </f7-block-footer>
  </f7-page>
</template>

<script lang="ts" setup>
import useAuthentication from '@composables/useAuthentication'
import useDevice from '@composables/useDevice'
import useProjects from '@composables/useProjects'
import useSettings from '@composables/useSettings'
import { APPLICATION_VERSION } from '@config/environment'
import { Permissions } from '@services/security/permissions'
import { f7 } from 'framework7-vue'
import { Router } from 'framework7/types'
import { perceptibleToast } from '@services/perceptibleToast'
import { confirmYesNo } from '@/functions/dialog'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const { hasActiveProjects, activeProjectCount } = useProjects()
const { logout, isAuthenticated, currentUser, isGranted } = useAuthentication()
const { deregisterDevice } = useDevice()
const { deviceId, setDeviceId } = useSettings()

const appVersion = APPLICATION_VERSION

const forceReload = () => {
  confirmYesNo({
    title: 'Are you sure that you want to reload the app?',
    yesButtonCallback: () => window.location.reload(true)
  })
}

const navigateToPage = (uri: string, options?: Router.RouteOptions) => {
  f7.views.main.router.navigate(uri, options)

  f7.panel.close('right')
}

const doLogout = async () => {
  f7.panel.close('right')

  await logout()
}

const onDeregisteredDevice = async () => {
  await perceptibleToast.success('Device deregistered!')

  await setDeviceId(null)
  await logout()
  await window.location.reload()
}

const onDeregisterDevice = async () => {
  if (!deviceId.value) {
    return
  }

  await confirmYesNo({
    title: 'Are you sure that you want to de-register this device?',
    yesButtonCallback: async () => {
      try {
        const result = await deregisterDevice()

        if (!result) {
          await perceptibleToast.error('Deregister of device failed!')
          return
        }

        await onDeregisteredDevice()
      } catch (e: any) {
        if (e.message.includes('not registered!')) {
          await onDeregisteredDevice()

          return
        }

        await perceptibleToast.error(e.message)

        throw e
      } finally {
        f7.panel.close('right')
      }
    }
  })
}
</script>
<style scoped lang="less">
.version-container {
  position: absolute;
  bottom: 0;
  margin: 1rem;
  text-align: right;
  font-size: 0.7rem;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
