import { GetterTree } from 'vuex'
import { IProjectOutboundSortState } from '@store/modules/projects/outbound-sort/types'
import { IRootState } from '@store/types'

const getters: GetterTree<IProjectOutboundSortState, IRootState> = {
  isActive: (state) => state.items.length > 0,
  items: (state) => state.items,
  count: (state) => state.items.length,
  itemCount: (state) => state.items.length,
  activeItem: (state) => (state.items.length > 0 ? state.items[0] : null)
}

export default getters
