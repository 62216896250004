import { IArticle, IArticleBatch, IArticleUnit } from '@graphql/article/types'
import { IWarehouse } from '@graphql/location/types'
import { IPickBatch } from '@graphql/pick/types'
import { IShipment } from '@graphql/shipment/types'
import { ID, IGraphQLResponse, INode } from '@graphql/types'
import { ITask } from '@graphql/task/types'
import { TypeName } from '@graphql/search/types'
import { IKeyValuePair } from '@store/types'
import { ILoadCarrier } from '@graphql/outboundLoadCarrier/types'
import { IOutboundLoadCarrier } from '@graphql/outbound/types'

export interface IPackStation {
  id: ID
  description: string
  code: string
  warehouse: IWarehouse
  type: string
}

export interface IGetPackStationsResponse extends IGraphQLResponse {
  data: {
    getPackStations: Partial<IPackStation>[]
  }
}

export interface IPackingList extends INode {
  shipment: IShipment
  loadCarrierId: ID
  type: number
  packableItems: IPackableItem[]
  process: string
}

export interface IGetPackingListByContextResponse extends IGraphQLResponse {
  data: {
    getPackingListByContext: Partial<IPackingList>
  }
}

export interface IGetPackableItemsByLoadCarrierIdRequest {
  loadCarrierId: ID
  perPage?: number
  processedShipmentsNextCursor?: string
}

export interface IPackableIdentity {
  name: string
  identifier: number
  barcodes: string[]
}

export interface IRelationReference {
  identifier: ID
  type: TypeName
  typeForHuman: string
}

export interface IPackableItem {
  packableIdentity: IPackableIdentity
  stockUnit?: IArticleUnit
  articleBatch?: Partial<IArticleBatch>
  quantity: number
  quantityPacked: number
  relationReference?: IRelationReference
  parentItem?: IPackableItem | null
}

export interface IPackableItemsResult {
  pickBatch: Partial<IPickBatch>
  packableItems: Array<Partial<IPackableItem>>
}

export interface IGetPackableItemsByLoadCarrierIdResponse extends IGraphQLResponse {
  data: {
    getPackableItemsByLoadCarrierId: Partial<IPackableItemsResult>
  }
}

export interface IGetPackingListByContextRequest {
  loadCarrierId: ID
  reference: string
  metaData?: IKeyValuePair[]
}

export interface IMarkPackedRequest {
  loadCarrierId: ID
}

export interface IMarkPackedResponse extends IGraphQLResponse {
  data: {
    markPacked: boolean
  }
}

export interface IBulkMarkPrePackedRequest {
  loadCarrierId: ID
  packStationId: ID
}

export interface IBulkMarkPrePackedResponse extends IGraphQLResponse {
  data: {
    bulkMarkPrePacked: ITask
  }
}

export const enum Type {
  /** @deprecated */
  ARTICLE = 'article',
  BARCODE_MAPPING = 'barcode_mapping',
  SORT_COMPARTMENT = 'sort_compartment',
  PRE_PACK = 'pre_pack'
}

export const enum PackStationType {
  PRE_PACK = 'pre_pack',
  MANUAL = 'manual'
}

export interface IPrePackColloRequest {
  shipmentId: ID
  packStationId: ID
  compartmentReference: string
  packedLines: IPackedLine[]
}

export interface IPrePackColloResponse extends IGraphQLResponse {
  data: {
    prePackCollo: ID
  }
}

export interface IPackedLine {
  shipmentLineId: ID
  quantity: number
}

export const enum ProcessEnum {
  PACK = 'pack',
  SORT_COMPARTMENT_PACK = 'sort_compartment_pack'
}

export interface IGetLoadCarrierByIdRequest extends IGraphQLResponse {
  id: ID
}

export interface IGetLoadCarrierByIdResponse extends IGraphQLResponse {
  data: {
    getLoadCarrierById: ILoadCarrier
  }
}

export interface IPackReadyItemIdentity {
  description: string
  identifier: ID
  barcodes: string[]
}

export interface IPackReadyItem {
  identity: IPackReadyItemIdentity
  relationReference: IRelationReference
  picked: boolean
  locationName: string | null
}

export interface IPackableItemsGroup {
  items: IPackReadyItem[]
  relationReference: IRelationReference
  description: string | null
}
export interface IPackableReadyItemList {
  groups: IPackableItemsGroup[]
}

export interface IGetPackReadyItemResponse extends IGraphQLResponse {
  data: {
    getPackReadyItems: IPackableReadyItemList
  }
}

export interface IStartPackRequest {
  pickBatchId: ID
}

export interface IStartPackResponse extends IGraphQLResponse {
  data: {
    startPack: IPackProject
  }
}

export interface INestedLoadCarriersListItem {
  id: ID
  name: string
  quantity: string
  level: number
  type: string
}

export interface IPackProject {
  id: ID
  description: string | null
  pickBatch: IPickBatch
  outboundLoadCarriers: ILoadCarrier[]
  packableItems: IPackableItem[]
  nestedLoadCarriersList: INestedLoadCarriersListItem[]
}

export interface IPackSourceItem {
  id: ID
  name: string
  quantity: string
  type: string
  typeForHuman: string
}

export interface IGetPackProjectByIdRequest {
  packProjectId: ID
}

export interface IGetPackProjectByIdResponse extends IGraphQLResponse {
  data: {
    getPackProjectById: IPackProject
  }
}

export interface IGetPackSourceItemRequest {
  packProjectId: ID
  barcode: string
}

export interface IGetPackSourceItemResponse extends IGraphQLResponse {
  data: {
    getPackSourceItem: IPackSourceItem | null
  }
}

export interface IAddPackSourceItemToOutboundLoadCarrierRequest {
  packSourceItemId: ID
  packSourceItemType: ID
  loadCarrierId: ID
  packProjectId: ID
}

export interface IAddPackSourceItemToOutboundLoadCarrierResponse extends IGraphQLResponse {
  data: {
    addPackSourceItemToOutboundLoadCarrier: IPackProject
  }
}

export interface IRegisterableLoadCarrier {
  loadCarrier: IOutboundLoadCarrier
  registered: boolean
}

export interface IGetRegisterableLoadCarriersRequest {
  packProjectId: ID
}

export interface IGetRegisterableLoadCarriersResponse extends IGraphQLResponse {
  data: {
    getRegisterableLoadCarriers: IRegisterableLoadCarrier[]
  }
}

export interface IRegisterLoadCarrierRequest {
  loadCarrierId: ID
  heightInCentimeter: number
  widthInCentimeter: number
  lengthInCentimeter: number
  weightInKilogram: number
}

export interface IRegisterLoadCarrierResponse extends IGraphQLResponse {
  data: {
    registerLoadCarrier: IRegisterableLoadCarrier
  }
}

export interface ICompletePackRequest {
  packProjectId: ID
}

export interface ICompletePackResponse extends IGraphQLResponse {
  data: {
    completePackProject: boolean
  }
}

export interface IRemoveFromOutboundLoadCarrierRequest {
  nestedLoadCarrierItemId: ID
  nestedLoadCarrierItemType: ID
}

export interface IRemoveFromOutboundLoadCarrierResponse extends IGraphQLResponse {
  data: {
    removeFromOutboundLoadCarrier: boolean
  }
}
