<template>
  <page-with-search-subscriber v-slot="{ searchSubscriber }">
    <navigation show-search-icon v-slot="{ searchId }">
      <search-bar
        :scan-subscriber="searchSubscriber"
        :search-id="searchId"
        :search-type="TypeName.GoodsReceipt"
        :search-filters="searchFilters"
        :restricted="true"
        expandable
        @result="onSearchResult"
        @no-result="onNoResult"
      ></search-bar>
    </navigation>
    <items-card-with-loading-state
      title="Receipts"
      :is-loading="isLoading"
      :items="processableItems"
    >
      <f7-list>
        <f7-list-item
          v-for="(processableItem, index) in processableItems"
          :key="index"
          @click="onProcessableItemClick(processableItem)"
        >
          <template #inner>
            <div class="width-100 fill-height">
              <processable-item :processable-item="processableItem" />
            </div>
          </template>
        </f7-list-item>
      </f7-list>
    </items-card-with-loading-state>
  </page-with-search-subscriber>
</template>
<script lang="ts" setup>
import Navigation from '@components/AppNavigation.vue'
import SearchBar from '@components/search/SearchBar.vue'
import useGoodsReceipt from '@composables/useGoodsReceipt'
import useLoading from '@composables/useLoading'
import { IProcessableReceipt, IProcessableReceiptConnection } from '@graphql/goodsReceipt/types'
import { ISearchResult, TypeName } from '@graphql/search/types'
import { f7 } from 'framework7-vue'
import { onMounted, ref } from 'vue'
import { ISearchFilter } from '@services/search/search'
import ProcessableItem from '@pages/goods-receipt/components/ProcessableItem.vue'
import ItemsCardWithLoadingState from '@components/ItemsCardWithLoadingState.vue'
import useSearch from '@composables/useSearch'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'

const searchFilters: ISearchFilter[] = [
  {
    key: 'state',
    operator: 'IN',
    value: '4,10'
  }
]

const { getProcessableReceipts } = useGoodsReceipt()
const { isLoading, withAsyncLoading } = useLoading()
const { onNoResult } = useSearch()

const processableItemConnection = ref<IProcessableReceiptConnection>()
const processableItems = ref<IProcessableReceipt[]>([])

onMounted(async () => {
  await withAsyncLoading(async () => {
    processableItemConnection.value = await getProcessableReceipts()
    processableItems.value = processableItemConnection.value.edges.map((edge) => edge.node)
  })
})

const onProcessableItemClick = (processableItem: IProcessableReceipt) => {
  f7.views.main.router.navigate(`/goodsReceipt/${processableItem.identifier}/`)
}

const onSearchResult = (result: ISearchResult) => {
  if (
    processableItems.value.findIndex((p: IProcessableReceipt) => p.identifier === result.id) !== -1
  ) {
    f7.views.main.router.navigate(`/goodsReceipt/${result.id}/`)
  }
}
</script>
