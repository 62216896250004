import { ActionTree } from 'vuex'
import {
  IProjectOutboundSortState,
  IOutboundSortProject
} from '@store/modules/projects/outbound-sort/types'
import { IRootState } from '@store/types'

const actions: ActionTree<IProjectOutboundSortState, IRootState> = {
  async clearProjects({ commit }) {
    await commit('clearProjects')
  },
  async addProject({ commit }, project: IOutboundSortProject) {
    await commit('add', project)
  },
  async updateProject({ commit }, project: IOutboundSortProject) {
    await commit('update', project)
  },
  async removeProject({ commit }, pickBatchId) {
    await commit('remove', pickBatchId)
  }
}

export default actions
