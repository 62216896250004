<template>
  <page-with-search-subscriber :scan-callback="onScan" :page-content="false">
    <navigation title="Kardex" />
    <f7-page-content ptr :ptr-distance="55" ptr-mousewheel @ptr:refresh="onPullToRefresh">
      <pull-to-refresh-preloader />
      <f7-card title="Details">
        <f7-list>
          <f7-list-item>
            <div class="info" v-if="kardexProject?.inboundSortLoadCarrier">
              <div class="key">Load carrier:</div>
              <div class="value">
                <string-label :value="kardexProject?.inboundSortLoadCarrier.reference" />
              </div>
            </div>
            <div class="info" v-if="kardexProject?.pickBatchPartition">
              <div class="key">Pick batch partition:</div>
              <div class="value">
                <string-label :value="kardexProject?.pickBatchPartition.id" />
              </div>
            </div>
          </f7-list-item>
        </f7-list>
      </f7-card>
      <f7-tabs>
        <f7-tab id="kardex-todo" tab-active>
          <items-card-with-loading-state
            :title="`Todo (${todoCount})`"
            :is-loading="isLoading"
            :items="todoItems"
          >
            <f7-list
              infinite
              :infinite-distance="50"
              :infinite-preloader="isLoadingTodoItems"
              @infinite="onLoadMoreTodoItems"
            >
              <f7-list-item swipeout v-for="(item, index) in todoItems" :key="index">
                <f7-swipeout-actions right>
                  <f7-swipeout-button color="dimass" overswipe close @click="onRetrySelected(item)">
                    <font-awesome-icon
                      :icon="['fad', 'rotate-right']"
                      style="font-weight: bold; font-size: 24px"
                      class="text-color-white"
                    />
                  </f7-swipeout-button>
                </f7-swipeout-actions>
                <template #inner>
                  <kardex-item :item="item" />
                </template>
              </f7-list-item>
            </f7-list>
          </items-card-with-loading-state>
        </f7-tab>
        <f7-tab id="kardex-done">
          <items-card-with-loading-state
            :title="`Done (${doneCount})`"
            :is-loading="isLoading"
            :items="doneItems"
            :no-items-icon="['far', 'empty-set']"
            no-items-title="No items done yet"
            no-items-description="Start processing to see items here"
          >
            <f7-list
              infinite
              :infinite-distance="50"
              :infinite-preloader="isLoadingDoneItems"
              @infinite="onLoadMoreDoneItems"
            >
              <f7-list-item v-for="(item, index) in doneItems" :key="index">
                <kardex-item :item="item" />
              </f7-list-item>
            </f7-list>
          </items-card-with-loading-state>
        </f7-tab>
      </f7-tabs>
    </f7-page-content>
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#kardex-todo" tab-link-active>Todo ({{ todoCount }})</f7-link>
      <f7-link tab-link="#kardex-done">Done ({{ doneCount }})</f7-link>
    </f7-toolbar>
    <f7-fab
      style="margin-bottom: 110px"
      position="right-bottom"
      v-if="isOutbound && doneCount > 0"
      @click="onAddLoadCarrier"
    >
      <font-awesome-icon :icon="['far', 'pallet-boxes']" />
    </f7-fab>
    <f7-fab position="right-bottom">
      <f7-fab-button @click="onCloseProject">
        <f7-icon material="check" />
      </f7-fab-button>
    </f7-fab>
  </page-with-search-subscriber>
</template>

<script setup lang="ts">
import { ID } from '@graphql/types'
import Navigation from '@components/AppNavigation.vue'
import { computed, onMounted, ref, watch } from 'vue'
import useLoading from '@composables/useLoading'
import useKardex from '@composables/useKardex'
import {
  IKardexDoneItem,
  IKardexDoneItemConnection,
  IKardexItem,
  IKardexProject,
  IKardexTodoItem,
  IKardexTodoItemConnection
} from '@graphql/kardex/types'
import StringLabel from '@components/label/StringLabel.vue'
import KardexItem from '@pages/kardex/components/KardexItem.vue'
import { IEvent } from '@/utilities/scanInput'
import { perceptibleToast } from '@services/perceptibleToast'
import { f7 } from 'framework7-vue'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'
import PullToRefreshPreloader from '@components/PullToRefreshPreloader.vue'
import ItemsCardWithLoadingState from '@components/ItemsCardWithLoadingState.vue'
import { captureException } from '@sentry/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { confirmYesNo } from '@/functions/dialog'
import useProjectPick from '@composables/useProjectPick'

const { withAsyncLoading, isLoading } = useLoading()
const { getKardexProjectById, getItem, retryOrder, closeKardexProject } = useKardex()
const { updateProject } = useProjectPick()

const kardexProject = ref<IKardexProject>()
const todoItems = ref<IKardexTodoItem[]>([])
const doneItems = ref<IKardexDoneItem[]>([])
const kardexTodoItemConnection = ref<IKardexTodoItemConnection>()
const kardexDoneItemConnection = ref<IKardexDoneItemConnection>()
const isLoadingTodoItems = ref<boolean>(false)
const isLoadingDoneItems = ref<boolean>(false)

const isOutbound = computed<boolean>(() => kardexProject.value?.pickBatchPartition !== null)

const todoCount = computed<number>(() => kardexTodoItemConnection.value?.totalCount ?? 0)
const doneCount = computed<number>(() => kardexDoneItemConnection.value?.totalCount ?? 0)

const props = defineProps<{
  kardexProjectId: ID
}>()

onMounted(async () => {
  await onPullToRefresh(() => {})
})

watch(todoCount, async (value, oldValue) => {
  if (oldValue === 0 || value > 0) {
    return
  }

  await onCloseProject()
})

const onAddLoadCarrier = async () => {
  confirmYesNo({
    title:
      'Are you sure that you want to proceed with finalizing this load carrier and add another?',
    yesButtonCallback: async () => {
      await withAsyncLoading(async () => {
        f7.views.main.router.navigate('/pick/confirm-picked/', {
          props: {
            pickBatchPartition: kardexProject.value!.pickBatchPartition,
            afterVerificationAction: 'new-load-carrier'
          }
        })
      })
    }
  })
}

const onCloseProject = async () => {
  await onPullToRefresh(() => {
    confirmYesNo({
      title: `Are you sure you want to close the kardex project?`,
      yesButtonCallback: async () => {
        try {
          await closeKardexProject({
            kardexProjectId: props.kardexProjectId
          })

          await perceptibleToast.success('Project closed')

          if (isOutbound.value) {
            f7.views.main.router.navigate(`/pick-entry/`)
            return
          }

          f7.views.main.router.navigate(`/inbound/putaway/`)
        } catch (e) {
          captureException(e)

          await perceptibleToast.error(e.message)
        }
      },
      noButtonCallback: async () => {}
    })
  })
}

const onPullToRefresh = async (done: () => void) => {
  await withAsyncLoading(async () => {
    kardexProject.value = await getKardexProjectById({
      id: props.kardexProjectId
    })

    if (isOutbound.value) {
      await updateProject({
        selectedPickBatchPartition: kardexProject.value.pickBatchPartition!,
        kardexProject: kardexProject.value
      })
    }

    kardexTodoItemConnection.value = kardexProject.value.todoItems

    todoItems.value = kardexTodoItemConnection.value.edges.map((edge) => edge.node)

    kardexDoneItemConnection.value = kardexProject.value.doneItems

    doneItems.value = kardexDoneItemConnection.value.edges.map((edge) => edge.node)

    done()
  })
}

const onScan = async (event: IEvent) => {
  if (kardexProject.value?.pickBatchPartition) {
    return
  }

  await onInboundScan(event)
}

const onInboundScan = async (event: IEvent) => {
  let identifier = String(event.payload)

  if (event.extraData.length > 0) {
    const goodsReceiptLineIdentifier = event.extraData.find((item) => item.startsWith('PUT'))

    if (goodsReceiptLineIdentifier) {
      identifier = goodsReceiptLineIdentifier

      try {
        const kardexItem = await getItem({
          id: props.kardexProjectId,
          barcode: identifier
        })

        event.subscriber.pause()
        f7.views.main.router.navigate(`/inbound/putaway/kardex/${props.kardexProjectId}/process/`, {
          props: {
            kardexItem: kardexItem
          }
        })
      } catch (e) {
        captureException(e)

        await perceptibleToast.error(e.message)
      }
      return
    }
  }

  await perceptibleToast.error('Scan article barcode.')
}

const onLoadMoreTodoItems = async () => {
  const connection = kardexTodoItemConnection.value
  if (!connection || !connection.pageInfo?.hasNextPage) {
    return
  }

  isLoadingTodoItems.value = true

  kardexProject.value = await getKardexProjectById({
    id: props.kardexProjectId,
    todoItemsAfterCursor: connection.pageInfo?.endCursor
  })

  kardexTodoItemConnection.value = kardexProject.value.todoItems

  todoItems.value = [
    ...todoItems.value,
    ...kardexTodoItemConnection.value.edges.map((edge) => edge.node)
  ]

  isLoadingTodoItems.value = false
}

const onLoadMoreDoneItems = async () => {
  const connection = kardexDoneItemConnection.value
  if (!connection || !connection.pageInfo?.hasNextPage) {
    return
  }

  isLoadingDoneItems.value = true

  kardexProject.value = await getKardexProjectById({
    id: props.kardexProjectId,
    doneItemsAfterCursor: connection.pageInfo?.endCursor
  })

  kardexDoneItemConnection.value = kardexProject.value.doneItems

  doneItems.value = [
    ...doneItems.value,
    ...kardexDoneItemConnection.value.edges.map((edge) => edge.node)
  ]

  isLoadingDoneItems.value = false
}

const onItemScan = async (query: string): Promise<IKardexItem> => {
  try {
    return await getItem({
      id: props.kardexProjectId,
      barcode: query
    })
  } catch (e) {
    captureException(e)

    await perceptibleToast.error(e.message)
  }
}

const onRetrySelected = async (item: IKardexTodoItem) => {
  confirmYesNo({
    title: `Is the item on the tray?`,
    yesButtonCallback: async () => {
      await onOrderRetry(item, true)
    },
    noButtonCallback: async () => {
      await onOrderRetry(item, false)
    }
  })
}

const onOrderRetry = async (item: IKardexTodoItem, isItemOnTray: boolean) => {
  try {
    await retryOrder({
      kardexProjectId: props.kardexProjectId,
      identifier: item.identifier,
      isItemOnTray: isItemOnTray
    })

    await perceptibleToast.success('Item retried')

    await onPullToRefresh(() => {})
  } catch (e) {
    captureException(e)

    await perceptibleToast.error(e.message)
  }
}
</script>

<style scoped lang="less">
.tabs {
  margin-bottom: 185px;
}

.info {
  font-size: 12px;

  .value {
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
