import { IConnection, ID, IGraphQLResponse, INode } from '@graphql/types'
import { ILocation } from '@graphql/location/types'

export interface IWarehouse extends INode {
  id: ID
  name: string
  default: boolean
  locations: ILocation[]
  site: any
}

export interface IGetListResponse extends IGraphQLResponse {
  data?: {
    warehouseList: IWarehouse[]
  }
}

export type IWarehouseZoneConnection = IConnection<IWarehouseZone>

export interface IGetWarehouseZonesResponse extends IGraphQLResponse {
  data?: {
    getZones: IWarehouseZoneConnection
  }
}

export interface IWarehouseZone extends INode {
  id: ID
  name: string
  prefix: string
  warehouse: IWarehouse
  functionalType: string
  requiresLocationTypeSelection: boolean
}

export interface IWarehouseZoneSuggestion extends INode {
  warehouseZone: IWarehouseZone
  requiresConfirmation: boolean
}

export const enum FunctionalType {
  STORAGE = 'storage',
  KARDEX_STORAGE = 'kardex_storage',
  QUALITY_CONTROL = 'quality_control',
  INBOUND_SORT = 'inbound_sort'
}
