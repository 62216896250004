<template>
  <f7-card :class="{ 'skeleton-text': isLoading }">
    <f7-card-content>
      <f7-list media-list v-if="doneItemEdges.length > 0">
        <template v-for="doneItemEdge in doneItemEdges" :key="doneItemEdge.cursor">
          <f7-list-item v-if="doneItemEdge.node">
            <template #media>
              {{ getStockQuantity(doneItemEdge.node.article, doneItemEdge.node.doneQuantity) }}
            </template>
            <template #header>
              <slot name="quantities">
                <f7-chip class="chip-small">
                  {{ doneItemEdge.node.compartmentReference }}
                </f7-chip>
              </slot>
            </template>
            <template #subtitle v-if="doneItemEdge.node.article.primaryBarcode">
              {{ doneItemEdge.node.article.primaryBarcode }}
            </template>
            <template #title>
              {{ doneItemEdge.node.article.name }}
            </template>
          </f7-list-item>
        </template>
      </f7-list>
      <icon-with-text
        v-else
        :icon="['far', 'empty-set']"
        title="No done items"
        description="No items done at the moment"
      />
    </f7-card-content>
  </f7-card>
</template>
<script setup lang="ts">
import { IInboundSortDoneItem } from '@graphql/goodsReceipt/types'
import IconWithText from '@components/IconWithText.vue'
import { IEdge } from '@graphql/types'
import useArticle from '@composables/useArticle'

const { getStockQuantity } = useArticle()

withDefaults(
  defineProps<{
    doneItemEdges: IEdge<IInboundSortDoneItem>[]
    isLoading: boolean
  }>(),
  {
    isLoading: false
  }
)
</script>
