import { useLoadCarrierStore } from '@store/pinia/load-carrier/store'
import { IGetByIdQueryRequest } from '@graphql/loadCarrier/types'

export default () => {
  const store = useLoadCarrierStore()

  const getLoadCarrierById = async (request: IGetByIdQueryRequest) => store.getById(request)

  const moveToLocation = async (request) => store.moveToLocation(request)

  const isLoadCarrierBarcode = (value: string) => value.match(/^9[0-9]{7}$/)

  return {
    getLoadCarrierById,
    moveToLocation,
    isLoadCarrierBarcode
  }
}
