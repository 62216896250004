import { defineStore } from 'pinia'

import outboundApi from '@graphql/outbound'
import {
  IGetTransportByIdQueryRequest,
  IGetTodoTransportQueryRequest,
  IGetPickableLoadCarriersQueryRequest,
  IMarkLoadCarrierPickedMutationRequest,
  IFinalizePickOfTransportMutationRequest,
  IStartProcessingOfTransportMutationRequest
} from '@graphql/outbound/types'

export const useOutboundTransportStore = defineStore('outbound-transport', {
  actions: {
    async getTodoTransports(request: IGetTodoTransportQueryRequest) {
      const response = await outboundApi.getTodoTransports(request)

      return response.data.data.getTodoTransports
    },
    async getTransportById(request: IGetTransportByIdQueryRequest) {
      const response = await outboundApi.getTransportById(request)

      return response.data.data.getTransportById
    },
    async getPickableLoadCarriers(request: IGetPickableLoadCarriersQueryRequest) {
      const response = await outboundApi.getPickableLoadCarriers(request)

      return response.data.data.getPickableLoadCarriers
    },
    async markLoadCarrierPicked(request: IMarkLoadCarrierPickedMutationRequest) {
      const response = await outboundApi.markLoadCarrierPicked(request)

      return response.data.data.markLoadCarrierPicked
    },
    async finalizePickOfTransport(request: IFinalizePickOfTransportMutationRequest) {
      const response = await outboundApi.finalizePickOfTransport(request)

      return response.data.data.finalizePick
    },
    async startProcessingOfTransport(request: IStartProcessingOfTransportMutationRequest) {
      const response = await outboundApi.startProcessingOfTransport(request)

      return response.data.data.startProcessingOfTransport
    }
  }
})
