<template>
  <f7-page :page-content="false">
    <app-navigation />
    <default-popup-header :popup-close="false" />
    <f7-page-content>
      <f7-card :class="{ 'skeleton-text': isLoading }">
        <f7-card-content>
          <icon-with-text
            :icon="['far', 'conveyor-belt-empty']"
            title="Scan a sort load carrier!"
            description="To start the sort process at least one sort load carrier barcode must be scanned!"
          />
        </f7-card-content>
      </f7-card>
    </f7-page-content>
  </f7-page>
</template>
<script lang="ts" setup>
import DefaultPopupHeader from '@components/DefaultPopupHeader.vue'
import IconWithText from '@components/IconWithText.vue'
import AppNavigation from '@components/AppNavigation.vue'
import { perceptibleToast } from '@services/perceptibleToast'
import { f7 } from 'framework7-vue'
import useLoading from '@composables/useLoading'
import { onBeforeUnmount } from 'vue'
import { eventBus } from '@/utilities/scanInput'
import { toast } from '@services/toast'
import { ID } from '@graphql/types'
import useProjectOutboundSort from '@composables/useProjectOutboundSort'
import useOutboundSort from '@composables/useOutboundSort'

const props = defineProps<{
  pickLoadCarrierId: ID
}>()

const { withAsyncLoading, isLoading } = useLoading()
const { getLoadCarrierByReference } = useOutboundSort()
const { activeItem, updateProject } = useProjectOutboundSort()

async function onScanInput(query: string) {
  try {
    const sortLoadCarrier = await getLoadCarrierByReference({
      pickLoadCarrierId: props.pickLoadCarrierId,
      reference: query
    })

    if (!sortLoadCarrier) {
      await perceptibleToast.noSearchHit(
        `No sort load carrier found with reference ${query} for pick load carrier #${props.pickLoadCarrierId} found!`
      )

      return
    }

    await unsubscribe()

    await perceptibleToast.success(`Sort load carrier #${query} found!`)

    if (activeItem.value) {
      activeItem.value.isBulkSortCompleted = false
      await updateProject(activeItem.value)
    }

    await f7.views.main.router.navigate(`/outbound-sort/entry/`)
  } catch (e: any) {
    await toast.error(e.message).open()
  }
}

const unsubscribe = eventBus.on('scanInput', async (event) => {
  await withAsyncLoading(async () => {
    await onScanInput(String(event.payload))
  })
})

onBeforeUnmount(() => {
  unsubscribe()
})
</script>
