import { useKardexStore } from '@store/pinia/kardex/store'
import {
  ICompleteKardexProjectRequest,
  IGetItemRequest,
  IGetKardexProjectByIdRequest,
  IRetryOrderRequest
} from '@graphql/kardex/types'

export default () => {
  const store = useKardexStore()

  const getKardexProjectById = async (request: IGetKardexProjectByIdRequest) =>
    store.getKardexProjectById(request)

  const getItem = async (request: IGetItemRequest) => store.getItem(request)

  const retryOrder = async (request: IRetryOrderRequest) => store.retryOrder(request)
  const closeKardexProject = async (request: ICompleteKardexProjectRequest) =>
    store.completeKardexProject(request)

  return {
    getKardexProjectById,
    getItem,
    retryOrder,
    closeKardexProject
  }
}
