<template>
  <span v-if="!html">{{ currentValue }}</span>
  <span v-else v-html="currentValue"></span>
</template>
<script lang="ts" setup>
import { stringLimit } from '@/utilities/string'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    value?: string | number | null
    fallbackValue?: string | number
    limit?: number
    excerpt?: string
    html?: boolean
  }>(),
  {
    value: undefined,
    fallbackValue: 'N/A',
    limit: undefined,
    excerpt: '',
    html: false
  }
)

const currentValue = computed<string>(() => {
  let value = ''
  if (props.value) {
    value = String(props.value)
  }

  if (value === '') {
    value = props.fallbackValue.toString()
  }

  if (props.limit) {
    return stringLimit(value.toString(), props.limit, props.excerpt)
  }

  return value
})
</script>
