import Base from '@graphql/base'
import GRAPHQL_API_CLIENT from '@graphql/client'
import {
  IBookReadyRequest,
  IDeleteGoodsEntryRequest,
  IGetProcessableReceiptsResponse,
  IGetSortableItemsRequest,
  IGetSortableItemsResponse,
  IGetWarehouseZoneSuggestionByGoodsReceiptLineRequest,
  IGetWarehouseZoneSuggestionByGoodsReceiptLineResponse,
  IGoodsEntryQueryContext,
  IGoodsEntryRequest,
  IGoodsReceiptLinePrintArticleLabelRequest,
  IGoodsReceiptLinePrintArticleLabelResponse,
  IGoodsReceiptQueryContext,
  IGoodsReceiptResponse,
  IStartProcessingRequest
} from '@graphql/goodsReceipt/types'
import { AxiosInstance } from 'axios'
import { ID } from '@graphql/types'

class GoodsReceipt extends Base {
  constructor(protected axios: AxiosInstance) {
    super(axios, 'goodsReceipt')
  }

  public async getGoodsReceipt(context: IGoodsReceiptQueryContext) {
    return await this.makeRequest('GoodsReceiptQuery', context)
  }

  public async getGoodsEntry(id: ID) {
    return await this.makeRequest<IGoodsReceiptResponse>('GoodsEntryQuery', { id })
  }

  public async getGoodsEntries(context: IGoodsEntryQueryContext) {
    return await this.makeRequest('GoodsEntriesQuery', context)
  }

  public async createGoodsEntry(context: IGoodsEntryRequest) {
    return await this.makeRequest('GoodsEntryMutation', context)
  }

  public async removeGoodsEntry(context: IDeleteGoodsEntryRequest) {
    return await this.makeRequest('DeleteGoodsEntryMutation', context)
  }

  public async markAsBookReady(context: IBookReadyRequest) {
    return await this.makeRequest('BookReadyMutation', context)
  }

  public async getProcessableReceipts() {
    return await this.makeRequest<IGetProcessableReceiptsResponse>('GetProcessableReceiptsQuery')
  }

  public async startProcessing(context: IStartProcessingRequest) {
    return await this.makeRequest('StartProcessingMutation', context)
  }

  public async goodsReceiptLinePrintArticleLabel(
    request: IGoodsReceiptLinePrintArticleLabelRequest
  ) {
    return await this.makeRequest<IGoodsReceiptLinePrintArticleLabelResponse>(
      'GoodsReceiptLinePrintArticleLabelQuery',
      request
    )
  }

  public async getSortableItems(request: IGetSortableItemsRequest) {
    return await this.makeRequest<IGetSortableItemsResponse>('GetSortableItemsQuery', request)
  }

  public async getWarehouseZoneSuggestionByGoodsReceiptLine(
    request: IGetWarehouseZoneSuggestionByGoodsReceiptLineRequest
  ) {
    return await this.makeRequest<IGetWarehouseZoneSuggestionByGoodsReceiptLineResponse>(
      'GetWarehouseZoneSuggestionQuery',
      request
    )
  }
}

export default new GoodsReceipt(GRAPHQL_API_CLIENT)
