import { IConnection, ID, IGraphQLResponse } from '@graphql/types'
import { ILocation } from '@graphql/location/types'
import { IUser } from '@store/modules/auth/types'
import { IGoodsReceipt } from '@graphql/goodsReceipt/types'
import { ILoadCarrierItem, LoadCarrierItemConnection } from '@graphql/pick/types'
import { IWarehouseZone } from '@graphql/warehouse/types'
import { ISearchResult } from '@graphql/search/types'

export interface IInboundReception {
  id: ID
  startedAt: Date
  colli: IInboundCollo[]
  state: string
  stateForHuman: string
  createdAt: Date
  updatedAt: Date
  internalReference: string
  notes: string | null
}

export interface IInboundCollo extends ISearchResult {
  id: ID
  barcode: string
  internalReference: string
  type: string
  typeForHuman: string
  createdAt: Date
  updatedAt: Date
  location: ILocation | null
  reception: IInboundReception
  putAwayAt: Date | null
  putAwayBy: IUser | null
  goodsReceipts: IGoodsReceipt[]
}

export interface IQuantityInboundColloTypeInput {
  type: string
  quantity: number
}

export interface IProcessedColloInput {
  barcode: string
  type: string
  locationId: ID
}

export type IInboundColloConnection = IConnection<IInboundCollo>

export interface IRegisterReceptionMutationRequest {
  startedAt: Date
  colli: IQuantityInboundColloTypeInput[]
  notes: string | null
}

export interface IRegisterReceptionMutationResponse extends IGraphQLResponse {
  data: {
    registerReception: IInboundReception
  }
}

export interface IGetReceptionByIdQueryRequest {
  id: ID
}

export interface IGetReceptionByIdQueryResponse extends IGraphQLResponse {
  data: {
    getReceptionById: IInboundReception
  }
}

export interface IGetColloByIdQueryRequest {
  id: ID
}

export interface IUpdateColloMutationRequest {
  colloId: ID
  locationId: ID
}

export interface IUpdateColloMutationResponse extends IGraphQLResponse {
  data: {
    updateCollo: IInboundCollo
  }
}

export interface IGetColloByIdQueryResponse extends IGraphQLResponse {
  data: {
    getColloById: IInboundCollo
  }
}

export interface IGetReceptionsQueryResponse extends IGraphQLResponse {
  data: {
    getReceptions: IInboundReception[]
  }
}

export const enum ColloType {
  EURO_PALLET = 'euro_pallet',
  BLOCK_PALLET = 'block_pallet',
  COLLI = 'colli',
  CONTAINER_20_FT = 'container_20ft',
  CONTAINER_40_FT = 'container_40ft',
  CONTAINER_40_FT_HIGH_CUBE = 'container_40ft_high_cube'
}

export const colloTypeOptions: KeyValuePair<string, string> = [
  { key: ColloType.COLLI, value: 'Colli' },
  { key: ColloType.EURO_PALLET, value: 'Euro pallet' },
  { key: ColloType.BLOCK_PALLET, value: 'Block pallet' },
  { key: ColloType.CONTAINER_20_FT, value: 'Container 20 feet' },
  { key: ColloType.CONTAINER_40_FT, value: 'Container 40 feet' },
  { key: ColloType.CONTAINER_40_FT_HIGH_CUBE, value: 'Container 40 feet high cube' }
]

export interface IGetUncompletedInboundSortLoadCarriersByWarehouseZoneRequest {
  warehouseZoneId: ID
}

export interface ICreateInboundSortLoadCarrierRequest {
  reference: string
  warehouseZoneId: ID
}

export interface IInboundSortLoadCarrier {
  sequence: string | null
  createdAt: Date
  updatedAt: Date
  user: IUser
  startedAt: Date | null
  startedBy: IUser | null
  completedAt: Date | null
  completedBy: IUser | null
  blockedAt: Date | null
  blockedBy: IUser | null
  packedAt: Date | null
  state: string
  stateForHuman: string
  type: string
  typeForHuman: string
  reference: string | null
  verificationCode: string
  loadCarrierItems: LoadCarrierItemConnection
  warehouseZone: IWarehouseZone
  id: ID
}

export type IInboundSortLoadCarriersConnection = IConnection<IInboundSortLoadCarrier>

export interface IGetUncompletedInboundSortLoadCarriersByWarehouseZoneResponse
  extends IGraphQLResponse {
  data: {
    getUncompletedInboundSortLoadCarriersByZone: IInboundSortLoadCarriersConnection
  }
}

export interface ICreateInboundSortLoadCarrierResponse extends IGraphQLResponse {
  data: {
    createInboundSortLoadCarrier: IInboundSortLoadCarrier
  }
}

export interface IAddItemToInboundSortLoadCarrierRequest {
  goodsReceiptLineId: ID
  amount: number
  compartmentReference: string
  articleWeight?: number
  locationTypeId?: ID | null
}

export interface IAddItemToInboundSortLoadCarrierResponse extends IGraphQLResponse {
  data: {
    addItemToInboundSortLoadCarrier: boolean
  }
}

export interface IMarkInboundSortLoadCarrierAsSortedResponse extends IGraphQLResponse {
  data: {
    markInboundSortLoadCarrierAsSorted: boolean
  }
}

export interface IMarkInboundSortLoadCarrierAsSortedRequest {
  loadCarrierId: ID
}

export interface IInboundPutawayItem {
  identifier: ID
  description: string
  quantity: string
  type: string
  warehouseZone: IWarehouseZone
  readyAt: Date
}

export type IInboundPutawayItemConnection = IConnection<IInboundPutawayItem>

export interface IGetPutawayItemsResponse extends IGraphQLResponse {
  data: {
    getPutawayItems: IInboundPutawayItemConnection
  }
}

export interface IGetPutawayItemsRequest extends IGraphQLResponse {
  afterCursor?: string
}

export interface IGetInboundSortLoadCarrierItemsByLoadCarrierIdRequest extends IGraphQLResponse {
  inboundSortLoadCarrierId: ID
  afterCursor?: string
}

export type IInboundSortLoadCarrierItemConnection = IConnection<ILoadCarrierItem>

export interface IGetInboundSortLoadCarrierItemsByLoadCarrierIdResponse extends IGraphQLResponse {
  data: {
    getInboundSortLoadCarrierItemsById: IInboundSortLoadCarrierItemConnection
  }
}

export interface IInboundPutawaySource {
  identifier: ID
  description: string
  quantity: string
  type: string
  inboundSortLoadCarrier: IInboundSortLoadCarrier
}

export interface IGetPutawaySourceResponse extends IGraphQLResponse {
  data: {
    getPutawaySource: IInboundPutawaySource
  }
}

export interface IGetPutawaySourceRequest {
  loadCarrierId: ID
  identifier: string
}

export interface IPutawayItemToLocationRequest {
  loadCarrierId: ID
  identifier: ID
  locationCode: string
}

export interface IPutawayItemToLocationResponse extends IGraphQLResponse {
  data: {
    putawayItemToLocation: IPutawayItem
  }
}

export interface IGetInboundSortLoadCarrierByIdRequest {
  loadCarrierId: ID
  itemAfterCursor?: string
}

export interface IGetInboundSortLoadCarrierByIdResponse extends IGraphQLResponse {
  data: {
    getInboundSortLoadCarrierById: IInboundSortLoadCarrier
  }
}

export interface IAddColliToReceptionRequest {
  receptionId: ID
  colli: IQuantityInboundColloTypeInput[]
}

export interface IAddColliToReceptionResponse extends IGraphQLResponse {
  data: {
    addColliToReception: IInboundReception
  }
}

export interface IPutawayToKardexRequest {
  kardexProjectId: ID
  goodsReceiptLineId: ID
  kardexBarcode: string
}

export interface IPutawayToKardexResponse extends IGraphQLResponse {
  data: {
    putawayToKardex: boolean
  }
}
