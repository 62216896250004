import Base from '@graphql/base'
import { AxiosInstance } from 'axios'
import GRAPHQL_API_CLIENT from './client'
import {
  IGetTransportByIdQueryRequest,
  IGetTransportByIdQueryResponse,
  IGetTodoTransportQueryRequest,
  IGetTodoTransportsQueryResponse,
  IGetPickableLoadCarriersQueryRequest,
  IGetPickableLoadCarriersQueryResponse,
  IMarkLoadCarrierPickedMutationRequest,
  IMarkLoadCarrierPickedMutationResponse,
  IFinalizePickOfTransportMutationRequest,
  IFinalizePickOfTransportMutationResponse,
  IStartProcessingOfTransportMutationRequest
} from '@graphql/outbound/types'
import { ISavePickBatchLineStateMutationResponse } from '@graphql/pick/types'

export class Outbound extends Base {
  constructor(axios: AxiosInstance) {
    super(axios, 'outbound')
  }

  async getTodoTransports(request: IGetTodoTransportQueryRequest) {
    return this.makeRequest<IGetTodoTransportsQueryResponse>('getTodoTransportsQuery', request)
  }

  async getTransportById(request: IGetTransportByIdQueryRequest) {
    return this.makeRequest<IGetTransportByIdQueryResponse>('getTransportByIdQuery', request)
  }

  async getPickableLoadCarriers(request: IGetPickableLoadCarriersQueryRequest) {
    return this.makeRequest<IGetPickableLoadCarriersQueryResponse>(
      'getPickableLoadCarriersQuery',
      request
    )
  }

  async markLoadCarrierPicked(request: IMarkLoadCarrierPickedMutationRequest) {
    return this.makeRequest<IMarkLoadCarrierPickedMutationResponse>(
      'markLoadCarrierPickedMutation',
      request
    )
  }

  async finalizePickOfTransport(request: IFinalizePickOfTransportMutationRequest) {
    return this.makeRequest<IFinalizePickOfTransportMutationResponse>(
      'finalizePickOfTransportMutation',
      request
    )
  }

  async startProcessingOfTransport(request: IStartProcessingOfTransportMutationRequest) {
    return this.makeRequest<ISavePickBatchLineStateMutationResponse>(
      'startProcessingOfTransportMutation',
      request
    )
  }
}

export default new Outbound(GRAPHQL_API_CLIENT)
