import { SearchResults } from '@store/modules/search/types'
import { ID, INode } from '@graphql/types'

export interface IColorMap extends INode {
  backgroundColor: string
  foregroundColor: string
}

export interface ILocationProperties extends INode {
  heightCode: string
  colorMap: IColorMap
}

export interface ICompany extends INode {
  id: ID
  name: string
  code: string
  packingControl: boolean
}

export interface ISearch {
  search: SearchResults
}

export interface ISearchResult extends INode {
  searchPreview: string
  id: ID
}

export const enum TypeName {
  Article = 'Article',
  Location = 'Location',
  Company = 'Company',
  Collo = 'Collo',
  ArticleLocation = 'ArticleLocation',
  StockLevel = 'StockLevel',
  LocationProperties = 'LocationProperties',
  PurchaseOrder = 'PurchaseOrder',
  PurchaseOrderLine = 'PurchaseOrderLine',
  PurchaseOrderState = 'PurchaseOrderState',
  GoodsReceipt = 'GoodsReceipt',
  GoodsReceiptLine = 'GoodsReceiptLine',
  Shipment = 'Shipment',
  ShipmentLine = 'ShipmentLine',
  PickBatch = 'PickBatch',
  PickBatchPartition = 'PickBatchPartition',
  PickBatchLine = 'PickBatchLine',
  PickBatchLineItem = 'PickBatchLineItem',
  LoadCarrier = 'LoadCarrier',
  InboundReception = 'InboundReception',
  InboundCollo = 'InboundCollo'
}
