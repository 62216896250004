<template>
  <f7-page>
    <navigation />
    <f7-block-title class="margin-top" :class="{ 'skeleton-text': isLoading }">
      Settings
    </f7-block-title>
    <f7-card :class="{ 'skeleton-text': isLoading }">
      <f7-card-content>
        <f7-list>
          <f7-list-input
            label="Pack station"
            type="select"
            :value="settings.packStation"
            @input="settings.packStation = $event.target.value"
          >
            <option value="">Select pack station</option>
            <option
              v-for="packStationItem in packStations"
              :value="packStationItem.code"
              :key="packStationItem.code"
            >
              {{ packStationItem.description }}
            </option>
          </f7-list-input>
        </f7-list>
      </f7-card-content>
    </f7-card>
    <f7-block>
      <f7-row>
        <f7-col>
          <f7-button large fill-md color="text-secondary" :disabled="isLoading" @click="onSubmit">
            Save
          </f7-button>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>
<script lang="ts" setup>
import useLoading from '@composables/useLoading'
import useSettings from '@composables/useSettings'
import { IPackStation } from '@graphql/pack/types'
import Navigation from '@components/AppNavigation.vue'
import { toast } from '@services/toast'
import { reactive, ref } from 'vue'

const { getPackStations, packStation, updatePackStation } = useSettings()
const { isLoading, toggleLoading } = useLoading()
const packStations = ref<Partial<IPackStation>[]>([])
const settings = reactive({
  packStation: packStation.value ? packStation.value.code : ''
})

isLoading.value = true
getPackStations().then((response) => {
  packStations.value = response.data.getPackStations
  toggleLoading()
})

const onSubmit = async () => {
  isLoading.value = true
  const index = packStations.value.findIndex((p) => p.code === settings.packStation)

  await updatePackStation(packStations.value[index] ?? undefined)

  toast.success('Settings saved successfully!').open()
  toggleLoading()
}
</script>
