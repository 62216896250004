import DetailPage from '@pages/load-carrier/DetailPage.vue'
import PutawayPage from '@pages/load-carrier/PutawayPage.vue'

const loadCarrier: Partial<Router.RouteParameters>[] = [
  {
    path: '/load-carrier/:id/',
    component: DetailPage
  },
  {
    path: '/load-carrier/:id/putaway/',
    component: PutawayPage
  }
]

export default loadCarrier
