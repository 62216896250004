<template>
  <f7-list-item swipeout>
    <template #media>
      <slot name="quantity">
        {{ getDeliveredQty(goodsReceiptLine) }}
      </slot>
    </template>
    <template #header>
      <slot name="quantities">
        {{ goodsReceiptLine.amountExpected }} expected,
        {{ getDeliveredQty(goodsReceiptLine) }} delivered
      </slot>
    </template>
    <template #subtitle>
      {{ goodsReceiptLine.purchaseOrderLine.article.primaryBarcode }}
      <br />
      <span
        v-if="
          goodsReceiptLine.goodsReceiptLineLocation &&
          goodsReceiptLine.goodsReceiptLineLocation.length > 0
        "
      >
        {{ flattenGoodsLocations(goodsReceiptLine.goodsReceiptLineLocation) }}
      </span>
    </template>
    <template #title>{{ goodsReceiptLine.purchaseOrderLine.article.name }}</template>
    <f7-swipeout-actions left>
      <f7-swipeout-button color="dimass" overswipe @click="onLineClick(goodsReceiptLine)">
        <f7-icon material="add_location" class="text-color-white" />
      </f7-swipeout-button>
    </f7-swipeout-actions>
    <f7-swipeout-actions right>
      <f7-swipeout-button
        color="dimass"
        :overswipe="!packStation"
        close
        @click="onArticleDetailClick(goodsReceiptLine.purchaseOrderLine.article)"
      >
        <f7-icon material="inventory" class="text-color-white" />
      </f7-swipeout-button>
      <f7-swipeout-button v-if="packStation" close @click="onPrintArticleLabel(goodsReceiptLine)">
        <font-awesome-icon :icon="['far', 'print']" />
      </f7-swipeout-button>
    </f7-swipeout-actions>
  </f7-list-item>
</template>
<script lang="ts" setup>
import { IGoodsReceiptLine } from '@graphql/goodsReceipt/types'
import { IArticle } from '@graphql/article/types'
import useGoodsReceipt from '@composables/useGoodsReceipt'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import useSettings from '@composables/useSettings'

const { flattenGoodsLocations, getDeliveredQty } = useGoodsReceipt()
const { packStation } = useSettings()

defineProps<{
  goodsReceiptLine: IGoodsReceiptLine
}>()

const emit = defineEmits<{
  (e: 'article-detail', article)
  (e: 'goods-entry', goodsEntry)
  (e: 'print-article-label', goodsEntry)
}>()

const onArticleDetailClick = async (article: IArticle) => {
  emit('article-detail', article)
}

const onLineClick = async (goodsReceiptLine: IGoodsReceiptLine) => {
  emit('goods-entry', goodsReceiptLine)
}

const onPrintArticleLabel = async (goodsReceiptLine: IGoodsReceiptLine) => {
  emit('print-article-label', goodsReceiptLine)
}
</script>
