<template>
  <f7-page>
    <navigation />
    <f7-card>
      <f7-card-header>Print labels</f7-card-header>
      <f7-card-content>
        <f7-button large fill @click="onPrint">Print</f7-button>
      </f7-card-content>
    </f7-card>

    <f7-button large @click="navigateToNextPage">Skip</f7-button>

    <scan-to-print-popup
      v-if="showScanNearbyPrinterPopup"
      v-model="showScanNearbyPrinterPopup"
      :identifier="pickBatchPartitionId"
      :type-name="TypeName.PickBatchPartition"
      :document-kind="DocumentKind.ARTICLE_LABELS"
      requires-confirmation
      @on-success="navigateToNextPage"
    />
  </f7-page>
</template>
<script lang="ts" setup>
import Navigation from '@components/AppNavigation.vue'
import { ID } from '@graphql/types'
import { ref } from 'vue'
import { TypeName } from '@graphql/search/types'
import useProjectPick from '@composables/useProjectPick'
import { f7 } from 'framework7-vue'
import ScanToPrintPopup from '@components/document/ScanToPrintPopup.vue'
import { DocumentKind } from '@graphql/document/types'

const { updatePrintedDocuments } = useProjectPick()

const showScanNearbyPrinterPopup = ref<boolean>(false)

defineProps<{
  pickBatchPartitionId: ID
}>()

const onPrint = () => {
  showScanNearbyPrinterPopup.value = true
}

const navigateToNextPage = async () => {
  await updatePrintedDocuments(true)

  f7.views.main.router.navigate('/pick-entry/')
}
</script>
