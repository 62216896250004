import { MutationTree } from 'vuex'
import { IPackProject, IProjectPackState } from '@store/modules/projects/pack/types'
import { ID } from '@graphql/types'

const mutations: MutationTree<IProjectPackState> = {
  addPackProject(state, project: IPackProject) {
    const exists = state.items.find((i) => i.id === project.id)

    if (exists) {
      return
    }

    state.items.push(project)
    state.activeItem = project
  },

  updatePackProject(state, project: IPackProject) {
    const index = state.items.findIndex((i) => i.id === project.id)

    if (index < 0) {
      return
    }

    state.items[index] = project
    state.activeItem = project
  },
  removePackProject(state, projectId: ID) {
    const index = state.items.findIndex((i) => i.id == projectId)

    if (index !== -1) {
      state.items.splice(index, 1)
    }

    if (projectId === state.activeItem?.id) {
      state.activeItem = null
    }
  },
  removeProjects(state) {
    state.items = []
    state.activeItem = null
  }
}

export default mutations
