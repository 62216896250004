import Base from '@graphql/base'
import {
  IAddPackSourceItemToOutboundLoadCarrierRequest,
  IAddPackSourceItemToOutboundLoadCarrierResponse,
  IBulkMarkPrePackedRequest,
  IBulkMarkPrePackedResponse,
  IGetLoadCarrierByIdResponse,
  IGetPackableItemsByLoadCarrierIdRequest,
  IGetPackableItemsByLoadCarrierIdResponse,
  IGetPackProjectByIdRequest,
  IGetPackProjectByIdResponse,
  IGetPackingListByContextRequest,
  IGetPackingListByContextResponse,
  IGetPackReadyItemResponse,
  IGetPackStationsResponse,
  IGetRegisterableLoadCarriersRequest,
  IGetRegisterableLoadCarriersResponse,
  IMarkPackedRequest,
  IMarkPackedResponse,
  IPrePackColloRequest,
  IPrePackColloResponse,
  IRegisterLoadCarrierRequest,
  IRegisterLoadCarrierResponse,
  IStartPackRequest,
  IStartPackResponse,
  ICompletePackRequest,
  ICompletePackResponse,
  IRemoveFromOutboundLoadCarrierRequest,
  IRemoveFromOutboundLoadCarrierResponse,
  IGetPackSourceItemResponse,
  IGetPackSourceItemRequest
} from '@graphql/pack/types'
import { AxiosInstance } from 'axios'
import GRAPHQL_API_CLIENT from './client'
import { IGetLoadCarrierByIdRequest } from '@graphql/pick/types'

class Pack extends Base {
  constructor(axios: AxiosInstance) {
    super(axios, 'pack')
  }

  public async getPackStations() {
    return this.makeRequest<IGetPackStationsResponse>('getPackStationsQuery')
  }

  public async getPackableItemsByLoadCarrierId(request: IGetPackableItemsByLoadCarrierIdRequest) {
    return this.makeRequest<IGetPackableItemsByLoadCarrierIdResponse>(
      'getPackableItemsByLoadCarrierIdQuery',
      request
    )
  }

  public async getPackingListByContext(request: IGetPackingListByContextRequest) {
    return this.makeRequest<IGetPackingListByContextResponse>('getPackingListByContextQuery', {
      input: request
    })
  }

  public async markPacked(request: IMarkPackedRequest) {
    return this.makeRequest<IMarkPackedResponse>('markPackedMutation', request)
  }

  public async bulkMarkPrePacked(request: IBulkMarkPrePackedRequest) {
    return this.makeRequest<IBulkMarkPrePackedResponse>('bulkMarkPrePackedMutation', request)
  }

  public async prePackCollo(request: IPrePackColloRequest) {
    return this.makeRequest<IPrePackColloResponse>('prePackColloMutation', request)
  }

  public async getLoadCarrierById(request: IGetLoadCarrierByIdRequest) {
    return this.makeRequest<IGetLoadCarrierByIdResponse>('getLoadCarrierByIdQuery', request)
  }

  public async getPackReadyItems() {
    return this.makeRequest<IGetPackReadyItemResponse>('getPackReadyItemsQuery')
  }

  async startPack(request: IStartPackRequest) {
    return this.makeRequest<IStartPackResponse>('startPackMutation', request)
  }

  async getPackProjectById(request: IGetPackProjectByIdRequest) {
    return this.makeRequest<IGetPackProjectByIdResponse>('getPackProjectByIdQuery', request)
  }

  async getPackSourceItem(request: IGetPackSourceItemRequest) {
    return this.makeRequest<IGetPackSourceItemResponse>('getPackSourceItemQuery', request)
  }

  async addPackSourceItemToOutboundLoadCarrier(
    request: IAddPackSourceItemToOutboundLoadCarrierRequest
  ) {
    return this.makeRequest<IAddPackSourceItemToOutboundLoadCarrierResponse>(
      'addPackSourceItemToOutboundLoadCarrierMutation',
      request
    )
  }

  async getRegisterableLoadCarriers(request: IGetRegisterableLoadCarriersRequest) {
    return this.makeRequest<IGetRegisterableLoadCarriersResponse>(
      'getRegisterableLoadCarriersQuery',
      request
    )
  }

  async registerLoadCarrier(request: IRegisterLoadCarrierRequest) {
    return this.makeRequest<IRegisterLoadCarrierResponse>('registerLoadCarrierMutation', request)
  }

  async completePack(request: ICompletePackRequest) {
    return this.makeRequest<ICompletePackResponse>('completePackProjectMutation', request)
  }

  async removeFromOutboundLoadCarrier(request: IRemoveFromOutboundLoadCarrierRequest) {
    return this.makeRequest<IRemoveFromOutboundLoadCarrierResponse>(
      'removeFromOutboundLoadCarrierMutation',
      request
    )
  }
}

export default new Pack(GRAPHQL_API_CLIENT)
