<template>
  <f7-page>
    <load-carrier-scan-to-print
      :verification-popup-opened="verificationPopupOpened"
      @print-documents="onPrintVerificationLabel"
      @confirm-barcode="onMarkCompleted"
      @verification-popup-closed="verificationPopupOpened = false"
    >
      <template #page-title>
        Finalize load carrier for pick batch partition #{{ pickBatchPartition.id }}
      </template>
      <template #page-content>
        <icon-with-text
          :icon="['far', 'print']"
          title="Scan a nearby label printer"
          description="Print the control label to finalize the picked items for this load carrier. And place the load carrier in the buffer-zone displayed on the printed label."
        />
      </template>
    </load-carrier-scan-to-print>
  </f7-page>
</template>
<script lang="ts" setup>
import useLoading from '@composables/useLoading'
import useProjectPick from '@composables/useProjectPick'
import { soundBoard } from '@services/sound'
import { f7 } from 'framework7-vue'
import { perceptibleToast } from '@services/perceptibleToast'
import { DocumentKind } from '@graphql/document/types'
import useDocument from '@composables/useDocument'
import { TypeName } from '@graphql/search/types'
import { perceptibleNotification } from '@services/perceptibleNotification'
import LoadCarrierScanToPrint from '@components/LoadCarrierScanToPrint.vue'
import { ref } from 'vue'
import IconWithText from '@components/IconWithText.vue'
import { IPickBatchPartition } from '@graphql/pick/types'
import { captureException } from '@sentry/vue'

const { isLoading, withAsyncLoading } = useLoading()
const { markPicked, removeProject, createNewPickLoadCarrier } = useProjectPick()
const { printDocumentsFor } = useDocument()

const verificationPopupOpened = ref<boolean>(false)

const props = withDefaults(
  defineProps<{
    pickBatchPartition: IPickBatchPartition
    afterVerificationAction?: string
  }>(),
  {
    afterVerificationAction: 'mark-picked'
  }
)

const onPrintVerificationLabel = async (printerCode: string) => {
  await withAsyncLoading(async () => {
    await printDocumentsFor({
      context: {
        identifier: props.pickBatchPartition.id,
        type: TypeName.PickBatchPartition,
        documentKind: DocumentKind.PICK_LABELS,
        printerCode: printerCode
      }
    })
    await soundBoard.playSuccessSound()
    verificationPopupOpened.value = true
  })
}

const onMarkCompleted = async (verificationCode: string) => {
  await withAsyncLoading(async () => {
    if (props.afterVerificationAction === 'new-load-carrier') {
      await onCreateNewLoadCarrier({
        pickBatchPartitionId: props.pickBatchPartition.id,
        verificationCode
      })
      return
    }

    await onMarkPicked(verificationCode)
  })
}

const onMarkPicked = async (verificationCode: string) => {
  try {
    let pickBatchPartitionId = props.pickBatchPartition.id
    const response = await markPicked({
      pickBatchPartitionId,
      verificationCode
    })

    verificationPopupOpened.value = false

    if (response.pickCompleted) {
      await removeProject(pickBatchPartitionId)

      await perceptibleNotification.finishedTask(
        `Pick batch partition #"${pickBatchPartitionId}" completed!`
      )

      await f7.views.main.router.navigate('/home/', {
        clearPreviousHistory: true
      })

      return
    }

    await perceptibleToast.success(
      `Load carrier successfully finalized for pick batch #${pickBatchPartitionId}!`
    )

    await f7.views.main.router.navigate(`/pick-entry/`)
  } catch (e: any) {
    await perceptibleToast.error(e.message)
    isLoading.value = false
    throw e
  }
}

const onCreateNewLoadCarrier = async (verificationCode: string) => {
  try {
    const loadCarrier = createNewPickLoadCarrier(verificationCode)

    if (!loadCarrier) {
      await perceptibleToast.error('Could not create new load carrier for current partition!')
    }

    verificationPopupOpened.value = false

    await f7.views.main.router.navigate(`/pick-entry/`)
  } catch (e) {
    captureException(e)
    await perceptibleToast.error(e.message)
    isLoading.value = false

    throw e
  }
}
</script>
