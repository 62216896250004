<template>
  <f7-card :class="{ 'skeleton-text': isLoading }">
    <f7-card-content>
      <f7-list media-list>
        <f7-list-item>
          <template #header>Goods receipt #</template>
          <template #title>
            <string-label :value="goodsReceipt.id" />
          </template>
        </f7-list-item>
        <f7-list-item v-if="purchaseOrder">
          <template #header>Purchase order</template>
          <template #title>
            <string-label :value="purchaseOrder?.searchPreview" />
          </template>
        </f7-list-item>
        <f7-list-item>
          <template #header>Supplier</template>
          <template #title>
            <string-label :value="goodsReceipt.supplier.name" />
          </template>
        </f7-list-item>
        <f7-list-item>
          <template #header>Reception date</template>
          <template #title>
            <date-time-label :value="goodsReceipt.deliveredAt" />
          </template>
        </f7-list-item>
        <f7-list-item>
          <template #header>Warehouse</template>
          <template #title>
            <string-label v-if="goodsReceipt.warehouse" :value="goodsReceipt.warehouse.name" />
            <string-label v-else />
          </template>
        </f7-list-item>
      </f7-list>
    </f7-card-content>
  </f7-card>
</template>
<script setup lang="ts">
import DateTimeLabel from '@components/label/DateTime.vue'
import StringLabel from '@components/label/StringLabel.vue'
import { IGoodsReceipt } from '@graphql/goodsReceipt/types'
import { computed } from 'vue'
import { IPurchaseOrder } from '@graphql/purchaseOrder/types'

const props = defineProps<{
  isLoading: boolean
  goodsReceipt: IGoodsReceipt
}>()

const purchaseOrder = computed<IPurchaseOrder | null>(() => {
  if (!props.goodsReceipt) {
    return null
  }

  return props.goodsReceipt.purchaseOrder
})
</script>
