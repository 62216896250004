<template>
  <f7-block-header class="margin-top"> Stock </f7-block-header>
  <data-table-card :items="articleLocationsData" :is-loading="isLoading">
    <template #no-items>
      <icon-with-text :icon="['far', 'empty-set']" title="No inventory available" />
    </template>
    <data-table>
      <template #header>
        <table-row>
          <label-cell element="th">&nbsp;</label-cell>
          <numeric-cell element="th">
            Qty ({{ getStockQuantity(article, getPhysicalTotal(article?.stockLevel)) }})
          </numeric-cell>
          <numeric-cell element="th"
            >Packing ({{ getStockQuantity(article, article?.stockLevel.packing) }})</numeric-cell
          >
        </table-row>
      </template>
      <table-row
        v-for="(articleLocation, index) in articleLocationsData"
        :key="index"
        @click="onSelectArticleLocation(articleLocation)"
      >
        <label-cell>{{ articleLocation.location.name }}</label-cell>
        <numeric-cell v-if="articleLocation.stockLevel">{{
          getStockQuantity(article, getPhysicalTotal(articleLocation.stockLevel))
        }}</numeric-cell>
        <numeric-cell v-if="articleLocation.stockLevel">{{
          getStockQuantity(article, articleLocation.stockLevel.packing)
        }}</numeric-cell>
      </table-row>
      <table-row
        v-if="article?.articleLocation?.length > locationsToShowThreshold"
        @click="onShowMoreStockLevels"
      >
        <label-cell>
          More
          <span class="float-right">
            <f7-icon material="chevron_right" />
          </span>
        </label-cell>
        <numeric-cell>
          {{ invisiblePhysicalStockTotal }}
        </numeric-cell>
        <numeric-cell>
          {{ invisiblePackingStockTotal }}
        </numeric-cell>
      </table-row>
    </data-table>
  </data-table-card>
  <f7-popup
    class="article-location-stock"
    :opened="popupOpened"
    @popup:closed="popupOpened = false"
  >
    <div v-if="selectedArticleLocation">
      <stock-popup
        :stock-level="selectedArticleLocation?.stockLevel"
        :article="article"
        :location="selectedArticleLocation?.location"
      />
    </div>
  </f7-popup>
</template>
<script lang="ts" setup>
import IconWithText from '@components/IconWithText.vue'
import StockPopup from '@components/StockPopup.vue'
import { IArticle } from '@graphql/article/types'
import { IArticleLocation } from '@graphql/location/types'
import { getPhysicalTotal } from '@store/modules/article/helper'
import { f7 } from 'framework7-vue'
import { computed, ref } from 'vue'
import DataTableCard from '@components/data-table/DataTableCard.vue'
import DataTable from '@components/data-table/DataTable.vue'
import LabelCell from '@components/data-table/LabelCell.vue'
import NumericCell from '@components/data-table/NumericCell.vue'
import TableRow from '@components/data-table/TableRow.vue'
import useArticle from '@composables/useArticle'

const props = withDefaults(
  defineProps<{
    article: IArticle
    isLoading?: boolean
  }>(),
  {
    isLoading: false
  }
)

const emits = defineEmits<{
  (e: 'load-more', payload: boolean)
}>()

const { getStockQuantity } = useArticle()

const locationsToShowThreshold = ref<number>(3)
const selectedArticleLocation = ref<IArticleLocation | null>(null)
const popupOpened = ref<boolean>(false)

const articleLocationsData = computed<IArticleLocation[]>(() =>
  props.article.articleLocation
    ? props.article.articleLocation.slice(0, locationsToShowThreshold.value)
    : []
)

const onSelectArticleLocation = (articleLocation: IArticleLocation) => {
  selectedArticleLocation.value = articleLocation
  popupOpened.value = true
}

const visiblePhysicalStockTotal = computed<number>(() => {
  if (articleLocationsData.value.length === 0) {
    return 0
  }

  return articleLocationsData.value
    .map((al) => getPhysicalTotal(al.stockLevel))
    .reduce((total, current) => total + current)
})

const visiblePackingStockTotal = computed<number>(() => {
  if (articleLocationsData.value.length === 0) {
    return 0
  }

  return articleLocationsData.value
    .map((al) => al.stockLevel.packing)
    .reduce((total, current) => total + current)
})

const invisiblePhysicalStockTotal = computed<number>(() => {
  if (props.article) {
    return getPhysicalTotal(props.article.stockLevel) - visiblePhysicalStockTotal.value
  }
  return 0
})

const invisiblePackingStockTotal = computed<number>(() => {
  if (props.article) {
    return props.article.stockLevel.packing - visiblePackingStockTotal.value
  }
  return 0
})

const onShowMoreStockLevels = async () => {
  await emits('load-more', true)

  await f7.views.main.router.navigate(`/article/${props.article?.id}/stock/`, {
    props: { item: props.article }
  })
}
</script>
