import { defineStore } from 'pinia'
import outboundLoadCarrierApi from '@graphql/outbound-load-carrier'
import { ICreateLoadCarrierRequest } from '@graphql/outboundLoadCarrier/types'

export const useOutbound = defineStore('outbound', {
  actions: {
    async getLoadCarrierTypes() {
      const response = await outboundLoadCarrierApi.getLoadCarrierTypes()

      return response.data.data.getLoadCarrierTypes
    },
    async createLoadCarrier(request: ICreateLoadCarrierRequest) {
      const response = await outboundLoadCarrierApi.createLoadCarrier(request)

      return response.data.data.createLoadCarrier
    }
  }
})
