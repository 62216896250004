import { useOutboundTransportStore } from '@store/pinia/outbound/transport/store'
import {
  IGetTransportByIdQueryRequest,
  IGetTodoTransportQueryRequest,
  IGetPickableLoadCarriersQueryRequest,
  IMarkLoadCarrierPickedMutationRequest,
  IFinalizePickOfTransportMutationRequest,
  IStartProcessingOfTransportMutationRequest
} from '@graphql/outbound/types'
import { ID } from '@graphql/types'
import { perceptibleToast } from '@services/perceptibleToast'
import { captureEvent } from '@sentry/vue'
import { confirmYesNo } from '@/functions/dialog'
import { f7 } from 'framework7-vue'

export default () => {
  const store = useOutboundTransportStore()

  const getTodoTransports = async (request: IGetTodoTransportQueryRequest) =>
    await store.getTodoTransports(request)

  const getTransportById = async (request: IGetTransportByIdQueryRequest) =>
    await store.getTransportById(request)

  const getPickableLoadCarriers = async (request: IGetPickableLoadCarriersQueryRequest) =>
    store.getPickableLoadCarriers(request)

  const markLoadCarrierPicked = async (request: IMarkLoadCarrierPickedMutationRequest) =>
    store.markLoadCarrierPicked(request)

  const finalizePickOfTransport = async (request: IFinalizePickOfTransportMutationRequest) =>
    store.finalizePickOfTransport(request)

  const startProcessingOfTransport = async (request: IStartProcessingOfTransportMutationRequest) =>
    store.startProcessingOfTransport(request)

  const onFinalizeTransport = async (transportId: ID) => {
    await confirmYesNo({
      title: 'Are you sure that you want to mark this transport complete?',
      yesButtonCallback: async () => {
        try {
          await finalizePickOfTransport({ transportId: transportId })

          await perceptibleToast.success(`Transport #${transportId} successfully finalized!`)

          await f7.views.main.router.navigate('/outbound/transport/todo/')
        } catch (e) {
          await perceptibleToast.error(e.message)

          captureEvent(e)

          throw e
        }
      }
    })
  }

  return {
    getTodoTransports,
    getTransportById,
    getPickableLoadCarriers,
    markLoadCarrierPicked,
    finalizePickOfTransport,
    startProcessingOfTransport,
    onFinalizeTransport
  }
}
