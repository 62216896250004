import {
  IGetSortableItemsRequest,
  IGetWarehouseZoneSuggestionByGoodsReceiptLineRequest,
  IGoodsReceipt,
  IGoodsReceiptLine,
  IGoodsReceiptLineConnection,
  IGoodsReceiptLineLocation,
  IGoodsReceiptLinePrintArticleLabelRequest,
  IGoodsReceiptQueryContext,
  IProcessableReceiptConnection,
  IStartProcessingRequest
} from '@graphql/goodsReceipt/types'
import { ILocation } from '@graphql/location/types'
import { useStore } from '@store/store'
import { computed } from 'vue'
import { IWarehouseZoneSuggestion } from '@graphql/warehouse/types'

export default () => {
  const store = useStore()
  const goodsReceipt = computed<IGoodsReceipt | undefined>(
    () => store.getters['goodsReceipt/activeGoodsReceipt']
  )
  const goodsReceiptLines = computed<IGoodsReceiptLineConnection | undefined>(() => {
    if (goodsReceipt.value) {
      return goodsReceipt.value.goodsReceiptLine
    }

    return undefined
  })
  const goodsReceiptLine = computed<IGoodsReceiptLine | undefined>(
    () => store.getters['goodsReceipt/activeGoodsReceiptLine']
  )
  const goodsReceiptLineLocations = computed<IGoodsReceiptLineLocation[]>(() => {
    if (goodsReceiptLine.value) {
      return goodsReceiptLine.value.goodsReceiptLineLocation
    }

    return []
  })
  const goodsEnteredLocations = computed<ILocation[]>(() => {
    if (goodsReceiptLineLocations.value.length === 0) {
      return []
    }

    return goodsReceiptLineLocations.value.map((grll) => grll.location)
  })

  const openGoodsReceipts = computed<IGoodsReceipt[]>(
    () => store.getters['goodsReceipt/openGoodsReceipts']
  )

  const getGoodsReceipt = async (context: IGoodsReceiptQueryContext): Promise<IGoodsReceipt> =>
    store.dispatch('goodsReceipt/getGoodsReceipt', context)
  const startProcessing = async (context: IStartProcessingRequest) =>
    store.dispatch('goodsReceipt/startProcessing', context)
  const getGoodsReceiptLine = async (id: number): Promise<IGoodsReceiptLine> =>
    store.dispatch('goodsReceipt/getGoodsReceiptLine', id)
  const getProcessableReceipts = async (): Promise<IProcessableReceiptConnection> =>
    store.dispatch('goodsReceipt/getProcessableReceipts')

  const flattenGoodsLocations = (goodsReceiptLineLocations: IGoodsReceiptLineLocation[]) => {
    if (goodsReceiptLineLocations.length === 0) {
      return ''
    }

    const mapped = goodsReceiptLineLocations.map(
      (grll) => `${grll.location?.name ?? 'N/A'} (${grll.amount})`
    )

    return mapped.join(', ')
  }

  const getDeliveredQty = (goodsReceiptLine: IGoodsReceiptLine) => {
    if (goodsReceiptLine.goodsReceiptLineLocation.length === 0) {
      return 0
    }

    return goodsReceiptLine.goodsReceiptLineLocation
      .map((l) => l.amount)
      .reduce((total, current) => total + current)
  }

  const goodsReceiptLinePrintArticleLabel = async (
    request: IGoodsReceiptLinePrintArticleLabelRequest
  ): Promise<boolean> => store.dispatch('goodsReceipt/goodsReceiptLinePrintArticleLabel', request)

  const getSortableItems = async (request: IGetSortableItemsRequest): Promise<IGoodsReceipt> =>
    store.dispatch('goodsReceipt/getSortableItems', request)

  const getWarehouseZoneSuggestionByGoodsReceiptLine = async (
    request: IGetWarehouseZoneSuggestionByGoodsReceiptLineRequest
  ): Promise<IWarehouseZoneSuggestion | null> =>
    store.dispatch('goodsReceipt/getWarehouseZoneSuggestionByGoodsReceiptLine', request)

  return {
    goodsReceipt,
    goodsReceiptLine,
    goodsReceiptLines,
    goodsReceiptLineLocations,
    goodsEnteredLocations,
    openGoodsReceipts,
    getGoodsReceipt,
    startProcessing,
    getGoodsReceiptLine,
    getProcessableReceipts,
    flattenGoodsLocations,
    getDeliveredQty,
    goodsReceiptLinePrintArticleLabel,
    getSortableItems,
    getWarehouseZoneSuggestionByGoodsReceiptLine
  }
}
