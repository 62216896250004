<template>
  <f7-card :class="{ 'skeleton-text': isLoading }">
    <f7-card-content>
      <f7-list media-list v-if="lines.length">
        <f7-list-item
          v-for="line in lines"
          :key="line.packableIdentity.identifier"
          :swipeout="line.quantity > 1"
        >
          <f7-swipeout-actions v-if="line.quantity > 1" left>
            <f7-swipeout-button color="primary" overswipe close @click="onBulkPrint(line)">
              <font-awesome-icon :icon="['far', 'fa-boxes-stacked']" />
            </f7-swipeout-button>
          </f7-swipeout-actions>
          <template #media>
            {{
              line.stockUnit
                ? getStockQuantity(line.stockUnit, line.quantity - line.quantityPacked)
                : line.quantity - line.quantityPacked
            }}
          </template>
          <template #title>
            {{ line.packableIdentity.name }}
          </template>
          <template #subtitle>
            <string-label :value="line.packableIdentity.barcodes[0]" fallback-value="NOBARCODE" />
          </template>
        </f7-list-item>
      </f7-list>
      <icon-with-text v-else :icon="['far', 'laugh-beam']" title="Hooray, all done!" />
    </f7-card-content>
  </f7-card>
</template>
<script lang="ts" setup>
import IconWithText from '@components/IconWithText.vue'
import StringLabel from '@components/label/StringLabel.vue'
import { IPackableItem } from '@graphql/pack/types'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import useArticle from '@composables/useArticle'

const { getStockQuantity } = useArticle()

withDefaults(
  defineProps<{
    lines: Array<IPackableItem>
    isLoading?: boolean
  }>(),
  {
    isLoading: false
  }
)

const emit = defineEmits<{
  (e: 'bulk-print', packableItem: IPackableItem)
}>()

const onBulkPrint = async (packableItem: IPackableItem) => {
  await emit('bulk-print', packableItem)
}
</script>
