import goodsReceiptApi from '@graphql/goods-receipt'
import {
  IGetSortableItemsRequest,
  IGetWarehouseZoneSuggestionByGoodsReceiptLineRequest,
  IGoodsReceipt,
  IGoodsReceiptLine,
  IGoodsReceiptLinePrintArticleLabelRequest,
  IGoodsReceiptQueryContext,
  IStartProcessingRequest
} from '@graphql/goodsReceipt/types'
import { IGoodsReceiptState } from '@store/modules/goods-receipt/types'
import { IRootState } from '@store/types'
import { ActionContext, ActionTree } from 'vuex'
import { ID } from '@graphql/types'

const actions: ActionTree<IGoodsReceiptState, IRootState> = {
  async getProcessableReceipts() {
    const response = await goodsReceiptApi.getProcessableReceipts()

    return response.data.data.getProcessableReceipts
  },

  async getGoodsReceipt(
    { commit }: ActionContext<IGoodsReceiptState, IRootState>,
    context: IGoodsReceiptQueryContext
  ): Promise<IGoodsReceipt> {
    const response = await goodsReceiptApi.getGoodsReceipt(context)

    await commit('setActiveGoodsReceipt', response.data.data.goodsReceipt)

    return response.data.data.goodsReceipt
  },

  async startProcessing(context, request: IStartProcessingRequest) {
    return await goodsReceiptApi.startProcessing(request)
  },

  async getGoodsReceiptLine({ commit }, id: ID): Promise<IGoodsReceiptLine> {
    const response = await goodsReceiptApi.getGoodsEntry(id)

    await commit('setActiveGoodsReceiptLine', response.data.data.goodsReceiptLine)

    return response.data.data.goodsReceiptLine as IGoodsReceiptLine
  },

  async goodsReceiptLinePrintArticleLabel(
    context,
    request: IGoodsReceiptLinePrintArticleLabelRequest
  ) {
    const response = await goodsReceiptApi.goodsReceiptLinePrintArticleLabel(request)

    return response.data.data.goodsReceiptLinePrintArticleLabel
  },

  async getSortableItems(context, request: IGetSortableItemsRequest) {
    const response = await goodsReceiptApi.getSortableItems(request)

    return response.data.data.goodsReceipt
  },

  async getWarehouseZoneSuggestionByGoodsReceiptLine(
    context,
    request: IGetWarehouseZoneSuggestionByGoodsReceiptLineRequest
  ) {
    const response = await goodsReceiptApi.getWarehouseZoneSuggestionByGoodsReceiptLine(request)

    return response.data.data.getWarehouseZoneSuggestion
  }
}

export default actions
