<template>
  <page-with-search-subscriber v-slot="{ searchSubscriber }" :page-content="false">
    <navigation show-search-icon v-slot="{ searchId }">
      <search-bar :scan-subscriber="searchSubscriber" :search-id="searchId" expandable />
    </navigation>
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#collo-detail" tab-link-active>Detail</f7-link>
      <f7-link tab-link="#collo-lines">Lines ({{ shipmentColliLines.length }})</f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="collo-detail" class="page-content" tab-active>
        <f7-block-title v-if="collo" class="margin-top" :class="{ 'skeleton-text': isLoading }">
          Collo #{{ collo.id }}
        </f7-block-title>
        <f7-card media-list class="margin-bottom" :class="{ 'skeleton-text': isLoading }">
          <f7-card-content>
            <f7-list v-if="collo" media-list>
              <f7-list-item header="Reference">
                <string-label :value="collo.reference" />
              </f7-list-item>
              <f7-list-item header="International reference">
                <string-label :value="collo.internationalReference" />
              </f7-list-item>
              <f7-list-item header="Tracking url">
                <font-awesome-icon
                  v-if="collo.trackingUrl"
                  :icon="['far', 'external-link']"
                  @click="visitExternalPage(collo.trackingUrl)"
                />
                <string-label v-else :value="collo.trackingUrl" />
              </f7-list-item>
              <f7-list-item header="International tracking url">
                <font-awesome-icon
                  v-if="collo.internationalTrackingUrl"
                  :icon="['far', 'external-link']"
                  @click="visitExternalPage(collo.internationalTrackingUrl)"
                />
                <string-label :value="collo.internationalTrackingUrl" />
              </f7-list-item>
              <f7-list-item header="state">
                <string-label :value="collo.stateDetail" />
              </f7-list-item>
              <f7-list-item header="Sequence">
                <string-label
                  :value="`${collo.sequence} / ${collo.shipmentColli.shipment.colli}`"
                />
              </f7-list-item>
              <f7-list-item
                header="Shipment"
                @click="onNavigateToShipment(collo.shipmentColli.shipment)"
              >
                <string-label :value="collo.shipmentColli.shipment.id" />
              </f7-list-item>
              <f7-list-item header="Created at">
                <date-time-label :value="collo.createdAt" />
              </f7-list-item>
            </f7-list>
          </f7-card-content>
        </f7-card>
      </f7-tab>
      <f7-tab id="collo-lines" class="page-content">
        <data-table-card :items="shipmentColliLines" :is-loading="isLoading">
          <template #no-items>No collo lines available.</template>
          <data-table>
            <template #header>
              <table-row>
                <numeric-cell>Qty</numeric-cell>
                <label-cell>Article</label-cell>
              </table-row>
            </template>
            <table-row v-for="shipmentLineColli in shipmentColliLines" :key="shipmentLineColli.id">
              <numeric-cell>{{ shipmentLineColli.amount }}</numeric-cell>
              <label-cell>{{ shipmentLineColli.shipmentLine.article.name }}</label-cell>
            </table-row>
          </data-table>
        </data-table-card>
      </f7-tab>
    </f7-tabs>
    <app-actions
      v-if="actions.length > 0"
      :actions="actions"
      :fixed="true"
      @action-clicked="onActionClick"
      position="right-bottom"
    />
  </page-with-search-subscriber>
</template>
<script lang="ts" setup>
import Navigation from '@components/AppNavigation.vue'
import SearchBar from '@components/search/SearchBar.vue'
import AppActions from '@components/AppActions.vue'
import DateTimeLabel from '@components/label/DateTime.vue'
import useShipment from '@composables/useShipment'
import { ID } from '@graphql/types'
import { computed, onMounted, ref } from 'vue'
import useLoading from '@composables/useLoading'
import { IAction } from '@components/actions'
import { ICollo, IShipmentLineColli } from '@graphql/shipment/types'
import StringLabel from '@components/label/StringLabel.vue'
import useAction from '@composables/useAction'
import DataTableCard from '@components/data-table/DataTableCard.vue'
import DataTable from '@components/data-table/DataTable.vue'
import NumericCell from '@components/data-table/NumericCell.vue'
import LabelCell from '@components/data-table/LabelCell.vue'
import TableRow from '@components/data-table/TableRow.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import useNavigation from '@composables/useNavigation'
import useSettings from '@composables/useSettings'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'

const { onActionClick } = useAction()
const {
  getColloById,
  printShippingLabelForCollo,
  printPackingAttachmentsForCollo,
  onNavigateToShipment
} = useShipment()
const { withAsyncLoading, isLoading } = useLoading()
const { visitExternalPage } = useNavigation()
const { packStation } = useSettings()

const props = defineProps<{
  id: ID
}>()

const collo = ref<ICollo | undefined>(undefined)
const actions = ref<IAction[]>([])

const shipmentColliLines = computed<IShipmentLineColli[]>(() => {
  if (collo.value) {
    return collo.value.shipmentColli.shipmentLineColli
  }
  return []
})

onMounted(async () => {
  await withAsyncLoading(async () => {
    collo.value = await getColloById({ id: props.id })
  })

  if (!packStation.value) {
    return
  }

  actions.value.push({
    name: 'Print label',
    href: '#',
    requiresConfirmation: true,
    callback: () => printShippingLabelForCollo(collo.value.id, packStation.value.id)
  })

  actions.value.push({
    name: 'Print packing attachments',
    href: '#',
    requiresConfirmation: true,
    callback: async () =>
      await printPackingAttachmentsForCollo(collo.value.id, packStation.value.id)
  })
})
</script>
