import { IParser, ParserResult } from '@services/search/parser'

export class Spec1BarcodeParser implements IParser {
  private regex = /B1<<.*?>>/

  parse(query: string): ParserResult {
    const match = query.match(this.regex)
    let barcode = query
    let extraData: string[] = []

    if (match) {
      barcode = match[0]
      const data = query.split('>>') // Split on the delimiter
      extraData = data.length > 1 ? data[1].split('|') : []
    }

    return new ParserResult(barcode, 'DEFAULT', [], true, false, extraData)
  }

  supports(query: string): boolean {
    return this.regex.test(query)
  }
}
