import Base from '@graphql/base'
import {
  IGetPickBatchByIdResponse,
  IAddLoadCarrierMutationResponse,
  IAddLoadCarrierRequest,
  IGetLoadCarrierByIdQueryResponse,
  IGetLoadCarrierByIdRequest,
  IGetLocationToVisitQueryResponse,
  ISavePickBatchLineStateMutationResponse,
  ISavePickBatchStateRequest,
  IGetPickBatchPartitionQueuesResponse,
  IGetPickTaskRequest,
  IStartPickProcessRequest,
  IStartPickProcessResponse,
  IGetPickTaskResponse,
  IStartKardexPickResponse,
  IStartKardexPickRequest
} from '@graphql/pick/types'
import {
  ICreateNewLoadCarrierRequest,
  ICreateNewLoadCarrierResponse,
  IMarkPickedRequest,
  IMarkPickedResponse
} from '@store/modules/projects/pick/types'
import { AxiosInstance } from 'axios'
import GRAPHQL_API_CLIENT from './client'

export class Pick extends Base {
  constructor(axios: AxiosInstance) {
    super(axios, 'pick')
  }

  public async getPickTask(request: IGetPickTaskRequest) {
    return await this.makeRequest<IGetPickTaskResponse>('getPickTaskQuery', request)
  }

  public async startPickProcess(request: IStartPickProcessRequest) {
    return await this.makeRequest<IStartPickProcessResponse>('startPickProcessMutation', request)
  }

  public async startKardexPick(request: IStartKardexPickRequest) {
    return await this.makeRequest<IStartKardexPickResponse>('startKardexPickMutation', request)
  }

  public async savePickBatchLineState(request: ISavePickBatchStateRequest) {
    return await this.makeRequest<ISavePickBatchLineStateMutationResponse>(
      'savePickBatchLineStateMutation',
      request
    )
  }

  public async markPicked(context: IMarkPickedRequest) {
    return await this.makeRequest<IMarkPickedResponse>('markPickedMutation', context)
  }

  public async getById(pickBatchId: ID) {
    return await this.makeRequest<IGetPickBatchByIdResponse>('getPickBatchById', { pickBatchId })
  }

  public async getLocationToVisit(pickBatchPartitionId: ID) {
    return await this.makeRequest<IGetLocationToVisitQueryResponse>('getLocationToVisitQuery', {
      pickBatchPartitionId
    })
  }

  public async addLoadCarrier(request: IAddLoadCarrierRequest) {
    return await this.makeRequest<IAddLoadCarrierMutationResponse>(
      'addLoadCarrierMutation',
      request
    )
  }

  public async getLoadCarrierById(request: IGetLoadCarrierByIdRequest) {
    return await this.makeRequest<IGetLoadCarrierByIdQueryResponse>(
      'getLoadCarrierByIdQuery',
      request
    )
  }

  public async getPickBatchPartitionQueues() {
    return await this.makeRequest<IGetPickBatchPartitionQueuesResponse>(
      'getPickBatchPartitionQueuesQuery'
    )
  }

  public async createNewLoadCarrier(request: ICreateNewLoadCarrierRequest) {
    return await this.makeRequest<ICreateNewLoadCarrierResponse>(
      'createNewLoadCarrierMutation',
      request
    )
  }
}

export default new Pick(GRAPHQL_API_CLIENT)
