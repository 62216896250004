<template>
  <page-with-search-subscriber v-slot="{ searchSubscriber }" :page-content="false">
    <navigation show-search-icon v-slot="{ searchId }">
      <search-bar
        :search-id="searchId"
        :search-type="searchType"
        :search-filters="searchFilters"
        :search-strategy="searchStrategy"
        :scan-subscriber="searchSubscriber"
        expandable
        @result="onSearchResult"
        @results="onSearchResults"
        @no-result="onNoSearchResult"
      />
    </navigation>

    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#shipment-todo-lines" tab-link-active>
        Todo ({{ todoShipmentLineCount }})
      </f7-link>
      <f7-link tab-link="#shipment-done-lines"> Done ({{ doneLines.count() }})</f7-link>
      <f7-link tab-link="#shipment-details">Detail</f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="shipment-todo-lines" class="page-content" tab-active>
        <f7-card :class="{ 'skeleton-text': isLoading }">
          <f7-card-content>
            <f7-list media-list>
              <template v-if="todoShipmentLineCount > 0">
                <f7-list-item v-for="todoLineEdge in todoLinesEdges" :key="todoLineEdge.node.id">
                  <template #title>{{ todoLineEdge.node.article.name }}</template>
                  <template #subtitle>
                    {{ todoLineEdge.node.amount
                    }}<span v-show="todoLineEdge.node.originalAmount">
                      ({{ todoLineEdge.node.originalAmount }})</span
                    >
                  </template>
                </f7-list-item>
              </template>
              <f7-list-item v-else title="Hooorraay, all done!" />
            </f7-list>
          </f7-card-content>
        </f7-card>
      </f7-tab>

      <f7-tab id="shipment-done-lines" class="page-content">
        <f7-card :class="{ 'skeleton-text': isLoading }">
          <f7-card-content>
            <f7-list media-list>
              <template v-if="doneLines.isNotEmpty()">
                <f7-list-item v-for="doneLineEdge in doneLines.all()" :key="doneLineEdge.node.id">
                  <template #title>{{ doneLineEdge.node.article.name }}</template>
                  <template #subtitle>{{ doneLineEdge.node.originalAmount }}</template>
                </f7-list-item>
              </template>
              <f7-list-item v-else title="Nothing to show yet..." />
            </f7-list>
          </f7-card-content>
        </f7-card>
      </f7-tab>

      <f7-tab id="shipment-details" class="page-content">
        <f7-card media-list :class="{ 'skeleton-text': isLoading }">
          <f7-card-content>
            <f7-list v-if="shipment" media-list>
              <f7-list-item header="Order number" v-if="shipment.order"
                >{{ shipment.order.orderNr }}
              </f7-list-item>
              <f7-list-item header="Partner">{{ shipment.flatPartner }}</f7-list-item>
              <f7-list-item header="Courier">{{ shipment.flatCourier }}</f7-list-item>
              <f7-list-item header="Colli" v-if="shipment.colli">{{ shipment.colli }}</f7-list-item>
              <f7-list-item header="State">{{ shipment.humanState }}</f7-list-item>
              <f7-list-item header="Created at">
                <date-time-label :value="shipment.createdAt" />
              </f7-list-item>
              <f7-list-item header="Site" v-if="shipment.site"
                >{{ shipment.site.name }}
              </f7-list-item>
              <f7-list-item header="Weight (in gram)" v-if="shipment.weight"
                >{{ shipment.weight }}
              </f7-list-item>
              <f7-list-item header="Latest shipping date">
                <date-time-label :value="shipment.latestShippingDate" />
              </f7-list-item>
            </f7-list>
          </f7-card-content>
        </f7-card>
      </f7-tab>
    </f7-tabs>

    <f7-popup
      :opened="popupOpened"
      class="search-results-popup"
      @popup:closed="popupOpened = false"
      :close-on-ecape="false"
      :close-by-backdrop-click="false"
      :tablet-fullscreen="true"
      :swipe-to-close="false"
    >
      <f7-navbar>
        <f7-nav-title> Select {{ searchType }}</f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>
            <f7-icon material="close" />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <search-result-list
        :items="searchResults"
        :search-strategy="searchStrategy"
        @result="onPopupSearchResult"
        :is-loading="isLoading"
        :key="1"
      />
    </f7-popup>
  </page-with-search-subscriber>
</template>
<script lang="ts" setup>
import DateTimeLabel from '@components/label/DateTime.vue'
import Navigation from '@components/AppNavigation.vue'
import SearchBar from '@components/search/SearchBar.vue'
import SearchResultList from '@components/search/ResultList.vue'
import useLoading from '@composables/useLoading'
import useShipment from '@composables/useShipment'
import { IArticle } from '@graphql/article/types'
import { TypeName } from '@graphql/search/types'
import { IShipmentLineConnection, IShipmentLineEdge } from '@graphql/shipment/types'
import Collection, { cursorEquals, nodeIdEquals } from '@services/collection'
import { ISearchFilter, SearchStrategy } from '@services/search/search'
import { soundBoard } from '@services/sound'
import { toast } from '@services/toast'
import { f7 } from 'framework7-vue'
import { computed, onBeforeMount, ref, watch } from 'vue'
import { perceptibleToast } from '@services/perceptibleToast'
import { ID } from '@graphql/types'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'

const props = defineProps<{
  id: ID
}>()

const searchType = TypeName.Article
const searchResults = ref<Array<Partial<IArticle>>>([])
const popupOpened = ref<boolean>(false)
const searchStrategy = SearchStrategy.PackingControlShipmentLine
const todoLines = ref<Collection<IShipmentLineEdge>>(new Collection<IShipmentLineEdge>())
const doneLines = ref<Collection<IShipmentLineEdge>>(new Collection<IShipmentLineEdge>())
const searchFilters = ref<ISearchFilter[]>([])

const { shipment, finalizePackingControl, getShipmentById } = useShipment()
const { isLoading, withAsyncLoading } = useLoading()

const shipmentLines = computed<IShipmentLineConnection | null>(() => {
  if (shipment.value && shipment.value.shipmentLine) {
    return shipment.value.shipmentLine
  }

  return null
})

const controlCompleted = computed<boolean>(() => {
  if (shipmentLineCount.value === 0) {
    return false
  }

  return shipmentLineCount.value === doneLines.value.count()
})

const shipmentLineCount = computed<number>(() => {
  if (shipmentLines.value) {
    return shipmentLines.value.totalCount ?? 0
  }

  return 0
})

const todoShipmentLineCount = computed<number>(() => {
  return shipmentLineCount.value - doneLines.value.count()
})

const todoLinesEdges = computed<IShipmentLineEdge[]>(() => {
  return todoLines.value.all()
})

const onSearchResult = async (result: Partial<IArticle>): Promise<void> => {
  const matchingTodoEdges = todoLines.value
    .all()
    .filter((i: IShipmentLineEdge) => i.node.article.id === result.id)
  const matchingDoneEdges = doneLines.value
    .all()
    .filter((i: IShipmentLineEdge) => i.node.article.id === result.id)

  if (matchingTodoEdges.length === 0 && matchingDoneEdges.length === 0) {
    await handleArticleNotMatchingShipmentLine()

    return
  }

  if (matchingTodoEdges.length === 0) {
    await perceptibleToast.noSearchHit(`Article "${result.searchPreview}" is already in done!`)
    return
  }

  await computeResult(matchingTodoEdges[0])
}

const computeResult = async (shipmentLineEdge: IShipmentLineEdge) => {
  if (shipmentLineEdge.node.amount === 0) {
    return
  }

  if (!Object.hasOwn(shipmentLineEdge.node, 'originalAmount')) {
    shipmentLineEdge.node.originalAmount = shipmentLineEdge.node.amount
  }

  shipmentLineEdge.node.amount--

  if (shipmentLineEdge.node.amount === 0) {
    const originalLineEdge = await findFirstMatchingLine(shipmentLineEdge)
    if (originalLineEdge) {
      doneLines.value.add(originalLineEdge)
    }

    todoLines.value.remove(shipmentLineEdge, nodeIdEquals)

    if (!controlCompleted.value) {
      await soundBoard.playSuccessSound()
    }

    return
  }

  await soundBoard.playSearchSingleHit()
}

const findFirstMatchingLine = async (shipmentLineEdge: IShipmentLineEdge) => {
  if (!shipmentLines.value) {
    return
  }
  const results = shipmentLines.value.edges.filter((s: IShipmentLineEdge) =>
    cursorEquals(s, shipmentLineEdge)
  )

  if (results.length === 0) {
    return
  }

  return results[0]
}

const onNoSearchResult = async (query: string): Promise<void> => {
  toast
    .error(`No article with barcode "${query}" found for partner ${shipment.value.flatPartner}!`)
    .open()
}

const onSearchResults = async (results: Array<Partial<IArticle>>) => {
  searchResults.value = results
  popupOpened.value = true
}

const onPopupSearchResult = async (result: Partial<IArticle>) => {
  popupOpened.value = false
  searchResults.value = []

  await onSearchResult(result)
}

const handleArticleNotMatchingShipmentLine = async () => {
  await perceptibleToast.error('No shipment lines found for given barcode!')

  await f7.views.main.router.navigate('/packing-control/')
}

watch(
  controlCompleted,
  async (newValue, oldValue) => {
    if (oldValue === newValue) {
      return
    }

    if (newValue && shipment.value && shipmentLineCount.value > 0) {
      const shipmentId = shipment.value.id

      try {
        await finalizePackingControl({ shipmentId })

        await perceptibleToast.finishedTask(`Control of shipment "${shipmentId}" succeeded!`, 5000)

        await f7.views.main.router.navigate('/packing-control/')
      } catch (e: any) {
        toast.error(e.message).open()
        throw e
      }
    }
  },
  {
    immediate: true
  }
)

watch(
  shipmentLineCount,
  (newValue, oldValue) => {
    if (newValue > 50 || (oldValue && oldValue > 50)) {
      toast
        .error(
          'Shipment lines exceeds the maximum (currently) supported amount of 50, scan another shipment!!!'
        )
        .open()

      f7.views.main.router.navigate('/packing-control/')
    }
  },
  {
    immediate: true
  }
)

onBeforeMount(async () => {
  await withAsyncLoading(async () => {
    todoLines.value.clear()
    doneLines.value.clear()

    await getShipmentById({ id: props.id })
  })

  if (shipmentLines.value) {
    todoLines.value = new Collection<IShipmentLineEdge>(shipmentLines.value.edges)
  }

  searchFilters.value.push({
    key: 'companyId',
    operator: '=',
    value: shipment.value.partner.id
  })
})
</script>
