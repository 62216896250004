<template>
  <page-with-search-subscriber v-slot="{ searchSubscriber }" :page-content="false">
    <navigation show-search-icon v-slot="{ searchId }">
      <search-bar :scan-subscriber="searchSubscriber" :search-id="searchId" expandable />
    </navigation>

    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#load-carrier-detail" tab-link-active>Detail</f7-link>
      <f7-link tab-link="#load-carrier-items">Items ({{ totalLoadCarrierItems }})</f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="load-carrier-detail" class="page-content" tab-active>
        <load-carrier-detail-tab-content
          v-if="loadCarrier"
          :load-carrier="loadCarrier"
          :is-loading="isLoading"
        />
      </f7-tab>
      <f7-tab id="load-carrier-items" class="page-content">
        <f7-card>
          <f7-list
            media-list
            infinite
            :infinite-distance="50"
            :infinite-preloader="isLoading"
            @infinite="onLoadMoreItems(searchSubscriber)"
          >
            <load-carrier-item-line
              v-for="loadCarrierItem in loadCarrierItems"
              :key="loadCarrierItem.id"
              :load-carrier-item="loadCarrierItem"
            />
          </f7-list>
        </f7-card>
      </f7-tab>
    </f7-tabs>
    <app-actions v-if="actions.length > 0" :actions="actions" @action-clicked="onActionClick" />
    <mark-as-sorted-popup
      v-if="loadCarrier"
      :load-carrier="loadCarrier"
      :model-value="markAsSortedPopupOpened"
      @scanned="onMarkAsSortedScanned($event, searchSubscriber)"
      @update:model-value="onMarkAsSortedClosed(searchSubscriber)"
    />
  </page-with-search-subscriber>
</template>
<script lang="ts" setup>
import Navigation from '@components/AppNavigation.vue'
import LoadCarrierDetailTabContent from '@components/pick/LoadCarrierDetailTabContent.vue'
import useLoading from '@composables/useLoading'
import { computed, onMounted, ref } from 'vue'
import { ID } from '@graphql/types'
import useInbound from '@composables/useInbound'
import {
  IInboundSortLoadCarrier,
  IInboundSortLoadCarrierItemConnection
} from '@graphql/inbound/types'
import { IGetLoadCarrierByIdRequest, ILoadCarrierItem } from '@graphql/pick/types'
import AppActions from '@components/AppActions.vue'
import { IAction } from '@components/actions'
import MarkAsSortedPopup from '@pages/inbound/components/MarkAsSortedPopup.vue'
import { captureEvent } from '@sentry/vue'
import { perceptibleToast } from '@services/perceptibleToast'
import useAction from '@composables/useAction'
import SearchBar from '@components/search/SearchBar.vue'
import { Subscriber } from '@/utilities/scanInput'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'
import LoadCarrierItemLine from '@pages/load-carrier/components/LoadCarrierItemLine.vue'

const props = defineProps<{
  loadCarrierId: ID
}>()

const { isLoading, withAsyncLoading } = useLoading()
const { onActionClick } = useAction()
const { getInboundSortLoadCarrierById, markInboundSortLoadCarrierAsSorted } = useInbound()

const actions = ref<IAction[]>([])
const loadCarrier = ref<IInboundSortLoadCarrier | null>(null)
const loadCarrierItems = ref<ILoadCarrierItem[]>([])
const loadCarrierItemConnection = ref<IInboundSortLoadCarrierItemConnection>()
const markAsSortedPopupOpened = ref(false)

const totalLoadCarrierItems = computed(() => {
  if (loadCarrierItemConnection.value) {
    return loadCarrierItemConnection.value.totalCount
  }

  return loadCarrierItems.value.length
})

const onLoadMoreItems = async (subscriber: Subscriber | null) => {
  let request: IGetLoadCarrierByIdRequest = {
    loadCarrierId: props.loadCarrierId
  }

  if (loadCarrierItemConnection.value && loadCarrierItemConnection.value.pageInfo?.hasNextPage) {
    request.itemAfterCursor = loadCarrierItemConnection.value.pageInfo?.endCursor
    return
  }

  await withAsyncLoading(async () => {
    const response = await getInboundSortLoadCarrierById(request)

    loadCarrier.value = response
    loadCarrierItemConnection.value = response.loadCarrierItems
    loadCarrierItems.value = [
      ...loadCarrierItems.value,
      ...response.loadCarrierItems.edges.map((edge) => edge.node)
    ]

    await updateActions(response, subscriber)
  })
}

const updateActions = async (
  loadCarrier: IInboundSortLoadCarrier,
  subscriber: Subscriber | null
) => {
  actions.value = []
  if (loadCarrier.state === 'sorting') {
    actions.value.push({
      name: 'Release for Putaway',
      callback: () => {
        if (subscriber) {
          subscriber.pause()
        }
        markAsSortedPopupOpened.value = true
      },
      requiresConfirmation: true
    })
  }

  if (loadCarrier.state === 'sorted' && loadCarrier.completedAt === null) {
    actions.value.push({
      name: 'Putaway',
      href: '/inbound/putaway/item/contents/',
      props: {
        putawayItem: {
          identifier: loadCarrier.id,
          description: loadCarrier.reference,
          quantity: loadCarrier.loadCarrierItems.edges
            .map((edge) => edge.node.quantityProcessed ?? 0)
            .reduce((acc, val) => acc + val, 0),
          type: 'Load carrier',
          warehouseZone: loadCarrier.warehouseZone,
          readyAt: new Date()
        }
      },
      requiresConfirmation: true
    })
  }
}

const onMarkAsSortedScanned = async (
  loadCarrier: IInboundSortLoadCarrier,
  subscriber: Subscriber
) => {
  try {
    await markInboundSortLoadCarrierAsSorted({
      loadCarrierId: loadCarrier.id
    })

    onMarkAsSortedClosed(subscriber)
    await onLoadMoreItems(subscriber)
  } catch (e) {
    captureEvent(e)

    await perceptibleToast.error(e.message)
  }
}

const onMarkAsSortedClosed = (subscriber: Subscriber) => {
  subscriber.resume()
  markAsSortedPopupOpened.value = false
}

onMounted(async () => {
  await onLoadMoreItems(null)
})
</script>
