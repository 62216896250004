<template>
  <f7-page
    ptr
    :ptr-distance="55"
    ptr-mousewheel
    infinite
    :infinite-distance="50"
    :infinite-preloader="isLoading"
    @infinite="loadMoreItems"
    @ptr:refresh="onPullToRefresh"
    @page:reinit="onInit"
  >
    <pull-to-refresh-preloader />
    <app-navigation title="Putaway"></app-navigation>
    <f7-block-title>Items ready for putaway </f7-block-title>
    <f7-card v-if="putawayItems.length === 0 && !isLoading">
      <f7-card-content>
        <icon-with-text :icon="['fad', 'party-horn']" title="All work done!"></icon-with-text>
      </f7-card-content>
    </f7-card>
    <f7-list v-if="putawayItems.length > 0">
      <f7-list-item
        v-for="(item, index) in putawayItems"
        :key="index"
        swipeout
        @swipeout:opened="onOverSwipeLeft(item)"
      >
        <template #inner>
          <putaway-item :putaway-item="item"></putaway-item>
        </template>
        <f7-swipeout-actions left>
          <f7-swipeout-button color="primary" close>
            <f7-icon material="swipe-right" class="text-color-white" />
          </f7-swipeout-button>
        </f7-swipeout-actions>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script setup lang="ts">
import AppNavigation from '@components/AppNavigation.vue'
import useInbound from '@composables/useInbound'
import { onMounted, ref } from 'vue'
import { IInboundPutawayItem, IInboundPutawayItemConnection } from '@graphql/inbound/types'
import useLoading from '@composables/useLoading'
import PutawayItem from '@pages/inbound/putaway/components/PutawayItem.vue'
import { f7 } from 'framework7-vue'
import IconWithText from '@components/IconWithText.vue'
import PullToRefreshPreloader from '@components/PullToRefreshPreloader.vue'

const { withAsyncLoading, isLoading } = useLoading()
const { getPutawayItems } = useInbound()

const putawayItems = ref<IInboundPutawayItem[]>([])
const putawayItemConnection = ref<IInboundPutawayItemConnection>()

onMounted(async () => {
  await onInit()
})

const onInit = async () => {
  await loadItems()
}

const onOverSwipeLeft = (putawayItem: IInboundPutawayItem) => {
  f7.views.main.router.navigate('/inbound/putaway/item/contents/', {
    props: {
      putawayItem: putawayItem
    }
  })
}

const onPullToRefresh = async (done: () => void) => {
  await loadItems()
  done()
}

const loadItems = async () => {
  await withAsyncLoading(async () => {
    putawayItemConnection.value = await getPutawayItems({})
    putawayItems.value = putawayItemConnection.value.edges.map((edge) => edge.node)
  })
}

const loadMoreItems = async () => {
  const connection = putawayItemConnection.value
  if (!connection || !connection.pageInfo?.hasNextPage) {
    return
  }

  await withAsyncLoading(async () => {
    putawayItemConnection.value = await getPutawayItems({
      afterCursor: connection.pageInfo?.endCursor
    })
    putawayItems.value = [
      ...putawayItems.value,
      ...putawayItemConnection.value.edges.map((edge) => edge.node)
    ]
  })
}
</script>
