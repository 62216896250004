import scannerConfig from '@config/scanner'
import { cleanScanInput, generateUniqueIdentifier } from '@/utilities/string'

export default class ScanInput {
  private static instance: ScanInput | null = null
  private enabled = true
  private capturing = false
  private buffer: string[] = []
  private query = ''
  private inputCallback?: (query: string) => void = undefined

  public enable() {
    this.enabled = true
  }

  public disable() {
    this.enabled = false
  }

  constructor(
    private triggerKeys: string[],
    private submitKeys: string[],
    private promptKeys: string[],
    private authTriggerKeys: string[],
    private timeout: number | undefined
  ) {}

  public static getInstance(
    triggerKeys: string[],
    submitKeys: string[],
    promptKeys: string[],
    authTriggerKeys: string[],
    timeout: number | undefined
  ) {
    if (!this.instance) {
      this.instance = new ScanInput(
        [...triggerKeys, ...authTriggerKeys],
        submitKeys,
        promptKeys,
        authTriggerKeys,
        timeout
      )
    }

    return this.instance
  }

  public onKeyboardEvent(event: KeyboardEvent) {
    if (!this.enabled) {
      return
    }

    const isTriggerKey = this.triggerKeys.includes(event.key)
    const isAuthTriggerKey = this.authTriggerKeys.includes(event.key)
    let isSubmitKey = this.submitKeys.includes(event.key)

    if (isTriggerKey) {
      clearTimeout(this.timeout)
      this.capturing = true
    }

    if (this.promptKeys.includes(event.key)) {
      const value = prompt('Query?')
      if (value) {
        this.capturing = true
        this.buffer = value.split('')
        isSubmitKey = true
      }
    }

    if (this.capturing) {
      if (isAuthTriggerKey || (!isTriggerKey && !isSubmitKey)) {
        this.buffer.push(event.key)
      }

      if (isSubmitKey) {
        const query = cleanScanInput(this.buffer.join(''), event.key)

        if (this.inputCallback) {
          this.inputCallback(query)
        }

        this.reset()
      }

      this.timeout = setTimeout(() => {
        this.reset()
      }, scannerConfig.timeout)
    }
  }

  onInput(callback: (query: string) => void) {
    this.inputCallback = callback
  }

  reset() {
    this.buffer = []
    this.capturing = false
    this.query = ''
  }
}

export const scanInput = ScanInput.getInstance(
  scannerConfig.triggerKeys,
  scannerConfig.submitKeys,
  scannerConfig.promptKeys,
  scannerConfig.authTriggerKeys,
  scannerConfig.timeout
)

export class Subscriber {
  constructor(
    public readonly identifier: string,
    public readonly event: string,
    public isPaused: boolean,
    public callback: SyncEventCallback
  ) {}

  public pause() {
    this.isPaused = true
  }

  public resume() {
    this.isPaused = false
  }
}

export type SyncEventCallback = (event: IEvent) => void

export interface IEvent {
  readonly name: string
  readonly payload?: unknown
  subscriber: Subscriber
  readonly extraData: string[]

  stopPropagation(): void
  isStopPropagation(): boolean
  setSubscriber(subscriber: Subscriber): void
}

export class Event implements IEvent {
  private _stopPropagation = false
  public subscriber: Subscriber

  constructor(
    public readonly name: string,
    public readonly payload?: unknown,
    public readonly extraData: string[] = []
  ) {}

  setSubscriber(subscriber: Subscriber): void {
    this.subscriber = subscriber
  }

  stopPropagation(): void {
    this._stopPropagation = true
  }

  isStopPropagation(): boolean {
    return this._stopPropagation
  }
}

export class SynchronousEventBus {
  private _subscribers: Map<string, Array<Subscriber>> = new Map()

  private static _instance?: SynchronousEventBus = undefined

  public emit(event: IEvent) {
    const eventSubscribers = this.getSubscribers(event.name)

    for (let i = 0; i < eventSubscribers.length; i++) {
      try {
        const subscriber = eventSubscribers[i]
        if (subscriber.isPaused) {
          continue
        }

        event.setSubscriber(subscriber)

        subscriber.callback(event)
        if (event.isStopPropagation()) {
          break
        }
      } catch (e) {
        break
      }
    }
  }

  /**
   * @deprecated Use subscribe() instead
   */
  public on(event: string, callback: SyncEventCallback, prepend = false) {
    const uid = generateUniqueIdentifier()
    const subscribers = this.getSubscribers(event)
    if (!subscribers.find((subscriber) => subscriber.callback === callback)) {
      if (prepend) {
        subscribers.unshift(new Subscriber(uid, event, false, callback))
      } else {
        subscribers.push(new Subscriber(uid, event, false, callback))
      }
    }

    this._subscribers.set(event, subscribers)

    return () => {
      const index = this.getSubscribers(event).findIndex(
        (subscriber) => subscriber.callback === callback
      )
      if (index > -1) {
        this.getSubscribers(event).splice(index, 1)
      }
    }
  }

  public subscribe(
    identifier: string,
    event: string,
    callback: SyncEventCallback,
    isPaused = false,
    prepend = false
  ): Subscriber {
    const subscribers = this.getSubscribers(event)
    const subscriber = new Subscriber(identifier, event, isPaused, callback)
    if (subscribers.find((subscriber) => subscriber.identifier === identifier)) {
      throw new Error(`Subscriber with identifier "${identifier}" already subscribed`)
    }

    if (prepend) {
      subscribers.unshift(subscriber)
    } else {
      subscribers.push(subscriber)
    }

    this._subscribers.set(event, subscribers)

    return subscriber
  }

  public unsubscribe(subscriber: Subscriber): void {
    const subscribers = this.getSubscribers(subscriber.event)
    const index = subscribers.findIndex((sub) => sub.identifier === subscriber.identifier)

    if (index > -1) {
      subscribers.splice(index, 1)
    }

    this._subscribers.set(subscriber.event, subscribers)
  }

  private getSubscribers(event: string) {
    return this._subscribers.get(event) ?? []
  }

  public off(event: string) {
    this._subscribers.set(event, [])
  }

  public static instance() {
    if (!this._instance) {
      this._instance = new SynchronousEventBus()
    }

    return this._instance
  }
}

export const eventBus = SynchronousEventBus.instance()
