<template>
  <f7-card :class="{ 'skeleton-text': isLoading }">
    <f7-card-header>
      <div>{{ totalColloQuantity }} Carriers, {{ quantityPerColloType.length }} types</div>
      <f7-button color="dimass" text-color="white" @click="onAdd">
        <font-awesome-icon :icon="['far', 'plus']" />
      </f7-button>
    </f7-card-header>
    <f7-card-content :padding="false">
      <f7-list media-list>
        <f7-list-item class="no-items" v-if="quantityPerColloType.length === 0 && !isLoading">
          No carriers added yet
        </f7-list-item>
        <f7-list-item v-for="collo in quantityPerColloType" :key="collo.type" swipeout>
          <template #media>
            {{ collo.quantity.value }}
          </template>
          <template #title>
            {{ getColloTypeForHuman(collo.type.value) }}
          </template>
          <f7-swipeout-actions left>
            <f7-swipeout-button color="dimass" close @click="onEdit(collo)">
              <font-awesome-icon :icon="['far', 'edit']" />
            </f7-swipeout-button>
          </f7-swipeout-actions>
          <f7-swipeout-actions right>
            <f7-swipeout-button color="red" close @click="onDelete(collo)">
              <font-awesome-icon :icon="['far', 'trash']" />
            </f7-swipeout-button>
          </f7-swipeout-actions>
        </f7-list-item>
      </f7-list>
    </f7-card-content>
  </f7-card>
  <f7-card :no-shadow="true" style="background: transparent">
    <div class="row">
      <div class="col">
        <f7-button large color="dimass" text-color="white" @click="onSave" :disabled="isLoading">
          Save
        </f7-button>
      </div>
      <div class="col">
        <f7-button large outline @click="onReset">Cancel</f7-button>
      </div>
    </div>
  </f7-card>
</template>
<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import useInbound from '@composables/useInbound'
import { computed } from 'vue'
import {
  IQuantityInboundColloTypeFormInput,
  IReceptionRegistration
} from '@store/pinia/inbound/types'
import { perceptibleToast } from '@services/perceptibleToast'
import { confirmYesNo } from '@/functions/dialog'

const { getColloTypeForHuman, findIndexOfExistingQuantityPerColloItem } = useInbound()

const props = defineProps<{
  receptionRegistration: IReceptionRegistration
  isLoading: boolean
}>()

const emits = defineEmits<{
  (e: 'addItem')
  (e: 'editItem')
  (e: 'clearForm')
  (e: 'deleteItem', item: IQuantityInboundColloTypeFormInput)
  (e: 'saveItem', item: IQuantityInboundColloTypeFormInput)
}>()

const quantityPerColloType = computed<IQuantityInboundColloTypeFormInput[]>(() => {
  if (!props.receptionRegistration) {
    return []
  }

  return props.receptionRegistration.colli
})

const totalColloQuantity = computed(() => {
  return quantityPerColloType.value
    .map((qpc) => qpc.quantity.value)
    .reduce((total, current) => total + current, 0)
})

const onDelete = async (item: IQuantityInboundColloTypeFormInput) => {
  const index = findIndexOfExistingQuantityPerColloItem(item)

  if (index === -1) {
    await perceptibleToast.error('Item could not be deleted!')

    return
  }

  await confirmYesNo({
    title: 'Are you sure that you want to delete this item?',
    yesButtonCallback: async () => {
      await emits('deleteItem', item)
    }
  })
}

const onAdd = () => {
  emits('addItem')
}

const onEdit = (item: IQuantityInboundColloTypeFormInput) => {
  emits('editItem', item)
}

const onSave = () => {
  emits('saveItem', props.receptionRegistration)
}

const onReset = () => {
  confirmYesNo({
    title: 'Are you sure that you want to reset the reception?',
    yesButtonCallback: async () => {
      await emits('clearForm')
    }
  })
}
</script>

<style scoped>
.no-items {
  opacity: 0.6;
}
</style>
