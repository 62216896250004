import { AxiosInstance } from 'axios'
import GRAPHQL_API_CLIENT from './client'
import Base from './base'
import {
  IAddColliToReceptionRequest,
  IAddColliToReceptionResponse,
  IAddItemToInboundSortLoadCarrierRequest,
  IAddItemToInboundSortLoadCarrierResponse,
  ICreateInboundSortLoadCarrierRequest,
  ICreateInboundSortLoadCarrierResponse,
  IGetColloByIdQueryRequest,
  IGetColloByIdQueryResponse,
  IGetInboundSortLoadCarrierByIdRequest,
  IGetInboundSortLoadCarrierByIdResponse,
  IGetInboundSortLoadCarrierItemsByLoadCarrierIdRequest,
  IGetInboundSortLoadCarrierItemsByLoadCarrierIdResponse,
  IGetPutawayItemsRequest,
  IGetPutawayItemsResponse,
  IGetPutawaySourceRequest,
  IGetPutawaySourceResponse,
  IGetReceptionByIdQueryRequest,
  IGetReceptionByIdQueryResponse,
  IGetReceptionsQueryResponse,
  IGetUncompletedInboundSortLoadCarriersByWarehouseZoneRequest,
  IGetUncompletedInboundSortLoadCarriersByWarehouseZoneResponse,
  IMarkInboundSortLoadCarrierAsSortedRequest,
  IMarkInboundSortLoadCarrierAsSortedResponse,
  IPutawayItemToLocationRequest,
  IPutawayItemToLocationResponse,
  IPutawayToKardexRequest,
  IPutawayToKardexResponse,
  IRegisterReceptionMutationRequest,
  IRegisterReceptionMutationResponse,
  IUpdateColloMutationRequest,
  IUpdateColloMutationResponse
} from '@graphql/inbound/types'
import {
  IMarkLineAsIncompleteRequest,
  IMarkLineAsIncompleteResponse,
  IStartKardexPutawayRequest,
  IStartKardexPutawayResponse,
  IUpdateReceptionNotesRequest,
  IUpdateReceptionNotesResponse
} from '@graphql/kardex/types'

class Inbound extends Base {
  constructor(protected axios: AxiosInstance) {
    super(axios, 'inbound')
  }

  public async registerReception(request: IRegisterReceptionMutationRequest) {
    return await this.makeRequest<IRegisterReceptionMutationResponse>(
      'registerReceptionMutation',
      request
    )
  }

  public async addColliToReception(request: IAddColliToReceptionRequest) {
    return await this.makeRequest<IAddColliToReceptionResponse>(
      'addColliToReceptionMutation',
      request
    )
  }

  async getReceptionById(request: IGetReceptionByIdQueryRequest) {
    return await this.makeRequest<IGetReceptionByIdQueryResponse>('getReceptionByIdQuery', request)
  }

  async getReceptions() {
    return await this.makeRequest<IGetReceptionsQueryResponse>('getReceptionsQuery')
  }

  async getColloById(request: IGetColloByIdQueryRequest) {
    return await this.makeRequest<IGetColloByIdQueryResponse>('getColloByIdQuery', request)
  }

  async updateCollo(request: IUpdateColloMutationRequest) {
    return await this.makeRequest<IUpdateColloMutationResponse>('updateColloMutation', request)
  }

  async getUncompletedInboundSortLoadCarriersByZone(
    request: IGetUncompletedInboundSortLoadCarriersByWarehouseZoneRequest
  ) {
    return await this.makeRequest<IGetUncompletedInboundSortLoadCarriersByWarehouseZoneResponse>(
      'getUncompletedInboundSortLoadCarriersByZoneQuery',
      request
    )
  }

  async createInboundSortLoadCarrier(request: ICreateInboundSortLoadCarrierRequest) {
    return await this.makeRequest<ICreateInboundSortLoadCarrierResponse>(
      'createInboundSortLoadCarrierMutation',
      request
    )
  }

  async addItemToInboundSortLoadCarrier(request: IAddItemToInboundSortLoadCarrierRequest) {
    return await this.makeRequest<IAddItemToInboundSortLoadCarrierResponse>(
      'addItemToInboundSortLoadCarrierMutation',
      request
    )
  }

  async markInboundSortLoadCarrierAsSorted(request: IMarkInboundSortLoadCarrierAsSortedRequest) {
    return await this.makeRequest<IMarkInboundSortLoadCarrierAsSortedResponse>(
      'markInboundSortLoadCarrierAsSortedMutation',
      request
    )
  }

  async getInboundSortLoadCarrierById(request: IGetInboundSortLoadCarrierByIdRequest) {
    return await this.makeRequest<IGetInboundSortLoadCarrierByIdResponse>(
      'getInboundSortLoadCarrierByIdQuery',
      request
    )
  }

  async getInboundSortLoadCarrierItemsByLoadCarrierId(
    request: IGetInboundSortLoadCarrierItemsByLoadCarrierIdRequest
  ) {
    return await this.makeRequest<IGetInboundSortLoadCarrierItemsByLoadCarrierIdResponse>(
      'getInboundSortLoadCarrierItemsByIdQuery',
      request
    )
  }

  async getPutawayItems(request: IGetPutawayItemsRequest) {
    return await this.makeRequest<IGetPutawayItemsResponse>('getPutawayItemsQuery', request)
  }

  async getPutawaySource(request: IGetPutawaySourceRequest) {
    return await this.makeRequest<IGetPutawaySourceResponse>('getPutawaySourceQuery', request)
  }

  async putawayItemToLocation(request: IPutawayItemToLocationRequest) {
    return await this.makeRequest<IPutawayItemToLocationResponse>(
      'putawayItemToLocationMutation',
      request
    )
  }

  async putawayToKardex(request: IPutawayToKardexRequest) {
    return await this.makeRequest<IPutawayToKardexResponse>('putawayToKardexMutation', request)
  }

  async startKardexPutaway(request: IStartKardexPutawayRequest) {
    return this.makeRequest<IStartKardexPutawayResponse>('StartKardexPutawayMutation', request)
  }

  async updateReceptionNotes(request: IUpdateReceptionNotesRequest) {
    return this.makeRequest<IUpdateReceptionNotesResponse>('updateReceptionNotesMutation', request)
  }

  async markLineAsIncomplete(request: IMarkLineAsIncompleteRequest) {
    return this.makeRequest<IMarkLineAsIncompleteResponse>('markLineAsIncompleteMutation', request)
  }
}

export default new Inbound(GRAPHQL_API_CLIENT)
