import useDialog from '@composables/useDialog'
import { ILine, ILineItem } from '@graphql/pick/types'
import { confirmYesNo } from '@/functions/dialog'
import { IArticleBarcode } from '@graphql/article/types'

export default () => {
  const { numberInputDialog } = useDialog()

  const isMatchingArticle = (lineItem: ILineItem, barcode: string) =>
    lineItem.article.articleBarcodes
      .map((articleBarcode: IArticleBarcode) => articleBarcode.content)
      .includes(barcode) && !isQuantityFulfilled(lineItem)

  const isMatchingArticleBatch = (lineItem: ILineItem, barcode: string): boolean => {
    return (
      lineItem.articleBatch !== null &&
      lineItem.articleBatch.barcode === barcode &&
      !isQuantityFulfilled(lineItem)
    )
  }

  const isMatchingLocation = (line: ILine, locationName: string) =>
    line.location.name === locationName

  const quantityFulFilled = (lineItem: ILineItem): number => {
    return lineItem.quantityMissed + lineItem.quantityDone
  }

  const calculateQuantityNotFulfilled = (lineItem: ILineItem): number => {
    return lineItem.quantityTodo - quantityFulFilled(lineItem)
  }

  const isQuantityFulfilled = (lineItem: ILineItem): boolean => {
    return calculateQuantityNotFulfilled(lineItem) === 0
  }

  const calculateQuantityToPick = (lineItem: ILineItem) => {
    return lineItem.quantityTodo - lineItem.quantityMissed
  }

  const calculateQuantityToFulfill = (lineItem: ILineItem) => {
    return lineItem.quantityTodo - lineItem.quantityDone
  }
  const hasQuantityMissed = (lineItem: ILineItem): boolean => lineItem.quantityMissed > 0

  const showConfirmMispicksDialog = async (lineItem: ILineItem, quantityMissed: number) => {
    await confirmYesNo({
      title: `Are you sure, that quantity of ${quantityMissed} cannot be picked from the current location?`,
      yesButtonCallback: async () => (lineItem.quantityMissed = quantityMissed)
    })
  }

  const onQuantityMissed = async (lineItem: ILineItem) => {
    const quantityToFulfill = calculateQuantityToFulfill(lineItem)

    if (quantityToFulfill === 0) {
      return
    }

    if (quantityToFulfill > 1) {
      await numberInputDialog(
        'Enter missing quantity',
        lineItem.quantityMissed,
        quantityToFulfill,
        async (dialog, event, value) => {
          await showConfirmMispicksDialog(lineItem, Number(value))
        }
      )
      return
    }

    await showConfirmMispicksDialog(lineItem, 1)
  }

  return {
    quantityFulFilled,
    isMatchingArticle,
    isMatchingArticleBatch,
    isMatchingLocation,
    isQuantityFulfilled,
    calculateQuantityNotFulfilled,
    hasQuantityMissed,
    onQuantityMissed,
    showConfirmMispicksDialog,
    calculateQuantityToPick,
    calculateQuantityToFulfill
  }
}
