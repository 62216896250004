<template>
  <f7-page :page-content="false">
    <navigation>
      <template #page-title>
        <f7-nav-title>
          <string-label
            class="padding-right"
            :value="item ? `Edit ${item.internalReference}` : 'New reception'"
          />
        </f7-nav-title>
      </template>
    </navigation>
    <f7-page-content>
      <div v-if="receptionRegistration">
        <f7-block-title>Notes</f7-block-title>
        <f7-block>
          <f7-input
            type="textarea"
            class="textarea-row-4"
            :value="receptionRegistration.notes"
            @input="receptionRegistration.notes = $event.target.value"
          />
        </f7-block>

        <collo-quantity-list-card
          :is-loading="isLoading"
          :reception-registration="receptionRegistration"
          @add-item="onItemAdd"
          @edit-item="onItemEdit"
          @delete-item="onItemDelete"
          @save-item="onSave"
          @clear-form="onClearForm"
        />
      </div>
    </f7-page-content>
    <f7-popup
      :opened="formPopupOpened"
      class="collo-quantity-input-popup"
      @popup:closed="onFormPopupClose"
      :close-on-ecape="false"
      :close-by-backdrop-click="false"
      :tablet-fullscreen="true"
      :swipe-to-close="false"
    >
      <f7-page :page-content="false">
        <default-popup-header />
        <f7-page-content>
          <inbound-collo-form
            :is-edit="isEdit"
            v-if="receptionRegistration"
            :value="form"
            @submitted="onSubmit"
          />
        </f7-page-content>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<script setup lang="ts">
import Navigation from '@components/AppNavigation.vue'
import { ref } from 'vue'
import useInbound from '@composables/useInbound'
import { IQuantityInboundColloTypeFormInput } from '@store/pinia/inbound/types'
import { perceptibleToast } from '@services/perceptibleToast'
import InboundColloForm from '@components/inbound/InboundColloForm.vue'
import DefaultPopupHeader from '@components/DefaultPopupHeader.vue'
import ColloQuantityListCard from '@components/inbound/ColloQuantityListCard.vue'
import { f7 } from 'framework7-vue'
import { IInboundReception, IRegisterReceptionMutationRequest } from '@graphql/inbound/types'
import useLoading from '@composables/useLoading'
import StringLabel from '@components/label/StringLabel.vue'

const props = defineProps<{
  item: IInboundReception | null
}>()

const createNewFormObject: IQuantityInboundColloTypeFormInput = () => {
  return {
    type: {
      value: '',
      errors: []
    },
    quantity: {
      value: 0,
      errors: []
    }
  }
}

const formObject = createNewFormObject()

const {
  receptionRegistration,
  addColloTypeQuantity,
  createReceptionRegistration,
  clearReception,
  submitReceptionRegistration
} = useInbound()

const { isLoading, withAsyncLoading } = useLoading()

if (!receptionRegistration.value) {
  createReceptionRegistration(props.item)
}

const form = ref<IQuantityInboundColloTypeFormInput>(formObject)
const isEdit = ref(false)
const formPopupOpened = ref(false)

const { findIndexOfExistingQuantityPerColloItem, addColliToReception, updateReceptionNotes } =
  useInbound()

const resetForm = () => {
  form.value = createNewFormObject()
  isEdit.value = false
}

const closeFormPopup = () => (formPopupOpened.value = false)
const openFormPopup = () => (formPopupOpened.value = true)

const onSubmit = (value: IQuantityInboundColloTypeFormInput) => {
  if (isEdit.value) {
    onItemEdited(value)
    return
  }

  onItemAdded(value)
}

const onItemAdd = () => {
  resetForm()
  openFormPopup()
}

const onItemAdded = (value: IQuantityInboundColloTypeFormInput) => {
  addColloTypeQuantity(value)

  resetForm()
  closeFormPopup()
}

const onItemEdit = (item: IQuantityInboundColloTypeFormInput) => {
  form.value = Object.assign({}, item)
  isEdit.value = true
  openFormPopup()
}

const onItemEdited = (value: IQuantityInboundColloTypeFormInput) => {
  const index = findIndexOfExistingQuantityPerColloItem(value)
  if (index === -1) {
    perceptibleToast.error('Something went wrong while saving data!')

    return
  }

  receptionRegistration.value.colli[index] = value

  perceptibleToast.success('Item updated!')

  resetForm()
  closeFormPopup()
}

const onItemDelete = (item: IQuantityInboundColloTypeFormInput) => {
  const index = findIndexOfExistingQuantityPerColloItem(item)

  receptionRegistration.value.colli.splice(index, 1)

  perceptibleToast.success('Item deleted!')
}

const onSave = async () => {
  await withAsyncLoading(async () => {
    if (props.item) {
      const request = {
        receptionId: props.item.id,
        colli: receptionRegistration.value.colli.map((collo) => {
          return {
            type: collo.type.value.toString(),
            quantity: collo.quantity.value
          }
        })
      }

      await addColliToReception(request)
      await updateReceptionNotes({
        receptionId: props.item.id,
        notes: receptionRegistration.value.notes
      })

      await perceptibleToast.success('Registration saved successfully!')
      onClearForm()
      return
    }

    try {
      const request: IRegisterReceptionMutationRequest = {
        startedAt: receptionRegistration.value.startedAt,
        colli: receptionRegistration.value.colli.map((collo) => {
          return {
            type: collo.type.value.toString(),
            quantity: collo.quantity.value
          }
        }),
        notes: receptionRegistration.value.notes
      }

      await submitReceptionRegistration(request)

      await perceptibleToast.success('Registration saved successfully!')
      onClearForm()
    } catch (e) {
      await perceptibleToast.error(e.message)
      throw e
    }
  })
}

const onFormPopupClose = () => {
  formPopupOpened.value = false
  resetForm()
}

const onClearForm = () => {
  clearReception()
  f7.views.main.router.navigate('/inbound/reception/', {
    clearPreviousHistory: true
  })
}
</script>
