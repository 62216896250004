<template>
  <f7-row no-gap>
    <f7-col width="25">
      <f7-button @click="decrement" :disabled="isDecrementButtonDisabled">
        <f7-icon :material="iconMinus" />
      </f7-button>
    </f7-col>
    <f7-col width="50">
      <div class="input input-with-value">
        <input
          type="number"
          :ref="autoFocusRef"
          @click="onClick"
          v-model="currentValue"
          :min="minValue"
          :max="maxValue"
          class="input input-with-value text-align-center"
          pattern="[0-9]*"
          @keyup.enter="onSubmit"
        />
      </div>
    </f7-col>
    <f7-col width="25">
      <f7-button @click="increment" :disabled="isIncrementButtonDisabled">
        <f7-icon :material="iconPlus" />
      </f7-button>
    </f7-col>
  </f7-row>
</template>
<script lang="ts" setup>
import { f7 } from 'framework7-vue'
import { computed, ref, watch } from 'vue'
import { removeAutoFocus } from '@/utilities/generic'

const props = withDefaults(
  defineProps<{
    step?: number
    iconPlus?: string
    iconMinus?: string
    minValue?: number
    maxValue?: number
    autoFocus?: boolean
    shouldConfirm?: boolean
    value: number | string
  }>(),
  {
    step: 1,
    iconPlus: 'add_circle_outline',
    iconMinus: 'remove_circle_outline',
    minValue: 0,
    maxValue: 1000,
    autoFocus: false,
    shouldConfirm: true
  }
)

const emits = defineEmits<{
  (e: 'submit', payload: unknown)
  (e: 'change', payload: number)
}>()

const currentValue = ref(props.value)
const autofocus = ref(props.autoFocus)

const isDecrementButtonDisabled = computed(() => Number(currentValue.value) <= props.minValue)
const isIncrementButtonDisabled = computed(() => Number(currentValue.value) >= props.maxValue)

const autoFocusRef = async (element: HTMLElement | null) => {
  if (element && autofocus.value) {
    autofocus.value = false
    setTimeout(() => {
      element.focus()
    }, 300)
  }
}

watch(currentValue, (newValue) => {
  emits('change', Number(newValue))
})

const onClick = (e: any) => {
  if (e.target.value && props.shouldConfirm) {
    f7.dialog.confirm('Are you sure you want to change this value?', async () => {
      currentValue.value = ''
      autofocus.value = true
      await autoFocusRef(e.target)
    })
  }
}

const onSubmit = () => {
  removeAutoFocus()

  emits('submit', true)
}

const increment = () => {
  currentValue.value = Number(currentValue.value) // (re)cast to number

  currentValue.value += props.step
}

const decrement = () => {
  currentValue.value = Number(currentValue.value) // (re)cast to number

  if (currentValue.value <= props.minValue) {
    currentValue.value = props.minValue
  } else {
    currentValue.value -= props.step
  }
}

watch(
  () => props.value,
  (value) => {
    currentValue.value = value
  }
)

watch(
  () => props.autoFocus,
  (value) => {
    autofocus.value = value
  }
)
</script>
