<template>
  <page-with-search-subscriber
    :scan-callback="onScan"
    v-slot="{ searchSubscriber }"
    :page-content="false"
  >
    <navigation title="Pack" />
    <f7-tabs>
      <f7-tab id="packing-todo-lines" tab-active>
        <f7-page-content ptr :ptr-distance="50" ptr-mousewheel @ptr:refresh="onPullToRefresh">
          <f7-card v-if="pack?.description">
            <f7-card-header>Description</f7-card-header>
            <f7-card-content>
              <div style="white-space: pre-wrap">{{ pack?.description }}</div>
            </f7-card-content>
          </f7-card>
          <f7-card>
            <f7-card-header>Scan item to start packing</f7-card-header>
            <scan-barcode-animation title="Scan item" />
          </f7-card>
          <div
            class="loader display-flex justify-content-center"
            v-if="isLoading && pack?.packableItems.length === 0"
          >
            <f7-preloader />
          </div>
          <f7-card v-if="!isLoading && pack?.packableItems.length === 0">
            <f7-card-content>
              <icon-with-text :icon="['fad', 'party-horn']" title="All work done!"></icon-with-text>
            </f7-card-content>
          </f7-card>
          <f7-list v-if="pack?.packableItems.length > 0">
            <f7-list-item
              :class="{ 'skeleton-text': isLoading }"
              v-for="(packableItem, index) in pack?.packableItems"
              :key="index"
            >
              <template #inner>
                <div class="packable-item display-flex">
                  <div class="parent" v-if="!!packableItem.parentItem">
                    <font-awesome-icon :icon="['far', 'turn-down-right']" />
                  </div>
                  <div class="name">
                    {{ packableItem.packableIdentity.name }}
                  </div>
                  <div class="amount">
                    <quantity-label :quantity="getQuantity(packableItem)" />
                  </div>
                </div>
              </template>
            </f7-list-item>
          </f7-list>
        </f7-page-content>
      </f7-tab>
      <f7-tab id="packing-load-carriers">
        <f7-page-content ptr :ptr-distance="50" ptr-mousewheel @ptr:refresh="onPullToRefresh">
          <f7-block>
            <h1>Outbound load carriers</h1>
          </f7-block>
          <div
            class="loader display-flex justify-content-center"
            v-if="!isLoading && pack?.nestedLoadCarriersList.length === 0"
          >
            <f7-preloader />
          </div>
          <load-carriers-list
            v-if="pack?.nestedLoadCarriersList.length > 0"
            :is-loading="isLoading"
            @on-deleted="refreshProject"
            :nested-load-carrier-list-items="pack?.nestedLoadCarriersList ?? []"
          />
        </f7-page-content>
      </f7-tab>
    </f7-tabs>

    <create-load-carrier-popup
      v-model="createLoadCarrierPopupOpened"
      :pack-project-id="pack?.id ?? 0"
      @load-carrier-created="onLoadCarrierCreated"
    />

    <scan-destination-popup
      v-if="packSourceItem"
      v-model="scanDestinationPopenOpened"
      @on-closed="searchSubscriber.resume()"
      @on-destination-hit="onDestinationHit($event, searchSubscriber)"
      :pack-source-item="packSourceItem"
    />

    <f7-fab class="fab" position="right-bottom" @click="onAddLoadCarrier">
      <font-awesome-icon :icon="['far', 'plus']" />
    </f7-fab>
    <f7-fab
      class="fab"
      position="center-bottom"
      v-if="!isLoading && todoCount === 0"
      @click="onCompletePack"
    >
      <font-awesome-icon :icon="['far', 'check']" />
    </f7-fab>
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#packing-todo-lines" tab-link-active>Todo ({{ todoCount }})</f7-link>
      <f7-link tab-link="#packing-load-carriers">Load carriers ({{ loadCarrierCount }})</f7-link>
    </f7-toolbar>
  </page-with-search-subscriber>
</template>
<script lang="ts" setup>
import Navigation from '@components/AppNavigation.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { computed, onMounted, ref } from 'vue'
import { ILoadCarrier as IPickLoadCarrier } from '@graphql/pick/types'
import CreateLoadCarrierPopup from '@components/search/Outbound/LoadCarrier/CreateLoadCarrierPopup.vue'
import { IPackableItem, IPackProject, IPackSourceItem } from '@graphql/pack/types'
import useProjectPack from '@composables/useProjectPack'
import usePack from '@composables/usePack'
import useLoading from '@composables/useLoading'
import useArticle from '@composables/useArticle'
import QuantityLabel from '@components/QuantityLabel.vue'
import ScanBarcodeAnimation from '@components/ScanBarcodeAnimation.vue'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'
import { IEvent, Subscriber } from '@/utilities/scanInput'
import { perceptibleToast } from '@services/perceptibleToast'
import { captureEvent, captureException } from '@sentry/vue'
import ScanDestinationPopup from '@pages/pack/single-shipment/component/ScanDestinationPopup.vue'
import LoadCarriersList from '@pages/pack/single-shipment/component/LoadCarriersList.vue'
import { f7 } from 'framework7-vue'
import IconWithText from '@components/IconWithText.vue'
import useLoadCarrier from '@composables/useLoadCarrier'

const pack = ref<IPackProject>()
const createLoadCarrierPopupOpened = ref<boolean>(false)
const scanDestinationPopenOpened = ref<boolean>(false)
const packSourceItem = ref<IPackSourceItem | null>(null)

const { activeProject, updateProject } = useProjectPack()
const { getPackProjectById, getPackSourceItem, addPackSourceItemToOutboundLoadCarrier } = usePack()
const { isLoading, withAsyncLoading } = useLoading()
const { getStockQuantity, isArticleBatchBarcode } = useArticle()
const { isLoadCarrierBarcode } = useLoadCarrier()

const todoCount = computed<number>(() => pack.value?.packableItems.length ?? 0)
const loadCarrierCount = computed<number>(() => pack.value?.outboundLoadCarriers.length ?? 0)

defineProps<{
  pickLoadCarrier: IPickLoadCarrier
}>()

onMounted(() => {
  pack.value = activeProject.value!.packProject

  if (pack.value.outboundLoadCarriers.length === 0) {
    createLoadCarrierPopupOpened.value = true
  }
})

const onScan = async (event: IEvent) => {
  const query = String(event.payload)

  if (createLoadCarrierPopupOpened.value) {
    return
  }

  const isArticleBatch = isArticleBatchBarcode(query)

  if (!isLoadCarrierBarcode(query) && !isArticleBatch) {
    await perceptibleToast.error('Scan valid item.')
    return
  }

  if (isArticleBatch && event.extraData.length > 0 && event.extraData[0].startsWith('PICK')) {
    await getSourceItem(event.extraData[0], event.subscriber)
    return
  }

  await getSourceItem(query, event.subscriber)
}

const getSourceItem = async (barcode: string, subscriber: Subscriber) => {
  try {
    packSourceItem.value = await getPackSourceItem({
      packProjectId: pack.value!.id,
      barcode: barcode
    })

    if (!packSourceItem.value) {
      await perceptibleToast.error(`No item found for "${barcode}"`)
      return
    }

    scanDestinationPopenOpened.value = true
    subscriber.pause()
  } catch (e) {
    captureEvent(e)

    await perceptibleToast.error(e.message)
  }
}

const onAddLoadCarrier = async () => {
  createLoadCarrierPopupOpened.value = true
}

const onLoadCarrierCreated = async () => {
  createLoadCarrierPopupOpened.value = false

  await refreshProject()
}

const onPullToRefresh = async (done: () => void) => {
  await refreshProject()

  done()
}

const refreshProject = async () => {
  await withAsyncLoading(async () => {
    pack.value = await getPackProjectById({
      packProjectId: pack.value!.id
    })
  })
}

const getQuantity = (packableItem: IPackableItem) => {
  if (packableItem.stockUnit) {
    return getStockQuantity(
      packableItem.stockUnit,
      packableItem.quantity - packableItem.quantityPacked
    )
  }

  return packableItem.quantity - packableItem.quantityPacked
}

const onDestinationHit = async (destinationBarcode: string, subscriber: Subscriber) => {
  try {
    pack.value = await addPackSourceItemToOutboundLoadCarrier({
      packSourceItemId: packSourceItem.value!.id,
      packSourceItemType: packSourceItem.value!.type,
      loadCarrierId: destinationBarcode,
      packProjectId: pack.value!.id
    })

    await perceptibleToast.success(`${packSourceItem.value!.name} added to ${destinationBarcode}`)

    packSourceItem.value = null
    scanDestinationPopenOpened.value = false
    subscriber.resume()
  } catch (e) {
    captureEvent(e)

    await perceptibleToast.error(e.message)
  }
}

const onCompletePack = async () => {
  await updateProject({
    ...activeProject.value!,
    markedReady: true
  })

  f7.views.main.router.navigate('/pack-entry/', {
    clearPreviousHistory: true
  })
}
</script>

<style scoped lang="less">
.tab {
  height: 100%;

  .page-content {
    padding-bottom: 85px;
  }
}

.packable-item {
  width: 100%;
  gap: 10px;

  .name {
    flex: 1;
  }
}

.loader {
  width: 100%;
}

.fab {
  margin-bottom: 45px;
}
</style>
