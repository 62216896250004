<template>
  <f7-sheet
    swipe-to-close
    backdrop
    :opened="sheetOpen"
    class="sheet bg-color-white"
    @sheet:closed="onClose"
  >
    <f7-page :page-content="false">
      <navigation
        hide-back-button
        hide-home-button
        hide-menu
        show-close-button
        @close="onClose"
        title="Create new load carrier"
      >
      </navigation>
      <f7-page-content>
        <f7-card>
          <scan-barcode-animation title="Scan carrier reference" />
        </f7-card>
        <f7-button @click="createWithoutBarcode">I don't have a barcode</f7-button>
      </f7-page-content>
    </f7-page>
  </f7-sheet>
</template>

<script setup lang="ts">
import Navigation from '@components/AppNavigation.vue'
import useInbound from '@composables/useInbound'
import { onUnmounted, ref } from 'vue'
import { IInboundSortLoadCarrier } from '@graphql/inbound/types'
import { IWarehouseZone } from '@graphql/warehouse/types'
import { eventBus } from '@/utilities/scanInput'
import ScanBarcodeAnimation from '@components/ScanBarcodeAnimation.vue'
import { perceptibleToast } from '@services/perceptibleToast'
import { captureException } from '@sentry/vue'

const sheetOpen = defineModel<boolean>()
const props = defineProps<{
  zone: IWarehouseZone
}>()
const { createInboundSortLoadCarrier } = useInbound()

const createdLoadCarrier = ref<IInboundSortLoadCarrier>()

const emits = defineEmits<{
  (e: 'load-carrier-created', payload: IInboundSortLoadCarrier)
}>()

const createWithoutBarcode = () => {
  createLoadCarrier('XX-900')
}

const onClose = () => {
  sheetOpen.value = false
}

const unsubscribe = eventBus.on('scanInput', async (event) => {
  const regexMatch = /^([0-9]{2}|XX)-[0-9]{3}$/
  if (String(event.payload).match(regexMatch) && sheetOpen.value) {
    await createLoadCarrier(String(event.payload))
    return
  }

  await perceptibleToast.error(`"${event.payload}" is not a valid carrier reference.`)
})

const createLoadCarrier = async (reference: string) => {
  try {
    createdLoadCarrier.value = await createInboundSortLoadCarrier({
      reference: reference,
      warehouseZoneId: props.zone.id
    })

    onClose()
    emits('load-carrier-created', createdLoadCarrier.value)
  } catch (e) {
    captureException(e)
    await perceptibleToast.error(e.message)
  }
}

onUnmounted(() => {
  unsubscribe()
})
</script>

<style scoped>
.sheet {
  height: 85vh;
}
</style>
