<template>
  <f7-popup
    :opened="popupOpened"
    :close-by-backdrop-click="false"
    :close-on-escape="false"
    :swipe-to-close="false"
    :tablet-fullscreen="true"
    @popup:closed="popupOpened = false"
  >
    <page-with-search-subscriber :is-paused="false" :scan-callback="onScan" :page-content="false">
      <navigation hide-back-button hide-home-button hide-menu />
      <f7-page-content>
        <f7-card>
          <f7-card-header>Printer</f7-card-header>
          <f7-card-content>
            <scan-barcode-animation title="Scan nearby label printer"></scan-barcode-animation>
          </f7-card-content>
        </f7-card>
      </f7-page-content>
    </page-with-search-subscriber>
  </f7-popup>
</template>

<script lang="ts" setup>
import ScanBarcodeAnimation from '@components/ScanBarcodeAnimation.vue'
import Navigation from '@components/AppNavigation.vue'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'
import { IEvent } from '@/utilities/scanInput'
import { DocumentKind, PrintContext } from '@graphql/document/types'
import { TypeName } from '@graphql/search/types'
import { soundBoard } from '@services/sound'
import { perceptibleToast } from '@services/perceptibleToast'
import useDocument from '@composables/useDocument'
import { ID } from '@graphql/types'
import { captureException } from '@sentry/vue'

const { printDocumentsFor } = useDocument()

const popupOpened = defineModel({ default: false })

const props = withDefaults(
  defineProps<{
    identifier: ID
    typeName: TypeName
    documentKind: DocumentKind
    requiresConfirmation: boolean
  }>(),
  {
    requiresConfirmation: false
  }
)

const emits = defineEmits<{
  (e: 'onSuccess'): void
}>()

const onScan = async (event: IEvent) => {
  const query = String(event.payload)

  if (query.startsWith('PRNT')) {
    try {
      await printDocumentsFor({
        context: new PrintContext(props.identifier, props.typeName, props.documentKind, query),
        showErrorsAsToast: true,
        requiresConfirmation: props.requiresConfirmation
      })

      await soundBoard.playSuccessSound()
      popupOpened.value = false

      emits('onSuccess')
    } catch (e) {
      captureException(e)

      await perceptibleToast.error(e.message)
    }
  }
}
</script>
