<template>
  <f7-popup @popup:closed="onClosed" :opened="popupOpened">
    <f7-page :page-content="false">
      <app-navigation
        hide-back-button
        hide-menu
        hide-home-button
        show-close-button
        @close="onClosed"
      />
      <h1 style="text-align: center">Mark {{ loadCarrier.reference }} as sorted</h1>
      <f7-card>
        <scan-barcode-animation title="Scan load carrier" />
      </f7-card>
    </f7-page>
  </f7-popup>
</template>

<script setup lang="ts">
import { IInboundSortLoadCarrier } from '@graphql/inbound/types'
import AppNavigation from '@components/AppNavigation.vue'
import { onUnmounted } from 'vue'
import { eventBus } from '@/utilities/scanInput'
import { perceptibleToast } from '@services/perceptibleToast'
import ScanBarcodeAnimation from '@components/ScanBarcodeAnimation.vue'

const popupOpened = defineModel<boolean>({
  required: true
})

const props = defineProps<{
  loadCarrier: IInboundSortLoadCarrier
}>()

const emits = defineEmits<{
  (e: 'scanned', payload: IInboundSortLoadCarrier)
}>()

const unsubscribe = eventBus.on('scanInput', async (event) => {
  if (!popupOpened.value) {
    return
  }

  await onScanInput(String(event.payload))
})

const onScanInput = async (query: string) => {
  if (query !== props.loadCarrier.id) {
    await perceptibleToast.error('Scan correct load carrier')
    return
  }

  emits('scanned', props.loadCarrier)
}

const onClosed = () => {
  popupOpened.value = false
}

onUnmounted(() => {
  unsubscribe()
})
</script>

<style scoped>
h1 {
  margin-top: calc(var(--f7-navbar-height) + 20px);
}
</style>
