import searchApi from '@graphql/search'
import { ISearch, TypeName } from '@graphql/search/types'
import { SearchResults, UnionTypeSearchResult } from '@store/modules/search/types'
import { f7 } from 'framework7-vue'
import {
  ISearchFilter,
  ISearchStrategy,
  PluralResultCallback,
  SearchOptions,
  SingleResultCallback
} from '../search'
import { TypeEnum } from '@graphql/pick/types'

export default abstract class AbstractSearchStrategy implements ISearchStrategy {
  /**
   * @inheritdoc
   */
  public async search(
    query: string,
    exact = true,
    type: string | null = null,
    filters: ISearchFilter[] = []
  ): Promise<ISearch> {
    const response = await searchApi.scan(query, exact, type, filters)

    return response.data.data
  }

  /**
   * @inheritdoc
   */
  public async parseSearchResults(
    searchResults: ISearch,
    searchType?: string
  ): Promise<SearchResults> {
    if (searchType) {
      return searchResults.search.filter((s) => s.__typename === searchType)
    }

    return searchResults.search
  }

  /**
   * @inheritdoc
   */
  public async handleResults(
    query: string,
    results: SearchResults,
    options?: SearchOptions
  ): Promise<void> {
    let callback = undefined
    if (results.length === 1) {
      if (options && options.singularCallback) {
        callback = options.singularCallback
      }

      await this.handleSingularResult(results[0], callback)
    } else if (results.length > 1) {
      if (options && options.pluralCallback) {
        callback = options.pluralCallback
      }

      await this.handlePluralResult(results, callback)
    } else {
      if (options && options.noResultCallback) {
        await options.noResultCallback(query)
      }
    }
  }

  /**
   * @inheritdoc
   */
  public async handleSingularResult(
    result: UnionTypeSearchResult,
    callback?: SingleResultCallback
  ): Promise<void> {
    if (callback) {
      await callback(result)
    }

    const url = this.generateUrlFor(result)

    await f7.views.main.router.navigate(url, {
      props: { item: result },
      reloadCurrent: f7.views.main.router.currentRoute.url === url
    })
  }

  /**
   * @inheritdoc
   */
  public async handlePluralResult(
    items: SearchResults,
    callback?: PluralResultCallback
  ): Promise<void> {
    if (callback) {
      await callback(items)
    }

    await f7.views.main.router.navigate('/search/', { props: { items } })
  }

  private generateUrlFor(result: UnionTypeSearchResult) {
    if (result.__typename === TypeName.LoadCarrier) {
      if (result.type === TypeEnum.PICK.toString()) {
        return `/pick/load-carrier/${result.id}/`
      }

      if (result.type === TypeEnum.INBOUND_SORT.toString()) {
        return `/inbound/sort/load-carrier/${result.id}/`
      }

      return `/load-carrier/${result.id}/`
    }

    if (result.__typename === TypeName.InboundCollo) {
      return `/inbound/collo/${result.id}/`
    }

    if (result.__typename === TypeName.InboundReception) {
      if (result.colli.length === 0) {
        return '/inbound/reception/registration/'
      }

      return `/inbound/reception/${result.id}/process/`
    }

    return `/${result.__typename.toLowerCase()}/${result.id}/`
  }
}
