<template>
  <f7-page no-toolbar no-navbar no-swipeback login-screen class="scanner-login-screen">
    <f7-login-screen-title>Login</f7-login-screen-title>
    <template v-if="useUsernamePasswordLogin">
      <login-form />
    </template>
    <template v-else-if="device.desktop">
      <login-with-nfc-authenticator @login-with-username="onLoginWithUsername" />
    </template>
    <template v-else>
      <login-with-nfc
        :nfc-permission-string="nfcPermissionString"
        @login-with-username="onLoginWithUsername"
      />
    </template>
  </f7-page>
</template>
<script lang="ts" setup>
import { NfcReader } from '@services/nfc'
import { toast } from '@services/toast'
import { nextTick, onMounted, ref } from 'vue'
import { f7 } from 'framework7-vue'
import useAuthentication from '@composables/useAuthentication'
import LoginForm from '@components/LoginForm.vue'
import LoginWithNfc from '@components/LoginWithNfc.vue'
import { getDevice } from 'framework7'
import LoginWithNfcAuthenticator from '@components/LoginWithNfcAuthenticator.vue'

const device = getDevice()

const nfcPermissionString = ref<PermissionState | null>(null)
const isNfcAvailable = ref(NfcReader.isNfcFeatureAvailable())
const useUsernamePasswordLogin = ref(false)

const { isAuthenticated, logout } = useAuthentication()

if (isNfcAvailable.value) {
  NfcReader.permissionGranted((permissionStatus: PermissionState) => {
    nfcPermissionString.value = permissionStatus

    if (permissionStatus === 'granted') {
      toast.success('Token login enabled!', 10000).open()

      return
    }

    if (permissionStatus === 'denied') {
      toast.error('NFC permission is denied.', 0, true).open()
    }
  }).then((permissionStatus: PermissionState) => {
    nfcPermissionString.value = permissionStatus
  })
}

const onLoginWithUsername = async () => {
  useUsernamePasswordLogin.value = true
}

onMounted(async () => {
  await logout()

  if (isAuthenticated.value) {
    nextTick(() => {
      f7.views.main.router.navigate('/home/', { reloadAll: true })
    })
  }
})
</script>
