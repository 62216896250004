<template>
  <f7-list-item>
    <template #header>
      <div class="display-flex gap-10 align-items-center justify-content-space-between">
        {{ getStockQuantity(loadCarrierItem.article, loadCarrierItem.quantity) }} /
        {{ getStockQuantity(loadCarrierItem.article, loadCarrierItem.quantityProcessed) }}
        <f7-chip class="chip-small">
          <string-label :value="loadCarrierItem.compartmentReference" />
        </f7-chip>
      </div>
    </template>
    <template #title>{{ loadCarrierItem.article.name }}</template>
    <template #subtitle>{{ loadCarrierItem.article.primaryBarcode }}</template>
    <template #after></template>
  </f7-list-item>
</template>
<script setup lang="ts">
import StringLabel from '@components/label/StringLabel.vue'
import { ILoadCarrierItem } from '@graphql/loadCarrier/types'
import useArticle from '@composables/useArticle'

const { getStockQuantity } = useArticle()

defineProps<{
  loadCarrierItem: ILoadCarrierItem
}>()
</script>
