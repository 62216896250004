<template>
  <f7-fab :position="position" :fixed="fixed" @click="opened = true">
    <f7-icon :material="menuIcon" />
  </f7-fab>
  <f7-actions id="bottom-actions" :grid="grid" :opened="opened" @actions:close="opened = false">
    <f7-actions-group v-if="operationsActions.length > 0">
      <f7-actions-button
        bold
        v-for="(action, key) in operationsActions"
        :key="key"
        @click="onClick(action)"
      >
        <template #media v-if="grid && action.icon">
          <font-awesome-icon class="action-icon" :icon="['far', action.icon]" />
        </template>
        {{ action.name }}
      </f7-actions-button>
    </f7-actions-group>
    <f7-actions-group v-if="viewActions.length > 0">
      <f7-actions-button
        bold
        v-for="(action, key) in viewActions"
        :key="key"
        @click="onClick(action)"
      >
        <template #media v-if="grid && action.icon">
          <font-awesome-icon class="action-icon" :icon="['far', action.icon]" />
        </template>
        {{ action.name }}
      </f7-actions-button>
    </f7-actions-group>
    <f7-actions-group v-if="!grid">
      <f7-actions-button color="red" close>Cancel</f7-actions-button>
    </f7-actions-group>
  </f7-actions>
</template>
<script lang="ts" setup>
import { IAction } from '@components/actions'
import useAuthentication from '@composables/useAuthentication'
import { computed, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { ActionGroup } from '@components/ActionGroup'

const props = withDefaults(
  defineProps<{
    actions: IAction[]
    fixed?: boolean
    morphTo?: string
    position?: string
    grid?: boolean
  }>(),
  {
    actions: () => [],
    fixed: true,
    morphTo: undefined,
    position: 'center-bottom',
    grid: false
  }
)

const emits = defineEmits<{
  (e: 'action-clicked', action: IAction)
}>()

const { isGranted } = useAuthentication()

const opened = ref(false)

const visibleActions = computed<IAction[]>(() =>
  props.actions?.filter((action) => isGranted(action.permissions ?? []))
)

const operationsActions = computed<IAction[]>(() =>
  visibleActions.value.filter((action: IAction) => {
    return !action.group || action.group === ActionGroup.OPERATIONS
  })
)

const viewActions = computed<IAction[]>(() =>
  visibleActions.value.filter((action: IAction) => {
    return action.group === ActionGroup.VIEW
  })
)

const menuIcon = computed(() => {
  if (props.actions.length === 1) {
    return props.actions[0].icon != undefined ? props.actions[0].icon : 'menu'
  }

  return 'menu'
})

const onClick = async (action: IAction) => {
  await emits('action-clicked', action)
}
</script>

<style scoped>
.action-icon {
  font-size: 32px;
  margin: 0 auto;
}
</style>
