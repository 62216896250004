<template>
  <page-with-search-subscriber
    v-slot="{ searchSubscriber }"
    :page-content="false"
    class="page-with-subnavbar"
  >
    <scan-input
      :items="preFilledItems"
      :scan-subscriber="searchSubscriber"
      :success-url="successUrl"
      :success-props="successProps"
      :search-type="searchType"
      :search-strategy="searchStrategy"
      :restricted="restricted"
      :items-callback="itemsCallback"
      key="location-search"
      @item-selected="closeSuggester(searchSubscriber)"
    />
    <f7-fab
      position="center-bottom"
      class="fab-location-suggest"
      text="Find location"
      @click="openSuggester(searchSubscriber)"
    >
      <f7-icon material="my_location" />
    </f7-fab>

    <f7-sheet
      swipe-to-close
      backdrop
      :opened="suggesterPopupOpened"
      class="location-suggestion-popup bg-color-white"
      @sheet:closed="closeSuggester(searchSubscriber)"
      style="height: 85vh"
    >
      <location-suggestion
        v-if="activeGoodsEntry"
        :company-id="activeGoodsEntry.goodsReceiptLine.purchaseOrderLine.purchaseOrder.supplier.id"
        :warehouse-id="activeGoodsEntry.goodsReceiptLine.goodsReceipt.warehouse?.id"
        @close="closeSuggester(searchSubscriber)"
        @selected-item="onSelectedItem($event, searchSubscriber)"
        :items="preFilledItems"
      />
    </f7-sheet>
  </page-with-search-subscriber>
</template>
<script lang="ts" setup>
import { lcFirst } from '@/utilities/string'
import useLoading from '@composables/useLoading'
import useLocation from '@composables/useLocation'
import useProjectInbound from '@composables/useProjectInbound'
import { ILocation } from '@graphql/location/types'
import { SearchStrategy } from '@services/search/search'
import { store } from '@store/store'
import { f7 } from 'framework7-vue'
import { onBeforeMount, ref } from 'vue'
import LocationSuggestion from '@components/LocationSuggestion.vue'
import ScanInput from '@components/ScanInput.vue'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'
import { Subscriber } from '@/utilities/scanInput'

const props = withDefaults(
  defineProps<{
    items: ILocation
    successUrl?: string
    searchStrategy: string
    searchType?: string
    restricted: boolean
    itemsCallback?: () => void
    successProps?: object
  }>(),
  {
    items: () => [],
    successUrl: undefined,
    searchStrategy: SearchStrategy.Default,
    searchType: undefined,
    restricted: false,
    itemsCallback: undefined,
    successProps: undefined
  }
)

const preFilledItems = ref<ILocation[]>([])
const suggesterPopupOpened = ref<boolean>(false)
const { isLoading } = useLoading()
const { setSuggestions } = useLocation()
const { activeGoodsEntry } = useProjectInbound()

onBeforeMount(async () => {
  isLoading.value = true

  preFilledItems.value = props.items

  if (props.itemsCallback) {
    const items: ILocation[] = await props.itemsCallback()
    preFilledItems.value = preFilledItems.value
      .concat(items)
      .filter(
        (item: ILocation, index: number, self: ILocation[]) =>
          index === self.findIndex((s) => s.id === item.id)
      )
      .sort((a: ILocation, b: ILocation) => a.name.localeCompare(b.name))

    await setSuggestions(preFilledItems.value)
  }

  isLoading.value = false

  await openSuggester(null)
})

const closeSuggester = (subscriber: Subscriber) => {
  suggesterPopupOpened.value = false
  subscriber.resume()
}

const openSuggester = (subscriber: Subscriber | null) => {
  if (subscriber) {
    subscriber.pause()
  }
  suggesterPopupOpened.value = true
}

const onSelectedItem = async (location: ILocation, subscriber: Subscriber) => {
  await closeSuggester(subscriber)
  await store.commit(`${lcFirst(location.__typename)}/setActive${location.__typename}`, location)

  if (props.successUrl) {
    f7.views.main.router.navigate(props.successUrl, { props: props.successProps })
  }
}
</script>
