<template>
  <f7-page :page-content="false">
    <navigation hide-back-button />
    <f7-page-content>
      <f7-block-title class="margin-top">
        <slot name="page-title"></slot>
      </f7-block-title>
      <f7-card :class="{ 'skeleton-text': isLoading }">
        <f7-card-content>
          <slot name="page-content"></slot>
        </f7-card-content>
      </f7-card>
      <f7-popup
        id="scan-verification-code-popup"
        :opened="verificationPopupOpened"
        :swipe-to-close="!isLoading"
        :close-on-escape="false"
        tablet-fullscreen
      >
        <f7-page :page-content="false">
          <default-popup-header
            :popup-close="false"
            :close-callback="onVerificationCodePopupClose"
          />
          <f7-page-content :class="{ 'skeleton-text': isLoading }">
            <f7-card>
              <f7-card-content>
                <icon-with-text
                  :icon="['far', 'barcode-scan']"
                  title="Scan verification code"
                  description="Place the printed label in an easy scannable and visible place on the load carrier and scan the verification code!"
                />
              </f7-card-content>
            </f7-card>
            <f7-block>
              <f7-button large fill-md color="text-secondary" @click="onReprintLabel">
                Reprint verification label?
              </f7-button>
            </f7-block>
          </f7-page-content>
        </f7-page>
      </f7-popup>
    </f7-page-content>
  </f7-page>
</template>
<script setup lang="ts">
import DefaultPopupHeader from '@components/DefaultPopupHeader.vue'
import IconWithText from '@components/IconWithText.vue'
import Navigation from '@components/AppNavigation.vue'
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { eventBus } from '@/utilities/scanInput'
import { confirmYesNo } from '@/functions/dialog'
import { perceptibleToast } from '@services/perceptibleToast'
import { soundBoard } from '@services/sound'
import useLoading from '@composables/useLoading'

const props = withDefaults(
  defineProps<{
    verificationPopupOpened: boolean
    printerCode?: string | null
  }>(),
  {
    verificationPopupOpened: false,
    printerCode: null
  }
)

const emits = defineEmits<{
  (e: 'confirm-barcode', barcode: string): void
  (e: 'print-documents', barcode: string): void
  (e: 'verification-popup-closed'): void
}>()

const captureScanInput = ref<boolean>(true)

const { isLoading } = useLoading()

const unsubscribe = eventBus.on('scanInput', async (event) => {
  if (!captureScanInput.value) {
    return
  }

  await onScanInput(String(event.payload))
})

const onScanInput = async (query: string) => {
  if (!query || query.length === 0) {
    await perceptibleToast.error('Scan not recognized')
    return
  }

  query = query.toUpperCase()

  if (!props.printerCode && query.startsWith('PRNT')) {
    try {
      await printDocuments(query)
    } catch (e: any) {
      await perceptibleToast.error(e.message)
      isLoading.value = false
    }

    return
  }

  if (!props.verificationPopupOpened) {
    await perceptibleToast.error('Invalid printer barcode scanned')
    return
  }

  if (query.length < 8) {
    await perceptibleToast.error('Invalid verification code!')
    return
  }

  await emits('confirm-barcode', query)
}

const printDocuments = async (query: string) => {
  await emits('print-documents', query)
  await soundBoard.playSuccessSound()
}

const closePopupAndEnableScanInput = async () => {
  await emits('verification-popup-closed')

  await nextTick(() => (captureScanInput.value = true))
}

const onVerificationCodePopupClose = async () => {
  confirmYesNo({
    title: 'Are you sure that you want to cancel the verification process for this load carrier?',
    yesButtonCallback: async () => await closePopupAndEnableScanInput()
  })
}

const onReprintLabel = async () => {
  confirmYesNo({
    title: 'Are you sure that you want to reprint the load carrier verification label?',
    yesButtonCallback: async () => printDocuments(props.printerCode)
  })
}

watch(
  () => props.verificationPopupOpened,
  (value) => {
    captureScanInput.value = value
  }
)

onMounted(async () => {
  if (props.printerCode) {
    await printDocuments(props.printerCode)
  }
})

onBeforeUnmount(() => {
  unsubscribe()
})
</script>
