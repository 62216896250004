<template>
  <page-with-search-subscriber v-slot="{ searchSubscriber }" :page-content="false">
    <scan-input
      :items="items"
      :scan-subscriber="searchSubscriber"
      :search-type="TypeName.Shipment"
      :restricted="false"
      :search-strategy="SearchStrategy.PackingControl"
      key="packing-control-search"
    />
  </page-with-search-subscriber>
</template>
<script lang="ts" setup>
import ScanInput from '@components/ScanInput.vue'
import { TypeName } from '@graphql/search/types'
import { IShipment } from '@graphql/shipment/types'
import { SearchStrategy } from '@services/search/search'
import { ref } from 'vue'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'

const items = ref<Array<Partial<IShipment>>>([])
</script>
