import { MutationTree } from 'vuex'
import {
  IProjectOutboundSortState,
  IOutboundSortProject
} from '@store/modules/projects/outbound-sort/types'
import { ID } from '@graphql/types'

const findIndex = (state: IProjectOutboundSortState, project: IOutboundSortProject) => {
  return state.items.findIndex((item) => item.pickBatchId === project.pickBatchId)
}

const mutations: MutationTree<IProjectOutboundSortState> = {
  clearProjects(state) {
    state.items = []
  },
  add(state, project: IOutboundSortProject) {
    const index = findIndex(state, project)

    if (index !== -1) {
      return
    }

    if (project.isBulkSortCompleted === undefined) {
      project.isBulkSortCompleted = false
    }

    state.items.push(project)
  },
  update(state, project: IOutboundSortProject) {
    const index = findIndex(state, project)

    if (index === -1) {
      return
    }

    if (project.isBulkSortCompleted === undefined) {
      project.isBulkSortCompleted = false
    }

    state.items[index] = project
  },
  remove(state, pickBatchId: ID) {
    const index = state.items.findIndex((item) => item.pickBatchId === pickBatchId)

    if (index === -1) {
      return
    }

    state.items.splice(index, 1)
  }
}

export default mutations
