<template>
  <f7-page :page-content="false">
    <app-navigation>
      <template #page-title>Load carrier #{{ id }}</template>
    </app-navigation>
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#load-carrier-details" tab-link-active>Details</f7-link>
      <f7-link tab-link="#load-carrier-items">Items ({{ loadCarrierItems.length }})</f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="load-carrier-details" tab-active class="page-content">
        <f7-card :class="{ 'skeleton-text': isLoading }">
          <f7-list media-list v-if="loadCarrier">
            <f7-list-item header="#">{{ loadCarrier.id }}</f7-list-item>
            <f7-list-item header="Reference">{{ loadCarrier.reference }}</f7-list-item>
            <f7-list-item header="Type">{{ loadCarrier.typeForHuman }}</f7-list-item>
            <f7-list-item header="Created at">
              <date-time-label :value="loadCarrier.createdAt" />
            </f7-list-item>
            <f7-list-item header="Updated at">
              <date-time-label :value="loadCarrier.createdAt" />
            </f7-list-item>
            <f7-list-item header="Location">
              <string-label :value="loadCarrier.location?.name" />
            </f7-list-item>
          </f7-list>
        </f7-card>
      </f7-tab>
      <f7-tab id="load-carrier-items" class="page-content">
        <f7-card>
          <f7-list media-list>
            <load-carrier-item-line
              v-for="loadCarrierItem in loadCarrierItems"
              :key="loadCarrierItem.id"
              :load-carrier-item="loadCarrierItem"
            />
          </f7-list>
        </f7-card>
      </f7-tab>
    </f7-tabs>
    <app-actions v-if="actions.length > 0" :actions="actions" @action-clicked="onActionClick" />
  </f7-page>
</template>
<script setup lang="ts">
import AppNavigation from '@components/AppNavigation.vue'
import { ID } from '@graphql/types'
import useLoading from '@composables/useLoading'
import useLoadCarrier from '@composables/useLoadCarrier'
import { onMounted, ref } from 'vue'
import { ILoadCarrier, ILoadCarrierItem } from '@graphql/loadCarrier/types'
import StringLabel from '@components/label/StringLabel.vue'
import DateTimeLabel from '@components/label/DateTime.vue'
import LoadCarrierItemLine from '@pages/load-carrier/components/LoadCarrierItemLine.vue'
import AppActions from '@components/AppActions.vue'
import useAction from '@composables/useAction'
import { IAction } from '@components/actions'

const props = defineProps<{
  id: ID
}>()

const { isLoading, withAsyncLoading } = useLoading()
const { getLoadCarrierById } = useLoadCarrier()
const { onActionClick } = useAction()

const loadCarrier = ref<ILoadCarrier>()
const loadCarrierItems = ref<ILoadCarrierItem[]>([])
const actions = ref<IAction[]>([])

onMounted(async () => {
  await withAsyncLoading(async () => {
    loadCarrier.value = await getLoadCarrierById({ id: props.id })
    loadCarrierItems.value = loadCarrier.value?.loadCarrierItems.edges.map((edge) => edge.node)

    actions.value.push({
      name: 'Putaway',
      icon: 'pallet-boxes',
      href: `/load-carrier/${loadCarrier.value?.id}/putaway/`
    })
  })
})
</script>
