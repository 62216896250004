<template>
  <f7-list-item swipeout @swipeout:opened="onOverSwipe">
    <template #media>
      <search-result-icon :icon="['far', 'truck-ramp-box']" />
    </template>
    <template #title>
      <string-label value="Collo" />
    </template>
    <template #subtitle>#{{ searchResult.searchPreview }}</template>
    <template v-if="searchResult.typeForHuman" #after-title>
      <f7-badge>{{ searchResult.typeForHuman }}</f7-badge>
    </template>
    <slot name="actions" />
  </f7-list-item>
</template>
<script lang="ts" setup>
import SearchResultIcon from '@components/search/SearchResultIcon.vue'
import StringLabel from '@components/label/StringLabel.vue'
import { IInboundCollo } from '@graphql/inbound/types'

defineProps<{
  searchResult: Partial<IInboundCollo>
}>()

const emit = defineEmits<{
  (e: 'over-swipe', result: Partial<IInboundCollo>): void
}>()

const onOverSwipe = async (result: Partial<IInboundCollo>) => emit('over-swipe', result)
</script>
