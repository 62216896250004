<template>
  <f7-block-title v-if="goodsReceipt" class="margin-top" :class="{ 'skeleton-text': isLoading }">
    Goods receipt #{{ goodsReceipt.id }}
  </f7-block-title>
  <f7-card :class="{ 'skeleton-text': isLoading }">
    <f7-card-content>
      <f7-list v-if="goodsReceipt" media-list>
        <f7-list-item header="Purchase order">
          <string-label
            v-if="purchaseOrder"
            :value="`${purchaseOrder.id} / ${purchaseOrder.reference}`"
          />
        </f7-list-item>
        <f7-list-item header="State">
          <string-label :value="goodsReceipt.stateString" :fallback-value="goodsReceipt.state" />
        </f7-list-item>
        <f7-list-item v-if="goodsReceipt.supplier" header="Supplier">
          <string-label :value="goodsReceipt.supplier.name" />
        </f7-list-item>
        <f7-list-item header="Delivered at">
          <date-time-label :value="goodsReceipt.deliveredAt" />
        </f7-list-item>
        <f7-list-item header="Booked at">
          <date-time-label :value="goodsReceipt.bookedAt" />
        </f7-list-item>
        <f7-list-item header="Created at">
          <date-time-label :value="goodsReceipt.createdAt" />
        </f7-list-item>
      </f7-list>
    </f7-card-content>
  </f7-card>
  <f7-card v-if="goodsReceipt && goodsReceipt.note" :class="{ 'skeleton-text': isLoading }">
    <f7-card-header> Notes </f7-card-header>
    <f7-card-content>
      {{ goodsReceipt.note }}
    </f7-card-content>
  </f7-card>
</template>
<script setup lang="ts">
import StringLabel from '@components/label/StringLabel.vue'
import DateTimeLabel from '@components/label/DateTime.vue'
import { IGoodsReceipt } from '@graphql/goodsReceipt/types'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    goodsReceipt?: IGoodsReceipt
    isLoading: boolean
  }>(),
  {
    goodsReceipt: undefined,
    isLoading: false
  }
)

const purchaseOrder = computed(() => {
  if (props.goodsReceipt?.goodsReceiptLine?.totalCount > 0) {
    return props.goodsReceipt.goodsReceiptLine.edges[0].node.purchaseOrderLine.purchaseOrder
  }

  return undefined
})
</script>
