<template>
  <f7-page :page-content="false">
    <navigation />

    <f7-page-content>
      <f7-block-title class="margin-top"> Pack projects </f7-block-title>
      <f7-list media-list>
        <f7-list-item v-for="(item, key) in items" :key="key" :link="`/pack-entry/`">
          <template #title>#{{ item.id }}</template>
        </f7-list-item>
      </f7-list>
      <is-granted :attributes="Permissions.SCANNER_DELETE_PROJECTS">
        <f7-fab position="right-bottom" @click="onDeleteProjects">
          <font-awesome-icon :icon="['far', 'trash']" />
        </f7-fab>
      </is-granted>
    </f7-page-content>
  </f7-page>
</template>
<script lang="ts" setup>
import Navigation from '@components/AppNavigation.vue'
import { f7 } from 'framework7-vue'
import IsGranted from '@components/IsGranted.vue'
import { Permissions } from '@services/security/permissions'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { confirmYesNo } from '@/functions/dialog'
import { toast } from '@services/toast'
import useProjectPack from '@composables/useProjectPack'

const { items, removeProjects } = useProjectPack()

const onDeleteProjects = async () => {
  await confirmYesNo({
    title: 'Are you sure that you want to clear all projects?',
    yesButtonCallback: async () => {
      await removeProjects()

      await toast.success('Cleared pack projects.').open()

      await f7.views.main.router.navigate('/home/')
    }
  })
}
</script>
