<template>
  <f7-card>
    <f7-card-content>
      <template v-if="getLoadCarrierItems().totalCount > 0">
        <f7-list media-list>
          <f7-list-item
            v-for="loadCarrierItemEdge in getLoadCarrierItems().edges"
            :key="loadCarrierItemEdge.node.id"
          >
            <template #media>
              {{
                getStockQuantity(
                  loadCarrierItemEdge.node.pickBatchLineItem.article,
                  loadCarrierItemEdge.node.quantity
                )
              }}
            </template>
            <template #subtitle>
              {{ loadCarrierItemEdge.node.pickBatchLineItem.article.name }}<br />
              {{ loadCarrierItemEdge.node.pickBatchLineItem.article.primaryBarcode }}
            </template>
            <template #header v-if="loadCarrierItemEdge.node.pickBatchLineItem.articleBatch">
              {{ loadCarrierItemEdge.node.pickBatchLineItem.articleBatch.reference }}
            </template>
          </f7-list-item>
        </f7-list>
      </template>
      <template v-else-if="loadCarrier.id < 90000001">
        <icon-with-text
          :icon="['far', 'person-dolly-empty']"
          title="This load carrier does not support items!"
        />
      </template>
      <template v-else>
        <icon-with-text
          :icon="['far', 'person-dolly-empty']"
          title="This load carrier does not have items yet!"
        />
      </template>
    </f7-card-content>
  </f7-card>
</template>
<script lang="ts" setup>
import { ILoadCarrier, ILoadCarrierItem } from '@graphql/pick/types'
import IconWithText from '@components/IconWithText.vue'
import { IConnection } from '@graphql/types'
import useArticle from '@composables/useArticle'

const { getStockQuantity } = useArticle()

const props = withDefaults(
  defineProps<{
    loadCarrier: ILoadCarrier
    isLoading: boolean
  }>(),
  {
    isLoading: false
  }
)

const getLoadCarrierItems = (): IConnection<ILoadCarrierItem> => {
  if (props.loadCarrier.sortLoadCarrierItems.totalCount !== 0) {
    return props.loadCarrier.sortLoadCarrierItems
  }

  return props.loadCarrier.pickLoadCarrierItems
}
</script>
