import { AxiosInstance } from 'axios'
import GRAPHQL_API_CLIENT from './client'
import Base from './base'
import {
  ICreateLoadCarrierRequest,
  ICreateLoadCarrierResponse,
  IGetLoadCarrierTypesResponse
} from '@graphql/outbound/types'

class Outbound extends Base {
  constructor(protected axios: AxiosInstance) {
    super(axios, 'outbound')
  }

  async getLoadCarrierTypes() {
    return this.makeRequest<IGetLoadCarrierTypesResponse>('getLoadCarrierTypesQuery')
  }

  async createLoadCarrier(request: ICreateLoadCarrierRequest) {
    return this.makeRequest<ICreateLoadCarrierResponse>('createLoadCarrierMutation', request)
  }
}

export default new Outbound(GRAPHQL_API_CLIENT)
