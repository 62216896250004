<template>
  <div class="location-title" :class="{ 'skeleton-text': isLoading }">
    <span>{{ locationName }}</span
    >&nbsp;
    <font-awesome-icon :class="getTaskContextClass(isScanned)" :icon="['far', 'check-circle']" />
  </div>
</template>
<script lang="ts" setup>
import useContext from '@composables/useContext'

withDefaults(
  defineProps<{
    isScanned: boolean
    locationName: string
    isLoading: boolean
  }>(),
  {
    isScanned: false
  }
)

const { getTaskContextClass } = useContext()
</script>
