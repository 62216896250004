<template>
  <page-with-search-subscriber v-slot="{ searchSubscriber }" :page-content="false">
    <app-navigation>
      <template #page-title>
        <string-label class="padding-right" value="Putaway collo" />
      </template>
      <template #default="{ searchId }">
        <search-bar
          :scan-subscriber="searchSubscriber"
          :search-strategy="SearchStrategy.DefaultWithoutRouting"
          :search-id="searchId"
          expandable
          :search-type="TypeName.Location"
          @result="onSearchResult"
          @no-result="onNoResult"
          :search-callback="onSearchCallback"
          :no-result-callback="onNoSearchResultCallback"
        />
      </template>
    </app-navigation>
    <f7-page-content>
      <f7-block-header class="margin-top-10">Collo #{{ colloId }}</f7-block-header>
      <f7-card>
        <f7-card-header>Location</f7-card-header>
        <f7-card-content v-if="!form.location">
          <scan-barcode-animation title="Scan location" />
        </f7-card-content>
        <f7-list v-else>
          <f7-list-item class="location">{{ form.location.name }}</f7-list-item>
        </f7-list>
      </f7-card>

      <f7-card>
        <f7-card-header>Barcode</f7-card-header>
        <f7-card-content v-if="!form.barcode">
          <scan-barcode-animation
            title="Assign barcode"
            help-text="Scan INBC barcode to assign to this collo"
          />
        </f7-card-content>
        <f7-list v-else>
          <f7-list-item class="barcode">{{ form.barcode }}</f7-list-item>
        </f7-list>
      </f7-card>
    </f7-page-content>
    <fab-with-loading-state v-show="isSubmittable" :on-click="onSubmit" :is-loading="isLoading">
      <font-awesome-icon :icon="['far', 'save']"></font-awesome-icon>
    </fab-with-loading-state>
  </page-with-search-subscriber>
</template>
<script setup lang="ts">
import AppNavigation from '@components/AppNavigation.vue'
import { ID } from '@graphql/types'
import useInbound from '@composables/useInbound'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { IInboundCollo } from '@graphql/inbound/types'
import SearchBar from '@components/search/SearchBar.vue'
import useSearch from '@composables/useSearch'
import { UnionTypeSearchResult } from '@store/modules/search/types'
import { TypeName } from '@graphql/search/types'
import { f7 } from 'framework7-vue'
import { IOnSearchCallbackContext, SearchStrategy } from '@services/search/search'
import { ILocation } from '@graphql/location/types'
import FabWithLoadingState from '@components/FabWithLoadingState.vue'
import useLoading from '@composables/useLoading'
import { captureEvent } from '@sentry/vue'
import { perceptibleToast } from '@services/perceptibleToast'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import StringLabel from '@components/label/StringLabel.vue'
import ScanBarcodeAnimation from '@components/ScanBarcodeAnimation.vue'
import { ReceptionState } from '@store/pinia/inbound/types'
import { eventBus } from '@/utilities/scanInput'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'

interface IColloScanForm {
  barcode: string | null
  location: ILocation | null
}

const props = defineProps<{
  colloId: ID
}>()

const { getColloById, updateCollo } = useInbound()
const { onNoResult } = useSearch()
const { isLoading, withAsyncLoading } = useLoading()

const collo = ref<IInboundCollo>()
const form = ref<IColloScanForm>({ location: null, barcode: null })

const isSubmittable = computed(() => form.value.location && form.value.barcode)

const onSearchCallback = (context: IOnSearchCallbackContext) => {
  if (context.query === '') {
    return
  }

  context.stopAfterCallback = false
  if (context.query.startsWith('INBC')) {
    form.value.barcode = context.query
    context.stopAfterCallback = true
    return
  }
}

const onNoSearchResultCallback = async (query: string) => {
  if (query.startsWith('INBC') || form.value.location) {
    await perceptibleToast.error(`"${query}" is not a valid inbound collo barcode.`)

    return
  }

  await perceptibleToast.error(`"${query}" is not a valid location`)
}

const onSearchResult = async (result: UnionTypeSearchResult) => {
  if (result.__typename == TypeName.Location.toString()) {
    form.value.location = result
    return
  }
}

const onSubmit = async () => {
  await withAsyncLoading(async () => {
    try {
      const updatedCollo = await updateCollo({
        colloId: props.colloId,
        locationId: form.value.location.id,
        barcode: form.value.barcode
      })

      if (updatedCollo.reception.state === ReceptionState.STAGED) {
        await perceptibleToast.success(`Hooray, reception is fully staged!`)
        f7.views.main.router.navigate(`/inbound/reception/`)
        return
      }

      await perceptibleToast.success(`Collo #${props.colloId} updated!`)

      f7.views.main.router.navigate(`/inbound/reception/${updatedCollo.reception.id}/process/`, {
        clearPreviousHistory: true
      })
    } catch (e) {
      captureEvent(e)

      await perceptibleToast.error(e.message)

      throw e
    }
  })
}

onMounted(async () => {
  await withAsyncLoading(async () => {
    collo.value = await getColloById({ id: props.colloId })
  })
})
</script>

<style scoped>
.location,
.barcode {
  display: grid;
  place-items: center;
  width: 100%;
  font-weight: bold;
  font-size: 32px;
}
</style>
