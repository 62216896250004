import { IRootState } from '@store/types'
import { GetterTree } from 'vuex'
import { IProjectPackState } from '@store/modules/projects/pack/types'

const getters: GetterTree<IProjectPackState, IRootState> = {
  isActive: (state) => state.items.length > 0,
  itemCount: (state) => state.items.length,
  count: (state) => state.items.length,
  items: (state) => state.items,
  activeItem: (state) => state.activeItem
}

export default getters
