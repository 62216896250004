import { asBoolean } from '@/utilities/string'

export const GRAPHQL_API_URI =
  import.meta.env.APP_API_URL || 'http://www.supportplaza.local/graphql/v1'
export const APPLICATION_VERSION = import.meta.env.APP_VERSION || 'n.a'
export const APPLICATION_NAME = import.meta.env.APP_NAME || 'SupportPlaza Scan'
export const APPLICATION_ENVIRONMENT = import.meta.env.APP_ENV || import.meta.env.MODE || 'dev'
export const SENTRY_ENABLED = asBoolean(import.meta.env.APP_SENTRY_ENABLED)
export const SENTRY_REPLAY_SESSION_SAMPLE_RATE =
  import.meta.env.APP_SENTRY_REPLAY_SESSION_SAMPLE_RATE || 0.1
