import { AxiosInstance } from 'axios'
import GRAPHQL_API_CLIENT from './client'
import Base from './base'
import {
  ICompleteKardexProjectRequest,
  ICompleteKardexProjectResponse,
  IGetItemRequest,
  IGetItemResponse,
  IGetKardexProjectByIdRequest,
  IGetKardexProjectByIdResponse,
  IRetryOrderRequest,
  IRetryOrderResponse
} from '@graphql/kardex/types'

class Kardex extends Base {
  constructor(protected axios: AxiosInstance) {
    super(axios, 'kardex')
  }
  async getKardexProjectById(request: IGetKardexProjectByIdRequest) {
    return this.makeRequest<IGetKardexProjectByIdResponse>('GetKardexProjectByIdQuery', request)
  }

  async getItem(request: IGetItemRequest) {
    return this.makeRequest<IGetItemResponse>('GetItemQuery', request)
  }

  async retryOrder(request: IRetryOrderRequest) {
    return this.makeRequest<IRetryOrderResponse>('RetryOrderMutation', request)
  }

  async completeKardexProject(request: ICompleteKardexProjectRequest) {
    return this.makeRequest<ICompleteKardexProjectResponse>(
      'CompleteKardexProjectMutation',
      request
    )
  }
}

export default new Kardex(GRAPHQL_API_CLIENT)
