<template>
  <f7-page :page-content="false">
    <app-navigation back-link="/inbound/reception/">
      <template #page-title>
        <string-label class="padding-right" value="Process inbound colli" />
      </template>
    </app-navigation>
    <f7-page-content>
      <f7-block-title class="margin-top-10">
        Reception #{{ reception?.internalReference }}, {{ colli.length }} colli
      </f7-block-title>

      <f7-block-title class="margin-top-10">Notes</f7-block-title>
      <f7-block v-if="!isEditingNotes" class="notes" @click="isEditingNotes = true">
        <font-awesome-icon :icon="['far', 'edit']" />
        {{ notes }}
      </f7-block>
      <div v-if="isEditingNotes">
        <f7-block>
          <f7-input
            type="textarea"
            class="textarea-row-4"
            :value="notes"
            @input="notes = $event.target.value"
          />
        </f7-block>
        <f7-card :no-shadow="true" style="background: transparent">
          <div class="row">
            <div class="col">
              <f7-button large color="dimass" text-color="white" @click="onNotesEditSave">
                Save
              </f7-button>
            </div>
            <div class="col">
              <f7-button large outline @click="onNotesEditCancel">Cancel</f7-button>
            </div>
          </div>
        </f7-card>
      </div>

      <f7-card :class="{ 'skeleton-text': isLoading }">
        <f7-list media-list v-if="reception">
          <f7-list-item v-for="collo in colli" :key="collo.id" @click="onProcessCollo(collo)">
            <template #header>#{{ collo.id }}</template>
            <template #title>{{ collo.typeForHuman }}</template>
            <template #after-title>
              <font-awesome-icon
                :class="getTaskContextClass(collo.barcode !== '')"
                :icon="['far', collo.barcode !== '' ? 'check' : 'circle-check']"
              />
            </template>
            <template #default>
              <div class="chips">
                <barcode-chip v-if="collo.barcode != ''" :text="collo.barcode" />
                <location-chip v-if="collo.location" :text="collo.location.name" />
              </div>
            </template>
          </f7-list-item>
        </f7-list>
      </f7-card>
    </f7-page-content>
  </f7-page>
</template>
<script lang="ts" setup>
import AppNavigation from '@components/AppNavigation.vue'
import useInbound from '@composables/useInbound'
import { computed, onMounted, ref } from 'vue'
import useLoading from '@composables/useLoading'
import { IInboundCollo, IInboundReception } from '@graphql/inbound/types'
import StringLabel from '@components/label/StringLabel.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import useContext from '@composables/useContext'
import { f7 } from 'framework7-vue'
import { perceptibleToast } from '@services/perceptibleToast'
import LocationChip from '@components/chip/LocationChip.vue'
import BarcodeChip from '@components/chip/BarcodeChip.vue'
import { ReceptionState } from '@store/pinia/inbound/types'
import { ID } from '@graphql/types'
import { captureException } from '@sentry/vue'

const { getReceptionById, updateReceptionNotes } = useInbound()
const { isLoading, withAsyncLoading } = useLoading()
const { getTaskContextClass } = useContext()
const reception = ref<IInboundReception>()
const notes = ref<string | null>(null)
const isEditingNotes = ref<boolean>(false)

const props = defineProps<{
  receptionId: ID
}>()

const colli = computed<IInboundCollo[]>(() => {
  if (reception.value) {
    return reception.value.colli
  }

  return []
})

const onProcessCollo = async (collo: IInboundCollo) => {
  if (collo.barcode && collo.location) {
    return
  }

  f7.views.main.router.navigate(`/inbound/collo/${collo.id}/scan/`)
}

onMounted(async () => {
  await withAsyncLoading(async () => {
    const receptionResponse = await getReceptionById({ id: props.receptionId })

    if (receptionResponse.state === ReceptionState.STAGED) {
      await perceptibleToast.finishedTask(`Reception #${receptionResponse.id} is staged!`)
      f7.views.main.router.navigate('/inbound/reception/', {
        clearPreviousHistory: true
      })
      return
    }

    reception.value = receptionResponse
    notes.value = reception.value.notes
  })
})

const onNotesEditSave = async () => {
  try {
    reception.value = await updateReceptionNotes({
      receptionId: props.receptionId,
      notes: notes.value
    })
    notes.value = reception.value.notes

    isEditingNotes.value = false
  } catch (e) {
    captureException(e)

    await perceptibleToast.error('Something went wrong with saving the notes. Try again later')
  }
}

const onNotesEditCancel = () => {
  if (!reception.value) {
    return
  }

  notes.value = reception.value.notes

  isEditingNotes.value = false
}
</script>

<style scoped>
.chips {
  display: flex;
  gap: 5px;
  align-items: center;
}

.notes {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  cursor: pointer;
  white-space: pre-wrap;
}
</style>
