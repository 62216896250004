import { defineStore } from 'pinia'
import warehouseApi from '@graphql/warehouse'
import { IWarehouse, IWarehouseZoneConnection } from '@graphql/warehouse/types'

export const useWarehouseStore = defineStore('warehouse', {
  actions: {
    async getList(): Promise<IWarehouse[]> {
      const response = await warehouseApi.getList()

      if (response.data.data == undefined) {
        throw new Error("Response doesn't contain any data.")
      }

      return response.data.data.warehouseList
    },
    async getWarehouseZones(): Promise<IWarehouseZoneConnection> {
      const response = await warehouseApi.getWarehouseZones()

      if (response.data.data == undefined) {
        throw new Error("Response doesn't contain any data.")
      }

      return response.data.data.getZones
    }
  }
})
