<template>
  <f7-page :page-content="false">
    <navigation />
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#gr-detail" tab-link-active>Details</f7-link>
      <f7-link tab-link="#gr-lines">Lines</f7-link>
      <f7-link tab-link="#gr-reception">Reception</f7-link>
      <f7-link tab-link="#gr-inbound-colli">Colli</f7-link>
    </f7-toolbar>

    <f7-tabs>
      <f7-tab id="gr-detail" class="page-content" tab-active>
        <details-card :goods-receipt="goodsReceipt" :is-loading="isLoading" />
      </f7-tab>
      <f7-tab id="gr-lines" class="page-content">
        <lines-card :goods-receipt-lines="goodsReceiptLines" :is-loading="isLoading" />
      </f7-tab>
      <f7-tab id="gr-reception" class="page-content">
        <number-of-colli-card :goods-receipt="goodsReceipt" :is-loading="isLoading" />
      </f7-tab>
      <f7-tab id="gr-inbound-colli" class="page-content">
        <collo-table :inbound-colli="inboundColli" :is-loading="isLoading" />
      </f7-tab>
    </f7-tabs>
    <app-actions v-if="actions.length > 0" :actions="actions" @action-clicked="onActionClick" />
  </f7-page>
</template>
<script lang="ts" setup>
import { IAction } from '@components/actions'
import AppActions from '@components/AppActions.vue'
import Navigation from '@components/AppNavigation.vue'
import useAuthentication from '@composables/useAuthentication'
import useGoodsReceipt from '@composables/useGoodsReceipt'
import useLoading from '@composables/useLoading'
import useProjectInbound from '@composables/useProjectInbound'
import { IGoodsReceipt, StateEnum, WorkflowEnum } from '@graphql/goodsReceipt/types'
import { IInboundProject } from '@store/modules/projects/inbound/types'
import { computed, onMounted, ref } from 'vue'
import { ID } from '@graphql/types'
import NumberOfColliCard from '@pages/goods-receipt/components/NumberOfColliCard.vue'
import ColloTable from '@pages/goods-receipt/components/ColliTable.vue'
import LinesCard from '@pages/goods-receipt/components/LinesCard.vue'
import DetailsCard from '@pages/goods-receipt/components/DetailsCard.vue'
import useAction from '@composables/useAction'

const props = defineProps<{
  id: ID
}>()

const { isLoading, withAsyncLoading } = useLoading()
const { getGoodsReceipt, startProcessing } = useGoodsReceipt()
const { add: addInboundProject } = useProjectInbound()
const { currentUser } = useAuthentication()
const { onActionClick } = useAction()

const actions = ref<IAction[]>([])
const goodsReceipt = ref<IGoodsReceipt | undefined>(undefined)

const goodsReceiptLines = computed(() =>
  goodsReceipt.value ? goodsReceipt.value?.goodsReceiptLine : undefined
)

const inboundColli = computed(() =>
  goodsReceipt.value ? goodsReceipt.value.inboundColli : undefined
)

onMounted(async () => {
  await withAsyncLoading(async () => {
    goodsReceipt.value = await getGoodsReceipt({ id: props.id })
    if (
      goodsReceipt.value &&
      goodsReceipt.value.workflow === WorkflowEnum.DEFAULT.toString() &&
      (goodsReceipt.value.state === StateEnum.READY_FOR_PROCESSING ||
        goodsReceipt.value.state === StateEnum.PROCESSING)
    ) {
      actions.value.push({
        name: 'Goods entry',
        href: `/goodsentry/${goodsReceipt.value.id}/`,
        props: {
          item: goodsReceipt.value
        },
        callback: async () => {
          const project: IInboundProject = {
            id: goodsReceipt.value.id,
            items: [],
            addedOn: new Date(),
            addedBy: currentUser.value
          }

          await addInboundProject(project)

          await startProcessing({ goodsReceiptId: props.id })
        }
      })
    }

    if (
      goodsReceipt.value &&
      goodsReceipt.value.workflow === WorkflowEnum.SORT_AND_PUT_AWAY.toString() &&
      (goodsReceipt.value.state === StateEnum.READY_FOR_PROCESSING ||
        goodsReceipt.value.state === StateEnum.PROCESSING)
    ) {
      actions.value.push({
        name: 'Sort',
        href: `/inbound/sort/${goodsReceipt.value.id}/process/`,
        callback: async () => {
          await startProcessing({ goodsReceiptId: props.id })
        }
      })
    }
  })
})
</script>
