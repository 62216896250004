<template>
  <span>
    <template v-if="value">{{ convertDateValue(value, format) }}</template>
    <template v-else>{{ fallbackValue }}</template>
  </span>
</template>
<script lang="ts" setup>
import { DateTime } from 'luxon'

const props = withDefaults(
  defineProps<{
    value?: Date | string | null
    format?: string
    fallbackValue?: string
    toRelative?: boolean
  }>(),
  {
    value: undefined,
    format: 'dd-MM-yyyy HH:mm',
    fallbackValue: 'N/A',
    toRelative: false
  }
)

const convertDateValue = (value: Date | string, format: string) => {
  if (value instanceof Date) {
    return DateTime.fromJSDate(value).toFormat(format)
  }

  let date = DateTime.fromFormat(value, 'yyyy-MM-dd HH:mm:ss')

  if (!date.isValid) {
    date = DateTime.fromISO(value)
  }

  if (props.toRelative) {
    return date.toRelative()
  }

  return date.toFormat(format)
}
</script>
