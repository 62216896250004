<template>
  <f7-page :page-content="false">
    <app-navigation />
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#outbound-transport-detail" tab-link-active>Details</f7-link>
      <f7-link tab-link="#outbound-transport-load-carriers">Load carriers</f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="outbound-transport-detail" class="page-content" tab-active>
        <f7-card :class="{ 'skeleton-text': isLoading }">
          <f7-list v-if="transport">
            <f7-list-item header="External Id">
              <string-label :value="transport.externalReference" />
            </f7-list-item>
            <f7-list-item header="Internal Id">
              <string-label :value="transport.reference" />
            </f7-list-item>
            <f7-list-item header="Created at">
              <date-time-label :value="transport.createdAt" />
            </f7-list-item>
            <f7-list-item header="Courier name">
              <string-label :value="transport.courierName" />
            </f7-list-item>
            <f7-list-item header="State">
              <string-label :value="transport.stateForHuman" />
            </f7-list-item>
          </f7-list>
        </f7-card>
      </f7-tab>
      <f7-tab id="outbound-transport-load-carriers" class="page-content">
        <f7-card :class="{ 'skeleton-text': isLoading }">
          <f7-list v-if="transport">
            <f7-card-content v-if="transport.loadCarriers.length === 0">
              There are no load carrie(r) attached to this transport!
            </f7-card-content>
            <f7-list-item
              v-else
              v-for="loadCarrier in transport.loadCarriers"
              :key="loadCarrier.id"
              :header="loadCarrier.id"
              :title="loadCarrier.stateForHuman"
            >
              <string-label :value="loadCarrier.location?.name" />
            </f7-list-item>
          </f7-list>
        </f7-card>
      </f7-tab>
    </f7-tabs>
    <app-actions v-if="actions.length > 0" :actions="actions" @action-clicked="onActionClick" />
  </f7-page>
</template>
<script setup lang="ts">
import AppNavigation from '@components/AppNavigation.vue'
import { ID } from '@graphql/types'
import useLoading from '@composables/useLoading'
import useOutboundTransport from '@composables/useOutboundTransport'
import {
  IOutboundTransport,
  OutboundLoadCarrierState,
  OutboundTransportState
} from '@graphql/outbound/types'
import { computed, ref, onMounted } from 'vue'
import StringLabel from '@components/label/StringLabel.vue'
import DateTimeLabel from '@components/label/DateTime.vue'
import AppActions from '@components/AppActions.vue'
import { IAction } from '@components/actions'
import useAction from '@composables/useAction'
import { f7 } from 'framework7-vue'
import { perceptibleToast } from '@services/perceptibleToast'
import { captureEvent } from '@sentry/vue'

const props = defineProps<{
  transportId: ID
}>()

const actions = ref<IAction[]>([])

const { isLoading, withAsyncLoading } = useLoading()
const { getTransportById, startProcessingOfTransport, onFinalizeTransport } = useOutboundTransport()
const { onActionClick } = useAction()

const transport = ref<IOutboundTransport>()

const incompleteLoadCarriers = computed(() =>
  transport.value?.loadCarriers.filter(
    (loadCarrier) => loadCarrier.state === OutboundLoadCarrierState.TODO
  )
)

onMounted(async () => {
  await withAsyncLoading(async () => {
    transport.value = await getTransportById({ id: props.transportId })
  })

  if (incompleteLoadCarriers.value.length > 0) {
    const stateIsNew = transport.value?.state === OutboundTransportState.NEW

    actions.value.push({
      name: stateIsNew ? 'Start pick' : 'Continue pick',
      callback: async () => {
        if (stateIsNew) {
          await startProcessingOfTransport({ transportId: props.transportId })
        }

        f7.views.main.router.navigate(`/outbound/transport/${props.transportId}/pick/`)
      }
    })
  }

  if (transport.value?.loadCarriers.length > 0 && incompleteLoadCarriers.value.length === 0) {
    actions.value.push({
      name: 'Finalize pick',
      callback: async () =>
        await withAsyncLoading(async () => await onFinalizeTransport(props.transportId))
    })
  }
})
</script>
