import { defineStore } from 'pinia'
import { IDocumentState } from '@store/pinia/document/types'
import { IPrintSubjectDocumentsRequest } from '@graphql/document/types'
import api from '@graphql/document'

export const useDocumentStore = defineStore('document', {
  state: (): IDocumentState => ({}),
  actions: {
    async printSubjectDocuments(request: IPrintSubjectDocumentsRequest) {
      if (!request.packStationId) {
        request.packStationId = null
      }

      const response = await api.printSubjectDocuments(request)

      return response.data.data.printSubjectDocuments
    }
  }
})
