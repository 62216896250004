<template>
  <f7-page :page-content="false">
    <app-navigation title="Todo transports" />

    <f7-page-content ptr :ptr-distance="50" ptr-mousewheel @ptr:refresh="onPullToRefresh">
      <pull-to-refresh-preloader />
      <f7-card :class="{ 'skeleton-text': isLoading }">
        <f7-card-content v-if="transports.length === 0">
          <icon-with-text :icon="['fad', 'party-horn']" title="All work done!" />
        </f7-card-content>
        <f7-list
          v-else
          infinite
          :infinite-distance="50"
          :infinite-preloader="isLoading"
          @infinite="onLoadMoreItems"
        >
          <f7-list-item
            v-for="transport in transports"
            :key="transport.id"
            :header="transport.reference"
            :title="transport.externalReference"
            @click="onLoadCarrierClicked(transport)"
          >
            {{ transport.stateForHuman }}
          </f7-list-item>
        </f7-list>
      </f7-card>
    </f7-page-content>
  </f7-page>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import {
  IGetTodoTransportQueryRequest,
  IOutboundTransport,
  ITodoOutboundTransportConnection
} from '@graphql/outbound/types'
import useLoading from '@composables/useLoading'
import AppNavigation from '@components/AppNavigation.vue'
import useOutboundTransport from '@composables/useOutboundTransport'
import PullToRefreshPreloader from '@components/PullToRefreshPreloader.vue'
import { f7 } from 'framework7-vue'
import IconWithText from '@components/IconWithText.vue'
const { getTodoTransports } = useOutboundTransport()

const transportsConnection = ref<ITodoOutboundTransportConnection>()
const transports = ref<IOutboundTransport[]>([])

const { isLoading, withAsyncLoading } = useLoading()

const onLoadMoreItems = async () => {
  const connection = transportsConnection.value

  let request: IGetTodoTransportQueryRequest = {}
  if (connection && connection.pageInfo?.hasNextPage) {
    request.after = connection.pageInfo.endCursor
  }

  await withAsyncLoading(async () => {
    transportsConnection.value = await getTodoTransports(request)

    transports.value = [
      ...transports.value,
      ...transportsConnection.value.edges.map((edge) => edge.node)
    ]
  })
}

const onPullToRefresh = async (done: () => void) => {
  transportsConnection.value = undefined
  transports.value = []

  await onLoadMoreItems()

  await done()
}

const onLoadCarrierClicked = async (transport: IOutboundTransport) => {
  f7.views.main.router.navigate(`/outbound/transport/${transport.id}/`)
}

onMounted(async () => {
  await onPullToRefresh()
})
</script>
