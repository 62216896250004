import { ID, IGraphQLResponse, INode } from '@graphql/types'
import { IArticle } from '@graphql/article/types'

export interface ILoadCarrier {
  number: string
  maxRows: number
  reference: string
  rows: ILoadCarrierRow[]
}

interface ILoadCarrierCompartmentItem {
  shipmentId: ID
  shipmentLineId: ID
  loadCarrierItemId: ID
  title: string
  barcode: string
}

interface ILoadCarrierCompartment {
  reference: string
  type: string
  maximumItemCount: number
  loadCarrierCompartmentItems: ILoadCarrierCompartmentItem[]
}

export interface ILoadCarrierRow {
  reference: string
  maxCompartment: number
  loadCarrierCompartments: ILoadCarrierCompartment
}

export interface IGetLoadCarrierByReferenceRequest {
  pickLoadCarrierId: ID
  reference: string
}

export interface IGetLoadCarrierByReferenceResponse extends IGraphQLResponse {
  data: {
    getLoadCarrierByReference: Partial<ILoadCarrier>
  }
}

export interface IPutItemInCompartmentRequest {
  barcode: string
  pickLoadCarrierId: ID
}

export interface IPutItemInCompartmentResponse extends IGraphQLResponse {
  data: {
    putItemInCompartment: IPutItemInCompartmentResult
  }
}

export interface IWantsNewCompartmentRequest {
  loadCarrierItemId: ID
}

export interface IWantsNewCompartmentResponse extends IGraphQLResponse {
  data: {
    wantsNewCompartment: IPutItemInCompartmentResult
  }
}

export interface IPutItemInCompartmentResult {
  sortableItem: ISortableItem | null
  compartmentReference: ICompartmentReference | null
  loadCarrierItemId: ID | null
  isSortLoadCarrierFull: boolean
  isPickLoadCarrierSorted: boolean
  sortLoadCarrierSequence: number | null
}

export interface ICompartmentReference {
  value: string
  carrier: string
  carrierType: string
  compartmentReference: string
  rowReference: string
}

export interface IGetSortResultRequest {
  pickLoadCarrierId: ID
}

export interface ISortableItem extends INode {
  identifier: ID
  name: string
  barcode: string
  quantity: number
  quantitySorted: number
  batchId?: ID
  batchName?: string
}

export interface ISortCompartment extends INode {
  compartmentReference: ICompartmentReference
  sortableItems: ISortableItem[]
}

export class SortCompartment implements ISortCompartment {
  constructor(
    public compartmentReference: ICompartmentReference,
    public sortableItems: ISortableItem[] = []
  ) {}
}

export interface IPickLoadCarrierSortResult extends INode {
  itemsTodo: ISortableItem[]
  itemsDone: ISortableItem[]
  sortCompartments: ISortCompartment[]
  compartmentDirectionResult: IPutItemInCompartmentResult | null
  completed: IPutItemInCompartmentResult[]
}

export interface IGetSortResultResponse extends IGraphQLResponse {
  data: {
    getSortResult: IPickLoadCarrierSortResult
  }
}

export interface ILoadCarrierReference extends INode {
  identifier: ID
  sorted: boolean
  reference: string
}

export interface IPickBatchSortState extends INode {
  pickLoadCarrierReferences: ILoadCarrierReference[]
  sortLoadCarrierReferences: ILoadCarrierReference[]
  lastSortReference?: ILastSortReference
  pickLoadCarriersSorted: boolean
  sortLoadCarriersSorted: boolean
  isBulkSortEnabled: boolean
}

export interface IGetPickBatchSortStateRequest {
  pickBatchId: ID
}

export interface IGetPickBatchSortStateResponse {
  data: {
    getPickBatchSortState: IPickBatchSortState
  }
}

export class ILastSortReference {
  pickLoadCarrierId: ID | null
  sortLoadCarrierId: ID | null
}

export interface IMarkSortLoadCarrierSortedRequest {
  sortLoadCarrierId: ID
  verificationCode: string
}

export interface IMarkSortLoadCarrierSortedResponse extends IGraphQLResponse {
  data: {
    markSortLoadCarrierSorted: boolean
  }
}

export interface IMarkPickBatchSortedRequest {
  pickBatchId: ID
}

export interface IMarkPickBatchSortedResponse extends IGraphQLResponse {
  data: {
    markPickBatchSorted: boolean
  }
}

export interface ICompartmentSeries {
  startCompartment: string
  endCompartment: string
  types: string[]
}

export interface IBulkSortableItem {
  article: IArticle
  quantity: number
  compartmentSeries: ICompartmentSeries[]
}

export interface IGetBulkSortableItemsRequest {
  pickLoadCarrierId: ID
  sortLoadCarrierId: ID
}

export interface IGetBulkSortableItemsResponse extends IGraphQLResponse {
  data: {
    getBulkSortableItems: IBulkSortableItem[]
  }
}

export interface IBulkSortRequest {
  sortLoadCarrierId: ID
  articleId: ID
  startCompartment: string
  endCompartment: string
  compartmentTypes: string[]
}

export interface IBulkSortResponse extends IGraphQLResponse {
  data: {
    bulkSort: boolean
  }
}
