import { defineStore } from 'pinia'
import { IGetByIdRequest } from '@graphql/task/types'
import taskApi from '@graphql/task'

export const useTaskStore = defineStore('task', {
  actions: {
    async getById(request: IGetByIdRequest) {
      const response = await taskApi.getById(request)

      return response.data.data.getById
    }
  }
})
