import loadCarrierApi from '@graphql/load-carrier'
import { defineStore } from 'pinia'
import { IGetByIdQueryRequest, IMoveToLocationMutationRequest } from '@graphql/loadCarrier/types'

export const useLoadCarrierStore = defineStore('load-carrier', {
  actions: {
    async getById(request: IGetByIdQueryRequest) {
      const response = await loadCarrierApi.getById(request)

      return response.data.data.getById
    },
    async moveToLocation(request: IMoveToLocationMutationRequest) {
      const response = await loadCarrierApi.moveToLocation(request)

      return response.data.data.moveToLocation
    }
  }
})
