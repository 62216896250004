<template>
  <page-with-search-subscriber v-slot="{ searchSubscriber }" :page-content="false">
    <app-navigation v-slot="{ searchId }">
      <search-bar
        :search-id="searchId"
        :scan-subscriber="searchSubscriber"
        :search-strategy="SearchStrategy.DefaultWithoutRouting"
        :singular-result-callback="onResult"
        :search-type="TypeName.LoadCarrier"
        :search-filters="searchFilters"
        @no-result="onNoResult"
        expandable
      />
    </app-navigation>
    <f7-page-content>
      <f7-block-header
        >Please scan a pick load carrier for pick batch #{{ pickBatchId }} to start the sort proces.
      </f7-block-header>
      <f7-card :class="{ 'skeleton-text': isLoading }">
        <f7-list v-if="pickLoadCarrierReferences.length > 0">
          <f7-list-item
            v-for="pickLoadCarrierReference in pickLoadCarrierReferences"
            :key="pickLoadCarrierReference.identifier"
          >
            #{{ pickLoadCarrierReference.identifier }}
            <font-awesome-icon
              :icon="calculateIcon(pickLoadCarrierReference.sorted)"
              :class="calculateStateClass(pickLoadCarrierReference.sorted)"
            />
          </f7-list-item>
        </f7-list>
      </f7-card>
    </f7-page-content>
  </page-with-search-subscriber>
</template>
<script lang="ts" setup>
import AppNavigation from '@components/AppNavigation.vue'
import { ID } from '@graphql/types'
import { computed, onMounted, ref } from 'vue'
import useOutboundSort from '@composables/useOutboundSort'
import useLoading from '@composables/useLoading'
import { IPickBatchSortState, ILoadCarrierReference } from '@graphql/sort/types'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { TypeName } from '@graphql/search/types'
import SearchBar from '@components/search/SearchBar.vue'
import { perceptibleToast } from '@services/perceptibleToast'
import { ILoadCarrier } from '@graphql/pick/types'
import { f7 } from 'framework7-vue'
import { ISearchFilter, SearchStrategy } from '@services/search/search'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'

const props = defineProps<{
  pickBatchId: ID
}>()

const { getPickBatchSortState, calculateStateClass } = useOutboundSort()
const { withAsyncLoading, isLoading } = useLoading()

let pickBatchSortState = ref<IPickBatchSortState>()
let searchFilters: ISearchFilter[] = [
  {
    key: 'type',
    operator: '=',
    value: 'pick'
  }
]

const pickLoadCarrierReferences = computed<ILoadCarrierReference[]>(() => {
  if (!pickBatchSortState.value) {
    return []
  }

  return pickBatchSortState.value?.pickLoadCarrierReferences ?? []
})

const calculateIcon = (value: boolean) => {
  if (value) {
    return ['far', 'box-open']
  }
  return ['far', 'box-open-full']
}

const onNoResult = async (query: string) => {
  await perceptibleToast.error(`No pick load carrier found for barcode "${query}"`)
}

const onResult = async (loadCarrier: ILoadCarrier) => {
  const index = pickLoadCarrierReferences.value.findIndex(
    (pickLoadCarrierReference) => pickLoadCarrierReference.identifier === loadCarrier.id
  )

  if (index === -1) {
    await perceptibleToast.error('Scanned load carrier is not listed!')
    return
  }

  await refreshPickBatchSortState()

  const lastActiveSortLoadCarrierId = pickBatchSortState.value?.lastSortReference?.sortLoadCarrierId
  if (lastActiveSortLoadCarrierId) {
    await f7.views.main.router.navigate(
      `/outbound-sort/${loadCarrier.id}/process/${lastActiveSortLoadCarrierId}/`
    )
    return
  }

  await f7.views.main.router.navigate(`/outbound-sort/${loadCarrier.id}/scan-sort-load-carrier/`)
}

const refreshPickBatchSortState = async () => {
  await withAsyncLoading(async () => {
    pickBatchSortState.value = await getPickBatchSortState({
      pickBatchId: props.pickBatchId
    })
  })
}

onMounted(async () => {
  await refreshPickBatchSortState()
})
</script>
