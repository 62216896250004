import { Router } from 'framework7/types'
import { InternalWorkflow } from '@graphql/pick/types'
import { store } from '@store/store'
import { computed } from 'vue'
import { IPackProject } from '@store/modules/projects/pack/types'
import { IGetPackProjectByIdRequest } from '@graphql/pack/types'
import { f7 } from 'framework7-vue'
import { perceptibleToast } from '@services/perceptibleToast'
import { captureEvent } from '@sentry/vue'

export const redirectMiddleware = async ({ resolve }: Router.RouteCallbackCtx) => {
  f7.preloader.show('#d6d95f')

  const activeProject = computed<IPackProject | undefined>(
    () => store.getters['projects/pack/activeItem']
  )

  const getPackProjectById = async (request: IGetPackProjectByIdRequest) => {
    return store.dispatch('pack/getPackProjectById', request)
  }

  const updateProject = async (payload: IPackProject) =>
    store.dispatch('projects/pack/updatePackProject', payload)

  if (!activeProject.value || activeProject.value?.pickLoadCarriers.length === 0) {
    resolve('/pack/')

    f7.preloader.hide()

    return
  }

  try {
    const packProject = await getPackProjectById({
      packProjectId: activeProject.value!.id
    })

    await updateProject({
      ...activeProject.value!,
      packProject: packProject
    } as IPackProject)
  } catch (e) {
    captureEvent(e)

    await perceptibleToast.error(e.message)

    return
  } finally {
    f7.preloader.hide()
  }

  if (activeProject.value.markedReady) {
    resolve('/pack/register-colli/')
    return
  }

  const firstPickLoadCarrier = activeProject.value?.pickLoadCarriers[0]!

  if (firstPickLoadCarrier.pickBatch.internalWorkflow === InternalWorkflow.SINGLE_SHIPMENT) {
    resolve('/pack/single-shipment/', {
      props: {
        pickLoadCarrier: firstPickLoadCarrier
      }
    })
    return
  }

  resolve('/pack/process-load-carrier/', {
    props: {
      loadCarrierId: firstPickLoadCarrier.id
    }
  })
}
