import { Router } from 'framework7/types'
import StartPage from '@pages/outbound/sort/StartPage.vue'
import ProcessPage from '@pages/outbound/sort/ProcessPage.vue'
import ScanSortLoadCarrierPage from '@pages/outbound/sort/ScanSortLoadCarrierPage.vue'
import { redirectMiddleware } from '@framework7/routes/outbound/sort/redirectMiddleware'
import ScanPickLoadCarrierPage from '@pages/outbound/sort/ScanPickLoadCarrierPage.vue'
import PrintDocumentsPage from '@pages/outbound/sort/PrintDocumentsPage.vue'
import PrintDocumentPage from '@pages/outbound/sort/PrintDocumentPage.vue'
import BulkProcessPage from '@pages/outbound/sort/BulkProcessPage.vue'

const outboundSort: Partial<Router.RouteParameters>[] = [
  {
    path: '/outbound-sort/',
    component: StartPage
  },
  {
    path: '/outbound-sort/entry/',
    redirect: redirectMiddleware
  },
  {
    path: '/outbound-sort/:pickLoadCarrierId/process/:sortLoadCarrierId/',
    component: ProcessPage
  },
  {
    path: '/outbound-sort/:pickLoadCarrierId/scan-sort-load-carrier/',
    component: ScanSortLoadCarrierPage
  },
  {
    path: '/outbound-sort/:pickLoadCarrierId/bulk/process/:sortLoadCarrierId/',
    component: BulkProcessPage
  },
  {
    path: `/outbound-sort/:pickBatchId/scan-pick-load-carrier/`,
    component: ScanPickLoadCarrierPage
  },
  {
    path: `/outbound-sort/:pickBatchId/print-documents/`,
    component: PrintDocumentsPage
  },
  {
    path: `/outbound-sort/:pickBatchId/print-documents/:sortLoadCarrierId/`,
    component: PrintDocumentPage
  }
]

export default outboundSort
