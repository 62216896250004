<template>
  <page-with-search-subscriber v-slot="{ searchSubscriber }" :page-content="false">
    <navigation hide-back-button hide-home-button>
      <f7-subnavbar class="no-padding">
        <search-bar :scan-subscriber="searchSubscriber" @no-result="onNoResult" />
      </f7-subnavbar>
    </navigation>

    <f7-page-content>
      <f7-block>
        <icon-with-text :icon="['far', 'user-circle']" :title="`Hi, ${currentUser?.name}`" />
      </f7-block>
      <f7-block>
        <f7-row>
          <is-granted
            v-for="(tile, index) in tiles"
            :key="index"
            :attributes="tile.permissions ?? []"
          >
            <home-tile :tile="tile" />
          </is-granted>
        </f7-row>
      </f7-block>
    </f7-page-content>

    <location-suggestion-popup
      :opened="locationSuggesterPopupOpened"
      @close="closeLocationSuggester"
      @on-selected-item="onSelectedItem"
    >
    </location-suggestion-popup>
  </page-with-search-subscriber>
</template>
<script lang="ts" setup>
import HomeTile, { ITile } from '@components/HomeTile.vue'
import useAuthentication from '@composables/useAuthentication'
import useSearch from '@composables/useSearch'
import { Attributes } from '@services/security/attributes'
import IsGranted from '@components/IsGranted.vue'
import IconWithText from '@components/IconWithText.vue'
import SearchBar from '@components/search/SearchBar.vue'
import Navigation from '@components/AppNavigation.vue'
import LocationSuggestionPopup from '@components/LocationSuggestionPopup.vue'
import { ref } from 'vue'
import { f7 } from 'framework7-vue'
import { ILocation } from '@graphql/location/types'
import { Permissions } from '@services/security/permissions'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'

const { currentUser } = useAuthentication()
const { onNoResult } = useSearch()

let locationSuggesterPopupOpened = ref<boolean>(false)

const closeLocationSuggester = (): void => {
  locationSuggesterPopupOpened.value = false
}

const onSelectedItem = (location: ILocation) => {
  closeLocationSuggester()

  f7.views.main.router.navigate(`/location/${location.id}/`, { item: location })
}

const navigateToRoute = (route: string): void => {
  f7.views.main.router.navigate(route)
}

const tiles: ITile[] = [
  {
    icon: ['far', 'list'],
    title: 'Projects',
    onClicked: (): void => navigateToRoute('/projects/')
  },
  {
    icon: ['far', 'inbox'],
    title: 'Goods receipts',
    onClicked: (): void => navigateToRoute('/goodsreceipt/')
  },
  {
    icon: ['far', 'box-check'],
    title: 'Packing control',
    onClicked: (): void => navigateToRoute('/packing-control/')
  },
  {
    icon: ['far', 'pallet-boxes'],
    title: 'Putaway',
    onClicked: (): void => navigateToRoute('/inbound/putaway/')
  },
  {
    icon: ['far', 'person-carry'],
    title: 'Pick',
    onClicked: (): void => navigateToRoute('/pick-entry/')
  },
  {
    icon: ['far', 'conveyor-belt-boxes'],
    title: 'Outbound Sort',
    onClicked: (): void => navigateToRoute('/outbound-sort/entry/')
  },
  {
    icon: ['far', 'truck-ramp-box'],
    title: 'Inbound reception',
    onClicked: (): void => navigateToRoute('/inbound/reception/'),
    permissions: Permissions.VIEW_INBOUND_RECEPTION.toString()
  },
  {
    icon: ['far', 'box-full'],
    title: 'Pack',
    onClicked: (): void => navigateToRoute('/pack-entry/'),
    permissions: Attributes.PACK_ACCESS
  },
  {
    icon: ['far', 'location-crosshairs'],
    title: 'Location suggester',
    onClicked: (): void => (locationSuggesterPopupOpened.value = true)
  },
  {
    icon: ['far', 'boxes-stacked'],
    title: 'Ship',
    onClicked: (): void => navigateToRoute('/outbound/transport/todo/'),
    permissions: Permissions.VIEW_OUTBOUND_TRANPORT
  }
]
</script>
<style scoped lang="less">
h2.welcome-text {
  text-align: center;
}

.page {
  background: #fff url('../assets/img/splash-bg.png') no-repeat !important;
  background-position: center 175px !important;
}
</style>
