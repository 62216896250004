import { IProjectPickState } from '@store/modules/projects/pick/types'
import { IRootState } from '@store/types'
import { GetterTree } from 'vuex'

const getters: GetterTree<IProjectPickState, IRootState> = {
  isActive: (state) => !!state.activeProject,
  itemCount: (state) => (state.activeProject !== undefined ? 1 : 0),
  items: (state) => [state.activeProject],
  count: (state) => (state.activeProject !== undefined ? 1 : 0),
  activeProject: (state) => state.activeProject,
  selectedQueue: (state) => state.selectedQueue,
  printedDocuments: (state) => state.printedDocuments,
  kardexProject: (state) => state.activeProject.kardexProject
}

export default getters
