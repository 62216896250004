import { IRootState } from '@store/types'
import { ActionContext, ActionTree } from 'vuex'
import { IPackProject, IProjectPackState } from '@store/modules/projects/pack/types'
import { ID } from '@graphql/types'

const actions: ActionTree<IProjectPackState, IRootState> = {
  async addPackProject(
    { commit }: ActionContext<IProjectPackState, IRootState>,
    project: IPackProject
  ) {
    await commit('addPackProject', project)
  },
  async updatePackProject(
    { commit }: ActionContext<IProjectPackState, IRootState>,
    project: IPackProject
  ) {
    await commit('updatePackProject', project)
  },
  async removeProject({ commit }: ActionContext<IProjectPackState, IRootState>, id: ID) {
    await commit('removePackProject', id)
  },
  async removeProjects({ commit }) {
    await commit('removeProjects')
  }
}

export default actions
