<template>
  <f7-card>
    <f7-card-header>{{ title }}</f7-card-header>
    <f7-card-content>
      <f7-list :class="{ 'skeleton-text': isLoading }">
        <f7-list-item class="no-results" v-if="loadCarriers.length === 0">
          No load carriers found...
        </f7-list-item>
        <template v-if="!isLoading">
          <f7-list-item
            v-for="carrier in loadCarriers"
            :key="carrier.id"
            swipeout
            @swipeout:opened="onOverSwipeLeft(carrier)"
          >
            <template #inner>
              <div class="load-carrier-info">
                <div class="reference">
                  {{ carrier.reference }}
                </div>
                <div class="state" v-if="carrier.stateForHuman">
                  {{ carrier.stateForHuman }}
                </div>
                <font-awesome-icon
                  v-if="carrier.loadCarrierItems.totalCount > 0"
                  :icon="['fad', 'dolly']"
                />
                <font-awesome-icon
                  v-if="carrier.loadCarrierItems.totalCount === 0"
                  :icon="['fad', 'dolly-empty']"
                />
              </div>
            </template>
            <f7-swipeout-actions
              left
              v-if="carrier.state === 'sorting' && carrier.loadCarrierItems.totalCount > 0"
            >
              <f7-swipeout-button color="dimass" overswipe close>
                <f7-icon material="swipe-right" class="text-color-white" />
              </f7-swipeout-button>
            </f7-swipeout-actions>
          </f7-list-item>
        </template>
      </f7-list>
    </f7-card-content>
  </f7-card>
  <mark-as-sorted-popup
    v-if="loadCarrierToMarkAsSorted !== null"
    v-model="sortedPopupOpened"
    :load-carrier="loadCarrierToMarkAsSorted"
    @update:model-value="onClose"
    @scanned="onMarkAsSortedScanned"
  ></mark-as-sorted-popup>
</template>

<script setup lang="ts">
import { IInboundSortLoadCarrier } from '@graphql/inbound/types'
import { perceptibleToast } from '@services/perceptibleToast'
import { captureException } from '@sentry/vue'
import MarkAsSortedPopup from '@pages/inbound/components/MarkAsSortedPopup.vue'
import useInbound from '@composables/useInbound'
import { ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const { markInboundSortLoadCarrierAsSorted } = useInbound()

const sortedPopupOpened = ref<boolean>(false)
const loadCarrierToMarkAsSorted = ref<IInboundSortLoadCarrier | null>(null)

withDefaults(
  defineProps<{
    title: string
    loadCarriers: IInboundSortLoadCarrier[]
    isLoading: boolean
  }>(),
  {
    title: 'Load carriers'
  }
)

const emits = defineEmits<{
  (e: 'start-marking-sorted', payload: IInboundSortLoadCarrier)
  (e: 'stopped-marking-sorted')
  (e: 'marked-sorted', payload: IInboundSortLoadCarrier)
}>()

const onOverSwipeLeft = async (loadCarrier: IInboundSortLoadCarrier) => {
  emits('start-marking-sorted', loadCarrier)
  loadCarrierToMarkAsSorted.value = loadCarrier
  sortedPopupOpened.value = true
}

const onClose = () => {
  loadCarrierToMarkAsSorted.value = null
  emits('stopped-marking-sorted')
}

const onMarkAsSortedScanned = async (loadCarrier: IInboundSortLoadCarrier) => {
  try {
    await markInboundSortLoadCarrierAsSorted({
      loadCarrierId: loadCarrier.id
    })

    emits('marked-sorted', loadCarrier)
    await perceptibleToast.success(`Marked ${loadCarrier.reference} as sorted.`)

    loadCarrierToMarkAsSorted.value = null
  } catch (e) {
    captureException(e)

    await perceptibleToast.error(e.message)
  }
}
</script>
<style scoped>
.no-results {
  color: gray;
  user-select: none;
}

.load-carrier-info {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;

  .reference {
    flex: 1;
  }
}
</style>
