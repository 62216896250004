export default () => {
  const getTaskContextClass = (value: boolean) =>
    value ? 'text-color-green' : 'color-text-gray-light'
  const getBackgroundContextClass = (value: boolean) => (value ? 'green' : 'gray')

  return {
    getTaskContextClass,
    getBackgroundContextClass
  }
}
