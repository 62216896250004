<template>
  <page-with-search-subscriber :scan-callback="onScan" :page-content="false">
    <navigation />
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#pack-scan-load-carrier" tab-link-active>Scan</f7-link>
      <f7-link tab-link="#pack-ready-items">Pack ready items</f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="pack-scan-load-carrier" class="page-content" tab-active>
        <f7-card :class="{ 'skeleton-text': isLoading }">
          <f7-card-content>
            <icon-with-text
              :icon="['far', 'box-full']"
              title="Scan load carrier barcode to start"
              description="To start packing you are required to scan a valid load carrier barcode."
            />
          </f7-card-content>
        </f7-card>
      </f7-tab>
      <f7-tab
        id="pack-ready-items"
        class="page-content ptr-content"
        :data-ptr-distance="55"
        :data-ptr-mousewheel="true"
        @ptr:refresh="onPullToRefreshPackReadyItems"
      >
        <pull-to-refresh-preloader />
        <f7-card :class="{ 'skeleton-text': isLoading }">
          <f7-card-content v-if="packReadyItems?.groups.length === 0">
            <icon-with-text :icon="['fad', 'party-horn']" title="All items done!" />
          </f7-card-content>
          <f7-list v-if="packReadyItems">
            <f7-list-item
              v-for="packReadyItemGroup in packReadyItems.groups"
              :key="packReadyItemGroup.relationReference.identifier"
            >
              <template #inner>
                <div class="block">
                  <div class="group-container">
                    {{ renderRelationReference(packReadyItemGroup.relationReference) }}
                    <div style="white-space: pre-wrap" v-if="packReadyItemGroup.description">
                      {{ packReadyItemGroup.description }}
                    </div>
                  </div>
                  <div class="items-container">
                    <div
                      v-for="packReadyItem in packReadyItemGroup.items"
                      :key="packReadyItem.relationReference.identifier"
                      class="item-container"
                    >
                      <div>
                        -&nbsp;&nbsp;{{ renderRelationReference(packReadyItem.relationReference) }}
                      </div>
                      <div class="text-align-right" v-if="packReadyItem.locationName">
                        <location-chip :text="packReadyItem.locationName" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </f7-list-item>
          </f7-list>
        </f7-card>
      </f7-tab>
    </f7-tabs>
  </page-with-search-subscriber>
</template>
<script lang="ts" setup>
import IconWithText from '@components/IconWithText.vue'
import Navigation from '@components/AppNavigation.vue'
import useLoading from '@composables/useLoading'
import usePack from '@composables/usePack'
import { onMounted, ref } from 'vue'
import { perceptibleToast } from '@services/perceptibleToast'
import { IPackableReadyItemList, IRelationReference } from '@graphql/pack/types'
import StringLabel from '@components/label/StringLabel.vue'
import PullToRefreshPreloader from '@components/PullToRefreshPreloader.vue'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'
import LocationChip from '@components/chip/LocationChip.vue'

const { getLoadCarrierById, onResult, getPackReadyItems } = usePack()
const { isLoading, withAsyncLoading } = useLoading()

const packReadyItems = ref<IPackableReadyItemList>()

const onScan = async (event: IEvent) => {
  const query = String(event.payload)

  if (query.match(/^\d+$/)) {
    await onScanInput(query)
    return
  }

  await perceptibleToast.error('Please scan a load carrier')
}

const onPullToRefreshPackReadyItems = async (e?: any) => {
  await withAsyncLoading(async () => {
    packReadyItems.value = await getPackReadyItems()
  })

  e?.detail()
}

const onScanInput = async (query: string) => {
  try {
    await withAsyncLoading(async () => {
      const loadCarrier = await getLoadCarrierById({
        id: query
      })

      await onResult(loadCarrier)
    })
  } catch (e) {
    await perceptibleToast.error(e.message)
  }
}

const renderRelationReference = (relationReference: IRelationReference) => {
  return `${relationReference.typeForHuman} #${relationReference.identifier}`
}

onMounted(async () => {
  await onPullToRefreshPackReadyItems()
})
</script>
<style lang="css">
.block {
  width: 100%;
}

.group-container {
  display: flex;
  flex-direction: column;
  width: max-content;
  flex: 1;
}

.items-container {
  width: 100%;
}

.item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  flex: 1;
}
</style>
