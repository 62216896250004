import { Module } from 'vuex'
import { IRootState } from '@store/types'
import actions from '@store/modules/outbound/sort/actions'
import { IOutboundSortState } from '@store/modules/outbound/sort/types'

const state: IOutboundSortState = {}

const outboundSort: Module<IOutboundSortState, IRootState> = {
  namespaced: true,
  state,
  actions
}

export default outboundSort
