import { APPLICATION_ENVIRONMENT, GRAPHQL_API_URI } from '@config/environment'
import { store } from '@store/store'
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { notification } from '../../notification'
import { randomString } from '@/utilities/string'
import * as Sentry from '@sentry/vue'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const timeout = APPLICATION_ENVIRONMENT !== 'dev' ? 20000 : null
const GRAPHQL_API_CLIENT = axios.create()
GRAPHQL_API_CLIENT.defaults.baseURL = GRAPHQL_API_URI
GRAPHQL_API_CLIENT.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
GRAPHQL_API_CLIENT.defaults.timeout = 20000
//SS-455 abort the request client side after timeout, if "hardening" did not solve the issue
// GRAPHQL_API_CLIENT.defaults.signal = newAbortSignal(timeout)
//
// const newAbortSignal = (timeout) => {
//   const abortController = new AbortController();
//   setTimeout(() => abortController.abort(), timeout || 0);
//
//   return abortController.signal;
// }

const responseRejectHandler = (error: AxiosError) => {
  const { response } = error

  if (response && response.status) {
    if (response.status === 401) {
      notification
        .error({ message: 'An authentication error has occurred: ' + response.statusText })
        .open()

      store.commit('auth/removeToken')
    }

    if (response.status >= 500) {
      notification.error({ message: 'A fatal error occurred: ' + response.statusText }).open()
    }
  }

  return Promise.reject(error)
}

function handleTokenLifetimeExtension(response: AxiosResponse) {
  if (response.headers['sp-token-lifetime'] !== undefined) {
    store.commit('auth/setTokenLifetime', Number(response.headers['sp-token-lifetime']))
  }
}

const responseOnFulfilledHandler = (response: AxiosResponse) => {
  handleTokenLifetimeExtension(response)

  return response
}

const requestOnFulfilledHandler = (requestConfig: InternalAxiosRequestConfig) => {
  const headers = requestConfig.headers ?? {}

  const authToken = store.getters['auth/token']
  if (authToken) {
    headers['Sp-Auth-Token'] = authToken
  }

  const deviceId = store.getters['settings/deviceId']
  if (deviceId) {
    headers['Sp-Device-Id'] = deviceId
  }

  const signature = randomString(32)
  headers['X-Request-Signature'] = signature
  Sentry.getCurrentScope().setTag('X-Request-Signature', signature) //hack for sentry not sending all headers.

  requestConfig.headers = headers

  return requestConfig
}

GRAPHQL_API_CLIENT.interceptors.request.use(requestOnFulfilledHandler)
GRAPHQL_API_CLIENT.interceptors.response.use(responseOnFulfilledHandler, responseRejectHandler)

export const isNetworkError = (error: any) => {
  return (
    !error.response &&
    Boolean(error.code) &&
    error.code !== 'ECONNABORTED' &&
    isRetryableError(error)
  )
}

export const isAbortedRequest = (error: any) => {
  return !error.response && Boolean(error.code) && error.code === 'ECONNABORTED'
}

export const isRetryableError = (error: any) => {
  return (
    error.code !== 'ECONNABORTED' &&
    (!error.response || (error.response.status >= 500 && error.response.status <= 599))
  )
}

export const isRetryableNetworkError = (error: any) => {
  return isNetworkError(error) && isRetryableError(error)
}

export default GRAPHQL_API_CLIENT
