import { IPackState } from '@store/modules/pack/types'
import { IRootState } from '@store/types'
import { ActionTree } from 'vuex'
import {
  IAddPackSourceItemToOutboundLoadCarrierRequest,
  IBulkMarkPrePackedRequest,
  IGetPackableItemsByLoadCarrierIdRequest,
  IGetPackProjectByIdRequest,
  IGetPackingListByContextRequest,
  IGetPackSourceItemRequest,
  IGetRegisterableLoadCarriersRequest,
  IMarkPackedRequest,
  IPrePackColloRequest,
  IRegisterLoadCarrierRequest,
  IStartPackRequest,
  ICompletePackRequest,
  IRemoveFromOutboundLoadCarrierRequest
} from '@graphql/pack/types'
import packApi from '@graphql/pack'
import { IGetLoadCarrierByIdRequest } from '@graphql/pick/types'

const actions: ActionTree<IPackState, IRootState> = {
  async prePackCollo(context, request: IPrePackColloRequest) {
    const result = await packApi.prePackCollo(request)

    return result.data.data.prePackCollo
  },
  async startPack(context, request: IStartPackRequest) {
    const result = await packApi.startPack(request)

    return result.data.data.startPack
  },
  async getPackProjectById(context, request: IGetPackProjectByIdRequest) {
    const result = await packApi.getPackProjectById(request)

    return result.data.data.getPackProjectById
  },
  async getPackSourceItem(context, request: IGetPackSourceItemRequest) {
    const result = await packApi.getPackSourceItem(request)

    return result.data.data.getPackSourceItem
  },
  async addPackSourceItemToOutboundLoadCarrier(
    context,
    request: IAddPackSourceItemToOutboundLoadCarrierRequest
  ) {
    const result = await packApi.addPackSourceItemToOutboundLoadCarrier(request)

    return result.data.data.addPackSourceItemToOutboundLoadCarrier
  },
  async removeFromOutboundLoadCarrier(context, request: IRemoveFromOutboundLoadCarrierRequest) {
    const result = await packApi.removeFromOutboundLoadCarrier(request)

    return result.data.data.removeFromOutboundLoadCarrier
  },
  async getRegisterableLoadCarriers(context, request: IGetRegisterableLoadCarriersRequest) {
    const result = await packApi.getRegisterableLoadCarriers(request)

    return result.data.data.getRegisterableLoadCarriers
  },
  async registerLoadCarrier(context, request: IRegisterLoadCarrierRequest) {
    const result = await packApi.registerLoadCarrier(request)

    return result.data.data.registerLoadCarrier
  },
  async completePack(context, request: ICompletePackRequest) {
    const result = await packApi.completePack(request)

    return result.data.data.completePackProject
  },
  async getPackableItemsByLoadCarrierId(context, request: IGetPackableItemsByLoadCarrierIdRequest) {
    request.perPage = 100

    const response = await packApi.getPackableItemsByLoadCarrierId(request)

    return response.data.data.getPackableItemsByLoadCarrierId
  },
  async getPackingListByContext(context, request: IGetPackingListByContextRequest) {
    const response = await packApi.getPackingListByContext(request)

    return response.data.data.getPackingListByContext
  },
  async markPacked(context, request: IMarkPackedRequest) {
    const response = await packApi.markPacked(request)

    return response.data.data.markPacked
  },
  async bulkMarkPrePacked(context, request: IBulkMarkPrePackedRequest) {
    const response = await packApi.bulkMarkPrePacked(request)

    return response.data.data.bulkMarkPrePacked
  },
  async getLoadCarrierById(context, request: IGetLoadCarrierByIdRequest) {
    const response = await packApi.getLoadCarrierById(request)

    return response.data.data.getLoadCarrierById
  },
  async getPackReadyItems() {
    const response = await packApi.getPackReadyItems()

    return response.data.data.getPackReadyItems
  }
}

export default actions
