<template>
  <f7-list>
    <f7-list-item
      :class="{ 'skeleton-text': isLoading }"
      v-for="(nestedItem, index) in nestedLoadCarrierListItems"
      :key="index"
      swipeout
      @swipeout:opened="onDelete(nestedItem)"
    >
      <f7-swipeout-actions left>
        <f7-swipeout-button color="red" overswipe close>
          <font-awesome-icon :icon="['far', 'trash']" />
        </f7-swipeout-button>
      </f7-swipeout-actions>

      <template #inner>
        <div class="item">
          <div
            class="spacer"
            v-for="(_, spacerIndex) in getLevel(nestedItem.level)"
            :key="spacerIndex"
          />
          <div class="icon" v-if="nestedItem.level">
            <font-awesome-icon :icon="['far', 'turn-down-right']" />
          </div>
          <div class="description">{{ nestedItem.name }}</div>
          <div class="quantity">
            <quantity-label :quantity="nestedItem.quantity" />
          </div>
        </div>
      </template>
    </f7-list-item>
  </f7-list>
</template>

<script setup lang="ts">
import { INestedLoadCarriersListItem } from '@graphql/pack/types'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import QuantityLabel from '@components/QuantityLabel.vue'
import usePack from '@composables/usePack'
import { captureException } from '@sentry/vue'
import { perceptibleToast } from '@services/perceptibleToast'
import { confirmYesNo } from '@/functions/dialog'

const { removeFromOutboundLoadCarrier } = usePack()

defineProps<{
  nestedLoadCarrierListItems: INestedLoadCarriersListItem[]
  isLoading: boolean
}>()

const emits = defineEmits<{
  (e: 'on-deleted', payload: INestedLoadCarriersListItem)
}>()

const getLevel = (currentLevel: number): number => {
  if (currentLevel <= 1) {
    return 0
  }

  return currentLevel - 1
}

const onDelete = (nestedLoadCarrierListItem: INestedLoadCarriersListItem) => {
  confirmYesNo({
    title: `Are you sure you want to delete ${nestedLoadCarrierListItem.name}`,
    yesButtonCallback: async () => {
      try {
        await removeFromOutboundLoadCarrier({
          nestedLoadCarrierItemId: nestedLoadCarrierListItem.id,
          nestedLoadCarrierItemType: nestedLoadCarrierListItem.type
        })

        await emits('on-deleted', nestedLoadCarrierListItem)
      } catch (e) {
        captureException(e)

        await perceptibleToast.error(e.message)
      }
    }
  })
}
</script>

<style scoped lang="less">
.item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  .spacer {
    flex-basis: 16px;
  }

  .icon {
    width: 16px;
  }

  .description {
    flex: 1;
  }
}
</style>
