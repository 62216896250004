import Base from '@graphql/base'
import { AxiosInstance } from 'axios'
import {
  IGetByIdQueryRequest,
  IGetByIdQueryResponse,
  IMoveToLocationMutationRequest,
  IMoveToLocationMutationResponse
} from '@graphql/loadCarrier/types'
import GRAPHQL_API_CLIENT from './client'

class LoadCarrier extends Base {
  constructor(protected axios: AxiosInstance) {
    super(axios, 'loadCarrier')
  }

  async getById(request: IGetByIdQueryRequest) {
    return this.makeRequest<IGetByIdQueryResponse>('getByIdQuery', request)
  }

  async moveToLocation(request: IMoveToLocationMutationRequest) {
    return this.makeRequest<IMoveToLocationMutationResponse>('moveToLocationMutation', request)
  }
}

export default new LoadCarrier(GRAPHQL_API_CLIENT)
