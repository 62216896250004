import { IRootState } from '@store/types'
import { Module } from 'vuex'
import { IProjectPackState } from '@store/modules/projects/pack/types'
import getters from '@store/modules/projects/pack/getters'
import actions from '@store/modules/projects/pack/actions'
import mutations from '@store/modules/projects/pack/mutations'

const state: IProjectPackState = {
  items: [],
  activeItem: null
}

const projectPack: Module<IProjectPackState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default projectPack
