<template>
  <f7-app v-bind="config">
    <f7-panel right reveal>
      <f7-view url="/panel-user/" name="panel-user" id="panel-user" />
    </f7-panel>
    <f7-view main :url="mainPage" />
    <f7-popup
      :opened="parserPopupOpened"
      class="parser-result-popup"
      :close-by-backdrop-click="false"
      :close-on-escape="false"
      :swipe-to-close="false"
      :tablet-fullscreen="true"
      @popup:closed="parserPopupOpened = false"
      style="z-index: 20000"
      v-if="parserResult"
    >
      <parser-results
        :parser-result="parserResult"
        :scan-input-enabled="parserPopupOpened"
        @result="onParserResult"
      />
    </f7-popup>
  </f7-app>
</template>

<script lang="ts" setup>
import { Event, eventBus, scanInput } from '@/utilities/scanInput'
import ParserResults from '@components/ParserResults.vue'
import useAuthentication from '@composables/useAuthentication'
import useSettings from '@composables/useSettings'
import config from '@framework7/config'
import * as Sentry from '@sentry/vue'
import { NfcReader } from '@services/nfc'
import { notification } from '@services/notification'
import { parserFactory, ParserResult } from '@services/search/parser'
import { playSoundListener } from '@services/sound'
import { toast } from '@services/toast'
import { IUser } from '@store/modules/auth/types'
import { UnionTypeSearchResult } from '@store/modules/search/types'
import { f7, f7ready } from 'framework7-vue'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { computed, nextTick, onBeforeMount, onMounted, ref, watch } from 'vue'
import { getDevice } from 'framework7'

const { deviceId } = useSettings()
const reloadSW: any = '__RELOAD_SW__'

const parserPopupOpened = ref(false)
const parserResult = ref<ParserResult | undefined>(undefined)

const {
  logout,
  tokenLifetime,
  isAuthenticated,
  currentUser: user,
  tokenLogin
} = useAuthentication()

const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
  immediate: true,
  onRegistered(r: ServiceWorkerRegistration) {
    if (reloadSW === 'true') {
      if (r) {
        r.update()
      }
    }
  }
})

const audioElement = new Audio()
const needServiceWorkerRefresh = computed<boolean>(() => needRefresh.value || offlineReady.value)

if (deviceId.value) {
  Sentry.getCurrentScope().setTag('device.id', deviceId.value)
}

const determinePageToShow = () => {
  const device = getDevice()

  if (!device.desktop && !deviceId.value) {
    return '/device/register/'
  }

  if (!isAuthenticated.value) {
    return '/login/'
  }
  return '/home/'
}

let mainPage = determinePageToShow()

watch(isAuthenticated, () => {
  nextTick(() => f7.views.main.router.navigate(determinePageToShow(), { reloadAll: true }))
})

watch(needServiceWorkerRefresh, () => {
  f7.toast
    .create({
      text: 'New version of the application found. Click install to update.',
      closeButton: true,
      closeButtonText: 'Install',
      on: {
        close: () => {
          updateServiceWorker(true)
        }
      }
    })
    .open()
})

const handleNfcRead = async () => {
  console.log('handleNfc')

  const nfcReader = new NfcReader()

  try {
    const reader = await nfcReader.start()

    reader.onreading = async (event: any) => {
      const loginResult = await tokenLogin(event.serialNumber)

      console.log('Got response from token login')

      if (loginResult !== null && loginResult.user !== null) {
        toast.default(`Hi, logged in as ${loginResult.user.name}!`, 10000).open()

        f7.views.main.router.navigate('/home/', { reloadAll: true })
      } else {
        toast.error('Token not recognized!', 10000).open()
      }
    }
  } catch (e: any) {
    console.log(`NFC Scan failed to start: ${e.message}.`)
  }
}

scanInput.onInput(async (query) => {
  const parser = parserFactory.get(query)
  const result = await parser.parse(query)

  if (result.type === 'NfcToken') {
    await eventBus.emit(new Event('authenticator.login', result.query, result.extraData))
    return
  }

  if (result.isBlocking()) {
    parserPopupOpened.value = true
    parserResult.value = result
    return
  }

  await eventBus.emit(new Event('scanInput', result.query, result.extraData))
})

const keydownListener = (event: KeyboardEvent) => scanInput.onKeyboardEvent(event)

const bindListeners = () => {
  document.addEventListener('visibilitychange', onAppVisibilityChange)
  window.addEventListener('keydown', keydownListener, { passive: false })
  window.addEventListener(
    'play-sound',
    async (event) => await playSoundListener(audioElement, event),
    { passive: false }
  )
}

const actOnTokenLifetime = () => {
  let timestampToCheck: number = Math.floor(Date.now() / 1000) - 60

  if (tokenLifetime.value !== undefined && tokenLifetime.value <= timestampToCheck) {
    logout()

    notification
      .info({
        title: 'Authentication required',
        message: 'Automatic logout due to inactivity.'
      })
      .open()
  }
}

const onAppVisibilityChange = () => {
  if (document.visibilityState !== 'visible') {
    return
  }

  actOnTokenLifetime()
}

const onParserResult = async (result: UnionTypeSearchResult) => {
  parserPopupOpened.value = false

  await nextTick(async () => {
    await eventBus.emit(new Event('scanInput', result.name, []))
  })
}

watch(user, (user: IUser | null) => {
  Sentry.configureScope((scope) => {
    if (!user) {
      scope.clear()

      return
    }

    scope.setUser({
      id: user.id,
      email: user.email,
      username: user.name
    })
  })
})

watch(deviceId, (value) => {
  if (!value) {
    nextTick(() => f7.views.main.router.navigate('/device/register/', { reloadAll: true }))
    return
  }
  Sentry.configureScope((scope) => {
    scope.setTag('device.id', value)
  })
})

onBeforeMount(() => {
  bindListeners()
})

onMounted(() => {
  f7ready((f7) => {
    f7.on('online', () => {
      toast.info('(Internet) connection is restored, app is in online-mode!', 5000).open()
    })

    f7.on('offline', () => {
      toast.error('(Internet) connection has been lost, app is in offline-mode!', 0, true).open()
    })
  })

  if (NfcReader.isNfcFeatureAvailable() && deviceId.value > 0) {
    handleNfcRead()
  }
})
</script>
