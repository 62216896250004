<template>
  <f7-chip class="chip-small" media-bg-color="primary">
    <template #media>
      <font-awesome-icon :icon="['far', 'barcode']" />
    </template>
  </f7-chip>
</template>
<script lang="ts" setup></script>
<style lang="less">
.chip-small {
  &.chip {
    height: 20px;
    line-height: 20px;

    > .chip-media {
      height: 20px !important;
      width: 20px !important;
      border-radius: 20px !important;
      line-height: 20px !important;
      font-size: 12px;
    }

    > .chip-label {
      font-size: 12px;
    }
  }
}
</style>
