<template>
  <f7-popup id="create-load-carrier-popup" :opened="popupOpened" :tablet-fullscreen="true">
    <f7-page>
      <template v-if="!createdLoadCarrier">
        <f7-navbar>
          <f7-nav-title>Create load carrier</f7-nav-title>
          <f7-nav-right>
            <f7-link @click="popupOpened = false">
              <f7-icon material="close" />
            </f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-block-header>Load carrier type</f7-block-header>
        <f7-card>
          <f7-card-content :padding="false">
            <f7-list>
              <f7-list-item
                radio
                radio-icon="start"
                v-for="loadCarrierType in loadCarrierTypes"
                :key="loadCarrierType.id"
                name="load-carrier-type"
                :title="loadCarrierType.name"
                @click="selectedLoadCarrierType = loadCarrierType"
              />
            </f7-list>
          </f7-card-content>
        </f7-card>

        <f7-card>
          <f7-button
            large
            color="dimass"
            text-color="white"
            :disabled="!selectedLoadCarrierType"
            @click="onSubmit"
          >
            Save
          </f7-button>
        </f7-card>
      </template>

      <load-carrier-scan-to-print
        v-if="createdLoadCarrier"
        :verification-popup-opened="verificationPopupOpened"
        @print-documents="onPrintVerificationLabel"
        @confirm-barcode="onVerifyLoadCarrier"
        @verification-popup-closed="verificationPopupOpened = false"
      >
        <template #page-title>Finalize load carrier</template>
        <template #page-content>
          <icon-with-text
            :icon="['far', 'print']"
            title="Scan a nearby label printer"
            description="Print the control label to finalize the picked items for this load carrier. And place the load carrier in the buffer-zone displayed on the printed label."
          />
        </template>
      </load-carrier-scan-to-print>
    </f7-page>
  </f7-popup>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { perceptibleToast } from '@services/perceptibleToast'
import { captureException } from '@sentry/vue'
import LoadCarrierScanToPrint from '@components/LoadCarrierScanToPrint.vue'
import IconWithText from '@components/IconWithText.vue'
import { TypeName } from '@graphql/search/types'
import { DocumentKind, PrintContext } from '@graphql/document/types'
import { soundBoard } from '@services/sound'
import useDocument from '@composables/useDocument'
import { f7 } from 'framework7-vue'
import { ID } from '@graphql/types'
import { IOutboundLoadCarrier, ILoadCarrierType } from '@graphql/outbound/types'
import { useOutbound } from '@store/pinia/outbound/store'

const popupOpened = defineModel<boolean>({ default: false, required: true })
const loadCarrierTypes = ref<ILoadCarrierType[]>()
const selectedLoadCarrierType = ref<ILoadCarrierType>()

const verificationPopupOpened = ref<boolean>(false)
const createdLoadCarrier = ref<IOutboundLoadCarrier | null>(null)

const { getLoadCarrierTypes, createLoadCarrier } = useOutbound()
const { printDocumentsFor } = useDocument()

const props = defineProps<{
  packProjectId: ID
}>()

onMounted(async () => {
  loadCarrierTypes.value = await getLoadCarrierTypes()
})

const emits = defineEmits<{
  (e: 'load-carrier-created', payload: IOutboundLoadCarrier): void
}>()

const onSubmit = async () => {
  try {
    createdLoadCarrier.value = await createLoadCarrier({
      articleId: selectedLoadCarrierType.value?.id!,
      packProjectId: props.packProjectId
    })

    await onPrintVerificationLabel()
  } catch (e) {
    captureException(e)

    await perceptibleToast.error(e.message)
  }
}

const onPrintVerificationLabel = async (printerCode: string | null = null) => {
  await printDocumentsFor({
    context: new PrintContext(
      createdLoadCarrier.value.id,
      TypeName.LoadCarrier,
      DocumentKind.OUTBOUND_LOAD_CARRIER_LABELS,
      printerCode
    )
  })

  await soundBoard.playSuccessSound()
  verificationPopupOpened.value = true
}

const onVerifyLoadCarrier = async (verificationCode: string) => {
  if (
    verificationCode !== createdLoadCarrier.value?.id &&
    verificationCode !== createdLoadCarrier.value?.verificationCode
  ) {
    await perceptibleToast.error('Invalid verification code')
    return
  }

  emits('load-carrier-created', createdLoadCarrier.value)

  f7.popup.close('#scan-verification-code-popup')
  verificationPopupOpened.value = false
  createdLoadCarrier.value = null
}
</script>

<style scoped></style>
