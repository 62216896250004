<template>
  <page-with-search-subscriber v-slot="{ searchSubscriber }" :page-content="false">
    <navigation show-search-icon v-slot="{ searchId }">
      <search-bar :scan-subscriber="searchSubscriber" :search-id="searchId" expandable />
    </navigation>

    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#load-carrier-detail" tab-link-active> Detail </f7-link>
      <f7-link tab-link="#load-carrier-items"> Items </f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="load-carrier-detail" class="page-content" tab-active>
        <load-carrier-detail-tab-content
          v-if="loadCarrier"
          :load-carrier="loadCarrier"
          :is-loading="isLoading"
        />
      </f7-tab>
      <f7-tab id="load-carrier-items" class="page-content">
        <load-carrier-detail-tab-items
          v-if="loadCarrier"
          :load-carrier="loadCarrier"
          :is-loading="isLoading"
        />
      </f7-tab>
    </f7-tabs>
    <app-actions v-if="actions.length > 0" :actions="actions" @action-clicked="onActionClick" />
  </page-with-search-subscriber>
</template>
<script lang="ts" setup>
import SearchBar from '@components/search/SearchBar.vue'
import Navigation from '@components/AppNavigation.vue'
import LoadCarrierDetailTabContent from '@components/pick/LoadCarrierDetailTabContent.vue'
import LoadCarrierDetailTabItems from '@components/pick/LoadCarrierDetailTabItems.vue'
import useLoading from '@composables/useLoading'
import { onMounted, ref } from 'vue'
import { ILoadCarrier } from '@graphql/pick/types'
import { ID } from '@graphql/types'
import useProjectPick from '@composables/useProjectPick'
import useAction from '@composables/useAction'
import AppActions from '@components/AppActions.vue'
import { IAction } from '@components/actions'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'

const props = defineProps<{
  loadCarrierId: ID
}>()

const { isLoading, withAsyncLoading } = useLoading()
const loadCarrier = ref<ILoadCarrier | null>(null)
const { getLoadCarrierById } = useProjectPick()
const { onActionClick } = useAction()

const actions = ref<IAction>([])

onMounted(async () => {
  await withAsyncLoading(async () => {
    loadCarrier.value = await getLoadCarrierById({ id: props.loadCarrierId })

    actions.value.push({
      name: 'Putaway',
      icon: 'pallet-boxes',
      href: `/load-carrier/${loadCarrier.value?.id}/putaway/`
    })
  })
})
</script>
