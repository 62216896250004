<template>
  <f7-page :page-content="false">
    <app-navigation />
    <f7-page-content>
      <app-breadcrumbs :breadcrumbs="breadcrumbs" />
      <f7-card :class="{ 'skeleton-text': !goodsReceiptLine || isLoading }" v-if="goodsReceiptLine">
        <f7-card-content>
          <f7-list media-list>
            <f7-list-item header="Delivered quantity">
              <input-article-quantity
                :article="goodsReceiptLine.purchaseOrderLine.article"
                :min-value="minimalQuantity"
                :value="deliveredQuantity"
                @change="onDeliveredQuantityChanged"
                auto-focus
              />
            </f7-list-item>
          </f7-list>
        </f7-card-content>
      </f7-card>
      <entry-article-details
        v-if="article"
        :article="article"
        :expected-quantity="goodsReceiptLine?.amountExpected ?? 0"
        @show-article-popup="onArticleDetailOpen"
      />
      <f7-fab v-if="goodsReceiptLine" position="center-bottom" text="Confirm" @click="onConfirm">
        <f7-icon material="done" />
      </f7-fab>
    </f7-page-content>
    <detail-popup
      v-if="article && article.articleBarcodes"
      :article="article"
      :is-loading="isLoading"
      show-stock-card
      :popup-opened="articleDetailPopupOpened"
      @popup-closed="onArticleDetailClosed"
    />
  </f7-page>
</template>
<script setup lang="ts">
import AppNavigation from '@components/AppNavigation.vue'
import { ID } from '@graphql/types'
import useGoodsReceipt from '@composables/useGoodsReceipt'
import { IGoodsReceiptLine } from '@graphql/goodsReceipt/types'
import { onMounted, ref } from 'vue'
import useLoading from '@composables/useLoading'
import EntryArticleDetails from '@pages/goods-entry/components/EntryArticleDetails.vue'
import { toast } from '@services/toast'
import DetailPopup from '@components/Article/DetailPopup.vue'
import useArticle from '@composables/useArticle'
import { IArticle } from '@graphql/article/types'
import { f7 } from 'framework7-vue'
import useProjectInboundSort from '@composables/useProjectInboundSort'
import InputArticleQuantity from '@components/form/InputArticleQuantity.vue'
import AppBreadcrumbs from '@components/AppBreadcrumbs.vue'

const props = defineProps<{
  goodsReceiptLineId: ID
}>()

const { getGoodsReceiptLine } = useGoodsReceipt()
const { withAsyncLoading, isLoading } = useLoading()
const { getArticle } = useArticle()
const { activeItem, updateProjectItem, createBreadcrumbs } = useProjectInboundSort()

const breadcrumbs = createBreadcrumbs(activeItem.value, {
  href: '#',
  title: 'Enter quantity'
})

const goodsReceiptLine = ref<IGoodsReceiptLine>()
const deliveredQuantity = ref<number>(0)
const minimalQuantity = 1
const articleDetailPopupOpened = ref(false)

const article = ref<IArticle>()

const onConfirm = async () => {
  if (deliveredQuantity.value < minimalQuantity) {
    await toast.error(`Value should be greater or equal than ${minimalQuantity}`).open()
    return
  }

  activeItem.value.todoItem.doneQuantity += deliveredQuantity.value
  activeItem.value.quantityEntered = deliveredQuantity.value

  await updateProjectItem(activeItem.value)

  await f7.views.main.router.navigate('/inbound/sort/redirect/')
}

const onDeliveredQuantityChanged = (value: number) => {
  deliveredQuantity.value = value
}

const onArticleDetailClosed = async () => {
  articleDetailPopupOpened.value = false
}

const onArticleDetailOpen = async () => {
  await withAsyncLoading(async () => {
    article.value = await getArticle({ id: article.value.id })
    articleDetailPopupOpened.value = true
  })
}

onMounted(async () => {
  await withAsyncLoading(async () => {
    goodsReceiptLine.value = await getGoodsReceiptLine(Number(props.goodsReceiptLineId))
    article.value = goodsReceiptLine.value.purchaseOrderLine.article
    deliveredQuantity.value = goodsReceiptLine.value.amountExpected ?? 0
  })
})
</script>
