<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="$emit('popup-closed', true)"
    :tablet-fullscreen="true"
    class="article-detail-popup"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-title> Article details</f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>
            <f7-icon material="close" />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <information-card :article="article" :is-loading="isLoading" :use-barcode-accordion="true" />
      <article-stock-card
        v-if="showStockCard && article.stockLevel"
        :article="article"
        :is-loading="isLoading"
        @load-more="onLoadMore"
      />
    </f7-page>
  </f7-popup>
</template>
<script lang="ts" setup>
import InformationCard from '@components/Article/InformationCard.vue'
import { IArticle } from '@graphql/article/types'
import ArticleStockCard from '@components/Article/StockCard.vue'

withDefaults(
  defineProps<{
    article: IArticle
    isLoading: boolean
    popupOpened: boolean
    showStockCard: boolean
  }>(),
  {
    isLoading: false,
    popupOpened: false,
    showStockCard: false
  }
)

const emit = defineEmits<{
  (e: 'popup-closed', payload: boolean)
}>()

const onLoadMore = async () => {
  emit('popup-closed', true)
}
</script>
