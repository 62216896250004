<template>
  <f7-popup id="scan-destination-popup" :opened="popupOpened" :tablet-fullscreen="true">
    <page-with-search-subscriber :is-paused="false" :scan-callback="onScan" :page-content="false">
      <f7-navbar>
        <f7-nav-title>Scan destination load carrier</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="onClose">
            <f7-icon material="close" />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-page-content>
        <f7-card>
          <f7-card-header>Source</f7-card-header>
          <f7-card-content>
            <div class="source display-flex align-items-center justify-content-space-between">
              <div class="description">
                {{ packSourceItem.name }}
              </div>
              <quantity-label class="quantity-label" :quantity="packSourceItem.quantity" />
            </div>
          </f7-card-content>
        </f7-card>
        <f7-card>
          <f7-card-header>Load carrier</f7-card-header>
          <f7-card-content>
            <scan-barcode-animation title="Scan load carrier" />
          </f7-card-content>
        </f7-card>
      </f7-page-content>
    </page-with-search-subscriber>
  </f7-popup>
</template>

<script setup lang="ts">
import { IPackSourceItem } from '@graphql/pack/types'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'
import ScanBarcodeAnimation from '@components/ScanBarcodeAnimation.vue'
import QuantityLabel from '@components/QuantityLabel.vue'
import { IEvent } from '@/utilities/scanInput'
import { perceptibleToast } from '@services/perceptibleToast'
import useLoadCarrier from '@composables/useLoadCarrier'

const popupOpened = defineModel<boolean>({ default: false, required: true })

const { isLoadCarrierBarcode } = useLoadCarrier()

defineProps<{
  packSourceItem: IPackSourceItem
}>()

const emits = defineEmits<{
  (e: 'onClosed', payload: void)
  (e: 'onDestinationHit', payload: string)
}>()

const onClose = async () => {
  popupOpened.value = false
  await emits('onClosed')
}

const onScan = async (event: IEvent) => {
  const query = String(event.payload)

  if (!isLoadCarrierBarcode(query)) {
    await perceptibleToast.error('Scan a valid load carrier.')
    return
  }

  await emits('onDestinationHit', query)
}
</script>

<style scoped>
.source {
  font-size: 32px;
  gap: 10px;

  .description {
    font-weight: bold;
  }
}
</style>
