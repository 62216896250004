import { Router } from 'framework7/framework7-types'
import ProjectsPage from '@pages/projects/ListPage.vue'
import ProjectsRelocatePage from '@pages/projects/RelocateProjectsPage.vue'
import ProjectsCountPage from '@pages/projects/CountProjectsPage.vue'
import ProjectInboundPage from '@pages/projects/InboundProjectsPage.vue'
import ProjectPickPage from '@pages/projects/PickProjectsPage.vue'
import ProjectOutboundSortPage from '@pages/projects/ProjectOutboundSortPage.vue'
import { redirectMiddleware } from '@framework7/routes/projects/redirectMiddleware'
import ProjectInboundSortPage from '@pages/projects/ProjectInboundSortPage.vue'
import PackProjectsPage from '@pages/projects/PackProjectsPage.vue'

const projects: Partial<Router.RouteParameters>[] = [
  {
    path: '/projects/',
    redirect: redirectMiddleware,
    routes: [
      {
        path: 'list/',
        component: ProjectsPage
      },
      {
        path: 'relocate/',
        component: ProjectsRelocatePage
      },
      {
        path: 'count/',
        component: ProjectsCountPage
      },
      {
        path: 'inbound/',
        component: ProjectInboundPage
      },
      {
        path: 'pack/',
        component: PackProjectsPage
      },
      {
        path: 'pick/',
        component: ProjectPickPage
      },
      {
        path: 'outbound-sort/',
        component: ProjectOutboundSortPage
      },
      {
        path: 'inbound-sort/',
        component: ProjectInboundSortPage
      }
    ]
  }
]

export default projects
