import { defineStore } from 'pinia'
import {
  IInboundSortProjectState,
  IProjectInboundSortItem
} from '@store/pinia/project-inbound-sort/types'
import { generateStoreKey } from '@store/pinia/helper'

export const useProjectInboundSortStore = defineStore('project-inbound-store', {
  state: (): IInboundSortProjectState => {
    return {
      title: 'Inbound sort',
      type: 'inbound_sort',
      items: [],
      activeProjectInboundSortItem: undefined
    }
  },
  getters: {
    isActive: (state) => state.items.length > 0,
    count: (state) => state.items.length,
    itemCount: (state) => state.items.length,
    activeItem: (state) => state.activeProjectInboundSortItem
  },
  actions: {
    add(item: IProjectInboundSortItem) {
      const index = this.items.findIndex((i) => i.goodsReceiptId === item.goodsReceiptId)

      this.activeProjectInboundSortItem = item

      if (index === -1) {
        this.items.push(item)
        return
      }

      this.items[index] = item
    },
    update(item: IProjectInboundSortItem) {
      const index = this.items.findIndex((i) => i.goodsReceiptId === item.goodsReceiptId)

      if (index === -1) {
        return
      }

      this.items[index] = item
    },
    remove(item: IProjectInboundSortItem) {
      const index = this.items.findIndex((i) => i.goodsReceiptId === item.goodsReceiptId)

      if (index !== -1) {
        this.items.splice(index, 1)
      }
    },
    clear() {
      this.items = []
    }
  },
  persist: {
    // Key can be set 'globally' but was not plug and play, see https://prazdevs.github.io/pinia-plugin-persistedstate/guide/advanced.html#global-key-option
    key: generateStoreKey('project-inbound-sort')
  }
})
