import { defineStore } from 'pinia'
import {
  IColloInput,
  IQuantityInboundColloTypeFormInput,
  IInboundState
} from '@store/pinia/inbound/types'
import {
  IRegisterReceptionMutationRequest,
  IGetReceptionByIdQueryResponse,
  IGetReceptionByIdQueryRequest,
  IGetColloByIdQueryRequest,
  IGetColloByIdQueryResponse,
  IUpdateColloMutationResponse,
  IUpdateColloMutationRequest,
  IGetUncompletedInboundSortLoadCarriersByWarehouseZoneRequest,
  IGetUncompletedInboundSortLoadCarriersByWarehouseZoneResponse,
  ICreateInboundSortLoadCarrierRequest,
  ICreateInboundSortLoadCarrierResponse,
  IAddItemToInboundSortLoadCarrierRequest,
  IAddItemToInboundSortLoadCarrierResponse,
  IMarkInboundSortLoadCarrierAsSortedRequest,
  IMarkInboundSortLoadCarrierAsSortedResponse,
  IGetPutawayItemsRequest,
  IGetInboundSortLoadCarrierItemsByLoadCarrierIdRequest,
  IGetPutawaySourceRequest,
  IPutawayItemToLocationRequest,
  IGetReceptionsQueryResponse,
  IInboundReception,
  IInboundCollo,
  IAddColliToReceptionRequest,
  IGetInboundSortLoadCarrierByIdRequest,
  IPutawayToKardexRequest
} from '@graphql/inbound/types'
import inboundApi from '@graphql/inbound'
import { generateStoreKey } from '@store/pinia/helper'
import {
  IMarkLineAsIncompleteRequest,
  IStartKardexPutawayRequest,
  IUpdateReceptionNotesRequest
} from '@graphql/kardex/types'

export const useInboundStore = defineStore('inbound', {
  state: (): IInboundState => ({
    receptionRegistration: null
  }),
  getters: {
    // reception: (state) => state.inbound.reception,
  },
  actions: {
    createReceptionRegistration(inboundReception: IInboundReception | null = null) {
      if (this.receptionRegistration) {
        throw Error('There is already an reception registration active!')
      }

      const colli: IQuantityInboundColloTypeFormInput[] = []
      let notes = null

      if (inboundReception) {
        inboundReception.colli.forEach((collo: IInboundCollo) => {
          const colloType = colli.find((formData: IQuantityInboundColloTypeFormInput) => {
            return formData.type.value === collo.type
          })

          if (colloType) {
            colloType.quantity.value += 1
            return
          }

          colli.push({
            type: {
              value: collo.type,
              errors: []
            },
            quantity: {
              value: 1,
              errors: []
            }
          })

          notes = inboundReception.notes
        })
      }

      this.receptionRegistration = {
        startedAt: new Date(),
        colli: colli,
        notes: notes
      }
    },
    addColloQuantity(input: IQuantityInboundColloTypeFormInput) {
      this.receptionRegistration?.colli.push(input)
    },
    addProcessedCollo(input: IColloInput) {
      this.receptionRegistration?.colli.push(input)
    },
    clearReception() {
      this.receptionRegistration = null
    },
    async submitReceptionRegistration(request: IRegisterReceptionMutationRequest) {
      const response = await inboundApi.registerReception(request)

      if (!response.data?.data?.registerReception) {
        throw Error()
      }

      return response.data.data.registerReception
    },
    async addColliToReception(request: IAddColliToReceptionRequest) {
      const response = await inboundApi.addColliToReception(request)

      return response.data.data.addColliToReception
    },
    async getReceptionById(request: IGetReceptionByIdQueryRequest) {
      const response = await inboundApi.getReceptionById<IGetReceptionByIdQueryResponse>(request)

      return response.data.data.getReceptionById
    },
    async getReceptions() {
      const response = await inboundApi.getReceptions<IGetReceptionsQueryResponse>()

      return response.data.data.getReceptions
    },
    async getColloById(request: IGetColloByIdQueryRequest) {
      const response = await inboundApi.getColloById<IGetColloByIdQueryResponse>(request)

      return response.data.data.getColloById
    },
    async updateCollo(request: IUpdateColloMutationRequest) {
      const response = await inboundApi.updateCollo<IUpdateColloMutationResponse>(request)

      return response.data.data.updateCollo
    },
    async getUncompletedInboundSortLoadCarriersByZone(
      request: IGetUncompletedInboundSortLoadCarriersByWarehouseZoneRequest
    ) {
      const response =
        await inboundApi.getUncompletedInboundSortLoadCarriersByZone<IGetUncompletedInboundSortLoadCarriersByWarehouseZoneResponse>(
          request
        )
      return response.data.data.getUncompletedInboundSortLoadCarriersByZone
    },
    async createInboundLoadCarrier(request: ICreateInboundSortLoadCarrierRequest) {
      const response =
        await inboundApi.createInboundSortLoadCarrier<ICreateInboundSortLoadCarrierResponse>(
          request
        )

      return response.data.data.createInboundSortLoadCarrier
    },
    async addItemToInboundSortLoadCarrier(request: IAddItemToInboundSortLoadCarrierRequest) {
      const response =
        await inboundApi.addItemToInboundSortLoadCarrier<IAddItemToInboundSortLoadCarrierResponse>(
          request
        )

      return response.data.data.addItemToInboundSortLoadCarrier
    },
    async markInboundSortLoadCarrierAsSorted(request: IMarkInboundSortLoadCarrierAsSortedRequest) {
      const response =
        await inboundApi.markInboundSortLoadCarrierAsSorted<IMarkInboundSortLoadCarrierAsSortedResponse>(
          request
        )

      return response.data.data.markInboundSortLoadCarrierAsSorted
    },
    async getPutawayItems(request: IGetPutawayItemsRequest) {
      const response = await inboundApi.getPutawayItems(request)

      return response.data.data.getPutawayItems
    },
    async getInboundSortLoadCarrierItemsByLoadCarrierId(
      request: IGetInboundSortLoadCarrierItemsByLoadCarrierIdRequest
    ) {
      const response = await inboundApi.getInboundSortLoadCarrierItemsByLoadCarrierId(request)

      return response.data.data.getInboundSortLoadCarrierItemsById
    },
    async getPutawaySource(request: IGetPutawaySourceRequest) {
      const response = await inboundApi.getPutawaySource(request)

      return response.data.data.getPutawaySource
    },
    async putawayItemToLocation(request: IPutawayItemToLocationRequest) {
      const response = await inboundApi.putawayItemToLocation(request)

      return response.data.data.putawayItemToLocation
    },
    async getInboundSortLoadCarrierById(request: IGetInboundSortLoadCarrierByIdRequest) {
      const response = await inboundApi.getInboundSortLoadCarrierById(request)

      return response.data.data.getInboundSortLoadCarrierById
    },
    async putawayToKardex(request: IPutawayToKardexRequest) {
      const response = await inboundApi.putawayToKardex(request)

      return response.data.data.putawayToKardex
    },
    async startKardexPutaway(request: IStartKardexPutawayRequest) {
      const response = await inboundApi.startKardexPutaway(request)

      return response.data.data.startKardexPutaway
    },
    async updateReceptionNotes(request: IUpdateReceptionNotesRequest) {
      const response = await inboundApi.updateReceptionNotes(request)

      return response.data.data.updateReceptionNotes
    },
    async markLineAsIncomplete(request: IMarkLineAsIncompleteRequest) {
      const response = await inboundApi.markLineAsIncomplete(request)

      return response.data.data.markLineAsIncomplete
    }
  },
  persist: {
    // Key can be set 'globally' but was not plug and play, see https://prazdevs.github.io/pinia-plugin-persistedstate/guide/advanced.html#global-key-option
    key: generateStoreKey(`inbound`)
  }
})
