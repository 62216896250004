import { useProjectInboundSortStore } from '@store/pinia/project-inbound-sort/store'
import { IProjectInboundSortItem } from '@store/pinia/project-inbound-sort/types'
import { Router } from 'framework7/types'

export const findRedirectUrl = (item: IProjectInboundSortItem) => {
  if (!item.todoItem) {
    return `/inbound/sort/${item.goodsReceiptId}/process/`
  }

  const goodsReceiptLineId = item.todoItem.goodsReceiptLineId
  const labelingRequired = item.todoItem.article.inboundLabelingRequired

  if (item.todoItem.article.weight === 0 && item.weightEntered === 0) {
    return `/inbound/sort/${goodsReceiptLineId}/process/enter-weight/`
  }

  if (item.quantityEntered > 0) {
    if (labelingRequired && !item.labelPrinted) {
      return `/inbound/sort/${goodsReceiptLineId}/process/print-labels/`
    }

    return `/inbound/sort/${goodsReceiptLineId}/process/warehouse-zone/suggestion/`
  }

  return `/inbound/sort/${goodsReceiptLineId}/process/enter-quantity/`
}

export const redirectMiddleware = async ({ resolve }: Router.RouteCallbackCtx) => {
  const store = useProjectInboundSortStore()

  const activeItem = store.activeItem

  let url = '/goodsreceipt/'
  if (activeItem) {
    url = findRedirectUrl(activeItem)
  }

  resolve(url)
}
