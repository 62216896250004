import { icons } from '@/fontawesome/icons'
import {
  APPLICATION_ENVIRONMENT,
  APPLICATION_VERSION,
  SENTRY_ENABLED,
  SENTRY_REPLAY_SESSION_SAMPLE_RATE
} from '@config/environment'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import * as Sentry from '@sentry/vue'
import { key, store } from '@store/store'
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle'
import Framework7 from 'framework7/lite/bundle'
import 'framework7/less/bundle'
import '@/assets/css/style.less'
import 'skeleton-elements/css/skeleton-elements.css'
import { createApp } from 'vue'
import App from './App.vue'
import ArticleSearchResult from '@components/search/ArticleResult.vue'
import ColloSearchResult from '@components/search/ColloResult.vue'
import GoodsReceiptSearchResult from '@components/search/GoodsReceiptResult.vue'
import LocationSearchResult from '@components/search/LocationResult.vue'
import PurchaseOrderSearchResult from '@components/search/PurchaseOrderResult.vue'
import PickBatchSearchResult from '@components/search/PickBatchResult.vue'
import LoadCarrierSearchResult from '@components/search/LoadCarrierResult.vue'
import GoodsReceiptLineResult from '@components/search/GoodsReceiptLineResult.vue'
import InboundReceptionSearchResult from '@components/search/InboundReceptionResult.vue'
import InboundColloSearchResult from '@components/search/InboundColloResult.vue'
import LocationHeightResult from '@components/parser-result/LocationHeight.vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import Vue3Lottie from 'vue3-lottie'

library.add(...icons)

Framework7.use(Framework7Vue)

const app = createApp(App)

app.component('FontAwesomeIcon', FontAwesomeIcon)
app.use(Vue3Lottie)

Sentry.init({
  app,
  dsn: 'https://81e23bf371a04a6db96f17e2cc6a1283@o72155.ingest.sentry.io/5357599',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      useCompression: false // https://github.com/getsentry/sentry/discussions/39460#discussioncomment-4660265
    })
  ],
  tracingOptions: {
    trackComponents: true
  },
  environment: APPLICATION_ENVIRONMENT,
  release: 'supportscan@' + APPLICATION_VERSION,
  enabled: SENTRY_ENABLED,
  replaysSessionSampleRate: SENTRY_REPLAY_SESSION_SAMPLE_RATE,
  replaysOnErrorSampleRate: 1.0
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

app.use(store, key)
app.use(pinia)

registerComponents(app)

app.component('ArticleSearchResult', ArticleSearchResult)
app.component('ColloSearchResult', ColloSearchResult)
app.component('GoodsReceiptSearchResult', GoodsReceiptSearchResult)
app.component('LocationSearchResult', LocationSearchResult)
app.component('LoadCarrierSearchResult', LoadCarrierSearchResult)
app.component('PurchaseOrderSearchResult', PurchaseOrderSearchResult)
app.component('PickBatchSearchResult', PickBatchSearchResult)
app.component('InboundReceptionSearchResult', InboundReceptionSearchResult)
app.component('InboundColloSearchResult', InboundColloSearchResult)
app.component('LocationHeightResult', LocationHeightResult)
app.component('GoodsReceiptLineSearchResult', GoodsReceiptLineResult)

app.mount('#app')
