<template>
  <f7-page :page-content="false">
    <app-navigation />
    <f7-page-content class="no-z-index">
      <app-breadcrumbs :breadcrumbs="breadcrumbs" />
      <entry-detail-with-detail-popup
        v-if="activeItem?.todoItem"
        :quantity="activeItem.quantityEntered ?? 0"
        :article="activeItem.todoItem.article"
      />
      <f7-card>
        <f7-card-content class="zone-suggestion">
          <prefix-block large :prefix="selectedZone?.prefix ?? ''" />
          <f7-list class="zone-selection">
            <input type="hidden" autofocus />
            <f7-list-input
              :class="{ 'skeleton-text': isLoading }"
              label="Zone"
              type="select"
              :value="zoneId"
              required
              error-message="Zone can't be empty..."
              @input="onZoneSelect(Number($event.target.value))"
              placeholder="Select a zone..."
            >
              <template
                v-if="
                  zoneSuggestion?.warehouseZone.functionalType ===
                  FunctionalType.QUALITY_CONTROL.toString()
                "
              >
                <option :value="zone.id" v-for="zone in qualityControlZones" :key="zone.id">
                  {{ zone.name }}
                </option>
              </template>
              <option :value="zone.id" v-for="zone in storageZones" :key="zone.id">
                {{ zone.name }}
              </option>
            </f7-list-input>
          </f7-list>
        </f7-card-content>
      </f7-card>
      <location-type-select
        v-if="selectedZone && selectedZone.requiresLocationTypeSelection"
        :warehouse-zone="selectedZone"
        @location-type-selected="onLocationTypeSelect"
      />
      <template
        v-if="
          (selectedZone && !selectedZone.requiresLocationTypeSelection) ||
          (selectedZone && selectedZone.requiresLocationTypeSelection && selectedLocationType)
        "
      >
        <f7-card>
          <scan-barcode-animation title="Scan compartment" />
        </f7-card>
        <div class="bottom-spacing">
          <inbound-sort-load-carrier-list
            title="Available load carriers"
            :is-loading="isLoadingCarriers"
            :load-carriers="loadCarriers"
            @start-marking-sorted="scanbusSubscriber.pause()"
            @stopped-marking-sorted="resumeSubscriber"
            @marked-sorted="onMarkedSorted"
          />
        </div>
      </template>
    </f7-page-content>
    <create-load-carrier-sheet
      v-if="selectedZone && showLoadCarrierCreationSheet"
      v-model="showLoadCarrierCreationSheet"
      @update:model-value="resumeSubscriber"
      :zone="selectedZone"
      @load-carrier-created="onLoadCarrierCreated"
    ></create-load-carrier-sheet>
    <f7-fab
      v-if="selectedZone && !createdLoadCarrier"
      position="right-bottom"
      @click="openLoadCarrierCreationSheet"
    >
      <font-awesome-icon :icon="['far', 'plus']" />
    </f7-fab>
  </f7-page>
  <load-carrier-scan-to-print
    v-if="createdLoadCarrier"
    :verification-popup-opened="verificationPopupOpened"
    @print-documents="onPrintVerificationLabel"
    @confirm-barcode="onVerificationScan"
    @verification-popup-closed="verificationPopupOpened = false"
  >
    <template #page-title>
      Create inbound sort load carrier for zone {{ selectedZone?.name }}
    </template>
    <template #page-content>
      <icon-with-text
        :icon="['far', 'print']"
        title="Scan a nearby label printer"
        description="Print the labels to finalize the creation of a new load carrier. And place the label on the corresponding load carrier."
      />
    </template>
  </load-carrier-scan-to-print>
</template>

<script setup lang="ts">
import AppNavigation from '@components/AppNavigation.vue'
import { ID } from '@graphql/types'
import { onMounted, onUnmounted, ref } from 'vue'
import useGoodsReceipt from '@composables/useGoodsReceipt'
import { IGoodsReceiptLine } from '@graphql/goodsReceipt/types'
import useLoading from '@composables/useLoading'
import { FunctionalType, IWarehouseZone, IWarehouseZoneSuggestion } from '@graphql/warehouse/types'
import useWarehouse from '@composables/useWarehouse'
import useInbound from '@composables/useInbound'
import { IInboundSortLoadCarrier } from '@graphql/inbound/types'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CreateLoadCarrierSheet from '@pages/inbound/components/CreateLoadCarrierSheet.vue'
import IconWithText from '@components/IconWithText.vue'
import { eventBus } from '@/utilities/scanInput'
import { DocumentKind, PrintContext } from '@graphql/document/types'
import { TypeName } from '@graphql/search/types'
import { perceptibleToast } from '@services/perceptibleToast'
import { soundBoard } from '@services/sound'
import useDocument from '@composables/useDocument'
import LoadCarrierScanToPrint from '@components/LoadCarrierScanToPrint.vue'
import { f7 } from 'framework7-vue'
import PrefixBlock from '@components/PrefixBlock.vue'
import { captureEvent } from '@sentry/vue'
import useProjectInboundSort from '@composables/useProjectInboundSort'
import ScanBarcodeAnimation from '@components/ScanBarcodeAnimation.vue'
import EntryDetailWithDetailPopup from '@components/Article/EntryDetailWithDetailPopup.vue'
import InboundSortLoadCarrierList from '@pages/inbound/components/InboundSortLoadCarrierList.vue'
import { confirmYesNo } from '@/functions/dialog'
import LocationTypeSelect from '@pages/inbound/components/LocationTypeSelect.vue'
import { ILocationType } from '@graphql/location/types'
import AppBreadcrumbs from '@components/AppBreadcrumbs.vue'

//TODO: this page must be trimmed down!!!
const { withAsyncLoading, isLoading } = useLoading()
const { getGoodsReceiptLine, getWarehouseZoneSuggestionByGoodsReceiptLine } = useGoodsReceipt()
const { getWarehouseZones } = useWarehouse()
const { getUncompletedInboundSortLoadCarriersByZone, addItemToInboundSortLoadCarrier } =
  useInbound()
const { printDocumentsFor } = useDocument()
const { activeItem, updateProjectItem, createBreadcrumbs } = useProjectInboundSort()

const breadcrumbs = createBreadcrumbs(activeItem.value, {
  href: '#',
  title: 'Zone suggestion'
})

const goodsReceiptLine = ref<IGoodsReceiptLine>()
const zoneSuggestion = ref<IWarehouseZoneSuggestion | null>(null)
const storageZones = ref<IWarehouseZone[]>([])
const qualityControlZones = ref<IWarehouseZone[]>([])
const selectedZone = ref<IWarehouseZone>()
const selectedLocationType = ref<ILocationType | null>(null)
const isLoadingCarriers = ref<boolean>(true)
const loadCarriers = ref<IInboundSortLoadCarrier[]>([])
const showLoadCarrierCreationSheet = ref<boolean>(false)
const createdLoadCarrier = ref<IInboundSortLoadCarrier | null>(null)
const verificationPopupOpened = ref<boolean>(false)

const props = defineProps<{
  goodsReceiptLineId: ID
}>()

const zoneId = ref<ID | null>(null)
const amount = ref(0)

const onLoadCarrierCreated = async (loadCarrier: IInboundSortLoadCarrier) => {
  createdLoadCarrier.value = loadCarrier

  try {
    await printDocumentsFor({
      context: new PrintContext(
        createdLoadCarrier.value.id,
        TypeName.LoadCarrier,
        DocumentKind.INBOUND_SORT_LABELS,
        null
      )
    })

    verificationPopupOpened.value = true
  } catch (e) {
    captureEvent(e)

    await perceptibleToast.error('Something went wrong with printing the label. Try to reprint')
  } finally {
    scanbusSubscriber.pause()
  }
}

const onLocationTypeSelect = (locationType: ILocationType) => {
  selectedLocationType.value = locationType

  resumeSubscriber()
}

const onZoneSelect = async (value: ID) => {
  if (
    zoneSuggestion.value?.requiresConfirmation &&
    selectedZone.value?.id === zoneSuggestion.value.warehouseZone.id
  ) {
    scanbusSubscriber.pause()
    confirmYesNo({
      title: 'Are you sure you want to change the zone?',
      yesButtonCallback: async () => {
        await selectZone(value)
        resumeSubscriber()
      },
      noButtonCallback: () => {
        resumeSubscriber()
      }
    })
    return
  }

  await selectZone(value)
}

const selectZone = async (value: ID) => {
  zoneId.value = value
  selectedLocationType.value = null

  selectedZone.value = [...storageZones.value, ...qualityControlZones.value].find(
    (zone: IWarehouseZone) => zone.id === zoneId.value.toString()
  )

  await retrieveUncompletedLoadCarriersForZone(zoneId.value)

  resumeSubscriber()
}

const retrieveUncompletedLoadCarriersForZone = async (zoneId: ID) => {
  isLoadingCarriers.value = true

  const response = await getUncompletedInboundSortLoadCarriersByZone({
    warehouseZoneId: zoneId
  })

  loadCarriers.value = response.edges.map((edge) => edge.node)

  isLoadingCarriers.value = false
}

const openLoadCarrierCreationSheet = () => {
  scanbusSubscriber.pause()
  showLoadCarrierCreationSheet.value = true
}

const scanbusSubscriber = eventBus.subscribe(
  'scan_compartment',
  'scanInput',
  async (event) => {
    const compartmentRegex = /^\d{1,3}-[0-9A-Z]{3}-[A-Z]-\d{2}$/

    if (String(event.payload).match(compartmentRegex)) {
      await addToCompartment(String(event.payload))
      return
    }

    await perceptibleToast.error(`Could not find compartment with code ${event.payload}`)
  },
  true
)

const addToCompartment = async (compartmentReference: string) => {
  try {
    const success = await addItemToInboundSortLoadCarrier({
      goodsReceiptLineId: props.goodsReceiptLineId,
      amount: amount.value,
      compartmentReference: compartmentReference,
      articleWeight: activeItem.value.weightEntered,
      locationTypeId: selectedLocationType.value?.id
    })

    if (success) {
      await perceptibleToast.success(
        `Item successfully placed in compartment "${compartmentReference}"`
      )

      unsubscribe()

      activeItem.value.todoItem = null
      activeItem.value.quantityEntered = 0
      await updateProjectItem(activeItem.value)

      f7.views.main.router.navigate('/inbound/sort/redirect/', {
        clearPreviousHistory: true
      })
    }
  } catch (e) {
    captureEvent(e)

    await perceptibleToast.error(e.message)
  }
}

const onPrintVerificationLabel = async (printerCode: string) => {
  await withAsyncLoading(async () => {
    await printDocumentsFor({
      context: new PrintContext(
        createdLoadCarrier.value.id,
        TypeName.LoadCarrier,
        DocumentKind.INBOUND_SORT_LABELS,
        printerCode
      )
    })
    await soundBoard.playSuccessSound()
    verificationPopupOpened.value = true
  })
}

const onVerificationScan = async (verificationCode: string) => {
  if (
    verificationCode !== createdLoadCarrier.value?.id &&
    verificationCode !== createdLoadCarrier.value?.verificationCode
  ) {
    await perceptibleToast.error('Invalid verification code')
    return
  }
  resumeSubscriber()
  verificationPopupOpened.value = false
  await perceptibleToast.success(
    `Succesfully added new load carrier "${createdLoadCarrier.value.reference}" for sorting`
  )
  createdLoadCarrier.value = null
  await retrieveUncompletedLoadCarriersForZone(Number(selectedZone.value?.id))
}

const onMarkedSorted = async () => {
  resumeSubscriber()
  await retrieveUncompletedLoadCarriersForZone(Number(selectedZone.value?.id))
}

const unsubscribe = () => {
  eventBus.unsubscribe(scanbusSubscriber)
}

onMounted(async () => {
  amount.value = activeItem.value.quantityEntered

  await withAsyncLoading(async () => {
    goodsReceiptLine.value = await getGoodsReceiptLine(Number(props.goodsReceiptLineId))

    const allZones = (await getWarehouseZones()).edges.map((edge) => edge.node)
    storageZones.value = allZones.filter(
      //TODO: filter on backend...
      (zone: IWarehouseZone) =>
        [FunctionalType.STORAGE.toString(), FunctionalType.KARDEX_STORAGE.toString()].includes(
          zone.functionalType
        )
    )
    qualityControlZones.value = allZones.filter(
      (zone: IWarehouseZone) => zone.functionalType === FunctionalType.QUALITY_CONTROL.toString()
    )

    try {
      zoneSuggestion.value = await getWarehouseZoneSuggestionByGoodsReceiptLine({
        goodsReceiptLineId: Number(props.goodsReceiptLineId)
      })
    } catch (e) {
      await perceptibleToast.error(e.message)
    }

    if (!zoneSuggestion.value) {
      isLoadingCarriers.value = false
      return
    }

    zoneId.value = zoneSuggestion.value?.warehouseZone.id
    selectedZone.value = zoneSuggestion.value?.warehouseZone

    resumeSubscriber()

    await retrieveUncompletedLoadCarriersForZone(Number(zoneSuggestion.value?.warehouseZone.id))
  })
})

const resumeSubscriber = () => {
  if (!selectedZone.value) {
    return
  }

  if (selectedZone.value.requiresLocationTypeSelection && !selectedLocationType.value) {
    return
  }

  scanbusSubscriber.resume()
}

onUnmounted(() => {
  unsubscribe()
})
</script>

<style scoped>
.zone-suggestion {
  display: flex;
  gap: 10px;
  align-items: center;
}

.zone-selection {
  flex: 1;
}

.bottom-spacing {
  padding-bottom: 64px;
}
</style>
