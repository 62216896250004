import { ActionTree } from 'vuex'
import {
  IBulkSortRequest,
  IGetBulkSortableItemsRequest,
  IGetLoadCarrierByReferenceRequest,
  IGetPickBatchSortStateRequest,
  IGetSortResultRequest,
  IMarkPickBatchSortedRequest,
  IMarkSortLoadCarrierSortedRequest,
  IPutItemInCompartmentRequest,
  IOutboundSortState,
  IWantsNewCompartmentRequest
} from '@graphql/sort/types'
import { IRootState } from '@store/types'
import outboundSortApi from '@graphql/outbound-sort'

const actions: ActionTree<IOutboundSortState, IRootState> = {
  async getLoadCarrierByReference(context, request: IGetLoadCarrierByReferenceRequest) {
    const response = await outboundSortApi.getLoadCarrierByReference(request)

    return response.data.data.getLoadCarrierByReference
  },
  async putItemInCompartment(context, request: IPutItemInCompartmentRequest) {
    const response = await outboundSortApi.putItemInCompartment(request)

    return response.data.data.putItemInCompartment
  },
  async wantsNewCompartment(context, request: IWantsNewCompartmentRequest) {
    const response = await outboundSortApi.wantsNewCompartment(request)

    return response.data.data.wantsNewCompartment
  },
  async getSortResult(context, request: IGetSortResultRequest) {
    const response = await outboundSortApi.getSortResult(request)

    return response.data.data.getSortResult
  },
  async getPickBatchSortState(context, request: IGetPickBatchSortStateRequest) {
    const response = await outboundSortApi.getPickBatchSortState(request)

    return response.data.data.getPickBatchSortState
  },
  async markSortLoadCarrierSorted(context, request: IMarkSortLoadCarrierSortedRequest) {
    const response = await outboundSortApi.markSortLoadCarrierSorted(request)

    return response.data.data.markSortLoadCarrierSorted
  },
  async markPickBatchSorted(context, request: IMarkPickBatchSortedRequest) {
    const response = await outboundSortApi.markPickBatchSorted(request)

    return response.data.data.markPickBatchSorted
  },
  async getBulkSortableItems(context, request: IGetBulkSortableItemsRequest) {
    const response = await outboundSortApi.getBulkSortableItems(request)

    return response.data.data.getBulkSortableItems
  },
  async bulkSort(context, request: IBulkSortRequest) {
    const response = await outboundSortApi.bulkSort(request)

    return response.data.data.bulkSort
  }
}

export default actions
