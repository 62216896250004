<template>
  <page-with-search-subscriber v-slot="{ searchSubscriber }" :page-content="false">
    <navigation show-search-icon v-slot="{ searchId }">
      <search-bar
        :search-strategy="SearchStrategy.DefaultWithoutRouting"
        :scan-subscriber="searchSubscriber"
        :search-id="searchId"
        :search-type="TypeName.GoodsReceiptLine"
        :search-filters="searchFilters"
        expandable
        @results="onSearchResults"
        @result="onSearchResult"
        @no-result="onNoSearchResult"
      />
    </navigation>
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#ge-todo" tab-link-active>Todo ({{ todoCount }})</f7-link>
      <f7-link tab-link="#ge-done">Done ({{ doneCount }})</f7-link>
      <f7-link tab-link="#ge-details">Details</f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="ge-todo" class="page-content" tab-active>
        <f7-card v-if="goodsReceipt" :class="{ 'skeleton-text': isLoading }">
          <f7-card-content>
            <f7-list media-list v-if="todoCount > 0">
              <template v-if="goodsEntryItemsTodo.edges.length > 0">
                <goods-entry-line
                  :goods-receipt-line="goodsReceiptLineEdge.node"
                  v-for="goodsReceiptLineEdge in goodsEntryItemsTodo.edges"
                  :key="goodsReceiptLineEdge.cursor"
                  @article-detail="onArticleDetail"
                  @goods-entry="onLineClick"
                  @print-article-label="onPrintArticleLabel"
                >
                  <template #quantity>
                    {{
                      goodsReceiptLineEdge.node.amountExpected -
                      getDeliveredQty(goodsReceiptLineEdge.node)
                    }}
                  </template>
                  <template #quantities>
                    {{ getDeliveredQty(goodsReceiptLineEdge.node) }} /
                    {{ goodsReceiptLineEdge.node.amountExpected }} processed
                  </template>
                </goods-entry-line>
              </template>
            </f7-list>
            <icon-with-text
              v-else
              :icon="['far', 'clipboard-check']"
              title="All items have been processed!"
              description="If you are ready you can mark the inbound as 'Book ready' to close this task on your scanner."
            />
          </f7-card-content>
        </f7-card>
      </f7-tab>
      <f7-tab id="ge-done" class="page-content">
        <f7-block-title class="margin-top">Overview of processed items</f7-block-title>
        <f7-card :class="{ 'skeleton-text': isLoading }">
          <f7-card-content>
            <f7-list media-list v-if="doneCount > 0">
              <template v-if="goodsEntryItemsDone.edges.length > 0">
                <goods-entry-line
                  :goods-receipt-line="goodsReceiptLineEdge.node"
                  v-for="goodsReceiptLineEdge in goodsEntryItemsDone.edges"
                  :key="goodsReceiptLineEdge.cursor"
                  @article-detail="onArticleDetail"
                />
              </template>
            </f7-list>
            <icon-with-text
              v-else
              :icon="['far', 'empty-set']"
              title="No processed items"
              description="Process some items before something is visible on this page."
            />
          </f7-card-content>
        </f7-card>
      </f7-tab>
      <f7-tab id="ge-details" class="page-content">
        <goods-entry-details-tab
          v-if="goodsReceipt"
          :is-loading="isLoading"
          :goods-receipt="goodsReceipt"
        />
      </f7-tab>
    </f7-tabs>
    <app-actions :actions="actions" @action-clicked="onActionClick" />
  </page-with-search-subscriber>
  <detail-popup
    v-if="selectedArticle"
    :article="selectedArticle"
    :is-loading="isLoading"
    show-stock-card
    :popup-opened="articleDetailPopupOpened"
    @popup-closed="onArticleDetailClosed"
  />
  <print-article-label-popup
    v-if="selectedGoodsReceiptLine"
    :popup-opened="printArticleLabelPopupOpened"
    :goods-receipt-line="selectedGoodsReceiptLine"
    :print-amount="selectedGoodsReceiptLine.amountExpected"
    @popup-closed="onPrintArticleLabelPopupClosed"
  />
  <goods-entry-search-results-popup
    v-if="searchResultsPopupOpened"
    :search-results="searchResults"
    :popup-opened="searchResultsPopupOpened"
    @popup-closed="onSearchResultsPopupClosed"
    @search-result="onSearchResult"
  />
</template>
<script lang="ts" setup>
import { IAction } from '@components/actions'
import AppActions from '@components/AppActions.vue'
import Navigation from '@components/AppNavigation.vue'
import SearchBar from '@components/search/SearchBar.vue'
import useGoodsReceipt from '@composables/useGoodsReceipt'
import useLoading from '@composables/useLoading'
import useProjectInbound from '@composables/useProjectInbound'
import {
  IGoodsReceipt,
  IGoodsReceiptLine,
  IGoodsReceiptLineConnection,
  StateEnum
} from '@graphql/goodsReceipt/types'
import { notification } from '@services/notification'
import { ISearchFilter, SearchStrategy } from '@services/search/search'
import { toast } from '@services/toast'
import { f7 } from 'framework7-vue'
import { computed, onMounted, ref } from 'vue'
import IconWithText from '@components/IconWithText.vue'
import { TypeName } from '@graphql/search/types'
import { IArticle } from '@graphql/article/types'
import GoodsEntryLine from '@pages/goods-entry/components/GoodsEntryLine.vue'
import GoodsEntryDetailsTab from '@pages/goods-entry/components/GoodsEntryDetailsTab.vue'
import DetailPopup from '@components/Article/DetailPopup.vue'
import useArticle from '@composables/useArticle'
import PrintArticleLabelPopup from '@pages/goods-entry/components/PrintArticleLabelPopup.vue'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'
import { UnionTypeSearchResult } from '@store/modules/search/types'
import GoodsEntrySearchResultsPopup from '@pages/goods-entry/components/GoodsEntrySearchResultsPopup.vue'
import { ID } from '@graphql/types'

const props = withDefaults(
  defineProps<{
    id: ID
    item?: IGoodsReceipt
  }>(),
  {
    item: undefined
  }
)

const { isLoading, withPreloaderAsync } = useLoading()
const {
  goodsEntries: goodsReceipt,
  getGoodsEntries,
  removeProject,
  markAsBookReady
} = useProjectInbound()
const { getGoodsReceiptLine, getDeliveredQty } = useGoodsReceipt()
const { getArticle } = useArticle()

isLoading.value = true

const actions = ref<IAction[]>([])
const articleDetailPopupOpened = ref(false)
const selectedArticle = ref<IArticle | null>(null)
const printArticleLabelPopupOpened = ref(false)
const selectedGoodsReceiptLine = ref<IGoodsReceiptLine | null>(null)
const searchResultsPopupOpened = ref(false)
const searchResults = ref<UnionTypeSearchResult[]>([])
const searchFilters: ISearchFilter[] = [
  {
    key: 'receiptId',
    operator: '=',
    value: props.id
  }
]

const goodsEntryItemsTodo = computed<IGoodsReceiptLineConnection | null>(() => {
  if (!goodsReceipt.value) {
    return null
  }

  return goodsReceipt.value.goodsReceiptLineTodo
})

const goodsEntryItemsDone = computed<IGoodsReceiptLineConnection | null>(() => {
  if (!goodsReceipt.value) {
    return null
  }

  if (!goodsReceipt.value.goodsReceiptLineDone) {
    return null
  }

  return goodsReceipt.value.goodsReceiptLineDone
})

const doneCount = computed<number>(() =>
  goodsEntryItemsDone.value ? Number(goodsEntryItemsDone.value.totalCount) : 0
)
const todoCount = computed<number>(() =>
  goodsEntryItemsTodo.value ? Number(goodsEntryItemsTodo.value.totalCount) : 0
)

onMounted(async () => {
  actions.value.push({
    name: 'Close project',
    href: '/projects/inbound/close-project/'
  })

  try {
    await getGoodsEntries({
      id: props.id,
      perPage: 50,
      completed: false
    })
  } catch (e: any) {
    toast.error(e.message, undefined, true).open()
    await onRemove(false)
  }

  isLoading.value = false

  if (goodsReceipt.value && goodsReceipt.value.state < StateEnum.READY_FOR_BOOKING) {
    actions.value.push({
      name: 'Book ready',
      icon: 'assignment_turned_in',
      href: '/goods-receipt/book-ready/'
    })
  }
})

const onSearchResult = async (result: IGoodsReceiptLine) => {
  await onSearchResultsPopupClosed()
  await onLineClick(result)
}

const onNoSearchResult = async (query: string) => {
  await toast.error(`No article with barcode "${query}" found in this project!`).open()
}

const onBookReady = async () => {
  try {
    await withPreloaderAsync(async () => await markAsBookReady({ id: props.id }))

    await notification
      .success({ message: `Goods receipt "${props.id}" marked as book ready` })
      .open()

    return f7.views.main.router.navigate('/home/')
  } catch (e: any) {
    await toast.error(e.message).open()
  }
}

const onRemove = async (showNotification = true) => {
  await removeProject(props.id)
  if (showNotification) {
    await notification.success({ message: `Inbound project "${props.id}" closed` }).open()
  }

  return f7.views.main.router.navigate('/home/')
}

const onActionClick = async (action: IAction) => {
  if (action.href === '/goods-receipt/book-ready/') {
    await f7.dialog.confirm('Are you sure that you want to mark this project as book ready?', () =>
      onBookReady()
    )
    return
  }

  if (action.href === '/projects/inbound/close-project/') {
    await f7.dialog.confirm('Are you sure that you want to close this project?', () => onRemove())
    return
  }

  return f7.views.main.router.navigate(action.href)
}

const onLineClick = async (goodsReceiptLine: IGoodsReceiptLine) => {
  await withPreloaderAsync(
    async () => (goodsReceiptLine = await getGoodsReceiptLine(goodsReceiptLine.id))
  )

  if (goodsReceiptLine.purchaseOrderLine.article.weight > 0 || goodsReceiptLine.articleWeight > 0) {
    return f7.views.main.router.navigate('/goods-entry-item/', {
      props: {
        companyId: (goodsReceipt.value as IGoodsReceipt).supplier.id,
        warehouseId: (goodsReceipt.value as IGoodsReceipt).warehouse?.id,
        item: goodsReceiptLine
      }
    })
  }

  await f7.views.main.router.navigate('/goods-entry-weight/', {
    props: {
      item: goodsReceiptLine
    }
  })
}

const onArticleDetail = async (article: IArticle) => {
  selectedArticle.value = await getArticle({ id: article.id })
  articleDetailPopupOpened.value = true
}

const onArticleDetailClosed = async () => {
  articleDetailPopupOpened.value = false
  selectedArticle.value = null
}

const onSearchResults = async (results: UnionTypeSearchResult[]) => {
  searchResultsPopupOpened.value = true
  searchResults.value = results
}

const onSearchResultsPopupClosed = async () => {
  searchResultsPopupOpened.value = false
  searchResults.value = []
}

const onPrintArticleLabel = async (goodsReceiptLine: IGoodsReceiptLine) => {
  selectedGoodsReceiptLine.value = goodsReceiptLine
  printArticleLabelPopupOpened.value = true
}

const onPrintArticleLabelPopupClosed = async () => {
  selectedGoodsReceiptLine.value = null
  printArticleLabelPopupOpened.value = false
}
</script>
