import { APPLICATION_ENVIRONMENT } from '@config/environment'

export const generateStoreKey = (store: string) =>
  `nl.dimass.scanner.${APPLICATION_ENVIRONMENT}.${store}`

export const migrateStoreData = (store: string) => {
  const piniaStoreName = store.replace('/', '.')
  const piniaStoreKey = generateStoreKey(piniaStoreName)

  if (localStorage.getItem(piniaStoreKey)) {
    return
  }

  const vuexStoreKey = `nl.dimass.scanner-${APPLICATION_ENVIRONMENT}`
  const vuexStateData = localStorage.getItem(vuexStoreKey)

  if (!vuexStateData) {
    return
  }

  const vuexJson = JSON.parse(vuexStateData)

  localStorage.setItem(piniaStoreKey, JSON.stringify(vuexJson[store]))
}
