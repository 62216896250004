<template>
  <f7-list-item swipeout @swipeout:opened="onOverSwipe">
    <template #title>
      {{ searchResult.typeForHuman }} load carrier #{{ searchResult.searchPreview }}
    </template>
    <template #media>
      <search-result-icon :icon="['far', 'cart-flatbed-boxes']" />
    </template>
    <template #subtitle>
      <date-time-label :value="searchResult.createdAt" />
      <string-label
        v-if="searchResult.pickBatch"
        :value="`Pick batch #${searchResult.pickBatch.id}`"
      />
      <br />
    </template>
    <slot name="actions"></slot>
  </f7-list-item>
</template>
<script lang="ts" setup>
import { ILoadCarrier } from '@graphql/pick/types'
import StringLabel from '@components/label/StringLabel.vue'
import DateTimeLabel from '@components/label/DateTime.vue'
import SearchResultIcon from '@components/search/SearchResultIcon.vue'

const props = defineProps<{
  searchResult: Partial<ILoadCarrier>
}>()

const emit = defineEmits<{
  (e: 'over-swipe', result: Partial<ILoadCarrier>): void
}>()

const onOverSwipe = async () => emit('over-swipe', props.searchResult)
</script>
