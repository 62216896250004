<template>
  <entry-article-details
    :article="article"
    :expected-quantity="quantity"
    @show-article-popup="onArticleDetailOpen"
  />
  <detail-popup
    :article="article"
    show-stock-card
    :is-loading="false"
    :popup-opened="articleDetailPopupOpened"
    @popup-closed="onPopupClosed"
  />
</template>

<script setup lang="ts">
import EntryArticleDetails from '@pages/goods-entry/components/EntryArticleDetails.vue'
import DetailPopup from '@components/Article/DetailPopup.vue'
import { ref } from 'vue'
import { IArticle } from '@graphql/article/types'

const articleDetailPopupOpened = ref<boolean>(false)

defineProps<{
  article: IArticle
  quantity: number
}>()

const onArticleDetailOpen = () => {
  articleDetailPopupOpened.value = true
}

const onPopupClosed = () => {
  articleDetailPopupOpened.value = false
}
</script>

<style scoped></style>
