<template>
  <f7-page :page-content="false">
    <navigation>
      <template #page-title>
        <f7-nav-title>
          <string-label class="padding-right" value="Inbound Collo" />
          <font-awesome-icon :icon="['far', 'truck-ramp-box']" />
        </f7-nav-title>
      </template>
    </navigation>
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#ibc-detail" tab-link-active>Details</f7-link>
      <f7-link tab-link="#ibc-goods-receipts">Goods Receipts</f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="ibc-detail" class="page-content" tab-active>
        <collo-details-card :inbound-collo="inboundCollo" :is-loading="isLoading" />
      </f7-tab>
      <f7-tab id="ibc-goods-receipts" class="page-content">
        <goods-receipts-table :goods-receipts="goodsReceipts" :is-loading="isLoading" />
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>
<script setup lang="ts">
import Navigation from '@components/AppNavigation.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import StringLabel from '@components/label/StringLabel.vue'
import { ID } from '@graphql/types'
import useLoading from '@composables/useLoading'
import { IInboundCollo } from '@graphql/inbound/types'
import { computed, onMounted, ref } from 'vue'
import useInbound from '@composables/useInbound'
import ColloDetailsCard from '@pages/inbound/components/ColloDetailsCard.vue'
import GoodsReceiptsTable from '@pages/inbound/components/GoodsReceiptsTable.vue'

const props = defineProps<{
  colloId: ID
}>()

const { isLoading } = useLoading()
const { getColloById } = useInbound()
const inboundCollo = ref<IInboundCollo>()

const goodsReceipts = computed(() => {
  if (inboundCollo.value) {
    return inboundCollo.value.goodsReceipts
  }

  return []
})

onMounted(async () => {
  inboundCollo.value = await getColloById({ id: props.colloId })
})
</script>
