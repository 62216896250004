<template>
  <data-table-card :items="goodsReceipts" :is-loading="isLoading">
    <template #no-items>
      <icon-with-text :icon="['far', 'empty-set']" title="No goods receipts available" />
    </template>
    <data-table>
      <template #header>
        <table-row>
          <label-cell element="th">#</label-cell>
          <label-cell element="th">Status</label-cell>
        </table-row>
      </template>
      <table-row
        v-for="goodsReceipt in goodsReceipts"
        :key="goodsReceipt.id"
        @click="onNavigateToGoodsReceipt(goodsReceipt)"
      >
        <label-cell>{{ goodsReceipt.id }}</label-cell>
        <label-cell>{{ goodsReceipt.stateString }}</label-cell>
      </table-row>
    </data-table>
  </data-table-card>
</template>
<script setup lang="ts">
import { IGoodsReceipt } from '@graphql/goodsReceipt/types'
import DataTableCard from '@components/data-table/DataTableCard.vue'
import IconWithText from '@components/IconWithText.vue'
import DataTable from '@components/data-table/DataTable.vue'
import TableRow from '@components/data-table/TableRow.vue'
import LabelCell from '@components/data-table/LabelCell.vue'
import { f7 } from 'framework7-vue'

withDefaults(
  defineProps<{
    goodsReceipts: IGoodsReceipt[]
    isLoading: boolean
  }>(),
  {
    goodsReceipts: () => [],
    isLoading: false
  }
)

const onNavigateToGoodsReceipt = async (goodsReceipt: IGoodsReceipt) => {
  await f7.views.main.router.navigate(`/goodsreceipt/${goodsReceipt.id}/`)
}
</script>
