import {
  faAbacus,
  faArrowDown,
  faArrowRight,
  faArrowsLeftRightToLine,
  faBarcode,
  faBarcodeScan,
  faBox,
  faBoxCheck,
  faBoxesStacked,
  faBoxFull,
  faBoxOpen,
  faBoxOpenFull,
  faCartFlatbedBoxes,
  faCartPlus,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faCircle,
  faCircleInfo,
  faClipboardCheck,
  faConveyorBeltBoxes,
  faConveyorBeltEmpty,
  faCubes,
  faEdit,
  faEmptySet,
  faExclamation,
  faExternalLink,
  faHandHoldingBox,
  faHandPaper,
  faHistory,
  faInbox,
  faInputNumeric,
  faLaughBeam,
  faList,
  faLocationCrosshairs,
  faLock,
  faMagnifyingGlassLocation,
  faMapMarker,
  faMapMarkerAlt,
  faMapMarkerSlash,
  faMapMarkerSmile,
  faMobile,
  faNfcSignal,
  faPalletBox,
  faPalletBoxes,
  faPersonCarry,
  faPersonDollyEmpty,
  faPlus,
  faPrint,
  faSave,
  faShovel,
  faSignOut,
  faSlidersH,
  faSquare,
  faTrash,
  faTruck,
  faTruckRampBox,
  faTurnDownRight,
  faUnlock,
  faUser,
  faUserCircle,
  faXmark,
  faPenSlash,
  IconDefinition
} from '@fortawesome/pro-regular-svg-icons'
import {
  faDolly,
  faDollyEmpty,
  faPartyHorn,
  faRotateRight,
  faSpinnerThird
} from '@fortawesome/pro-duotone-svg-icons'

export const icons: IconDefinition[] = [
  faAbacus,
  faArrowDown,
  faArrowRight,
  faArrowsLeftRightToLine,
  faBarcode,
  faBarcodeScan,
  faBox,
  faBoxCheck,
  faBoxesStacked,
  faBoxFull,
  faBoxOpen,
  faBoxOpenFull,
  faCartFlatbedBoxes,
  faCartPlus,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faCircle,
  faCircleInfo,
  faClipboardCheck,
  faConveyorBeltBoxes,
  faConveyorBeltEmpty,
  faCubes,
  faDolly,
  faDollyEmpty,
  faEdit,
  faEmptySet,
  faExclamation,
  faExternalLink,
  faHandHoldingBox,
  faHandPaper,
  faHistory,
  faInbox,
  faInputNumeric,
  faLaughBeam,
  faList,
  faLocationCrosshairs,
  faLock,
  faMagnifyingGlassLocation,
  faMapMarker,
  faMapMarkerAlt,
  faMapMarkerSlash,
  faMapMarkerSmile,
  faMobile,
  faNfcSignal,
  faPalletBox,
  faPalletBoxes,
  faPartyHorn,
  faPersonCarry,
  faPersonDollyEmpty,
  faPlus,
  faPrint,
  faRotateRight,
  faSave,
  faShovel,
  faSignOut,
  faSlidersH,
  faSpinnerThird,
  faSquare,
  faTrash,
  faTruck,
  faTruckRampBox,
  faUnlock,
  faUser,
  faUserCircle,
  faTurnDownRight,
  faXmark,
  faPenSlash
]
