<template>
  <f7-page :page-content="false">
    <navigation />
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#pack-process-batch-lines"> Todo ({{ packableItems.length }})</f7-link>
      <f7-link tab-link="#pack-process-batch-packed-colli"> Done ({{ doneCount }}) </f7-link>
      <f7-link tab-link="#pack-process-batch-details">Details</f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab
        id="pack-process-batch-lines"
        class="page-content ptr-content"
        :data-ptr-distance="55"
        :data-ptr-mousewheel="true"
        @ptr:refresh="onPullToRefresh"
        tab-active
      >
        <pull-to-refresh-preloader />
        <packable-items-card
          :lines="packableItems"
          :is-loading="isLoading"
          @bulk-print="onBulkPrint"
        />
      </f7-tab>
      <f7-tab id="pack-process-batch-packed-colli" class="page-content">
        <packed-colli-card
          v-if="pickBatch"
          :pick-batch="pickBatch as IPickBatch"
          :pack-station="packStation as IPackStation"
          :is-loading="isLoading"
        />
      </f7-tab>
      <f7-tab id="pack-process-batch-details" class="page-content">
        <pick-batch-details
          v-if="pickBatch"
          :pick-batch="pickBatch as IPickBatch"
          :is-loading="isLoading"
        />
      </f7-tab>
    </f7-tabs>
    <bulk-pack-popup :popup-opened="bulkPopupOpened" @closed="onBulkPrintPopupClosed" />
  </f7-page>
</template>
<script lang="ts" setup>
import { confirmThat, confirmYesNo } from '@/functions/dialog'
import Navigation from '@components/AppNavigation.vue'
import PickBatchDetails from '@components/pack/PickBatchDetails.vue'
import PackableItemsCard from '@components/pack/PackableItemsCard.vue'
import BulkPackPopup from '@components/pack/BulkPackPopup.vue'
import useLoading from '@composables/useLoading'
import usePack from '@composables/usePack'
import useSettings from '@composables/useSettings'
import { IPackableItem, IPackingList, IPackStation, ProcessEnum } from '@graphql/pack/types'
import { IPickBatch } from '@graphql/pick/types'
import { soundBoard } from '@services/sound'
import { toast } from '@services/toast'
import { f7 } from 'framework7-vue'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { eventBus } from '@/utilities/scanInput'
import { captureException } from '@sentry/vue'
import { perceptibleToast } from '@services/perceptibleToast'
import PullToRefreshPreloader from '@components/PullToRefreshPreloader.vue'
import { ID } from '@graphql/types'
import PackedColliCard from '@components/pack/PackedColliCard.vue'
import { IColloEdge } from '@graphql/shipment/types'
import { TypeName } from '@graphql/search/types'
import useProjectPack from '@composables/useProjectPack'

const props = defineProps<{
  loadCarrierId: ID
}>()

const packableItems = ref<Array<IPackableItem>>([])
const pickBatch = ref<Partial<IPickBatch>>() //TODO replace with load carrier? manuel pack has no LC's
const selectedPackableItem = ref<IPackableItem | null>(null)

const { isLoading, withLoading, withTextPreloaderAndNetworkErrorHandlingAsync, withAsyncLoading } =
  useLoading()

const { activeProject, removeProject } = useProjectPack()

const doneCount = computed<number>(() => {
  return packedColliCount.value
})

const {
  getPackableItemsByLoadCarrierId,
  markPacked,
  packedColli,
  lastPackedShipment,
  lastPackedCollo,
  packedColliCount,
  getPackingListByReferenceAndLoadCarrierId,
  startPackingControl,
  printBulkOnPackStation,
  prePackSortCompartment,
  getMatchingBarcode,
  printShipmentDocuments
} = usePack()
const { packStation } = useSettings()

const unsubscribe = eventBus.on('scanInput', async (event) => {
  await onScanInput(String(event.payload))
})

const refreshPackableItems = async () => {
  try {
    const response = await getPackableItemsByLoadCarrierId({ loadCarrierId: props.loadCarrierId })
    pickBatch.value = response.pickBatch
    packableItems.value = response.packableItems as Array<IPackableItem>
  } catch (e: any) {
    await toast.error(e.message).open()
    await f7.views.main.router.navigate('/outbound-sort/')
    throw e
  }
}

withLoading(refreshPackableItems)

const confirmBulkPrint = async (barcode: string) => {
  try {
    await onConfirmBulkPrint(barcode)
  } catch (e: any) {
    await perceptibleToast.error(e.message)
    selectedPackableItem.value = null
    bulkPopupOpened.value = false
    captureException(e)
  }
}

const onScanInput = async (barcode: string) => {
  if (selectedPackableItem.value) {
    await confirmBulkPrint(barcode)

    return
  }

  await withAsyncLoading(async () => {
    const result = await getPackingListByReferenceAndLoadCarrierId(barcode, props.loadCarrierId)

    if (!result) {
      return
    }

    const packingList = result as IPackingList

    try {
      if (packingList.process === ProcessEnum.SORT_COMPARTMENT_PACK) {
        await prePackSortCompartment(packingList.shipment.id, barcode, packingList)

        await movePrePackedItemToDone(packingList.shipment.id, barcode)
        return
      }

      if (packingList.type > 0) {
        await startPackingControl(packingList, barcode)
        return
      }

      await printShipmentDocuments(packingList.shipment.id, barcode, packingList)

      await soundBoard.playSuccessSound()

      await refreshPackableItems()
    } catch (e: any) {
      await perceptibleToast.error(e.message)
    }
  })
}

const movePrePackedItemToDone = async (identifier: ID, reference: string) => {
  if (!pickBatch.value) {
    throw Error('Pick batch not found!')
  }

  const index = packableItems.value.findIndex((pi) => pi.packableIdentity.name === reference)
  if (index != -1) {
    packableItems.value.splice(index, 1)
  }

  if (!Object.prototype.hasOwnProperty.call(pickBatch.value, 'packedColli')) {
    pickBatch.value.packedColli = {
      edges: [],
      totalCount: 0
    }
  }

  const colloEdge: IColloEdge = {
    __typename: 'ColloEdge',
    cursor: identifier,
    node: {
      __typename: TypeName.Collo,
      id: identifier,
      packReference: reference
    }
  }

  pickBatch.value.packedColli.edges.push(colloEdge)
  pickBatch.value.packedColli.totalCount += 1
}

const bulkPopupOpened = ref<boolean>(false)
const onBulkPrint = async (packableItem: IPackableItem) => {
  selectedPackableItem.value = packableItem
  bulkPopupOpened.value = true
}

const onBulkPrintPopupClosed = () => {
  selectedPackableItem.value = null
  bulkPopupOpened.value = false
}

const onConfirmBulkPrint = async (barcode: string) => {
  if (!selectedPackableItem.value) {
    toast.error('No items selected to confirm by barcode scan!').open()
    return
  }

  const barcodeMatches = getMatchingBarcode(selectedPackableItem.value, barcode)
  if (!barcodeMatches) {
    toast.error(`Scanned barcode "${barcode}" does not match with swiped item!`).open()
    return
  }

  await printBulkOnPackStation(
    selectedPackableItem.value,
    packStation.value as IPackStation,
    props.loadCarrierId
  )

  const defaultCallback = async () => {
    await withTextPreloaderAndNetworkErrorHandlingAsync({
      title: 'Refreshing batch status...',
      maxRetries: 3,
      callback: async () => {
        await refreshPackableItems()
      }
    })

    selectedPackableItem.value = null
    bulkPopupOpened.value = false
  }

  const noCallback = async () => {
    await confirmYesNo({
      title: 'Do you want to re-print all documents?',
      yesButtonCallback: async () => await onConfirmBulkPrint(barcode),
      noButtonCallback: defaultCallback
    })
  }

  await confirmYesNo({
    title:
      'Confirm that all documents have been printed. This operation can take a while depending on the number of shipments.',
    yesButtonCallback: defaultCallback,
    noButtonCallback: noCallback
  })
}

const onPullToRefresh = async (e: any) => {
  await withAsyncLoading(async () => {
    await refreshPackableItems()
    await e.detail() //equivalent of done(), tabs have no `native` support for pull to refresh
  })
}

watch(pickBatch, (newValue, oldValue) => {
  if (!newValue) {
    return
  }
  if (newValue === oldValue) {
    return
  }

  packedColli.value = newValue.packedColli
})

watch(
  packableItems,
  async (newValue) => {
    if (
      (lastPackedCollo.value || lastPackedShipment.value || selectedPackableItem.value) &&
      newValue.length === 0
    ) {
      await confirmThat('Confirm that all items are done!', async () => {
        try {
          const response = await markPacked({ loadCarrierId: props.loadCarrierId })

          if (response) {
            await removeProject(activeProject.value!.id)

            await perceptibleToast.finishedTask('Hooorray, all item done!')

            await f7.views.main.router.navigate('/pack/')

            return
          }

          await perceptibleToast.error(
            'Something went wrong while marking the pick batch as packed!'
          )
        } catch (e: any) {
          await perceptibleToast.error(e.message)
        }
      })
    }
  },
  {
    immediate: true,
    deep: true
  }
)

onBeforeUnmount(() => {
  unsubscribe()
})
</script>
