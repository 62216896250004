<!--This component manages the subscription for the search bar.-->
<!--We avoid using only onUnmounted for unsubscribing, as Framework7 retains multiple pages in memory,-->
<!--making onUnmounted unreliable for cleanup in this case.-->
<template>
  <f7-page @page:afterin="onPageAfterIn" @page:beforeout="onPageBeforeOut">
    <slot :search-subscriber="searchBarScanSubscriber"></slot>
  </f7-page>
</template>

<script setup lang="ts">
import { eventBus, SyncEventCallback } from '@/utilities/scanInput'
import { onUnmounted } from 'vue'
import { generateUniqueIdentifier } from '@/utilities/string'

const props = withDefaults(
  defineProps<{
    scanCallback?: SyncEventCallback
    isPaused?: boolean
  }>(),
  {
    scanCallback: () => {},
    isPaused: true
  }
)

const emits = defineEmits<{
  (e: 'active-changed', isActive: boolean): void
}>()

let searchBarScanSubscriber = eventBus.subscribe(
  generateUniqueIdentifier(),
  'scanInput',
  props.scanCallback,
  props.isPaused
)

onUnmounted(() => {
  eventBus.unsubscribe(searchBarScanSubscriber)
})

const onPageAfterIn = () => {
  searchBarScanSubscriber.resume()
  emits('active-changed', true)
}

const onPageBeforeOut = () => {
  searchBarScanSubscriber.pause()
  emits('active-changed', false)
}
</script>
