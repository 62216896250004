<template>
  <page-with-search-subscriber :scan-callback="onScan">
    <app-navigation title="Kardex putaway" />
    <f7-card>
      <f7-card-header>Source</f7-card-header>
      <f7-card-content>
        <div class="source">
          <div class="prefix-block">
            <prefix-block large :prefix="kardexItem.compartmentType" />
          </div>
          <div class="description">
            {{ kardexItem.name }}
            <div class="code">
              {{ kardexItem.code }}
            </div>
          </div>
          <quantity-label class="quantity-label" :quantity="kardexItem.quantity" />
        </div>
      </f7-card-content>
    </f7-card>
    <f7-card>
      <f7-card-header>Kardex</f7-card-header>
      <f7-card-content>
        <scan-barcode-animation v-if="!kardex" title="Scan kardex" />
        <div class="kardex" v-if="kardex">{{ kardex }}</div>
      </f7-card-content>
    </f7-card>
  </page-with-search-subscriber>
</template>

<script setup lang="ts">
import { IKardexItem } from '@graphql/kardex/types'
import AppNavigation from '@components/AppNavigation.vue'
import QuantityLabel from '@components/QuantityLabel.vue'
import PrefixBlock from '@components/PrefixBlock.vue'
import ScanBarcodeAnimation from '@components/ScanBarcodeAnimation.vue'
import { ref } from 'vue'
import { IEvent } from '@/utilities/scanInput'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'
import { perceptibleToast } from '@services/perceptibleToast'
import useInbound from '@composables/useInbound'
import { f7 } from 'framework7-vue'
import { captureException } from '@sentry/vue'

const { putawayToKardex } = useInbound()

const kardex = ref<string | null>(null)

const props = defineProps<{
  kardexProjectId: number
  kardexItem: IKardexItem
}>()

const onScan = async (event: IEvent) => {
  const query = String(event.payload)

  if (query.match(/^K\d{3}$/)) {
    await onKardexScan(query)
    return
  }

  await perceptibleToast.error('Scan a valid kardex barcode.')
}

const onKardexScan = async (query: string) => {
  kardex.value = query

  try {
    await putawayToKardex({
      kardexProjectId: props.kardexProjectId,
      goodsReceiptLineId: props.kardexItem.identifier,
      kardexBarcode: query
    })

    f7.view.main.router.navigate(`/kardex/${props.kardexProjectId}/`, {
      reloadCurrent: true
    })
  } catch (error) {
    captureException(error)

    await perceptibleToast.error(error.message)
  }
}
</script>

<style scoped lang="less">
.description,
.quantity-label {
  font-size: 21px;
}

.kardex {
  display: grid;
  place-items: center;
  width: 100%;
  font-weight: bold;
  font-size: 32px;
}

.source {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.description {
  text-align: center;
}

.code {
  font-size: 14px;
}
</style>
