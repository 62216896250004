<template>
  <f7-view>
    <page-with-search-subscriber v-slot="{ searchSubscriber }" :page-content="false">
      <navigation show-search-icon v-slot="{ searchId }" hide-back-button>
        <search-bar
          :search-id="searchId"
          :scan-subscriber="searchSubscriber"
          :search-strategy="searchStrategy"
          @no-result="onNoResult"
          :search-callback="onSearch"
          expandable
        />
      </navigation>
      <f7-page-content ptr :ptr-distance="50" ptr-mousewheel @ptr:refresh="onPullToRefresh">
        <pull-to-refresh-preloader />
        <location-header
          :is-loading="isLoading"
          :location-name="line.location.name"
          :is-scanned="isLocationScanned"
        />
        <f7-card :class="{ 'skeleton-text': isLoading }">
          <f7-card-content>
            <f7-list media-list>
              <pick-list-item
                v-for="lineItem in line.lineItems"
                :key="lineItem.pickBatchLineItemId"
                :line-item="lineItem"
                @article-popup-open="articleDetailPopupOpened = true"
                @quantity-enter="onEnterQuantity(lineItem)"
                @print-label="onArticleLabelPrint(lineItem, searchSubscriber)"
              />
            </f7-list>
          </f7-card-content>
        </f7-card>

        <progress-card :progress="progression" :is-loading="isLoading" />
      </f7-page-content>
      <f7-popup
        :opened="selectionPopupOpened"
        class="search-results-popup"
        @popup:closed="selectionPopupOpened = false"
        :close-on-ecape="false"
        :close-by-backdrop-click="false"
        :tablet-fullscreen="true"
        :swipe-to-close="false"
      >
        <default-popup-header title="Select Location or article" />
        <search-result-list
          :items="selectionPopupResults"
          :search-strategy="searchStrategy"
          :is-loading="isLoading"
          :key="1"
        />
      </f7-popup>
      <article-detail-popup
        v-if="activeArticle"
        :article="activeArticle"
        :is-loading="isLoading"
        :popup-opened="articleDetailPopupOpened"
        @popup-closed="articleDetailPopupOpened = false"
      />

      <quantity-popup
        v-if="selectedLineItem"
        :popup-opened="quantityPopupOpened"
        @popup-closed="quantityPopupOpened = false"
        :scanned-item="scannedItem"
        :article="selectedLineItem?.article"
        :max-value="selectedLineItem?.quantityTodo"
        @quantity-entered="(value) => onPickedQuantitiesEntered(value, selectedLineItem)"
      />

      <quantity-popup
        v-if="selectedLineItem"
        :popup-opened="quantityRequiredPopupOpened"
        @popup-closed="quantityRequiredPopupOpened = false"
        :scanned-item="scannedItem"
        :needs-confirmation-scan="false"
        :article="selectedLineItem?.article"
        :max-value="selectedLineItem?.quantityTodo"
        :value="selectedLineItem?.quantityTodo"
        @quantity-entered="(value) => onPickedQuantitiesEntered(value, selectedLineItem)"
      />

      <f7-fab position="center-bottom" v-if="canAddLoadCarrier && isLoading">
        <f7-preloader />
      </f7-fab>
      <f7-fab
        position="center-button"
        v-if="canAddLoadCarrier && !isLoading"
        @click="onAddLoadCarrier"
      >
        <font-awesome-icon :icon="['far', 'pallet-boxes']" />
      </f7-fab>
      <scan-to-print-popup
        v-if="lineItemToPrintDocumentsFor"
        v-model="printPopupOpened"
        :identifier="lineItemToPrintDocumentsFor.pickBatchLineItemId"
        :type-name="TypeName.PickBatchLineItem"
        :document-kind="DocumentKind.ARTICLE_LABELS"
        @on-success="onSuccessfulPrint(searchSubscriber)"
      />
    </page-with-search-subscriber>
  </f7-view>
</template>
<script lang="ts" setup>
import { NonEmptyArray } from '@/typings/types'
import ArticleDetailPopup from '@components/Article/DetailPopup.vue'
import Navigation from '@components/AppNavigation.vue'
import PickListItem from '@components/pick/PickListItem.vue'
import QuantityPopup from '@components/QuantityPopup.vue'
import SearchBar from '@components/search/SearchBar.vue'
import SearchResultList from '@components/search/ResultList.vue'
import useArticle from '@composables/useArticle'
import useLoading from '@composables/useLoading'
import usePickBatchLine from '@composables/usePickBatchLine'
import useProjectPick from '@composables/useProjectPick'
import {
  ILine,
  ILineItem,
  IPickBatchLineItemInput,
  IPickBatchPartition,
  IProgress
} from '@graphql/pick/types'
import { IOnSearchCallbackContext, SearchStrategy } from '@services/search/search'
import { soundBoard } from '@services/sound'
import { toast } from '@services/toast'
import { UnionTypeSearchResult } from '@store/modules/search/types'
import { computed, ref, watch } from 'vue'
import { perceptibleToast } from '@services/perceptibleToast'
import ProgressCard from '@components/pick/ProgressCard.vue'
import LocationHeader from '@components/pick/LocationHeader.vue'
import DefaultPopupHeader from '@components/DefaultPopupHeader.vue'
import { f7 } from 'framework7-vue'
import { confirmYesNo } from '@/functions/dialog'
import { sleep } from '@/utilities/generic'
import PullToRefreshPreloader from '@components/PullToRefreshPreloader.vue'
import { ILocation } from '@graphql/location/types'
import { TypeName } from '@graphql/search/types'
import { DocumentKind } from '@graphql/document/types'
import ScanToPrintPopup from '@components/document/ScanToPrintPopup.vue'
import { Subscriber } from '@/utilities/scanInput'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'
import { IArticle } from '@graphql/article/types'
import useLocation from '@composables/useLocation'

const props = defineProps<{
  modelValue: ILine
  progression: IProgress
  process: string
  loadCarrierLineCount: number
  pickBatchPartition: IPickBatchPartition
}>()

const emit = defineEmits([
  'completed',
  'no-result',
  'refreshPickBatch',
  'update:model-value',
  'refreshState'
])

const selectionPopupOpened = ref<boolean>(false)
const printPopupOpened = ref<boolean>(false)
const selectionPopupResults = ref<UnionTypeSearchResult[]>([])
const articleDetailPopupOpened = ref<boolean>(false)
const quantityPopupOpened = ref<boolean>(false)
const quantityRequiredPopupOpened = ref<boolean>(false)
const selectedLineItem = ref<ILineItem | null>(null)
const lineItemToPrintDocumentsFor = ref<ILineItem | null>(null)
const scannedItem = ref<IArticle | null>(null)

const { savePickBatchLineState, addLoadCarrier } = useProjectPick()
const { withAsyncLoading, isLoading } = useLoading()
const { activeArticle, isArticleBarcode, isArticleBatchBarcode } = useArticle()
const {
  isMatchingArticle,
  isMatchingArticleBatch,
  isMatchingLocation,
  quantityFulFilled,
  isQuantityFulfilled
} = usePickBatchLine()

const { isLocationName } = useLocation()

const searchStrategy = ref<string>(SearchStrategy.PickLine)
const currentLocation = ref<ILocation | undefined>()

const line = computed<ILine>(() => props.modelValue)
const lineItems = computed<ILineItem[]>(() => (line.value ? line.value.lineItems : []))
const loadCarrierLineCount = computed(() => props.loadCarrierLineCount)

const completedItems = computed<ILineItem[]>(() => {
  return lineItems.value.filter((lineItem) => {
    // cannot use `isQuantityFulfilled` here, causes behavior issues
    return lineItem.quantityTodo - (lineItem.quantityDone + lineItem.quantityMissed) === 0
  })
})

const isLocationScanned = computed<boolean>(() => isCurrentLocation(line.value.location.name))

const articlesScanned = computed<boolean>(
  () => completedItems.value.length === lineItems.value.length
)

const isComplete = computed<boolean>(() => isLocationScanned.value && articlesScanned.value)
const canAddLoadCarrier = computed(
  () =>
    loadCarrierLineCount.value > 0 ||
    (isLocationScanned.value &&
      lineItems.value.filter((li) => li.quantityDone > 0 && li.quantityDone < li.quantityTodo)
        .length > 0)
)

const isCurrentLocation = (locationName?: string) => {
  if (!currentLocation.value || !locationName) {
    return false
  }
  return currentLocation.value.name === locationName
}

const onSearch = async (context: IOnSearchCallbackContext) => {
  context.stopAfterCallback = true

  selectionPopupResults.value = []
  selectionPopupOpened.value = false

  if (isMatchingLocation(line.value, context.query)) {
    currentLocation.value = line.value.location
    await soundBoard.playSearchSingleHit()
    return
  }

  const lineItem = lineItems.value.find(
    (lineItem) =>
      isMatchingArticle(lineItem, context.query) || isMatchingArticleBatch(lineItem, context.query)
  )

  if (lineItem) {
    await onArticle(lineItem)
    return
  }

  await onNoResult(context.query)

  return
}

const onNoResult = async (query: string) => {
  await perceptibleToast.error(
    `The scan did not match any item(s) on this pick line for query: "${query}".`
  )
}

const onConfirmQuantityEntered = async (result: IArticle) => {
  if (!selectedLineItem.value) {
    return
  }

  if (result.id !== selectedLineItem.value.article.id) {
    await perceptibleToast.error('Article does not match line where you enter quantity for!')
    return
  }

  scannedItem.value = result

  await soundBoard.playSuccessSound()
}

const onArticle = async (lineItem: ILineItem) => {
  if (lineItem.article.articleUnit.displayUnit !== 'pc') {
    selectedLineItem.value = lineItem
    scannedItem.value = lineItem.article
    quantityRequiredPopupOpened.value = true

    await soundBoard.playSearchSingleHit()
    return
  }

  if (isQuantityFulfilled(lineItem)) {
    return
  }

  if (quantityPopupOpened.value) {
    await onConfirmQuantityEntered(lineItem.article)
    return
  }

  lineItem.quantityDone++
  await soundBoard.playSearchSingleHit()
}

function getPopulatedItemsInput(quantityMustBeFulfilled = true) {
  const itemsInput: IPickBatchLineItemInput[] = []

  line.value.lineItems.forEach((lineItem) => {
    if (!quantityFulFilled(lineItem) && quantityMustBeFulfilled) {
      const message = `Quantity for ${lineItem.article.primaryBarcode} (${lineItem.article.name}) is not fulfilled!`
      toast.error(message, undefined, true).open()
      throw Error(message)
    }

    itemsInput.push({
      pickBatchLineItemId: lineItem.pickBatchLineItemId,
      quantityDone: lineItem.quantityDone,
      quantityMissed: lineItem.quantityMissed,
      quantityReturned: 0
    })
  })

  return itemsInput as NonEmptyArray<IPickBatchLineItemInput>
}

const onCompleted = async () => {
  try {
    await withAsyncLoading(async () => {
      const response = await savePickBatchLineState({
        pickBatchLineId: line.value.pickBatchLineId,
        pickBatchLineItemsInput: getPopulatedItemsInput()
      })

      if (!response) {
        return
      }

      await emit('completed', line.value)
    })
  } catch (e: any) {
    await toast.error(e.message).open()
    throw e
  }
}

const onPickedQuantitiesEntered = async (value: number, lineItem: ILineItem) => {
  if (value > lineItem.quantityTodo) {
    await toast.error('Cannot enter more quantity then to pick from this location!').open()
    return
  }

  lineItem.quantityDone = value

  quantityPopupOpened.value = false
  quantityRequiredPopupOpened.value = false
  scannedItem.value = null
  selectedLineItem.value = null
}

const onEnterQuantity = async (lineItem: ILineItem) => {
  selectedLineItem.value = lineItem
  quantityPopupOpened.value = true
}

const onAddLoadCarrier = async () => {
  await confirmYesNo({
    title:
      'Are you sure that you want to proceed with finalizing this load carrier and add another?',
    yesButtonCallback: async () => {
      await withAsyncLoading(async () => {
        try {
          const result = await addLoadCarrier({
            pickBatchLineId: line.value.pickBatchLineId,
            pickBatchLineItemsInput: getPopulatedItemsInput(false)
          })

          if (result.addLoadCarrier.startVerificationProcess) {
            await f7.views.main.router.navigate('/pick/confirm-picked/', {
              props: {
                pickBatchPartition: props.pickBatchPartition,
                afterVerificationAction: 'new-load-carrier'
              }
            })
            return
          }

          await perceptibleToast.error('Adding load carrier failed!')
        } catch (e: any) {
          await perceptibleToast.error(e.message)
        }
      })
    }
  })
}

const onPullToRefresh = async (done: () => void) => {
  await emit('refreshState', true)
  await sleep(200) // simulate api call...
  await done()
}

const onArticleLabelPrint = (lineItem: ILineItem, scanSubscriber: Subscriber) => {
  scanSubscriber.pause()
  lineItemToPrintDocumentsFor.value = lineItem
  printPopupOpened.value = true
}

const onSuccessfulPrint = (scanSubscriber: Subscriber) => {
  scanSubscriber.resume()
  lineItemToPrintDocumentsFor.value = null
}

watch(isComplete, async (newValue: boolean, oldValue: boolean) => {
  if (newValue && !oldValue) {
    await onCompleted()
  }
})
</script>
<style scoped>
.fab {
  position: absolute;
  bottom: 6rem;
  right: 1rem;
}
</style>
